import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Colours from '../definitions/Colours';
import getBGColourClassName from '../functions/getBGColourClassName';


function Dot({ className, backgroundColour, ...otherProps }) {
    const bgColorClassName = getBGColourClassName(backgroundColour);

    return (
        <Container
            className={classNames('rh-border-radius-50p', bgColorClassName, className)}
            {...otherProps}
        />
    );
}

Dot.propTypes = {
    className: PropTypes.string,
    backgroundColour: PropTypes.string,
};

Dot.defaultProps = {
    className: undefined,
    backgroundColour: Colours.BLUEBERRY,
};

const Container = styled.div`
    width: 1em;
    height: 1em;
`;

export default Dot;
