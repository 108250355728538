import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { ImageSizes } from '@ratehub/base-ui';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import withLink from './withLink';
import VisualElement from './VisualElement';


const VisualElementWithLink = withLink(VisualElement);

/* STYLE CONSTS */
const MOBILE_LAYOUT_SWITCH = '50rem';

const LOGO_SIZES = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};

function LogoBlockItem({ 
    imageCollection, 
    imageSrc, 
    imageAlt = '', 
    imageHref, 
    className, 
    size, 
    ...otherProps 
}) {
    const Image = imageHref ? VisualElementWithLink : VisualElement;

    return (
        <Container className={`logo-size-${size}`}>
            <Image
                imageCollection={imageCollection
                    ? createImageCollection(imageCollection, size)
                    : undefined
                }
                imageUrl={imageSrc}
                alt={imageAlt}
                href={imageHref}
                target={imageHref ? '_blank' : undefined}
                isLazy
                className={classNames(
                    'rh-display-block rh-height-100p rh-opacity-0_6',
                    className,
                )}
                {...otherProps}
            />
        </Container>
    );
}

LogoBlockItem.propTypes = {
    imageCollection: ImageCollectionPropType,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    imageHref: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(LOGO_SIZES)).isRequired,
};

// Heights in px so we can do calculations in JS
//  below in createImageCollection
const HEIGHTS_BY_SIZE = {
    [LOGO_SIZES.small]: 35,  // ~2.5em
    [LOGO_SIZES.medium]: 44, // ~3.125em
    [LOGO_SIZES.large]: 51,  // ~3.65em
};

const Container = styled.li`
    list-style: none;

    &.logo-size-small {
        height: ${HEIGHTS_BY_SIZE[LOGO_SIZES.small]}px;
    }

    &.logo-size-medium {
        height: ${HEIGHTS_BY_SIZE[LOGO_SIZES.medium]}px;
    }

    &.logo-size-large {
        height: ${HEIGHTS_BY_SIZE[LOGO_SIZES.large]}px;
    }

    a {
        &:hover,
        &:focus {
            img {
                opacity: 1;
            }
        }
    }
`;

function createImageCollection(imageCollection, size) {
    const sizes = imageCollection.sizes;

    const targetSize = size === 'large'
        ? sizes?.[ImageSizes.S]
        : sizes?.[ImageSizes.XS];

    const defaultImageSize = sizes[ImageSizes.FULL];
    
    // Since we set the height of our items to look nice as a group, 
    //  we need to calculate the width as an aspect ratio to avoid SSR jank. 
    //  Otherwise images render server-side as way larger than we want.
    // Calculation: (Original width / Original height) * New height = New Width
    const aspectRatio = (targetSize?.width ?? defaultImageSize.width) / (targetSize?.height ?? defaultImageSize.height);
    const heightForSelectedSize = HEIGHTS_BY_SIZE[size];
    const calculatedWidth = Math.round(aspectRatio * heightForSelectedSize);

    return {
        sizes: [
            {
                url: targetSize?.url ?? defaultImageSize.url,
                breakpoint: `(min-width: ${MOBILE_LAYOUT_SWITCH})`,
            },
        ],
        fallback: targetSize?.url ?? defaultImageSize.url,
        width: calculatedWidth,
        height: heightForSelectedSize,
        alt: imageCollection.alt ?? imageCollection.title,
        mime: imageCollection.mime,
    };
}

LogoBlockItem.blockKey = 'rh/logo-block-item';

export default LogoBlockItem;
export { LOGO_SIZES };