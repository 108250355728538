import { css } from 'styled-components';


import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';


//Styles used in AnchorWithIcon and TextAnchor

const TRANSITION_DURATION = '300ms';

const VARIANTS = {
    LIGHT: 'light', // Glam & Pingu
    DARK: 'dark', // Glam & Pingu
    BLUEBERRY: 'blueberry', // Glamazon ONLY
};

const STATES = {
    DEFAULT: 'default',
    HOVER: 'hover',
    ACTIVE: 'active',
    DISABLED: 'disabled',
};

const GLAMAZON_STATE_COLOURS = {
    [VARIANTS.LIGHT]: {
        [STATES.DEFAULT]: Colours.COCONUT,
        [STATES.HOVER]: Colours.BLUEBERRY_LIGHT,
        [STATES.ACTIVE]: Colours.BLUEBERRY,
        [STATES.DISABLED]: Colours.STONE_DARKEST,
    },
    [VARIANTS.DARK]: {
        [STATES.DEFAULT]: Colours.BLUEBERRY_DARK,
        [STATES.HOVER]: Colours.BLUEBERRY,
        [STATES.ACTIVE]: Colours.BLUEBERRY_DARKEST,
        [STATES.DISABLED]: Colours.STONE_DARKEST,
    },
    [VARIANTS.BLUEBERRY]: {
        [STATES.DEFAULT]: Colours.BLUEBERRY,
        [STATES.HOVER]: Colours.BLUEBERRY_DARK,
        [STATES.ACTIVE]: Colours.BLUEBERRY_DARKEST,
        [STATES.DISABLED]: Colours.STONE_DARKEST,
    },
};

const GLAMAZON_STYLES = css`
    display: flex;
    align-items: center;

    outline: none;

    font-family: ${Typography.FONT_FAMILY_STACK};
    font-size: ${Sizes.FONTS.L};
    font-weight: ${Typography.WEIGHTS.REGULAR};

    text-decoration: none;
    text-transform: lowercase;

    transition: color ${TRANSITION_DURATION};

    ${props => !props.disabled && `
        color: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.DEFAULT]};

        &:hover,
        &:focus {
            color: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.HOVER]};

            > .text-wrapper {
                box-shadow: inset 0 -2px ${GLAMAZON_STATE_COLOURS[props.variant][STATES.HOVER]};
            }

            > .icon {
                stroke: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.HOVER]};
            }
        }

        &:active {
            color: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.ACTIVE]};

            > .text-wrapper {
                box-shadow: inset 0 -2px ${GLAMAZON_STATE_COLOURS[props.variant][STATES.ACTIVE]};
            }

            > .icon {
                stroke: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.ACTIVE]};
            }
        }

        > .icon {
            stroke: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.DEFAULT]}
        }
    `}

    ${props => props.disabled && `
        color: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.DISABLED]};

        > .icon {
            stroke: ${GLAMAZON_STATE_COLOURS[props.variant][STATES.DISABLED]};
        }
    `}

    /* Arrow Animation */
    ${props => !props.disabled && props.isIconArrow && `
        > .icon {
            /* transitioning left/right instead of transform so we don't break
            the rotate() transform on the arrow */

            position: relative;
            transition: left 200ms ease-out;

            left: 0;
        }
        &:hover {
            > .icon {
                left: 10px;
            }
        }
    `}

    > .text-wrapper {
        margin-right: ${Sizes.SPACING.EIGHTH};
        box-shadow: inset 0 -2px ${Colours.TRANSPARENT}; /* fake underline */
        transition: box-shadow ${TRANSITION_DURATION};
    }
`;

export { GLAMAZON_STYLES, VARIANTS };
