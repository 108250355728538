import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { rgba } from 'polished';

import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import MessagePropType from '../definitions/MessagePropType';
import { BOX_SHADOW_BLUEBERRY } from '../definitions/SharedInputStyles';
import RemoveDefaultButtonStyles from '../styles/RemoveDefaultButtonStyles';
import IconMinus from './icons/IconMinus';
import IconPlus from './icons/IconPlus';
import IconGarbage from './icons/IconGarbage';


function AccordionBlueberryHeader({ isExpanded, onClick, onDelete, title }) {
    const intl = useIntl();

    return (
        <Container className="accordion-fieldset-header">
            <div
                className="header-button"
                role="button"
                tabIndex="0"
                onClick={onClick}
                /* Accordions should be toggled with either Enter or Space */
                onKeyDown={(evt) => (evt?.key === 'Enter' || evt?.key === ' ') && onClick(evt)}
                data-name="toggle-accordion"

                aria-label={intl.formatMessage(isExpanded ? MESSAGES.COLLAPSE : MESSAGES.EXPAND)}
            >
                <Choose>
                    <When condition={isExpanded}>
                        <IconMinus
                            className="expand-icon rh-icon-m"
                            outlineWidth="0"
                            stroke={Colours.BLUEBERRY}
                        />
                    </When>
                    <Otherwise>
                        <IconPlus
                            className="expand-icon rh-icon-m"
                            outlineWidth="0"
                            stroke={Colours.BLUEBERRY}
                        />
                    </Otherwise>
                </Choose>

                <h3 className="title rh-title-m weight-regular rh-my-0 rh-mr-0 rh-ml-1">
                    {title}
                </h3>
            </div>

            <If condition={onDelete}>
                <button
                    className="delete-button"
                    type="button"
                    onClick={onDelete}
                    data-name="delete-accordion"

                    aria-label={intl.formatMessage(MESSAGES.DELETE)}
                >
                    <IconGarbage
                        className="delete-icon rh-icon-xs"
                        outlineWidth="0"
                        stroke={Colours.BLUEBERRY_DARK}
                    />
                </button>
            </If>
        </Container>
    );
}

AccordionBlueberryHeader.propTypes = {
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    title: MessagePropType.isRequired,
};

AccordionBlueberryHeader.defaultProps = {
    isExpanded: undefined,
    onClick: undefined,
    onDelete: undefined,
};


const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;

    overflow: hidden;
    width: 100%;

    padding: ${Sizes.SPACING.ONE};
    background-color: ${rgba(Colours.BLUEBERRY_LIGHTEST, 0.5)};

    border-left: 2px solid ${Colours.BLUEBERRY};
    box-sizing: border-box;

    > .header-button {
        flex-grow: 1;

        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        overflow: hidden;
        width: 100%;

        ${RemoveDefaultButtonStyles};
        cursor: pointer;

        > .expand-icon {
            flex: ${Sizes.ICONS.M} 0 0;
        }

        > .title {
            flex-grow: 1;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            line-height: 1.2;
            color: ${Colours.BLUEBERRY_DARK};

            transition: color 300ms;
        }

        &:hover {
            > .title {
                color: ${Colours.BLUEBERRY};
            }
        }

        &:active {
            > .expand-icon {
                stroke: ${Colours.BLUEBERRY_DARKEST};
            }

            > .title {
                color: ${Colours.BLUEBERRY_DARKEST};
            }
        }
    }

    > .delete-button {
        flex: ${Sizes.ICONS.M} 0 0;
        margin-left: ${Sizes.SPACING.ONE};

        ${RemoveDefaultButtonStyles};
        cursor: pointer;

        &:hover,
        &:focus {
            > .delete-icon {
                stroke: ${Colours.BLUEBERRY};
            }
        }

        &:active {
            > .delete-icon {
                stroke: ${Colours.BLUEBERRY_DARKEST};
            }
        }
    }

    &:focus-within {
        box-shadow: ${BOX_SHADOW_BLUEBERRY};
    }
`;


const MESSAGES = defineMessages({
    EXPAND: {
        id: 'base-ui.AccordionFieldset.expand',
        defaultMessage: 'Expand accordion',
    },
    COLLAPSE: {
        id: 'base-ui.AccordionFieldset.collapse',
        defaultMessage: 'Collapse accordion',
    },
    DELETE: {
        id: 'base-ui.AccordionFieldset.delete',
        defaultMessage: 'Delete accordion',
    },
    DELETE_SCREEN_READER: {
        id: 'base-ui.AccordionFieldset.deleteScreenReader',
        defaultMessage: 'Delete confirmation modal',
    },
});

export default AccordionBlueberryHeader;
