import STREET_DIRECTION_OPTIONS from '../definitions/StreetDirections';
import STREET_TYPE_OPTIONS from '../definitions/StreetTypes';


/**
 * Extracts the matching street option between a list of street options and the street name components.
 *
 * @param {string[]} streetOptions - The list of possible street options.
 * @param {string[]} streetNameComponents - The list of street name components to match against.
 * @returns {string|null} The value of the matched street option if found, otherwise null.
 */
function extractStreetOptionFromList(streetOptions, streetNameComponents) {
    let extractedStreetOption = null;

    // using for instead of forEach to allow for early exit
    for (let index = 0; index < streetNameComponents.length; index++) {
        // try to find a match in the street options from the current street component
        const matchingOption = streetOptions.find(
            opt => opt.value.toUpperCase() === streetNameComponents[index].toUpperCase(),
        );

        if (matchingOption) {
            extractedStreetOption = matchingOption.value;
            break;
        }
    }

    return extractedStreetOption;
}

/**
 * Extract the pieces which compose a street name.
 * @param {string} value full name of the street to extract from.
 * @returns {object}
 */
export default function extractStreetNameDetails(value) {
    // English route format assumed to be: <street name> [<type>] [<direction>]
    // French route format assumed to be: [<type>] <street name> [<direction>]
    const streetNameComponents = (value || '').split(' ');

    const streetDirection = extractStreetOptionFromList(STREET_DIRECTION_OPTIONS, streetNameComponents);
    const streetType = extractStreetOptionFromList(STREET_TYPE_OPTIONS, streetNameComponents);
    
    // Filter out the extracted options from the original array
    const streetName = streetNameComponents.filter(
        component => ![ streetDirection?.toUpperCase(), streetType?.toUpperCase() ].includes(component.toUpperCase()),
    ).join(' ');

    return {
        streetDirection,
        streetType,
        streetName,
    };

}
