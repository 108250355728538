import { useEffect } from 'react';
import { when, comparer } from 'mobx';

import debouncedReaction from '../functions/debouncedReaction';
import useForm from './useForm';
import useFormComputed from './useFormComputed';


function useFormComputedAsync({
    name,
    nameOfIsFetching,
    nameOfError,
    nameOfHasFetched,

    initialValue,

    getFormValues,      // Function: when supplied, pull arguments out (form.values, form).
    onlyWhen,           // Function: when supplied, allows user to bail out of an update.
    onFetchValue,       // Async function to compute the value to store

    debounce = 1500,    // milliseconds
}) {
    const form = useForm();

    // Use defaults if not passed.
    nameOfIsFetching = nameOfIsFetching ?? `${name}IsFetching`;
    nameOfError = nameOfError ?? `${name}Error`;
    nameOfHasFetched = nameOfHasFetched ?? `${name}HasFetched`;

    // Add the "buckets" to the Form with initial values.
    useFormComputed({ name, value: initialValue });
    useFormComputed({ name: nameOfIsFetching, value: false });
    useFormComputed({ name: nameOfError, value: null });
    useFormComputed({ name: nameOfHasFetched, value: false });

    // Run our async value whenever the args (inputs) change.
    useEffect(
        () => debouncedReaction(
            () => {
                // NOTE: we need to run getFormValues & onlyWhen here to ensure any changes are tracked.
                const extractedValues = getFormValues?.(form.values, form) ?? form.values;

                return {
                    extractedValues,
                    shouldBail: onlyWhen ? !onlyWhen(extractedValues, form) : false,
                };
            },
            async ({ extractedValues, shouldBail }) => {
                //  REQUIREMENT: bail early if requested
                if (shouldBail) {
                    return;
                }

                // REQUIREMENT: prevent overlapping fetches.
                await when(() => !form.getValue(nameOfIsFetching));

                // Starting a new fetch; clear out any pre-existing error
                form.setComputed(nameOfIsFetching, true);
                form.setComputed(nameOfError, null);

                // Retrieve the value and update the Form.
                try {
                    const result = await onFetchValue(extractedValues, form);

                    // TODO: if we're unmounted, we shouldn't try to update.
                    form.setComputed(name, result);
                } catch (ex) {
                    // eslint-disable-next-line no-console
                    console.error('useFormComputedAsync: error during fetch:', ex);

                    // ASSUMPTION: if we result in an error, leave the old value?
                    form.setComputed(nameOfError, ex);
                }

                form.setComputed(nameOfIsFetching, false);
                form.setComputed(nameOfHasFetched, true);
            },
            // mobx reaction options:
            {
                name: `useFormComputedAsync for ${name}`,
                delay: debounce,
                fireImmediately: true,
                // Do deep-equality check instead of === check
                // Otherwise we'll re-run the effect if the extracted values are the same
                equals: comparer.structural,
                onError: (error) => {
                    // eslint-disable-next-line no-console
                    console.error('useFormComputedAsync: error encountered in debouncedReaction:', error);

                    form.setComputed(nameOfError, error);
                },
            },
        ),
        // useEffect dependencies
        [
            form,
            name,
            nameOfIsFetching,
            nameOfError,

            getFormValues,
            onlyWhen,
            onFetchValue,

            debounce,
        ],
    );
}

export default useFormComputedAsync;
