import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';

import {
    Sizes,
    messageToString,
    Colours,
} from '@ratehub/base-ui';

import NEWSLETTER_MESSAGES from '../definitions/NewsletterMessages';
import NewsletterSignUpInlineForm from './NewsletterSignUpInlineForm';
import Checklist from './Checklist';


const NEWSLETTER_IMAGE = {
    src: '/static/newsletter-thumb-en-all.jpg',
    width: 368,
    height: 368,
};

function SidebarNewsletterModalAll({ onSignUpComplete }) {
    const intl = useIntl();

    return (
        <Container
            data-name="SidebarNewsletterModalAll-modal"
        >
            <div className="flex-container">
                <div className="column newsletter">
                    <img
                        className="newsletter-thumbnail"
                        alt={messageToString(MESSAGES.NEWSLETTER_THUMBNAIL_ALT, intl)}
                        {...NEWSLETTER_IMAGE}
                    />
                </div>

                <div className="column content">
                    <h2 className="rh-title-1xl rh-mt-0 rh-mb-1">
                        <FormattedMessage
                            id="web-components.SidebarNewsletterModalAll.title"
                            defaultMessage="Personal finance is hard. We’ll help you simplify it."
                        />
                    </h2>

                    <Checklist
                        className="rh-mt-1_25 rh-mb-2"
                        title={(
                            <FormattedMessage
                                id="web-components.SidebarNewsletterModalAll.listTitle"
                                defaultMessage="What you get in our weekly newsletter:"
                            />
                        )}
                        checkListMessages={getChecklistMessages(intl)}
                        iconProps={{
                            stroke: Colours.COCONUT,
                            strokeWidth: '2px',
                            fill: Colours.BLUEBERRY_DARK,
                            outlineWidth: '0px',
                        }}
                    />

                    <NewsletterSignUpInlineForm
                        className="rh-mb-0_75"
                        inputLabel={(
                            <FormattedMessage
                                id="web-components.SidebarNewsletterModalAll.inputAccessibilityLabel"
                                defaultMessage="Email address"
                            />
                        )}

                        isCompact

                        /* @TODO: Please revisit all analytics for Sidebar Newsletter in https://ratehub.atlassian.net/browse/FIT-3171 */
                        dataName="sidebar-modal-newsletter-sign-up-form-all"

                        disclaimer={NEWSLETTER_MESSAGES.DISCLAIMER}

                        onSignUpComplete={onSignUpComplete}

                        asyncButtonMessages={{
                            ready: NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_READY,
                            'in-progress': NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_IN_PROGRESS,
                            complete: NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_COMPLETE,
                        }}
                    />
                </div>
            </div>
        </Container>
    );
}

SidebarNewsletterModalAll.propTypes = {
    onSignUpComplete: PropTypes.func,
};

const MEDIA_QUERY_LAYOUT_TABLET = '68.75em'; // below this is tablet
const MEDIA_QUERY_LAYOUT_MOBILE = '28em'; // below this is mobile

const PADDING_LARGE = Sizes.SPACING.TWO;
const PADDING_SMALL = Sizes.SPACING.ONE;

const Container = styled.div`
    padding-left: ${PADDING_LARGE};
    padding-right: ${PADDING_LARGE};
    padding-bottom: ${PADDING_LARGE};
    text-align: center;

    @media (max-width: ${MEDIA_QUERY_LAYOUT_TABLET}) {
        padding-left: ${PADDING_SMALL};
        padding-right: ${PADDING_SMALL};
        padding-bottom: ${PADDING_SMALL};
    }

    > .flex-container {
        display: flex;
        flex-wrap: nowrap;
        gap: ${Sizes.SPACING.ONE_AND_A_HALF};
        justify-content: center;
        align-items: flex-start;

        > .column {
            flex-shrink: 1;

            &.content {
                flex-basis: 50%;
                text-align: left;
            }
            &.newsletter {
                flex-basis: 50%;
                height: 0;

                > .newsletter-thumbnail {
                    width: 100%;
                    height: auto;

                    box-shadow: 0 ${Sizes.SPACING.HALF} ${Sizes.SPACING.TWO} ${Colours.STONE};
                }
            }
        }

        @media (max-width: ${MEDIA_QUERY_LAYOUT_TABLET}) {
            flex-direction: column-reverse;
            align-items: stretch;

            > .column {
                &.content {
                    flex-basis: auto;
                }
                &.newsletter {
                    flex-basis: 23rem;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }

        @media (max-width: ${MEDIA_QUERY_LAYOUT_MOBILE}) {
            > .column {
                &.newsletter {
                    display: none;
                }
            }
        }
    }
`;

function getChecklistMessages(intl) {
    return [
        MESSAGES.CHECKLIST_LIST_ITEM_1,
        MESSAGES.CHECKLIST_LIST_ITEM_2,
        MESSAGES.CHECKLIST_LIST_ITEM_3,
        MESSAGES.CHECKLIST_LIST_ITEM_4,
    ].map(msg => intl.formatMessage(msg));
}

const MESSAGES = defineMessages({
    NEWSLETTER_THUMBNAIL_ALT: {
        id: 'web-components.SidebarNewsletterModalAll.newsletterThumbnailAlt',
        defaultMessage: 'Ratehub.ca Newsletter',
    },

    CHECKLIST_LIST_ITEM_1: {
        id: 'web-components.SidebarNewsletterModalAll.listItem1',
        defaultMessage: 'Compare the best mortgage, savings, and investment rates',
    },
    CHECKLIST_LIST_ITEM_2: {
        id: 'web-components.SidebarNewsletterModalAll.listItem2',
        defaultMessage: 'The latest credit card offers and exclusive promotions',
    },
    CHECKLIST_LIST_ITEM_3: {
        id: 'web-components.SidebarNewsletterModalAll.listItem3',
        defaultMessage: 'Breaking news on the Canadian real estate and mortgage markets',
    },
    CHECKLIST_LIST_ITEM_4: {
        id: 'web-components.SidebarNewsletterModalAll.listItem4',
        defaultMessage: 'Expert advice on how to manage credit cards, save, and invest',
    },
});

export default SidebarNewsletterModalAll;
