import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { Sizes, ImageSizes } from '@ratehub/base-ui';
import { Colours } from '@ratehub/base-ui/src/styles';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';


function BillboardImageContainer({
    imageCollection,
    imagePositionX,
    imagePositionY,
    heroImageHeight,
    backgroundColour,
    children,
    ...otherProps
}) {
    return (
        <Container
            imageCollection={imageCollection}
            imagePositionX={imagePositionX}
            imagePositionY={imagePositionY}
            heroImageHeight={heroImageHeight}
            backgroundColour={backgroundColour}
            {...otherProps}
        >
            <LazyLoadComponent
                height={heroImageHeight} // Fixes the height of the container before the image is loaded to prevent jank
            >
                <div className="hero" />
            </LazyLoadComponent>

            {children}
        </Container>
    );
}


const BACKGROUND_COLOURS = {
    STONE_LIGHT: Colours.STONE_LIGHT,
    STONE_LIGHTEST: Colours.STONE_LIGHTEST,
    TRANSPARENT: Colours.TRANSPARENT,
};

BillboardImageContainer.propTypes = {
    imageCollection: ImageCollectionPropType,
    imagePositionX: PropTypes.string,
    imagePositionY: PropTypes.string,
    heroImageHeight: PropTypes.number,
    backgroundColour: PropTypes.oneOf(Object.values(BACKGROUND_COLOURS)),
    children: PropTypes.any.isRequired,
};

BillboardImageContainer.defaultProps = {
    imageCollection: undefined,
    imagePositionX: 'center',
    imagePositionY: 'center',
    heroImageHeight: 474,
    backgroundColour: BACKGROUND_COLOURS.transparent,
};

// Linearly scale vertical margins based on available size
const SCALED_SPACING = `clamp(${Sizes.SPACING.ONE}, 4vw, ${Sizes.SPACING.FOUR})`;
const SCALED_SPACING_INVERTED = `clamp(-${Sizes.SPACING.FOUR}, -4vw, -${Sizes.SPACING.ONE})`;

const Container = styled.section`
    padding: 0 ${SCALED_SPACING};
    background-color: ${props => props.backgroundColour};

    > .hero {
        height: ${props => `${props.heroImageHeight}px`};
        margin: 0 ${SCALED_SPACING_INVERTED};

        ${props => props.imageCollection &&`
            background-image: url(${extractImageURL(props.imageCollection)});
            background-position: ${props.imagePositionX} ${props.imagePositionY};
            background-size: cover;
            background-repeat: no-repeat;
        `}
    }

    // Special case for BillboardImageContainer
    // Override padding at the top level or else elements have padding we don't want
    > * > .flex-content {
        padding-left: 0;
        padding-right: 0;
    }
`;

function extractImageURL(imageCollection) {
    const imageURL = imageCollection.sizes?.[ImageSizes.M]?.url
        || imageCollection.sizes?.[ImageSizes.FULL]?.url;

    return formatURL(imageURL, imageCollection.mime);
}

function formatURL(imageURL, mimeType) {
    return mimeType === 'image/webp'
        ? imageURL.slice(0, imageURL.length - '.webp'.length)
        : imageURL;
}

BillboardImageContainer.blockKey = 'rh/billboard-image-container';
BillboardImageContainer.BACKGROUND_COLOURS = BACKGROUND_COLOURS;

export default BillboardImageContainer;
