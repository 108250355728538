/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/

function BlueprintInterface() {
    return null;
}

BlueprintInterface.blockKey = 'rh/blueprint-interface';

export default BlueprintInterface;
