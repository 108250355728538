import { Colours } from '@ratehub/base-ui';


// `content` override properties for ModalFullScreenTakeoverStyles required by CreditCardIncentive
function getIncentiveFullScreenTakeoverStyles(size = 'small') {
    const topBandHeight = size === 'large'
        ? '180'     // large => includes modal close box, which adds more height
        : '145';    // small => no modal close box

    return {
        content: {
            padding: 0,
            inset: 0,
            borderRadius: 0,
            backgroundImage: `linear-gradient(180deg, ${Colours.GRAPE_LIGHTEST} ${topBandHeight}px, ${Colours.COCONUT} ${topBandHeight}px)`,
        },
    };
}

export default getIncentiveFullScreenTakeoverStyles;
