import React, { useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
    Colours,
    Sizes,
    AlternateAnchor,
    FormattedNumberRaisedSymbol,
    Locales, 
    getLanguageFromLocale,
    Form,
    FormComputedAsync,
    useSession,
    FormComputed,
} from '@ratehub/base-ui';
import { fetchBestRates, SCENARIO_TYPES } from '@ratehub/mtg-common';
import { LayoutGlobals } from '@ratehub/base-ui/src/styles';

import { DEFAULT_BEST_PURCHASE_PARAMS, MORTGAGE_TYPES } from '../definitions/MortgageTerms';
import PATHS from '../definitions/Paths';
import SkeletonPlaceholder from './SkeletonPlaceholder';


const DEFAULT_PARAMS = {
    ...DEFAULT_BEST_PURCHASE_PARAMS,
    type: [ MORTGAGE_TYPES.FIXED, MORTGAGE_TYPES.VARIABLE ],
    term: [ 60 ],
    isFeatured: 1,
};


function BestRatesMortgages({ ...otherProps }) {
    const intl = useIntl();
    const sessionStore = useSession();

    const WIZARD_FLOW_HREF = intl.locale === Locales.FRENCH
        ? PATHS.WIZARD_MTG_QUOTER_FR
        : PATHS.WIZARD_MTG_QUOTER_EN;

    // Must memoize this callback to cause a re-fetch when the interest location changes
    const fetchBestRatesForLocation = useCallback(
        async () => {
            const params = {
                ...DEFAULT_PARAMS,
                language: getLanguageFromLocale(intl.locale),
                ...(sessionStore.interestLocation && {
                    city: sessionStore.interestLocation.city,
                    province: sessionStore.interestLocation.province,
                }),
            };

            return fetchBestRates(SCENARIO_TYPES.PURCHASE, params);
        },
        [ intl.locale, JSON.stringify(sessionStore.interestLocation) ] // deeply compare location object changes
    );

    return (
        <Container {...otherProps}>
            <p className="title rh-title-2xs rh-text-align-center weight-medium">
                <FormattedMessage
                    id="web-components.BestRatesMortgages.title"
                    defaultMessage="Today’s best mortgage rates"
                />
            </p>

            <Form className="loaded-content rh-mb-1_5">
                <FormComputed
                    name="bestFixedRate"
                    value={({ bestRates }) => {
                        const fixedRate = bestRates?.[0];

                        return isValidRate(fixedRate)
                            ? fixedRate
                            : null;
                    }}
                />

                <FormComputed
                    name="bestVariableRate"
                    value={({ bestRates }) => {
                        const variableRate = bestRates?.[1];

                        return isValidRate(variableRate)
                            ? variableRate
                            : null;
                    }}
                />

                <FormComputedAsync
                    name="bestRates"
                    onFetchValue={fetchBestRatesForLocation}
                    nameOfHasFetched="isReady"
                    nameOfIsFetching="isFetching"
                >
                    {({ bestFixedRate, bestVariableRate, isFetching, isReady }) => (
                        <Choose>
                            <When condition={!isFetching && isReady}>
                                <Choose>
                                    <When condition={!!bestFixedRate && !!bestVariableRate}>
                                        {/* yay, show rates */}
                                        <div className="rates">
                                            <div className="rate">
                                                <output 
                                                    id="bestFixedRate" 
                                                    className="rateValue rh-title-m leading-xs weight-medium"
                                                >
                                                    <FormattedNumberRaisedSymbol
                                                        value={bestFixedRate.value / 100}
                                                        format="percent2"
                                                        symbolClassName="percentSymbol rh-title-2xs"
                                                    />
                                                </output>
                                                <label
                                                    htmlFor="bestFixedRate"
                                                    className="rateType rh-text-xs leading-xs rh-fg-blackberry"
                                                >
                                                    {bestFixedRate.description}
                                                </label>
                                            </div>

                                            <div className="rate">
                                                <output
                                                    id="bestVariableRate"
                                                    className="rateValue rh-title-m leading-xs weight-medium"
                                                >
                                                    <FormattedNumberRaisedSymbol
                                                        value={bestVariableRate.value / 100}
                                                        format="percent2"
                                                        symbolClassName="percentSymbol rh-title-2xs"
                                                    />
                                                </output>
                                                <label
                                                    htmlFor="bestVariableRate"
                                                    className="rateType rh-text-xs leading-xs rh-fg-blackberry"
                                                >
                                                    {bestVariableRate.description}
                                                </label>
                                            </div>
                                        </div>
                                    </When>
                                    <Otherwise>
                                        {/* boo, everything is broken */}
                                        <p className="rh-text-s rh-pt-0_875 rh-text-align-center">
                                            <FormattedMessage
                                                id="web-components.BestRatesMortgages.loadingRatesError"
                                                defaultMessage="Sorry, could not load rates."
                                            />
                                        </p>
                                    </Otherwise>
                                </Choose>
                            </When>
                            <Otherwise>
                                <SkeletonPlaceholder data-test-name="placeholder" />
                            </Otherwise>
                        </Choose>
                    )}
                </FormComputedAsync>
            </Form>

            <AlternateAnchor
                className="rh-mb-0_75"
                size="small"
                href={WIZARD_FLOW_HREF}
                message={(
                    <FormattedMessage
                        id="web-components.BestRatesMortgages.anchorLabel"
                        defaultMessage="See which rates I qualify for"
                    />
                )}
            />
        </Container>
    );
}

function isValidRate(rate) {
    return rate?.value && rate?.description;
}

const Container = styled.section`
    padding: ${Sizes.SPACING.HALF} ${Sizes.SPACING.ONE_AND_A_QUARTER};

    @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
        display: none;
    }

    > .title {
        margin: ${Sizes.SPACING.THREE_QUARTERS} 0 ${Sizes.SPACING.ONE};
    }

    > .loaded-content {
        position: relative;

        /* IMPORTANT: Prop open to prevent layout jank during rate fetch. Using
        a fixed height (not min-height) to be absolutely sure nothing moves.
        This value should be *just* tall enough to contain the .rates element
        and no more. */
        height: 1.8rem;

        > .rates {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            > .rate {
                flex-basis: 50%;
                flex-grow: 1;

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: ${Sizes.SPACING.HALF};

                color: ${Colours.BLUEBERRY};

                > .rateValue {
                    color: ${Colours.BLUEBERRY_DARK};
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export default BestRatesMortgages;
