/**
 * Gets a block of a certain type recursively as defined by an array
 *
 * @param {object} block - Wordpress block object
 * @param {array} blockTypes Array of block keys
 *
 * @returns {object} The first found block that matches one of the requested blockTypes
 */
export default function getBlockOfTypeRecursively(block, blockTypes) {
    if (blockTypes.some(blockType => block.blockName === blockType)) {
        return block;
    }

    if (block.innerBlocks?.length > 0) {
        for (let i = 0; i < block.innerBlocks.length; i++) {
            const matchedBlock = getBlockOfTypeRecursively(block.innerBlocks[i], blockTypes);

            if (matchedBlock) {
                return matchedBlock;
            }
        }
    }
}
