import isMobileDevice from 'is-mobile';


/**
 * Estimate if we are running on a mobile device. By default, this **includes** tablets.
 * To exclude tablets, call this like `isMobile({ excludeTablets: true })`.
 *
 *      >>> Only use this as a last resort!
 *      >>> We prefer using specific feature detection whenever possible.
 *
 * A prior attempt that used https://www.w3.org/TR/mediaqueries-4/#mf-interaction proved to be unreliable,
 * causing us to use this `navigator.userAgent` approach.
 *
 * @param {Object} [options = {}]
 * @param {boolean} [options.excludeTablets = false]
 * @return {undefined | boolean}
 */
function isMobile({ excludeTablets = false } = {}) {
    if (typeof window !== 'object') {
        return undefined;
    }

    // the documentation implies that the `featureDetect: true` property is not needed,
    // but experimentally I find that it is
    return isMobileDevice(excludeTablets
        ? {}
        : { tablet: true, featureDetect: true },
    );
}

export default isMobile;
