import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Config from '../definitions/Config';
import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import MessagePropType from '../definitions/MessagePropType';
import useSession from '../hooks/useSessionStore';
import IconHeart from './icons/IconHeart';
import Paragraph from './Paragraph';
import EmailCaptureModal from './EmailCaptureModal';
import SignInModal from './SignInModal';
import { MAGIC_LINK_SIGN_IN_SCREEN } from './MagicLinkForms';


function SaveToProfileButton({
    dataName,
    signInMessages,
}) {
    const sessionStore = useSession();
    const [ isClicked, setIsClicked ] = useState(false);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ resumeApplicationLink, setResumeApplicationLink ] = useState(undefined);

    // we show the Button only to Guest
    useEffect(() => {
        if (sessionStore.hasFetchedSession && !sessionStore.isLoggedIn) {
            setIsVisible(true);
        }
    }, [ sessionStore.hasFetchedSession, sessionStore.isLoggedIn ]);

    // We need to ensure the first render on the client matches the server
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setResumeApplicationLink(window.location.href);
        }
    }, []);

    function handleClick() {
        setIsClicked(true);
    }

    async function saveEmail({ email }) {
        // Save email to SessionStore and to guest profile
        await sessionStore?.pushProfileUpdate({ email: email });

        // Since user already submitted their email, we can safely hide the button
        setIsVisible(false);
    }

    return (
        <Choose>
            <When condition={sessionStore?.isLoggedIn === false && isClicked}>
                <Choose>
                    {/* TODO: remove EmailCaptureModal after new Save-to-profile experience confirmed to launch*/}
                    <When condition={Config.ENABLE_SIGN_IN_ON_SAVE_TO_PROFILE_BUTTON}>
                        <SignInModal
                            messages={signInMessages && {
                                [MAGIC_LINK_SIGN_IN_SCREEN]: signInMessages,
                            }}
                            shouldShowNewsletter={true}
                            shouldShowGoogleSignIn={true}
                            resumeApplicationLink={resumeApplicationLink}
                            onClose={() => setIsClicked(false)}
                        />
                    </When>
                    <Otherwise>
                        <EmailCaptureModal
                            onSubmit={saveEmail}
                            onClose={() => setIsClicked(false)}
                        />
                    </Otherwise>
                </Choose>
            </When>
            <When condition={isVisible}>
                <StyledButton
                    className="save-button"
                    onClick={handleClick}
                    type="button"
                    data-name={dataName}
                >
                    <IconHeart
                        className="save-icon"
                        width="2em"
                        height="2em"
                        stroke={Colours.LIME_DARKEST}
                        fill={Colours.LIME_LIGHTEST}
                        outlineWidth="0px"
                        strokeWidth="3px"
                    />
                    <Paragraph
                        as="span"
                        size="small"
                        variant="light"
                        className="save-text"
                        message={(
                            <FormattedMessage
                                id="Accounts.SaveToProfile.ButtonText"
                                defaultMessage="Save results"
                            />
                        )}
                    />
                </StyledButton>
            </When>
        </Choose>
    );
}

SaveToProfileButton.propTypes = {
    dataName: PropTypes.string,
    signInMessages: PropTypes.shape({
        MAGIC_LINK_SIGN_IN_TITLE: MessagePropType,
        MAGIC_LINK_SIGN_IN_CTA: MessagePropType,
        EMAIL_LABEL: MessagePropType,
        EMAIL_PLACEHOLDER: MessagePropType,
        DESCRIPTION: MessagePropType,
    }),
};

SaveToProfileButton.defaultProps = {
    dataName: 'SaveToProfileButton',
    signInMessages: undefined,
};

const StyledButton = styled.button`
    display: flex;

    width: ${Sizes.SPACING.EIGHT};
    background-color: transparent;
    border: none;
    outline: none;
    
    margin: ${Sizes.SPACING.QUARTER} 0;
    padding: 0;

    > .save-icon {
        flex-basis: ${Sizes.ICONS.S};
        flex-shrink: 0;
    }

    > .save-text {
        align-self: center;
        color: ${Colours.LIME_DARKEST};
        margin: 0 0 0 ${Sizes.SPACING.HALF};
        font-size: ${Sizes.FONTS.XS};
        font-weight: ${Typography.WEIGHTS.MEDIUM};
    }

    &:hover,
    &:focus {
        cursor: pointer;
        > .save-icon {
            stroke: ${Colours.LIME_LIGHTEST};
            fill: ${Colours.LIME};
            stroke-width: 4px;
            transition:
                fill 300ms ease,
                stroke 300ms ease;
        }
    }
`;

export default observer(SaveToProfileButton);
