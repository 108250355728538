import { useEffect, useLayoutEffect } from 'react';


/* useLayoutEffect issues a warning when running server-side
    This check allows us to use useLayoutEffect only on the client
    and fall back to useEffect on the server */
const useSafeLayoutEffect = typeof window !== 'undefined' 
    ? useLayoutEffect
    : useEffect;

export default useSafeLayoutEffect;