import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/magic-request`;

/**
 *
 * @param {string} email
 * @param {Object} params
 * @param {string} params.lang
 * @param {Object.<string, string>=} params.queryParams
 * @param {Object} documentOptions
 * @param {Object=} documentOptions.document
 * @param {string=} documentOptions.documentType
 * @param {string=} documentOptions.documentToken
 * @param {string=} documentOptions.documentRedirect
 * @param {string=} variant
 * @param {number=} delay
 * @returns {Promise<*>}
 */
function requestMagicLink(
    email,
    { lang, ...queryParams } = {},
    { document, documentType, documentToken, documentRedirect } = {},
    variant,
    delay = 0,
) {
    if (!email) {
        throw new Error(`Email is required to request a magic link. received: ${email}`);
    }

    // Error handling occurs within fetchJSON
    return fetchJSON({
        url: API_ENDPOINT, 
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email,
                queryParams,
                document,
                documentType,
                documentToken,
                documentRedirect,
                variant,
                lang,
                delay,
            }),
            credentials: 'include',
        },
    }, 'requestMagicLink');
}

export {
    requestMagicLink,
    API_ENDPOINT,
};
