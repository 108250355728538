import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


function Portal({ id, children }) {
    const [ hasMounted, setHasMounted ] = useState(false);

    // The portal container (portalDOMElement) below will be unavailable
    // on the server - wait until first client render to construct the portal.

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return null;
    }

    const portalDOMElement = document.getElementById(id);

    if (!portalDOMElement) {
        // eslint-disable-next-line no-console
        console.error(`Could not find portal DOM container... id: ${id}`);
        return null;
    }

    return ReactDOM.createPortal(
        children,
        portalDOMElement,
    );
}

Portal.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

export default Portal;
