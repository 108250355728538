import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import MessagePropType from '../definitions/MessagePropType';
import ERROR_MESSAGE_STYLES from '../definitions/MessageStyles';
import messageToString from '../functions/messageToString';
import getValueLabelOptionPairs from '../functions/getValueLabelOptionPairs';
import ErrorMessageContainer from './ErrorMessageContainer';
import InputController from './InputController';
import RadioButton from './RadioButton';


const FlowDirections = {
    COLUMN: 'column',
    ROW: 'row',
};
function RadioButtonGroup({
    id,
    name,
    value,

    options,
    renderOptionLabel,

    isRequired,
    isDisabled,

    onChange,
    onBlur,

    onValidityChange,
    onValidate,

    canShowInvalid,
    errorMessageStyle,

    flowDirection,
    shouldDisplayOptionsAsGrid, // Display options in a left aligned 3 column grid
    className,

    onCreateField,

    ...otherProps
}) {
    const intl = useIntl();
    const formattedOptions = getValueLabelOptionPairs(options, renderOptionLabel);

    const [ colCount, rowCount ] = getColRowCounts(formattedOptions.length, flowDirection);

    return (
        <InputController
            id={id}
            name={name}
            value={value}

            onChange={onChange}
            onBlur={onBlur}
            
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            canShowInvalid={canShowInvalid}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            
            isRequired={isRequired}
            isDisabled={isDisabled}
            
            getParsedValue={(newValue) => getParsedValue(newValue, formattedOptions)}

            onCreateField={onCreateField}

            className={className}
            
            {...otherProps}      
        >
            {(inputProps, inputState) => {
                function handleBlur(e) {
                    // We only want to fire the blur event when we
                    //  focus anything outside of our radio buttons/children.
                    //  We do not want it to fire when one of our
                    //  radio buttons are blurred to focus another button
                    //  in this group.
                    if (e.relatedTarget?.name !== name) {
                        inputProps.onBlur(e);
                    }
                }

                function handleChange(e) {
                    if (e.target.checked) {
                        // Within the DOM, .value can only be a string.
                        // Convert it back to their actual value.
                        const index = e.target.dataset.index;
                        inputProps.onChange(formattedOptions[index].value);
                    }
                }

                return (
                    <ErrorMessageContainer
                        message={inputState.errorMessage}
                        isInvalid={inputState.shouldShowInvalid}
                        messageStyle={errorMessageStyle}
                        className={inputProps.className}
                    >
                        <Container
                            id={inputProps.id}
                            colCount={colCount}
                            rowCount={rowCount}
                            flowDirection={flowDirection}
                            interButtonGap={INTER_BUTTON_GAP}
                            role="radiogroup"
                            className={classNames({
                                'display-options-as-grid': shouldDisplayOptionsAsGrid,
                                'grid-two-column': getGridColumnCount(formattedOptions.length) === 2,
                            })}
                        >
                            <For
                                each="option"
                                of={formattedOptions}
                                index="index"
                            >
                                <RadioButton
                                    {...inputProps}

                                    key={option.value}
                                    data-index={index}

                                    value={JSON.stringify(option.value)}
                                    checked={option.value === inputProps.value}

                                    text={messageToString(option.label, intl)}
                                    
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </For>
                        </Container>
                    </ErrorMessageContainer>
                );
            }}
        </InputController>
    );
}

RadioButtonGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,

    options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            label: MessagePropType,
            value: PropTypes.any.isRequired,
        })),
        PropTypes.array, // Case where values and labels are the same
    ]),
    renderOptionLabel: PropTypes.func,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,

    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    canShowInvalid: PropTypes.bool,
    errorMessageStyle: PropTypes.oneOf(Object.values(ERROR_MESSAGE_STYLES)),
    
    className: PropTypes.string,
    flowDirection: PropTypes.oneOf(Object.values(FlowDirections)),
    shouldDisplayOptionsAsGrid: PropTypes.bool,
    
    onCreateField: PropTypes.func,
};

RadioButtonGroup.defaultProps = {
    id: undefined,
    value: undefined,

    options: undefined,
    renderOptionLabel: undefined,

    isDisabled: false,
    isRequired: true,
    
    onChange: undefined,
    onBlur: undefined,

    onValidityChange: undefined,
    onValidate: undefined,

    canShowInvalid: false,
    errorMessageStyle: ERROR_MESSAGE_STYLES.DEFAULT,

    className: undefined,
    flowDirection: FlowDirections.COLUMN,
    shouldDisplayOptionsAsGrid: undefined,

    onCreateField: undefined,
};

const INTER_BUTTON_GAP = '0.625rem';

const MIN_COLUMN_WIDTH = '11rem';


function getOptionByValue(value, options) {
    return options.find(opt => opt.value === value);
}

function getParsedValue(newValue, options) {
    // Value must be within the list of options
    const selectedOption = getOptionByValue(newValue, options);

    return selectedOption
        ? selectedOption.value 
        : null;
}

function getGridColumnCount(optionCount){
    switch (optionCount) {
        case 2: // 2 or 4 options to display as two column grid
        case 4:
            return 2;
        default:
            return 3;
    }
}

function getColRowCounts(buttonCount, flowDirection) {
    switch (flowDirection) {
        case FlowDirections.ROW:
            return [ buttonCount, 1 ];
        default: // Glamazon defaults to column layout
            return [ 1, buttonCount ];
    }
}

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage 
        id="base-ui.radio-button-group.default-error"
        defaultMessage="Please select an option"
    />
);


const Container = styled.div`
    ${props => props.theme.fontFamily && `
        font-family: ${props.theme.fontFamily};
    `}
    ${props => props.theme.fontSize && `
        font-size: ${props.theme.fontSize};
    `}

    display: grid;
    grid-gap: ${props => props.interButtonGap};
    ${({ rowCount, colCount }) =>
        `grid-template: repeat(${rowCount}, 1fr) / repeat(${colCount}, 1fr)`
};

    grid-auto-flow: ${props => props.flowDirection};

    &.display-options-as-grid {
        grid-template-rows: min-content 1fr;
        grid-template-columns: repeat(auto-fill, minmax(max(${MIN_COLUMN_WIDTH}, calc((100% - (0.625rem * 3)) / 3)), 1fr));
        grid-auto-flow: unset;

        &.grid-two-column {
            grid-template-columns: repeat(auto-fill, minmax(max(${MIN_COLUMN_WIDTH}, calc((100% - (0.625rem * 2)) / 2)), 1fr));
        }
    }
`;

export default observer(RadioButtonGroup);
export { FlowDirections };
