import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sizes, usePageSettings, BusinessUnits } from '@ratehub/base-ui';

import NEWSLETTER_MESSAGES, {
    SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_ALL,
    SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_MORTGAGES,
} from '../definitions/NewsletterMessages';
import NewsletterIconLabel from './NewsletterIconLabel';
import Checklist from './Checklist';
import NewsletterSignUpInlineForm from './NewsletterSignUpInlineForm';


const SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES = {
    [BusinessUnits.ALL]: SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_ALL,
    [BusinessUnits.MORTGAGES]: SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_MORTGAGES,
};

function SidebarNewsletterSignUpLarge() {
    const intl = useIntl();
    const { businessUnit } = usePageSettings();

    const CHECKLIST_MESSAGES = SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES[businessUnit]
        ?? SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES[BusinessUnits.ALL];

    return (
        <Container>
            <NewsletterIconLabel />

            <If condition={CHECKLIST_MESSAGES}>
                <Checklist
                    className="rh-my-1"
                    checkListMessages={getChecklistMessages(CHECKLIST_MESSAGES, intl)}
                    variant="small"
                />
            </If>

            <NewsletterSignUpInlineForm
                inputLabel={(
                    <FormattedMessage
                        id="web-components.SidebarNewsletterSignUpLarge.inputAccessibilityLabel"
                        defaultMessage="Email address"
                    />
                )}
                // @TODO revisit this in FIT-3171
                dataName="sidebarNewsletterSignupLarge-open-modal"
                disclaimer={NEWSLETTER_MESSAGES.DISCLAIMER}
                asyncButtonMessages={{
                    ready: NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_READY,
                    'in-progress': NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_IN_PROGRESS,
                    complete: NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_COMPLETE,
                }}
                isCompact
            />
        </Container>
    );
}

const Container = styled.div`
    padding: ${Sizes.SPACING.ONE} ${Sizes.SPACING.THREE_QUARTERS};
`;

function getChecklistMessages(checklistMessages, intl) {
    return [
        checklistMessages.CHECKLIST_ITEM_1,
        checklistMessages.CHECKLIST_ITEM_2,
    ].map(msg => intl.formatMessage(msg));
}

export default SidebarNewsletterSignUpLarge;
