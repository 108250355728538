import React from 'react';
import PropTypes from 'prop-types';

import INPUT_TYPES from '../definitions/InputTypes';
import FieldPropType from '../definitions/FieldPropType';
import getInputTypeByField from '../functions/getInputTypeByField';
import ComponentSelector from './ComponentSelector';
import AddressInput from './AddressInput';
import Checkbox from './Checkbox';
import CurrencyInput from './CurrencyInput';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import NumberSelector from './NumberSelector';
import PercentageInput from './PercentageInput';
import PhoneInput from './PhoneInput';
import RadioButtonGroup from './RadioButtonGroup';
import Select from './Select';
import TextArea from './TextArea';
import CityInput from './CityInput';
import CheckboxGroup from './CheckboxGroup';
import CheckboxGroupExpandable from './CheckboxGroupExpandable';
import withField from './withField';
import EmailInput from './EmailInput';
import PostalCodeInput from './PostalCodeInput';
import StringInput from './StringInput';


function InputSelector({ 
    field,
    onCreateField,
    type,
    shouldDisplayOptionsAsGrid,
    ...inputProps 
}) {
    const inputType = getInputTypeByField(field, type);

    return (
        <ComponentSelector
            {...inputProps}
            componentId={inputType}
            componentMap={COMPONENT_MAP}
            // filter unused props
            {...inputType === INPUT_TYPES.RADIO && {
                shouldDisplayOptionsAsGrid,
            }}
            // passed to with*Field
            field={field}
            type={type}
            onCreateField={onCreateField}
        />
    );
}

InputSelector.propTypes = {
    field: FieldPropType.isRequired, // field descriptor
    type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    onCreateField: PropTypes.func, // Comes from with*Field, but want to make it clear what props are accepted.
    shouldDisplayOptionsAsGrid: PropTypes.bool,
};

InputSelector.defaultProps = {
    type: undefined,
    onCreateField: undefined,
    shouldDisplayOptionsAsGrid: undefined,
};

const COMPONENT_MAP = {
    [INPUT_TYPES.ADDRESS]: withField(AddressInput),
    [INPUT_TYPES.CHECKBOX]: withField(Checkbox),
    [INPUT_TYPES.CHECKBOX_GROUP]: withField(CheckboxGroup),
    [INPUT_TYPES.CHECKBOX_EXPANDABLE]: withField(CheckboxGroupExpandable),
    [INPUT_TYPES.CITY]: withField(CityInput),
    [INPUT_TYPES.CURRENCY]: withField(CurrencyInput),
    [INPUT_TYPES.DATE]: withField(DateInput),
    [INPUT_TYPES.DROPDOWN]: withField(Select),
    [INPUT_TYPES.EMAIL]: withField(EmailInput),
    [INPUT_TYPES.NUMBER]: withField(NumberInput),
    [INPUT_TYPES.PERCENT]: withField(PercentageInput),
    [INPUT_TYPES.POSTAL_CODE]: withField(PostalCodeInput),
    [INPUT_TYPES.PHONE]: withField(PhoneInput),
    [INPUT_TYPES.RADIO]: withField(RadioButtonGroup),
    [INPUT_TYPES.SELECTOR]: withField(NumberSelector),
    [INPUT_TYPES.TEXT]: withField(StringInput),
    [INPUT_TYPES.TEXTAREA]: withField(TextArea),
};

export default InputSelector;
