import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';

import { AdTypes } from '@ratehub/base-ui';

import getAdSizeForContainer from '../functions/getAdSizeForContainer';
import AdSizes from '../definitions/AdSizes';
import Ad from './Ad';


const ALIGNMENTS = {
    LEFT: 'left',
    CENTRE: 'centre',
    RIGHT: 'right',
};

const FLEX_ALIGNMENT_MAP = {
    [ALIGNMENTS.LEFT]: 'flex-start',
    [ALIGNMENTS.CENTRE]: 'center',
    [ALIGNMENTS.RIGHT]: 'flex-end',
};

function AdBigbox({ alignment, targeting, overrideAdIndex, size, ...otherProps }) {
    // size-me is expected to not be available on first render.
    const adSize = size?.width
        ? getAdSizeForContainer(size.width, AdSizes[AdTypes.Bigbox])
        : undefined;
    
    return (
        <MyContainer
            alignment={alignment}
            {...otherProps}
        >
            <Ad
                type={AdTypes.Bigbox}
                targeting={targeting}
                overrideAdIndex={overrideAdIndex}
                adSize={adSize}
            />
        </MyContainer>
    );
}

AdBigbox.propTypes = {
    alignment: PropTypes.oneOf(Object.values(ALIGNMENTS)),
    targeting: PropTypes.object,
    overrideAdIndex: PropTypes.number,
    size: PropTypes.object,
};

AdBigbox.defaultProps = {
    alignment: ALIGNMENTS.CENTRE,
    targeting: undefined,
    overrideAdIndex: undefined,
    size: undefined,
};

const MyContainer = styled.div`
    display: flex;
    justify-content: ${props => FLEX_ALIGNMENT_MAP[props.alignment]};
`;

const WrappedAdBigBox = withSize({ noPlaceholder: true })(AdBigbox);

// withSize() removes properties directly on AdBigbox so we need to add them *after* calling withSize()
WrappedAdBigBox.blockKey = 'rh/ad-bigbox';
WrappedAdBigBox.requiresLayoutRow = true;
WrappedAdBigBox.ALIGNMENTS = ALIGNMENTS;

export default WrappedAdBigBox;