const X_AXIS_TYPES = {
    DATETIME: 'datetime',
    LINEAR: 'linear',
    CATEGORY: 'category',
    LOGARITHMIC: 'logarithmic',
};

const X_AXIS_TYPE_OPTIONS = Object.values(X_AXIS_TYPES).map((type) => ({
    label: type,
    value: type,
}));

export { X_AXIS_TYPE_OPTIONS };
export default X_AXIS_TYPES;
