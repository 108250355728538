import { Locales, Languages } from '@ratehub/base-ui';

import PATHS from '../definitions/Paths';


export default function getBlogCategoryPath(category, locale, language = '') {
    const categoryPath = (locale === Locales.FRENCH || language === Languages.FRENCH)
        ? PATHS.CATEGORY_FR
        : PATHS.CATEGORY;

    return category.parent !== 0
        ? `${categoryPath}/${category.parent.slug}/${category.slug}/`
        : `${categoryPath}/${category.slug}/`; // Trailing slash is desired for /blog or /blogue
}
