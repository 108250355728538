import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import FormattedInput from 'react-number-format';
import classNames from 'classnames';

import withErrorMessageContainer from '../components/withErrorMessageContainer';
import {
    INPUT_HORIZONTAL_PADDING_CONDENSED,
} from '../definitions/SharedInputStyles';
import Locales from '../definitions/Locales';
import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import InputStyle from '../styles/InputStyle';
import messageToString from '../functions/messageToString';
import MessagePropType from '../definitions/MessagePropType';
import useIsMounted from '../hooks/useIsMounted';
import { useCondensedTheme } from './CondensedTheme';


function FormattedNumberInput({
    id,
    name,
    value,
    className,
    inputClassName,

    shouldShowInvalid = false,
    isInvalid,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    disabled,
    label,

    format,
    mask,
    decimalScale,

    'data-computed': dataComputed,

    ...otherProps
}) {
    const intl = useIntl();
    const isCondensed = useCondensedTheme();

    // NOTE FOR DEVELOPER:
    // This mount check to work around the way the `onFocus` event is written within `react-number-format`
    // Internally, they use a `setTimeout(, 0)` before invoking the bound `onFocus` callback so they can do a bit
    // of caret manipulation. This causes tests using mocked time to fail because it's attempting to perform a state update
    // on an unmounted React node.
    // Ideally this can be removed in the future.
    const getIsMounted = useIsMounted();

    const thousandSeparator = intl.locale === Locales.FRENCH ? ' ' : ',';
    const decimalSeparator = intl.locale === Locales.FRENCH ? ',' : '.';
    const translatedFormat = typeof format === 'function'
        ? unformattedValue => messageToString(format(unformattedValue), intl) // need to translate the output of callback
        : messageToString(format, intl);
    const translatedPlaceholder = messageToString(placeholder, intl);

    const displayedValue = value ?? '';

    function handleFocus(e) {
        // NOTE: as explained above, `react-number-format` has the `onFocus` callback invoked within
        // a `setTimeout(, 0)` which can break tests using faked time.
        if (!getIsMounted()) {
            return;
        }

        onFocus?.(e);
    }

    function handleBlur(e) {
        if (!getIsMounted()) {
            return;
        }

        onBlur?.(e);
    }

    function handleValueChange(values) {
        const fields = Array.from(document.querySelectorAll(`input[name="${name}"]`)); // we may have multiple form with same field, so we do querySelectorAll
        const isElementFound = fields.some(field => {
            return field.name === document.activeElement.name
                && field.value === document.activeElement.value;
        });
        //we find the element in field list which has same name and same value

        // The library calls this every time the value changes (even if via props).
        // To make this act like all our other components, only raise if it's from user interaction.
        if (isElementFound) {
            // Different components use different nodes off the values object
            // so the full object is passed, and the required nodes are extracted per component
            onChange?.(values);
        }
    }


    return (
        <Container
            className={classNames(className, {
                'has-label': !!label,
                'has-value': !!displayedValue,
                'is-invalid': shouldShowInvalid,
                'is-disabled': disabled,
                'is-condensed': isCondensed,
            })}
        >
            <If condition={label}>
                <label
                    className="label rh-text-m"
                    htmlFor={id ?? name}
                >
                    {messageToString(label, intl)}
                </label>
            </If>

            <FormattedInput
                id={id ?? name}
                name={name}
                value={displayedValue}
                className={classNames('input', inputClassName, {
                    // used by InputStyles
                    'is-condensed': isCondensed,
                })}
                isNumericString={true}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                aria-invalid={shouldShowInvalid ? 'true' : undefined}
                data-invalid={isInvalid ? 'true' : undefined}
                onValueChange={handleValueChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                format={translatedFormat}
                mask={mask}
                placeholder={translatedPlaceholder}
                data-computed={dataComputed}
                decimalScale={decimalScale}
                // inputMode affects the mobile virtual keyboard
                //  numeric -> integer keyboard
                //  decimal -> decimal keyboard
                inputMode={decimalScale === 0 ? 'numeric' : 'decimal'}
                disabled={disabled}
                {...otherProps}
            />
        </Container>
    );
}

FormattedNumberInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    shouldShowInvalid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    placeholder: MessagePropType,
    disabled: PropTypes.bool,
    label: MessagePropType,

    format: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object, // message descriptor
        PropTypes.element, // formatted message
        PropTypes.func, // value => string/formatted message/descriptor
    ]),
    // not translated, is just the character used as placeholder
    mask: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    decimalScale: PropTypes.number,

    'data-computed': PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
};


const TRANSITION_TIME = '150ms';

const Container = styled.div`
    > .input {
        ${InputStyle}
    }

    /* only apply compact label styling if we have a label */
    &.has-label {
        > .input {
            padding: ${Sizes.SPACING.ONE_AND_A_HALF} 1.125rem 0.125rem;

            ::placeholder {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s 100ms, opacity 100ms linear;
            }
        }

        > .label {
            position: absolute;
            top: 50%;
            left: ${Sizes.SPACING.ONE_AND_A_HALF};
            right: ${Sizes.SPACING.ONE_AND_A_HALF}; /* Used to force truncation if text is too long */
            z-index: 10;

            transform: translateY(-50%);
            transform-origin: left top;
            transition: transform ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1), color ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1);
            will-change: transform;

            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;

            text-overflow: ellipsis;
            overflow: hidden;

            color: ${Colours.STONE_DARK};
            white-space: nowrap;

            cursor: text;
            pointer-events: none;
        }

        /* Applying this style as a negation so we don't have to choose a default color
            for when we have a value or are focused */
        /* Some inputs like SelectNative don't have a real placeholder pseudo-element so
            this ensures only the label is visible at this point.
            Other alternatives were to always show the placeholder if its available so
            the label would always show on top, but that would also require us to target
            the placeholder via CSS since some inputs have default placeholders. And since
            somce inputs do not use real placeholders like SelectNative, that would also be insufficient. */
        &:not(.has-value, :focus-within) {
            > .input {
                    color: transparent;
                    transition: color ${TRANSITION_TIME};
                }
            }
        }

        :focus-within,
        &.has-value {
            > .input {
                ::placeholder {
                    visibility: visible;
                    opacity: 1;
                    transition: opacity ${TRANSITION_TIME} linear;
                }
            }

            > .label {
                /* Unset the right space in case the text was truncated and now can fit */
                right: unset;
                transform: translateX(-0.25rem) translateY(-100%) scale(0.75);
                color: ${Colours.BLACKBERRY};
            }
        }

        :hover {
            > .label {
                color: ${Colours.BLUEBERRY_DARK};
            }
        }

        &.is-invalid {
            > .label,
            > .input::placeholder {
                color: ${Colours.STRAWBERRY_DARK};
            }
        }

        &.is-disabled {
            > .label {
                color: ${Colours.STONE};
            }
        }

        /* Condensed style overrides */
        &.is-condensed {
            > .input {
                padding-left: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
                padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
            }

            > .label {
                left: 1.25rem;
            }

            &:focus-within,
            &.has-value {
                > .label {
                    transform: translateX(-0.25rem) translateY(-95%) scale(0.75);
                }
            }
        }
    }
`;

export default withErrorMessageContainer(FormattedNumberInput);
