import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import MessagePropType from '../definitions/MessagePropType';
import isPhoneNumberParsable from '../functions/isPhoneNumberValid';
import FormattedNumberInput from './FormattedNumberInput';
import InputController from './InputController';


/**
 * Default Canadian phone format
 * The Translation Bureau recommends inserting a non-breaking hyphen
 * after the area code and between groups of digits within a telephone number
 * https://www.btb.termiumplus.gc.ca/tpv2guides/guides/wrtps/index-eng.html?lang=eng&lettr=indx_catlog_t&page=9W7A26eVMyvE.html
 */
const PHONE_NUMBER_FORMAT = '###-###-####';

function PhoneInput({
    name,
    value,

    isRequired,
    isDisabled,

    onChange,
    onBlur,
    onValidityChange,
    onValidate,

    label,
    placeholder,

    canShowInvalid,
    
    onCreateField,

    ...otherProps
}) {
    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            canShowInvalid={canShowInvalid}
            isRequired={isRequired}
            isDisabled={isDisabled}
            getParsedValue={getParsedPhoneNumber}
            getFormattedValue={getUnformattedPhoneNumber}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => (
                <FormattedNumberInput
                    {...inputProps}
                    format={PHONE_NUMBER_FORMAT}
                    mask="#"
                    type="tel"
                    label={label}
                    placeholder={placeholder ?? PHONE_NUMBER_FORMAT}

                    onChange={values => inputProps.onChange(values.value)}

                    errorMessage={inputState.errorMessage}
                    shouldShowInvalid={inputState.shouldShowInvalid}
                    isInvalid={inputState.isInvalid}
                />
            )}
        </InputController>
        
    );
}

PhoneInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    canShowInvalid: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,

    onCreateField: PropTypes.func,
};

PhoneInput.defaultProps = {
    value: undefined,
    isRequired: undefined,
    isDisabled: false,
    onChange: undefined,
    onBlur: undefined,
    onValidityChange: undefined,
    canShowInvalid: false,
    onValidate: undefined,
    label: undefined,
    placeholder: undefined,
    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.phone-input.errors.default"
        defaultMessage="Please enter a valid phone number"
    />
);

// Strip all unnecessary characters before we pass to the mask
function getUnformattedPhoneNumber(phoneNumber) {
    return typeof phoneNumber === 'string' ? phoneNumber.replace(/[^0-9]/g, '') : null;
}

function getParsedPhoneNumber(phoneNumber) {
    if (typeof phoneNumber !== 'string') {
        return null;
    }

    const unformattedPhoneNumber = getUnformattedPhoneNumber(phoneNumber);

    return isPhoneNumberParsable(unformattedPhoneNumber)
        ? unformattedPhoneNumber
        : null;
}

export default observer(PhoneInput);
