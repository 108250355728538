/**
 * Regex to test if a string is an integer potentially with
 * either commas or blank spaces as thousands separators.
 */
export const TEST_REGEX = /^(-|\+)?(\d+|(\d{1,3})(((,|\s)\d{3})*))$/;

/**
 * Attempt to transform a value into a number and return it.
 * @param {any} value
 * @return {number|null}
 */
export default function toInteger(value) {
    return TEST_REGEX.test(value) ? parseInt(value.replace(/(,|\s)/g, '')) : null;
}
