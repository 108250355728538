import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withSize from 'react-sizeme';
import { defineMessages } from 'react-intl';
import { darken } from 'polished';

import Sizes from '../definitions/Sizes';
import ZIndex from '../definitions/ZIndex';
import Colours from '../definitions/Colours';
import MessagePropType from '../definitions/MessagePropType';
import NavigationCard, { LAYOUT_STYLES } from './NavigationCard';
import PageBehaviourButton from './PageBehaviourButton';
import IconArrow from './icons/IconArrow';
import AnimatedTranslateContainer from './AnimatedTranslateContainer';


const MAX_WIDTH_BREAKPOINT = 899;

const CARD_COLOURS = [
    Colours.BLUEBERRY_DARKEST,
    darken(0.05, Colours.BLUEBERRY_DARK),
    Colours.BLUEBERRY_DARK,
];

const CARD_HOVER_COLOURS = [
    darken(0.035, Colours.BLUEBERRY_DARKEST),
    darken(0.035, Colours.BLUEBERRY_DARKEST),
    darken(0.035, Colours.BLUEBERRY_DARKEST),
];

const CARD_ACTIVE_COLOURS = [
    darken(0.06, Colours.BLUEBERRY_DARKEST),
    darken(0.06, Colours.BLUEBERRY_DARKEST),
    darken(0.06, Colours.BLUEBERRY_DARKEST),
];

const ANIMATION_DURATIONS = [ 900, 750, 700 ];
const ANIMATION_DELAYS = [ 300, 200, 100 ];

function NavigationCardBlock({ cards, onBack, isAnimated, size, ...otherProps }) {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('pagehide', handlePageHide);

            return () => {
                window.removeEventListener('pagehide', handlePageHide);
            };
        }
    }, []);

    const isVertical = size.width < MAX_WIDTH_BREAKPOINT;

    /* This holds the index of the card that was clicked. We'll call
    the onClick of the card with this index when the animations are over. */
    const [ clickedCardIndex, setClickedCardIndex ] = useState(null);

    const isVisible = clickedCardIndex == null;

    /* We want all button clicks to always call this handleClick, which sets
    clickedCardIndex, triggering a re-render. We'll call the clicked card's
    onClick on the next render. */
    function handleClick(index) {
        setClickedCardIndex(index);
    }

    /* We want to reset card visibility ONLY if the page is entering
    back-forward cache. */
    function handlePageHide(e) {
        if (e.persisted) {
            setClickedCardIndex(null);
        }
    }

    const titleMinLines = cards.length === 2
        ? 2
        : 3;

    const descriptionMinLines = cards.length === 2
        ? 2
        : 3;

    const CardContainer = isAnimated ? AnimatedTranslateContainer : EmptyDIVWrapper;

    return (
        <Container
            isVertical={isVertical}
            hasButton={!!onBack}
            {...otherProps}
        >
            <ul
                className="card-list"
            >
                <For
                    each="card"
                    of={cards}
                    index="index"
                >
                    <CardContainer
                        key={index}
                        className="translate-container"
                        isVisible={isVisible}
                        isMountAnimated={false}

                        direction={isVertical ? 'up' : 'left'}
                        duration={ANIMATION_DURATIONS[index]}
                        delay={ANIMATION_DELAYS[index]}
                        easing="ease-in"

                        {...getCardContainerProps(isAnimated, clickedCardIndex, index, card)}
                    >
                        <li
                            className="card-list-item"
                        >
                            <NavigationCard
                                className="navigation-card"
                                layoutStyle={isVertical ? LAYOUT_STYLES.VERTICAL : LAYOUT_STYLES.NORMAL}
                                backgroundColour={CARD_COLOURS[index]}
                                hoverColour={CARD_HOVER_COLOURS[index]}
                                activeColour={CARD_ACTIVE_COLOURS[index]}
                                titleMinLines={titleMinLines}
                                descriptionMinLines={descriptionMinLines}
                                {...card}
                                onClick={isAnimated ? () => handleClick(index) : () => card.onClick()}
                                isFullScreen
                            />
                        </li>
                    </CardContainer>
                </For>
            </ul>

            <If condition={onBack}>
                <PageBehaviourButton
                    className="back-button"
                    onClick={onBack}
                    iconComponent={IconArrow}
                    iconLocation="left"
                    target="blank"
                    size="small"
                    variant="light"
                    message={MESSAGES.BACK}
                />
            </If>
        </Container>
    );
}

NavigationCardBlock.propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            title: MessagePropType.isRequired,
            description: MessagePropType,
            'data-name': PropTypes.string.isRequired,
            ctaText: MessagePropType,
        }),
    ).isRequired,
    onBack: PropTypes.func,
    isAnimated: PropTypes.bool,
    size: PropTypes.object.isRequired,
};

NavigationCardBlock.defaultProps = {
    onBack: undefined,
    isAnimated: true,
};

function getCardContainerProps(isAnimated, clickedCardIndex, currentCardIndex, card) {
    // onExited will throw an unrecognized prop error on a standard DOM element
    return isAnimated
        ? {
            onExited: () => {
                if (clickedCardIndex === currentCardIndex) {
                    card.onClick();
                }
            },
        }
        : {};
}

const EmptyDIVWrapper = styled.div``;

const Container = styled.div`
    position: relative;

    > .card-list {
        display: flex;
        flex-direction: row;

        margin: 0;
        padding: 0;
        list-style: none;

        height: 100vh;

        > .translate-container {
            /* We only ever expect 2 or 3 cards – no more, no less */
            /* Using two decimal places to eliminate a seam
            on the right caused by rounding errors in some browsers */
            flex-basis: 33.33%;
            flex-shrink: 0;
            flex-grow: 1;
            align-items: stretch;

            > .card-list-item {
                height: 100%;
            }

            &:nth-child(1) {
                z-index: 3;
            }
            &:nth-child(2) {
                z-index: 2;
            }
            &:nth-child(3) {
                z-index: 1;
            }

            /* If included, clear space for a back button when isVertical */
            &:last-child {
                > .card-list-item {
                    > .navigation-card {
                        ${props => props.hasButton && props.isVertical && `
                            padding-bottom: ${Sizes.SPACING.SIX};
                        `}
                    }
                }
            }
        }

        /* Stack cards on mobile */
        ${props => props.isVertical && `
            flex-direction: column;

            > .translate-container {
                height: auto;
                flex-grow: 1;
            }
        `}
    }

    > .back-button {
        z-index: ${ZIndex.ELEMENTS}
        position: absolute;
        left: ${Sizes.SPACING.TWO};
        bottom: ${Sizes.SPACING.TWO};

        &:hover,
        &:focus {
            color: ${Colours.BLUEBERRY_LIGHTEST};

            svg.invertable {
                fill: ${Colours.BLUEBERRY_LIGHTEST};
                stroke: ${Colours.BLUEBERRY_LIGHTEST};

                path {
                    stroke: ${Colours.BLUEBERRY_DARKEST};
                }
            }
        }

        &:active {
            color: ${Colours.BLUEBERRY_LIGHT};

            svg.invertable {
                fill: ${Colours.BLUEBERRY_LIGHT};
                stroke: ${Colours.BLUEBERRY_LIGHT};

                path {
                    stroke: ${Colours.BLUEBERRY_DARKEST};
                }
            }
        }
    }
`;

const MESSAGES = defineMessages({
    BACK: {
        id: 'base-ui.NavigationCardBlockGlamazon.backButtonLabel',
        defaultMessage: 'Back',
    },
});

export default withSize()(NavigationCardBlock);
