import { defineMessages } from 'react-intl';


const GIFT_OFFER_TYPES = {
    giftCard: 'gift-card',
    cashBonus: 'cash-bonus',
    rebate: 'rebate',
};

const GIFT_OFFER_TYPES_MESSAGES = defineMessages({
    [GIFT_OFFER_TYPES.giftCard] : {
        id: 'base-ui.gift-offer-type.gift-card',
        defaultMessage: 'gift card',
    },
    [GIFT_OFFER_TYPES.cashBonus] : {
        id: 'base-ui.gift-offer-type.cash-bonus',
        defaultMessage: 'cash bonus',
    },
    [GIFT_OFFER_TYPES.rebate] : {
        id: 'base-ui.gift-offer-type.rebate',
        defaultMessage: 'rebate',
    },
});

export { GIFT_OFFER_TYPES_MESSAGES };
export default GIFT_OFFER_TYPES;
