import { rgba } from 'polished';

import Colours from './Colours';


/* Opacity set to 0.70 instead of 0.99 so that image is visible when highlighted */
export const IMAGE_SELECTION_COLOUR = `${rgba(Colours.BLUEBERRY_LIGHT, 0.70)}`;

export default {
    IMAGE_SELECTION_COLOUR,
};
