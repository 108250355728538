import SPENDING_CATEGORIES from './SpendingCategories';


const SPENDING_CATEGORY_DEFAULTS = {
    [SPENDING_CATEGORIES.GROCERIES]:    341,
    [SPENDING_CATEGORIES.GAS]:          255,
    [SPENDING_CATEGORIES.RESTAURANTS]:  355,
    [SPENDING_CATEGORIES.BILLS]:        284,
    [SPENDING_CATEGORIES.TRAVEL]:       142,
    [SPENDING_CATEGORIES.ENTERTAINMENT]: 85,
    [SPENDING_CATEGORIES.PHARMACY]:      28,
    [SPENDING_CATEGORIES.OTHER]:        710,
};

const TOTAL_DEFAULT_SPENDING = Object.values(SPENDING_CATEGORY_DEFAULTS).reduce((sum, value) => sum + value); // 2200


export { TOTAL_DEFAULT_SPENDING };
export default SPENDING_CATEGORY_DEFAULTS;
