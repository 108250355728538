/**
 * Generate field options from the message strings and values
 * @param {object} values The values to translate
 * @param {object} messages 
 * @returns 
 */
export default function generateDropdownOptions(values, messages) {
    let missingTranslations = [];

    const dropdownOptions = Object.values(values)
        .reduce((options, value) => {
            if (!messages[value]) {
                missingTranslations.push(value);
            }
            
            options.push({
                value,
                label: messages[value],
            });

            return options;
        }, []);

    if (missingTranslations.length) {
        throw new Error(`Provided definitions are missing the following translations : ${missingTranslations}`);
    }

    return dropdownOptions;
}
