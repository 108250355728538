import isElementVisible from './isElementVisible';
import noticeError from './noticeError';


function getFirstInvalidInput(nodeOrSelector = document) {
    if (typeof window === 'undefined') {
        return;
    }

    const containerElement = typeof nodeOrSelector === 'string'
        ? document.querySelector(nodeOrSelector)
        : nodeOrSelector;

    if (!containerElement) {
        noticeError('[getFirstInvalidInput] could not find container element.');
        return;
    }

    // Loop through all invalid inputs until we can find 
    //  the first visible input
    const allInvalidInputs = Array.from(containerElement.querySelectorAll('[data-invalid="true"'));

    if (allInvalidInputs.length === 0) {
        return;
    }

    let index = 0;
    let currentInvalidInput = allInvalidInputs[index];
    
    // Remain in loop if we're not visible and we're still within the length of invalid inputs
    while (!isElementVisible(currentInvalidInput) && index < (allInvalidInputs.length - 1)) {
        index++;
        currentInvalidInput = allInvalidInputs[index];
    }
    
    return isElementVisible(currentInvalidInput) ? currentInvalidInput : undefined;
}


export default getFirstInvalidInput;
