import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { 
    usePageSettings, 
    IconMailOpen, 
    Sizes, 
    Colours,
} from '@ratehub/base-ui';


function NewsletterIconLabel({ hasHoverState }) {
    const intl = useIntl();
    const { businessUnit } = usePageSettings();

    return (
        <Container>
            <IconMailOpen
                className="icon"
                fill={Colours.GRAPE_LIGHTEST}
                stroke={Colours.GRAPE_DARK}
                outlineWidth="0"
                width="2em"
                height="2em"
            />

            <span className={
                classNames('label rh-fg-grape-dark weight-medium', {
                    'has-hover-state': hasHoverState,
                })}
            >
                {intl.formatMessage(getCTALabelMessage(businessUnit))}
            </span>
        </Container>
    );
}

NewsletterIconLabel.propTypes = {
    hasHoverState: PropTypes.bool,
};

NewsletterIconLabel.defaultProps = {
    hasHoverState: false,
};

function getCTALabelMessage(businessUnit) {
    return MESSAGES[`CTA_LABEL_${businessUnit?.toUpperCase()}`] ?? MESSAGES.CTA_LABEL_ALL;
}

const MESSAGES = defineMessages({
    CTA_LABEL_ALL: {
        id: 'web-components.SidebarNewsletterSignUpLarge.ctaLabelAll',
        defaultMessage: 'Ratehub.ca’s weekly newsletter',
    },
    CTA_LABEL_MORTGAGES: {
        id: 'web-components.SidebarNewsletterSignUpLarge.ctaLabelMortgages',
        defaultMessage: 'Sign up for rate updates',
    },
});

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${Sizes.SPACING.HALF};
    justify-content: center;

    font-size: 0.85rem; /* change this to scale icon & text together */

    > .icon {
        flex-shrink: 0;
    }

    > .label {
        /* Same size as CollapsibleContainer headings, which looks nice
        and consistent when all containers are collapsed */
        font-size: ${Sizes.FONTS.XS};

        &.has-hover-state {
            &:hover {
                color: ${Colours.GRAPE_DARKEST};
            }
        }
    }
`;

export default NewsletterIconLabel;
