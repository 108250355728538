import omit from 'lodash/omit';

import {
    BLOCK_ANIMATIONS,
    PAGE_CONTENT_BLOCK_ATTRIBUTES,
    LAYOUT_ROW_ATTRIBUTE_KEYS,
} from '@ratehub/web-components';


// function to remove redundant prop attributes
function getFilteredComponentAttributes(attributes, filterOptions = {
    shouldFilterPCBKeys: false,
    shouldFilterLayoutRowKeys: false,
    shouldFilterAnimationKeys: false,
}) {
    // Define which keys should be filtered out of attributes based on filterOptions.
    const attributesToFilter = {
        ...(filterOptions.shouldFilterPCBKeys && PAGE_CONTENT_BLOCK_ATTRIBUTES),
        ...(filterOptions.shouldFilterLayoutRowKeys && LAYOUT_ROW_ATTRIBUTE_KEYS),
        ...(filterOptions.shouldFilterAnimationKeys && BLOCK_ANIMATIONS),

        // rhId is always filtered out.
        RH_ID: 'rhId',
    };

    return omit(attributes, Object.values(attributesToFilter)); // returns object with the attributesToFilter filtered out
}

export default getFilteredComponentAttributes;
