import { defineMessages } from 'react-intl';

import { BusinessUnits } from '@ratehub/base-ui';


const BusinessUnitsMessages = defineMessages({
    [BusinessUnits.MORTGAGES]: {
        id: 'web-components.businessUnits.mortgages',
        defaultMessage: 'Mortgages',
    },
    [BusinessUnits.CREDIT_CARDS]: {
        id: 'web-components.businessUnits.credit_cards',
        defaultMessage: 'Credit cards',
    },
    [BusinessUnits.BANKING]: {
        id: 'web-components.businessUnits.banking',
        defaultMessage: 'Banking',
    },
    [BusinessUnits.INVESTING]: {
        id: 'web-components.businessUnits.investing',
        defaultMessage: 'Investing',
    },
    [BusinessUnits.INSURANCE]: {
        id: 'web-components.businessUnits.insurance',
        defaultMessage: 'Insurance',
    },
    [BusinessUnits.CRYPTO]: {
        id: 'web-components.businessUnits.crypto',
        defaultMessage: 'Cryptocurrency',
    },
    [BusinessUnits.ALL]: {
        id: 'web-components.businessUnits.all',
        defaultMessage: 'Everything',
    },
});


export default BusinessUnitsMessages;
