import React from 'react';
import { observer } from 'mobx-react-lite';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import {
    useApplyForRateCallback,
    SCENARIO_TYPES,
} from '@ratehub/mtg-common';
import {
    LegacyCityPropType,
    AnchorWithIcon,
    AnimatedOpacityContainer,
} from '@ratehub/base-ui';
import { CreditCardSynopsisShape } from '@ratehub/cc-common';

import useTrackSponsoredProductsHeapEvent from '../hooks/useTrackSponsoredProductsHeapEvent';
import Carousel from './Carousel';
import FeaturedProduct from './FeaturedProduct';


function FeaturedProductGroup({
    size,
    href,
    hrefText,
    products,
    isCarouselHidden,
    city,
    hideCTAIfNotMonetized,
    className,
    ...otherProps
}) {
    // filter out invalid products for tracking
    const validProducts = products.filter(product => product.productType && product.id);
    // used to track sponsored edb products onload
    useTrackSponsoredProductsHeapEvent(
        validProducts,
        validProducts?.[0]?.productType,
    );


    const containerWidth = size?.width;

    const isInitialized = !!containerWidth;

    const potentialColumns = !isInitialized
        ? DEFAULT_NUMBER_OF_SLOTS
        : Math.floor(containerWidth / MIN_CARD_WIDTH) < 1
            ? 1 // minimum of 1
            : Math.floor(containerWidth / MIN_CARD_WIDTH);

    const isCompact = getIsCompact(containerWidth, potentialColumns);

    // If potentialColumns is computed to a number higher than there are
    // available cards, limit visibleColumns to the number of available cards.
    // Default to 1 just in case.
    const visibleColumns = Math.max(Math.min(potentialColumns, DEFAULT_NUMBER_OF_SLOTS, products.length), 1);

    // Apply when we have one visible column.
    const hasMinWidth = visibleColumns === 1;

    // Apply if there is one visible column OR if there are multiple columns but is there is left-over space based on min card width
    // NOTE: This used to use potentialColumns in its calculation but that caused infinite re-renders fixed in FIT-3996
    const hasMaxWidth = visibleColumns === 1 || containerWidth > (MIN_CARD_WIDTH * products.length);

    const applyForRate = useApplyForRateCallback();

    function handleSelectRate({ rate }) {
        applyForRate({
            rate,
            rateFetchingOptions: {
                city,
                // We can assume that all rates passed to this handler are high ratio purchase rates
                scenario: SCENARIO_TYPES.PURCHASE,
            },
        });
    }


    return (
        <Container
            className={classNames('rh-text-align-center', className)}
            visibleColumns={visibleColumns}
            {...otherProps}
        >
            <If condition={Array.isArray(products) && products.length}>
                <AnimatedOpacityContainer
                    isOpaque={isInitialized}
                    alwaysRenderChildMarkup={true}
                >
                    <Carousel
                        as="ul"
                        isHidden={isCarouselHidden}
                        childContainerDataName="featuredProducts-carousel-inquire"
                        gap={GAP_WIDTH}
                        overflow="visible"
                    >
                        <For
                            each="product"
                            of={products}
                            index="index"
                        >
                            {/* If the product has a type or productType, render it as a FeaturedProduct.
                                Otherwise, skip it assuming it is an invalid/deleted product */}
                            <If condition={product.type || product.productType}>
                                <FeaturedProduct
                                    className="featured-product"
                                    key={index}
                                    productType={product.type || product.productType}
                                    /* hasPromo available on all products except mortgages */
                                    hasPromo={!!product.hasPromo}
                                    analyticsObject={product}
                                    creditCardSynopsis={product.creditCardSynopsis}
                                    {...product}
                                    mortgageCallback={handleSelectRate}
                                    city={city}

                                    hideCTAIfNotMonetized={hideCTAIfNotMonetized}
                                    hasMinWidth={hasMinWidth}
                                    hasMaxWidth={hasMaxWidth}
                                    isCompact={isCompact}
                                />
                            </If>
                        </For>
                    </Carousel>
                </AnimatedOpacityContainer>
            </If>

            <If condition={href && hrefText}>
                <AnchorWithIcon
                    className="rh-mt-3"
                    href={href}
                    message={hrefText}
                    dataName="featuredProductGroup-compareAll-cta"
                />
            </If>
        </Container>
    );
}

FeaturedProductGroup.propTypes = {
    size: PropTypes.object.isRequired,
    href: PropTypes.string,
    hrefText: PropTypes.string,

    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,

            type: PropTypes.string, // one or the other of these two
            productType: PropTypes.string,

            title: PropTypes.string,
            isSponsored: PropTypes.bool,
            hasPromo: PropTypes.bool,

            applyHref: PropTypes.string,
            applyText: PropTypes.string,
            imageSrc: PropTypes.string,
            imageAlt: PropTypes.string,

            rate: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
                PropTypes.object,
            ]),
            description: PropTypes.string,

            detailsHref: PropTypes.string,
            providerId: PropTypes.number,
            providerSlug: PropTypes.string,
            providerName: PropTypes.string,
            isMonetized: PropTypes.bool,

            analyticsObject: PropTypes.object,
            creditCardSynopsis: PropTypes.shape(CreditCardSynopsisShape),
        }),
    ).isRequired,

    isCarouselHidden: PropTypes.bool,
    city: LegacyCityPropType.isRequired,
    hideCTAIfNotMonetized: PropTypes.bool,      // this prop is only relevant to credit cards at the moment
    className: PropTypes.string,
};

FeaturedProductGroup.defaultProps = {
    href: undefined,
    hrefText: undefined,
    isCarouselHidden: false,
    hideCTAIfNotMonetized: false,
    className: undefined,
};

const GAP_WIDTH = '1rem'; // String, with unit
const MIN_CARD_WIDTH = 240; // In pixels, no unit

const BREAKPOINTS = {
    SMALL: 650,
    MEDIUM: 850,
    LARGE: 1500,
};

// The number of cards beyond which isCompact will get set to true for each
// container size.
const MIN_CARDS_COMPACT = {
    // anything below small defaults to 2
    [BREAKPOINTS.SMALL]: 2, // above SMALL = 2
    [BREAKPOINTS.MEDIUM]: 3, // above MEDIUM = 3
    [BREAKPOINTS.LARGE]: 4, // above LARGE = 4
};

const DEFAULT_NUMBER_OF_SLOTS = 4;


function getIsCompact(containerSize, columnCount) {
    let matchingBreakpoint = null;

    // Find breakpoint
    Object.values(BREAKPOINTS).forEach(breakpoint => {
        if (containerSize >= breakpoint) {
            matchingBreakpoint = breakpoint;
        }
    });

    // Get number of cards that can show in that breakpoint before needing
    // to enable isCompact
    const maxNonCompactCards = matchingBreakpoint
        ? MIN_CARDS_COMPACT[matchingBreakpoint]
        : 2; // anything below BREAKPOINTS.SMALL

    // Should be true if trying to show more columns than the maximum
    // number of non-compact cards.
    return columnCount >= maxNonCompactCards;
}

const Container = styled.div`
    /* Calculate visibleColumns:
    The number of gutters is always visibleColumns - 1 so if you want 3
    columns with 1rem gaps, you can't just do calc(33.3% - 2rem) because that
    takes 2rem away from ALL three columns.
    We need to subtract each column's *share* of the total gutter width. For
    our 3-col example, the following basically says calc(33.3% - ((2 / 3) *
    1rem)). */
    ${props => props.visibleColumns > 1 && `
        .featured-product {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc((100% / ${props.visibleColumns}) - ((${props.visibleColumns - 1} / ${props.visibleColumns}) * ${GAP_WIDTH}));
        }
    `}

    /* Nasty hack to to prevent credit card buttons with labels saying
    "check eligibility 🎁" from propping open the cards and making the whole
    product group too wide for its container. Buttons have a size prop
    we can use (and we do – we're passing "small") but we don't want to
    introduce a size="none" option to solve this one problem. */
    .cc-cta {
        padding-left: 0;
        padding-right: 0;
    }
`;

export default withSize({ noPlaceholder: true })(observer(FeaturedProductGroup));
