const FIELD_TYPES = {
    ANY: 'any',
    ADDRESS: 'address',
    BOOLEAN: 'boolean',
    CITY: 'city',
    CHOICE: 'choice',
    MULTIPLE_CHOICE: 'multiplechoice',
    DATE: 'date',
    EMAIL: 'email',
    FLOAT: 'float',
    INTEGER: 'integer',
    STRING: 'string',
    POSTAL_CODE: 'postalcode',
    PHONE: 'phone',
};

export default FIELD_TYPES;