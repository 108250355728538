import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';
import noticeError from './noticeError';


/**
 * Retrieve city information.
 * @param {object} city - city object which contains various identifying data
 * @param {number} city.id - Deprecated - please use city.name and optionally city.province
 * @param {string} city.slug - Deprecated - please use city.name and optionally city.province
 * @param {string} city.name - the name of the city
 * @param {string} city.province - province code where the city is located
 * @param {string} callerName name of the caller when generating Error messages
 * @returns {object}
 */
async function fetchCityInformation(city, callerName) {
    if (!city?.id && !city?.name && !city?.slug) {
        const error = new Error('[fetchCityInformation] must receive either cityId by itself or city with optional province');

        noticeError(error, {
            city,
        });

        throw error;
    }

    // This way of searching should be deprecated.
    if (city.id) {
        return fetchFromApi({ cityId: city.id }, callerName);
    }

    // Search by "<CityName>, <ProvinceCode>" where province code is optional.
    //  Slug should be deprecated in the future, we prefer city.name
    let cityParam = city.slug ?? city.name;

    if (city.province) {
        cityParam += `, ${city.province}`;
    }

    return fetchFromApi({ city: cityParam }, callerName);
}

async function fetchFromApi(params, callerName) {
    const target = {
        url: Config.CITIES_API_URL,
        params: params,
    };

    // fetchJSON handles errors for us, we don't need to catch here
    const response = await fetchJSON(target, callerName);

    // ASSUMPTION: if the response has a .data, then we are assuming it's the new API format.
    return response.data ?? response;
}

export default fetchCityInformation;
