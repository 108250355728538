import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';


const Context = createContext();

function CondensedTheme({ value, children }) {
    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    );
}

CondensedTheme.propTypes = {
    value: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

CondensedTheme.defaultProps = {
    value: true,
};

function useCondensedTheme() {
    return useContext(Context) ?? false;
}

export default CondensedTheme;
export { useCondensedTheme };