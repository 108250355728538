/**
 * Formats the given number in a localized manner (remember, in FR it’s 8.99 %, not 8.99%)
 * @param {number} percent number, typically in the 0…100 range, not 0…1
 * @param {IntlShape} intl
 * @param {number} [minDecimalPlaces=2] the minimum number of decimal places to show
 * @param {number} [maxDecimalPlaces=minDecimalPlaces] the maximum number of decimal places to show
 * @example
 * // returns '2,5 %'
 * formatPercentage(2.501, intl, 1, 2)      // when locale fr-CA
 * @return {string} localized percentage string
 */
function formatPercentage(percent, intl, minDecimalPlaces = 2, maxDecimalPlaces) {
    return intl.formatNumber(percent / 100, {
        style: 'percent',
        minimumFractionDigits: minDecimalPlaces,
        maximumFractionDigits: maxDecimalPlaces ?? minDecimalPlaces,
    });
}

export default formatPercentage;
