
/**
 * Extracts the language code; that prefixes a locale string.
 * 
 * @param {string} locale 
 * @returns {string}
 */
export default function getLanguageFromLocale(locale) {
    if (!locale) {
        throw new Error('No locale provided.');
    }

    const [ languageCode ] = locale.split('-'); // ['en', 'CA']

    return languageCode;
}