/**
 * Get the URL that the user should be directed to for the given rate. 
 * CPA and CPC rates have special requirements about which URL to open.
 * 
 * @param {object} rate
 * @param {string} rate.cpaLink
 * @param {string} rate.cpcLink
 * @param {string} rate.href
 * 
 * @returns {string} URL
 */
export default function getRateUrl({ cpaLink, cpcLink, href }) {
    return cpaLink ?? cpcLink ?? href; 
}