import { defineMessages } from 'react-intl';

import Brands from '../definitions/Brands';

/**
 * getBrandLettermark()
 *
 * @param {string} brand - one of the brands from Brands.js
 * @param {object} options - additional specifics needed for certain brands
 * @param {string} options.theme - 'dark' or 'light' - default 'dark'
 * @returns {object} a brand object containing a filePath, intrinsic dimentions & alt text for that brand
 */
function getBrandLettermark(brand, options) {
    const theme = options.theme ?? THEMES.DARK;

    return BRAND_LETTERMARKS[brand]?.[theme] ?? null;
}

const THEMES = {
    DARK: 'dark',
    LIGHT: 'light',
};

const MESSAGES = defineMessages({
    ALT_TEXT_RATEHUB: {
        id: 'base-ui.logoLettermark.altTextRatehub',
        defaultMessage: 'Ratehub logo',
    },
    ALT_TEXT_RH_INSURANCE: {
        id: 'base-ui.logoLettermark.altTextRHInsurance',
        defaultMessage: 'RH Insurance logo',
    },
});

const BRAND_LETTERMARKS = {
    [Brands.RATEHUB]: {
        [THEMES.DARK]: {
            filePath: '/static/logo_ratehub_lettermark_dark.svg',
            intrinsicWidth: 21,
            intrinsicHeight: 21,
            altText: MESSAGES.ALT_TEXT_RATEHUB,
        },
        [THEMES.LIGHT]: {
            filePath: '/static/logo_ratehub_lettermark_light.svg',
            intrinsicWidth: 21,
            intrinsicHeight: 21,
            altText: MESSAGES.ALT_TEXT_RATEHUB,
        },
    },

    [Brands.RH_INSURANCE]: {
        [THEMES.DARK]: {
            filePath: '/static/logo_rh_insurance_lettermark_dark.svg',
            intrinsicWidth: 36.4,
            intrinsicHeight: 23.8,
            altText: MESSAGES.ALT_TEXT_RH_INSURANCE,
        },
        [THEMES.LIGHT]: {
            filePath: '/static/logo_rh_insurance_lettermark_light.svg',
            intrinsicWidth: 36.4,
            intrinsicHeight: 23.8,
            altText: MESSAGES.ALT_TEXT_RH_INSURANCE,
        },
    },
};

export default getBrandLettermark;
