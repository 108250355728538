import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';

/**
 * Fetches historical chart data based on slugs and date range
 *
 * @param seriesSlugs {array} an array of slugs used to identify a series. (eg. 'best-rates.5y-fixed.max')
 * @param xStart {string} start date for date range filter - use YYYY-MM-DD
 * @param xEnd {string} end date for date range filter - use YYYY-MM-DD
 * @para xFormat {string} format for x axis data (default: 'datetime')
 * @param lang {string?} optional language code for fetching localized data
 * @returns {object} contains a series data and x axis data
 */

async function fetchHistoricalData({ series = [], xFormat = 'datetime', xStart, xEnd, language = 'en' }){
    if (series.length === 0) {
        throw new Error('[fetchHistoricalData] series required.');
    }

    const target = {
        url: Config.HISTORICAL_DATA_API_URL,
        params: {
            series: series.filter(Boolean).join(','),
            xFormat,
            xStart,
            ...(xEnd && { xEnd }),
            language,
        },
    };

    return fetchJSON(target, 'fetchHistoricalData');
}

export default fetchHistoricalData;
