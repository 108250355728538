import { FOREGROUND_COLOUR_CLASS_MAP } from '../definitions/Colours';

/**
* @param {string} fgColour foreground colour as a hex value
* @returns {string} .rh-fg-* colour utility class
*/
function getFGColourClassName(fgColour) {
    const matchingColourObject = FOREGROUND_COLOUR_CLASS_MAP
        .find(colourObject => colourObject.value === fgColour);

    return matchingColourObject.className;
}

export default getFGColourClassName;
