/**
 * NOTE TO DEVS:
 * Tried many times to do a direct request, but cannot due to CORS.
 * Read many StackOverflow articles which insist you HAVE to use the script :(
 *
 * Reference Material:
 * https://developers.google.com/maps/documentation/javascript/places
 * https://developers.google.com/maps/documentation/javascript/places#place_details
 * */
import mountGooglePlacesApi from './mountGooglePlacesApi';
import extractStreetNameDetails from './extractStreetNameDetails';


let service = null; // We only want to spawn it ONCE.
let OK = null;      // Google's "everything went fine" status value.
let ZERO_RESULTS = null;   // Google's "sorry we don't have anything"


/**
 * @async
 * Make an async request against the Google Place Details API.
 * @param {string} value placeId to look-up details for.
 * @returns {object}
 */
async function fetchAddressDetails(value) {

    // Ensure script is loaded.
    if (!global.google || !global.google.maps || !global.google.maps.places) {
        await mountGooglePlacesApi();
    }

    const response = await fetchPlaceDetailsAsPromise(value);

    // Re-map it into our desired shape.
    return {
        streetNumber: getAddressValue(response, 'street_number'),

        // streetName, streetType and streetDirection
        ...extractStreetNameDetails(getAddressValue(response, 'route')),

        province: getAddressValue(response, 'administrative_area_level_1'),
        provinceCode: getAddressValue(response, 'administrative_area_level_1', 'short_name'),
        city: getAddressValue(response, 'locality'),
        country: getAddressValue(response, 'country'),
        countryCode: getAddressValue(response, 'country', 'short_name'),

        postalCode: getAddressValue(response, 'postal_code'),
    };
}

/**
 * @private
 * @async
 * Google Places API only provides a callback API;
 * This returns it as a Promise.
 * @param {string} value
 * @returns {[object]}
 */
function fetchPlaceDetailsAsPromise(value) {

    // ASSUMPTION: script is already mounted.
    /* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
    service = service || new google.maps.Geocoder();
    OK = OK || google.maps.GeocoderStatus.OK;
    ZERO_RESULTS = ZERO_RESULTS || google.maps.GeocoderStatus.ZERO_RESULTS;
    /* eslint-enable jsx-control-statements/jsx-jcs-no-undef */

    return new Promise(function(resolve, reject) {
        service.geocode(
            {
                placeId: value,
            },
            (response, status) => {
                if (status === OK) {
                    // We requested by ID; there will only be one.
                    const entry = Array.isArray(response) && response.length > 0
                        ? response[0]
                        : {};

                    resolve(entry);
                }
                // If we receive no results, resolve with an empty object since this isn't necessarily an error. 
                //  It just means the address doesn't exist within the Google API.
                else if (status === ZERO_RESULTS) {
                    resolve({});
                }
                else {
                    reject(`Google Places Details API failed with status: ${status} and response: ${response}`);
                }
            },
        );
    });
}

/**
 * @private
 * Extract a type of value from a Google address details response
 * @param {object} address object to search
 * @param {string} typeName type of "address_component" to locate
 * @param {string} key which value from the "address_component" to return.
 */
function getAddressValue(address, typeName, key = 'long_name') {

    // Google response has shape of:
    // {
    //     "address_components": [
    //         {
    //             "short_name": "",
    //             "long_name": "",
    //             "types": []
    //         },
    //     ]
    // }

    if (!('address_components' in address)) {
        return undefined;
    }

    const requestedComponent = address.address_components.find(entry =>
        Array.isArray(entry.types) && entry.types.includes(typeName),
    );

    return requestedComponent ? requestedComponent[key] : undefined;
}

export default fetchAddressDetails;
