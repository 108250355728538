import { useContext } from 'react';

import useForm from '../hooks/useForm';
import { Context } from '../components/Fieldset';


function useFieldset() {
    const form = useForm();
    const fieldsetState = useContext(Context);

    // We don't want to find a fieldset that doesn't belong to the nearest form in the tree
    return form === fieldsetState?.form
        ? fieldsetState
        : null;
}

export default useFieldset;