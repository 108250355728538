import React from 'react';
import PropTypes from 'prop-types';

import { TableRawHTML, TABLE_STYLES } from '@ratehub/base-ui';


function GeneratedTable({ content, ...otherProps }) {
    return (
        <TableRawHTML
            {...otherProps}
            dangerouslySetInnerHTML={{
                __html: content,
            }}
        />
    );
}

GeneratedTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    hasFixedLayout: PropTypes.bool,
    hasFooter: PropTypes.bool,
    hasHeader: PropTypes.bool,
    content: PropTypes.string.isRequired,
    tableStyle: PropTypes.oneOf(Object.values(TABLE_STYLES)),
};

GeneratedTable.defaultProps = {
    title: undefined,
    subtitle: undefined,
    hasFixedLayout: false,
    hasFooter: false,
    hasHeader: false,
    tableStyle: TABLE_STYLES.DEFAULT,
};



GeneratedTable.blockKey = 'rh/generated-table';
GeneratedTable.requiresLayoutRow = true;

export default GeneratedTable;
