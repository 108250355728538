import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import EASINGS from '../definitions/Easings';


const DIRECTIONS = {
    UP: 'up',
    DOWN: 'down',
    LEFT: 'left',
    RIGHT: 'right',
};
const DISTANCES = {
    LONG: 'long', // translates from off-screen
    SHORT: 'short', // translates by a distance determined by the child element's size
};

// AnimatedSlideAndFadeContainer forwards a ref to this component so it doesn't
// have to output its own DOM element.
const AnimatedTranslateContainer = forwardRef(({
    isVisible = true,
    isMountAnimated = true,

    direction = DIRECTIONS.LEFT,
    duration = 300,
    easing = EASINGS.OUT,
    delay = 0,
    distance = DISTANCES.LONG,

    onEnter,
    onEntered,
    onExit,
    onExited,

    children,

    ...otherProps
}, ref) => {
    // we don't want to move to "x-state-done" until both the duration AND the
    // delay are complete. Otherwise, it blows away the "x-state-active" class
    // containing the transition BEFORE the element has completed it's full
    // timeline (cuts the animation off early)
    const timeout = duration + delay;

    const [ documentSize, setDocumentSize ] = useState(() => ({
        width: typeof document !== 'undefined' ? document.documentElement.scrollWidth : 0,
        height: typeof document !== 'undefined' ? document.documentElement.scrollHeight : 0,
    }));

    useEffect(() => {
        function handleResize() {
            if (typeof document !== 'undefined') {
                setDocumentSize({
                    width: document.documentElement.scrollWidth,
                    height: document.documentElement.scrollHeight,
                });
            }
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    return (
        <CSSTransition
            in={isVisible}
            appear={isMountAnimated}
            mountOnEnter={false}
            unmountOnExit={false}
            enter={true}
            timeout={{
                appear: timeout,
                enter: timeout,
                exit: timeout,
            }}
            classNames="translate"

            onEnter={onEnter}
            onExit={onExit}

            onEntered={onEntered}
            onExited={onExited}
        >
            <Container
                ref={ref}
                $direction={direction}
                duration={duration}
                easing={easing}
                delay={delay}
                distance={distance}
                documentSize={documentSize}
                {...otherProps}
            >
                {children}
            </Container>
        </CSSTransition>
    );
});

AnimatedTranslateContainer.propTypes = {
    isVisible: PropTypes.bool,
    isMountAnimated: PropTypes.bool,

    direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
    duration: PropTypes.number, // in milliseconds
    easing: PropTypes.oneOf(Object.values(EASINGS)),
    delay: PropTypes.number, // in milliseconds
    distance: PropTypes.oneOf(Object.values(DISTANCES)),

    onEnter: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExited: PropTypes.func,

    children: PropTypes.node.isRequired,
};

const Container = styled.div`
    /* "Reveal" animation */
    &.translate-appear,
    &.translate-enter {
        transform: ${props => getOffscreenTransform(props.$direction, props.documentSize, props.distance)};
    }
    &.translate-appear-active,
    &.translate-enter-active {
        transform: translate(0, 0);
        transition: transform ${props => props.duration}ms ${props => props.easing} ${props => props.delay}ms;
    }
    &.translate-appear-done,
    &.translate-enter-done {
        transform: translate(0, 0);
    }

    /* "Hide" animation */
    &.translate-exit {
        transform: translate(0, 0);
    }
    &.translate-exit-active {
        transform: ${props => getOffscreenTransform(props.$direction, props.documentSize, props.distance)};
        transition: transform ${props => props.duration}ms ${props => props.easing} ${props => props.delay}ms;
    }
    &.translate-exit-done {
        transform: ${props => getOffscreenTransform(props.$direction, props.documentSize, props.distance)};
    }
`;

function getOffscreenTransform(direction, documentSize, distance) {
    if (distance === DISTANCES.SHORT) {
        switch (direction) {
            case DIRECTIONS.UP:
                return 'translate(0, -25%)';
            case DIRECTIONS.DOWN:
                return 'translate(0, 25%)';
            case DIRECTIONS.LEFT:
                return 'translate(-25%, 0)';
            case DIRECTIONS.RIGHT:
                return 'translate(25%, 0)';
        }
    } else {
        switch (direction) {
            case DIRECTIONS.UP:
                return `translate(0, calc(-100% - ${documentSize.height}px))`;
            case DIRECTIONS.DOWN:
                return `translate(0, calc(100% + ${documentSize.height}px))`;
            case DIRECTIONS.LEFT:
                return `translate(calc(-100% - ${documentSize.width}px), 0)`;
            case DIRECTIONS.RIGHT:
                return `translate(calc(100% + ${documentSize.width}px), 0)`;
        }
    }
}

export default AnimatedTranslateContainer;
export { DIRECTIONS, DISTANCES };
