import React from 'react';
import PropTypes from 'prop-types';


function WPDataTable({ tableHTML }) {
    return (
        <div
            className="rh-wp-data-table"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: tableHTML,
            }}
        />
    );
}

WPDataTable.propTypes = {
    tableHTML: PropTypes.string,
};

WPDataTable.defaultProps = {
    tableHTML: undefined,
};

WPDataTable.blockKey = 'rh/wp-data-table';
WPDataTable.requiresLayoutRow = true;

export default WPDataTable;