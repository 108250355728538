import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Languages, getLanguageFromLocale } from '@ratehub/base-ui';
import { LayoutRow } from '@ratehub/web-components';


/**
 * Disqus code likes to have the following const set for identifying comments for posts,
 * --- this.page.identifier = ${postData.postID}
 * As a fallback, they use the page URL. Setting an identifier only seems to confuse it,
 * so as we are keeping the URL structure the same we are going to rely on the fallback
 * to keep our comments. In future we may wish to revive this
 */
function BlogComments({ postData, className, ...otherProps }) {
    const intl = useIntl();
    const languageCode = getLanguageFromLocale(intl.locale);
    const disqusID = languageCode === Languages.FRENCH
        ? 'ratehub-ca-fr'
        : 'ratehub-ca';

    // we don't want to inject the script on the server or we'll have hydration issues
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'disqus-script';

        script.innerHTML = `
            var disqus_config = function () {
                this.page.url = "${postData.postURL}";
                this.page.title = "${encodeURI(postData.postTitle)}";
            };

            (function generateDisqusScript() {  // DON'T EDIT BELOW THIS LINE
                var d = document, s = d.createElement('script');

                s.src = 'https://${disqusID}.disqus.com/embed.js';

                s.setAttribute('data-timestamp', +new Date());
                (d.head || d.body).appendChild(s);
            })();
        `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [ disqusID ]);

    return (
        <LayoutRow
            className={classNames('rh-layout-default', className)}
            flexDirection="column"
            includeContentPadding={false}
            {...otherProps}
        >
            <div id="disqus_thread" />

            <noscript>
                {'Please enable JavaScript to view the'}
                <a
                    href="https://disqus.com/?ref_noscript"
                    rel="nofollow"
                >
                    {'comments powered by Disqus.'}
                </a>
            </noscript>
        </LayoutRow>
    );
}

BlogComments.propTypes = {
    postData: PropTypes.shape({
        postURL: PropTypes.string,
        postID: PropTypes.number,
        postTitle: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
};

export default BlogComments;
