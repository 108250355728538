/**
 * NOTE TO DEVS:
 * Tried many times to do a direct request, but cannot due to CORS.
 * Read many StackOverflow articles which insist you HAVE to use the script :(
 *
 * Reference Material:
 * https://developers.google.com/maps/documentation/javascript/places
 * https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
 */
import ADDRESS_SEARCH_TYPE from '../definitions/AddressSearchType';
import mountGooglePlacesApi from './mountGooglePlacesApi';
import timeoutAsPromise from './timeoutAsPromise';
import noticeError from './noticeError';



let service = null; // We only want to spawn it ONCE.
let OK = null;      // Google's "everything went fine" status value.
let ZERO_RESULTS = null;    // Google's "sorry we don't have anything"
let UNKNOWN_ERROR = null;    // Google's indication that request could not be processed due to a server error

const DEFAULT_OPTIONS = {
    componentRestrictions: {
        country: 'ca',
    },
    types: [ ADDRESS_SEARCH_TYPE.ADDRESS ],
};


/**
 * Make an async request against the Google Places Autocomplete API.
 * @param {string} value what to offer suggestions for.
 * @param {object} options
 * @returns {[object]}
 */
async function fetchAddressSuggestion(value, options) {
    if (!global.google || !global.google.maps || !global.google.maps.places) {
        await mountGooglePlacesApi();
    }

    // ASSUMPTION: script is already mounted.
    /* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
    service = service || new google.maps.places.AutocompleteService();
    OK = OK || google.maps.places.PlacesServiceStatus.OK;
    ZERO_RESULTS = ZERO_RESULTS || google.maps.places.PlacesServiceStatus.ZERO_RESULTS;
    UNKNOWN_ERROR = UNKNOWN_ERROR || google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR;

    const predictions = await fetchPlacePredictionsWithRetries(value, options);

    // Re-construct them to our expected shape.
    return predictions.map(entry => ({
        id: entry.id,
        placeId: entry.place_id,

        description: entry.description,
        streetName: entry.structured_formatting.main_text,
        cityName: entry.structured_formatting.secondary_text,
    }));
}


/**
 * @private
 * @async
 * Retry the predictions fetch when we receive an UNKNOWN_ERROR
 * @param {string} value
 * @param {object} options
 * @param {number} currentAttempt - Current attempt number
 * @returns {Promise<[object]>}
 */
async function fetchPlacePredictionsWithRetries(value, options, currentAttempt = 0) {    
    try {
        return await fetchPlacePredictionsAsPromise(value, options);
    } catch (error) {
        let errorMessage = '[fetchPlacePredictionsWithRetries]';
        
        // Only retry when we receive an UNKNOWN_ERROR and we're under 3 attempts
        if (error.status === UNKNOWN_ERROR && currentAttempt < 3) {
            // Increase attempts since we're going to start a new one
            currentAttempt++;

            // Increase timeout between attempts by 200ms
            await timeoutAsPromise(200 * currentAttempt);

            return fetchPlacePredictionsWithRetries(value, options, currentAttempt);
        } else if (error.status === UNKNOWN_ERROR && currentAttempt >= 3) {
            errorMessage += ' Exceeded amount of retries (3)';
        } else {
            errorMessage += ` Google Places Autocomplete API failed with status ${error.status} and response: ${error.predictions}`;
        }

        noticeError(
            error,
            {
                message: errorMessage,
            },
        );

        throw error;
    }
}

/**
 * @private
 * @async
 * Google Places API only provides a callback API;
 * This returns it as a Promise.
 * @param {string} value
 * @returns {Promise<[object]>}
 */
function fetchPlacePredictionsAsPromise(value, { types = DEFAULT_OPTIONS.types } = DEFAULT_OPTIONS) {
    return new Promise(function(resolve, reject) {
        service.getPlacePredictions(
            {
                ...DEFAULT_OPTIONS,
                types,
                input: value,
            },
            (predictions, status) => {
                if (status === ZERO_RESULTS) {
                    resolve([]);
                } else if (status === OK) {
                    resolve(predictions);
                } else {
                    reject({ status, predictions });
                }
            },
        );
    });
}

export default fetchAddressSuggestion;