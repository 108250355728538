import React from 'react';
import PropTypes from 'prop-types';
import { IconChooser, IconChooserSizes, IconMapLazy, Colours } from '@ratehub/base-ui';


function IconChooserBlock({
    iconKey,
    size,
    includeOutline,
    strokeColour,
    fillColour,
    ...otherProps
}) {
    return (
        <IconChooser
            iconKey={iconKey}
            size={size}
            includeOutline={includeOutline}
            stroke={strokeColour}
            fill={fillColour}
            {...otherProps}
        />
    );
}

IconChooserBlock.propTypes = {
    iconKey: PropTypes.oneOf(Object.keys(IconMapLazy)).isRequired,
    size: PropTypes.oneOf(Object.keys(IconChooserSizes)),
    includeOutline: PropTypes.bool,
    strokeColour: PropTypes.oneOf(Object.values(Colours)),
    fillColour: PropTypes.oneOf(Object.values(Colours)),
};

IconChooserBlock.defaultProps = {
    size: 'XL',
    includeOutline: true,
    strokeColour: Colours.BLUEBERRY,
    fillColour: Colours.TRANSPARENT,
};

IconChooserBlock.blockKey = 'rh/icon-chooser';
IconChooserBlock.hasSpacingOptions = true;

export default IconChooserBlock;
