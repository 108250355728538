export default function getOptionByValue(options, value) {
    if (value === undefined) {
        return undefined;
    }

    for (let i = 0; i < options.length; i++) {
        if (options[i].options) {
            for (let j = 0; j < options[i].options.length; j++) {
                if (options[i].options[j].value === value) {
                    return options[i].options[j];
                }
            }
        }
        else if (options[i].value === value) {
            return options[i];
        }
    }
}