import { useState, useEffect } from 'react';
import { when } from 'mobx';

import { getExperimentSegment } from '../functions/getExperimentSegment';


function useExperiment(experiment) {
    // NOTE: if the experiment has already been previously computed, we will have an answer immediately.
    const [ segment, setSegment ] = useState(() => experiment 
        ? getExperimentSegment(experiment) 
        : 0,     // ASSUMPTION: user wanted to conditionally call the hook, but cannot due to React rules. Act like control.
    );

    // If it's not yet resolved, re-render once it has resolved.
    useEffect(() => {
        if (segment != null) {
            return;
        }

        // When we get an actual segment, request a re-render.
        // By returning the disposer, will cancel any retrieval if slug name changes.
        return when(
            () => getExperimentSegment(experiment) != null,
            () => setSegment(getExperimentSegment(experiment)),
            { name: `useExperiment(${experiment?.slug ?? experiment })` },
        );
    }, [ experiment, segment ]);

    return {
        segment,
        isVisible: segment != null,
    };
}

export default useExperiment;
