import { defineMessages } from 'react-intl';

import { InsLifeWizardInsuranceTypes } from '@ratehub/documents/ins-life-document/stable';


// These messages exist here rather than ins-life-wizard because they are shared with the quote launcher as well
export default defineMessages({
    // Standard
    [InsLifeWizardInsuranceTypes.TERM]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.term',
        defaultMessage: 'Term Life Insurance',
    },
    [InsLifeWizardInsuranceTypes.WHOLE]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.whole',
        defaultMessage: 'Whole Life Insurance',
    },
    [InsLifeWizardInsuranceTypes.UNIVERSAL]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.universal',
        defaultMessage: 'Universal Life Insurance',
    },
    [InsLifeWizardInsuranceTypes.CRITICAL]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.critical',
        defaultMessage: 'Critical Illness Insurance',
    },
    [InsLifeWizardInsuranceTypes.FUNERAL]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.funeral',
        defaultMessage: 'Funeral Insurance',
    },
    [InsLifeWizardInsuranceTypes.MORTGAGE]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.mortgage',
        defaultMessage: 'Mortgage Insurance',
    },
    [InsLifeWizardInsuranceTypes.JOINT_LIFE]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.jointLife',
        defaultMessage: 'Joint Life Insurance',
    },
    [InsLifeWizardInsuranceTypes.RENEWABLE]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.renewable',
        defaultMessage: 'Renewable Life Insurance',
    },
    [InsLifeWizardInsuranceTypes.SENIOR]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.senior',
        defaultMessage: 'Seniors Life Insurance',
    },
    // Disability
    [InsLifeWizardInsuranceTypes.DISABILITY]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.disability',
        defaultMessage: 'Disability Insurance',
    },
    // Group
    [InsLifeWizardInsuranceTypes.GROUP]: {
        id: 'InsLifeWizard.LifeInsuranceTypeMessages.group',
        defaultMessage: 'Group Insurance',
    },
});
