import React from 'react';
import PropTypes from 'prop-types';

import ModalContainer from './ModalContainer';
import EmailCaptureScreen from './EmailCaptureScreen';


function EmailCaptureModal({
    isOpen,
    onClose,
    onSubmit,
    ...otherProps
}) {

    return (
        <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            showBranding
            contentMaxWidth="712px"
            {...otherProps}
        >
            <EmailCaptureScreen
                onSubmit={onSubmit}
                onClose={onClose}
            />
        </ModalContainer>
    );
}

EmailCaptureModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

EmailCaptureModal.defaultProps = {
    isOpen: true,
    onClose: undefined,
    onSubmit: undefined,
};


export default EmailCaptureModal;
