import ExperimentSegment from '../components/ExperimentSegment';
import HERO_BLOCK_KEYS from '../definitions/HeroBlockKeys';
import DefaultHeroImage from '../definitions/DefaultHeroImage';
import getHeroBlocks from './getHeroBlocks';
import getFeaturedMedia from './getFeaturedMedia';


function getHeroDetailsFromPost(post) {
    if (post.rh_featured_media) {

        const featuredMedia = getFeaturedMedia(post);

        return {
            imageCollection: featuredMedia,
            imageAlt: featuredMedia.imageAlt,
        };
    }

    const blocks = post.rh_blocks;
    const heroBlocks = getHeroBlocks(blocks) ?? [];
    const chosenHero = heroBlocks.find(block => HERO_BLOCK_KEYS.includes(block.blockName));

    // If a hero is present, use that.
    if (chosenHero && chosenHero.attrs?.imageCollection) {
        return {
            imageCollection: chosenHero.attrs?.imageCollection ?? {},
            imageAlt: chosenHero.attrs?.imageAlt ?? chosenHero.attrs?.imageCollection?.imageAlt ?? '',
        };
    }

    // If there's no hero, take the first hero inside an experiment
    const heroExperimentHero = heroBlocks
        .find(block => block.blockName === ExperimentSegment.blockKey)
        ?.innerBlocks
        .find(innerBlock => HERO_BLOCK_KEYS.includes(innerBlock.blockName));

    if (heroExperimentHero) {
        return {
            imageCollection: heroExperimentHero.attrs?.imageCollection ?? {},
            imageAlt: heroExperimentHero.attrs?.imageAlt ?? heroExperimentHero.attrs?.imageCollection?.imageAlt ?? '',
        };
    }

    // eslint-disable-next-line no-console
    console.info(`No hero found for post with ID of ${post.id} and slug of ${post.slug}`);

    return DefaultHeroImage;
}

export default getHeroDetailsFromPost;
