export default function getNextFormFocusElement(currentQuestionName) {
    const allQuestions = document.querySelectorAll('[data-question-name]');

    if (allQuestions.length === 0) {
        return null;
    }

    // If we're in a question group, we want to select the last question in the group
    //  so that the next element is the first question outside of the group.
    const allQuestionsWithinGroup = document.querySelectorAll(
        `[data-question-name='${currentQuestionName}'] [data-question-name]`,
    );
    
    if (allQuestionsWithinGroup.length > 0) {
        currentQuestionName = allQuestionsWithinGroup?.[allQuestionsWithinGroup.length - 1].dataset.questionName;
    }

    if (!currentQuestionName) {
        return allQuestions[0];
    }

    // allQuestions is a NodeList and does not support Array functions like
    //  .map & .indexOf. We could use .forEach but that's not supported in IE11
    let index = 0;
    let currentQuestionIndex = null;

    while (currentQuestionIndex === null && index < allQuestions.length) {
        if (allQuestions[index].dataset.questionName === currentQuestionName) {
            currentQuestionIndex = index;
        }

        index++;
    }

    // Requirement: If we're the last input, return the submit button
    if (currentQuestionIndex === allQuestions.length - 1) {
        return document.querySelector('button[type="submit"]');
    }

    return currentQuestionIndex !== null
        ? allQuestions[currentQuestionIndex + 1]
        : null;
}