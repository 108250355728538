import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import LayoutRow from './LayoutRow';
import CallToActionBlock from './CallToActionBlock';
import HeroBannerWide from './HeroBannerWide';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function HeroBanner({ variant, isWide, imageUrl, imageCollection, className, includeContentPadding, ...otherProps }) {
    if (isWide) {
        return (
            <HeroBannerWide
                variant={variant}
                imageUrl={imageUrl}
                imageCollection={imageCollection}
                {...otherProps}
            />
        );
    }

    return (
        <LayoutRow
            overflow="hidden"

            // HeroBanner has special values not compatible with using verticalPaddingAbove
            className={classNames(
                imageUrl || imageCollection ? 'rh-pt-3' : 'rh-pt-5',
                'rh-pb-4',
                className,
            )}
            includeContentPadding={includeContentPadding}
        >
            <CallToActionBlock
                className="rh-flex-grow-1"
                // imageUrl is only needed for the PCB, when that is removed in PINGU-581
                // it can be removed as a separate prop (then pass all props directly to CTA block)
                imageUrl={imageUrl}
                imageCollection={imageCollection}

                isHero={true}
                {...otherProps}
            />
        </LayoutRow>
    );
}

HeroBanner.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    isWide: PropTypes.bool,
    imageUrl: PropTypes.string,
    imageCollection: ImageCollectionPropType,
    className: PropTypes.string,
    includeContentPadding: PropTypes.bool,
};

HeroBanner.defaultProps = {
    variant: VARIANTS.DARK,
    isWide: false,
    imageUrl: undefined,
    imageCollection: undefined,
    className: undefined,
    includeContentPadding: true,
};

HeroBanner.blockKey = 'rh/hero-banner';

export default HeroBanner;
