import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import classNames from 'classnames';

import {
    Form,
    Colours,
    Checkbox,
    IconArrow,
    EmailInput,
    StringInput,
    PrimaryButton,
    useExperiment,
    CondensedTheme,
    ExperimentSegments,
    PageBehaviourButton,
    AFFILIATE_QUERY_PARAM_KEYS,
} from '@ratehub/base-ui';

import EXPERIMENTS from '../definitions/Experiments';
import INCENTIVE_VARIANTS from '../definitions/IncentiveVariants';
import GiftOfferShape from '../definitions/GiftOfferShape';
import UserInfoCCPropTypes from '../definitions/UserInfoCCPropTypes';
import getIncentiveVariant from '../functions/getIncentiveVariant';
import getCardOfferTypeAsString from '../functions/getCardOfferTypeAsString';


function CreditCardIncentive({
    cardInfo,
    onAcceptOrDecline,
    className,
}) {
    const intl = useIntl();

    const incentiveVariant = getIncentiveVariant({
        isIncentiveCompactPage: useExperiment(EXPERIMENTS.incentiveCompactPage)?.segment === ExperimentSegments.VARIANT_1,
    });
    // we’re going to use these a lot; make them shorter
    const isDialogueBox = incentiveVariant === INCENTIVE_VARIANTS.dialogueBox;
    const isCompactPage = incentiveVariant === INCENTIVE_VARIANTS.compactPage;

    const giftOffer = cardInfo.giftCardOffer;
    const offerAmount = giftOffer.maxDollarValue;
    const offerType = giftOffer.offerType;

    const hasAffiliateId = typeof window !== 'undefined'
        && queryString.parse(window.location.search)[AFFILIATE_QUERY_PARAM_KEYS.affiliateId];

    function handleAcceptGiftOffer(email, usersName) {
        // naive splitting of usersName into first and last; will not be perfect
        // Example output for the input "Bruce Wayne":
        // matches[0] = "Bruce Wayne" (full match)
        // matches[1] = "Bruce" (first name)
        // matches[2] = " Wayne" (space followed by last name)
        // matches[3] = "Wayne" (last name)
        const matches = usersName.match(/(\w+)(\s(.+))?/);
        const firstName = matches[1];
        const lastName = matches?.[3];

        onAcceptOrDecline({ email, isOfferAccepted: true, firstName, lastName });
    }


    return (
        <Container
            className={classNames('rh-display-flex', 'rh-flex-direction-column', 'rh-align-items-center', className)}
        >
            <div
                className={classNames('incentive-form', 'rh-p-1_25', 'rh-border-radius-8px', {
                    'dialogue-box rh-box-shadow-surround-large rh-bg-coconut': isDialogueBox,
                    'compact-page': isCompactPage,
                })}
            >
                <header>
                    <PageBehaviourButton
                        onClick={() => onAcceptOrDecline({ isOfferAccepted: false })}
                        iconComponent={iconProps => (
                            <IconArrow
                                {...iconProps}
                                outlineWidth="0"
                                direction="right"
                            />
                        )}
                        message={(
                            <FormattedMessage
                                {...LOCAL_MESSAGES.applyWithout}
                                values={{ offerType: getCardOfferTypeAsString(giftOffer, intl) }}
                            />
                        )}
                        size={isDialogueBox
                            ? 'extra-small'
                            : 'small'}
                        className={classNames('rh-ml-auto', {
                            'rh-mb-1_5': isDialogueBox,
                            'rh-mb-2': isCompactPage,
                        })}
                        data-name="edb.cc.incentive.no-thanks"
                    />

                    <div
                        className={classNames('header-box', 'rh-position-relative', 'rh-p-1', 'rh-border-radius-8px', {
                            'rh-bg-coconut': isCompactPage,
                            'rh-bg-grape-lightest': isDialogueBox,
                        })}
                    >
                        <If condition={giftOffer?.expiryDate}>
                            <span
                                className={classNames('expiry-pill', 'rh-position-absolute', 'rh-display-inline-block', 'rh-px-0_5',
                                    'rh-fg-coconut', 'rh-bg-grape-dark', 'rh-border-radius-8px', {
                                        'rh-text-xs': isDialogueBox,
                                        'rh-text-s leading-l': isCompactPage,
                                    })}
                            >
                                <FormattedMessage
                                    {...LOCAL_MESSAGES.offerEnds}
                                />
                                <time
                                    className="rh-display-inline-block"
                                    dateTime={giftOffer.expiryDate}
                                    suppressHydrationWarning // prevent hydration warning if server timezone is different than browser timezone
                                >
                                    <FormattedDate
                                        value={giftOffer.expiryDate}
                                        year="numeric"
                                        month="short"
                                        day="numeric"
                                    />
                                </time>
                            </span>
                        </If>

                        <p
                            className={classNames('rh-m-0', 'rh-mt-0_5', 'rh-mb-0_25', {
                                'rh-text-s': isDialogueBox,
                                'rh-text-m': isCompactPage,
                            })}
                        >
                            {cardInfo.name}
                        </p>

                        <h2
                            className={classNames('rh-fg-grape-dark', 'rh-m-0', {
                                'rh-title-xs': isDialogueBox,
                                'rh-title-s': isCompactPage,
                            })}
                        >
                            <FormattedMessage
                                {...LOCAL_MESSAGES.mainTitle}
                                values={{ offerAmount, offerType }}
                            />
                        </h2>
                    </div>
                </header>

                <div className="rh-mt-1 rh-p-1">
                    <h2
                        className={classNames('rh-m-0', 'rh-mt-0_25', {
                            'rh-title-xs': isDialogueBox,
                            'rh-title-s': isCompactPage,
                        })}
                    >
                        <FormattedMessage {...LOCAL_MESSAGES.claimYourOffer} />
                    </h2>

                    <p
                        className={classNames('rh-mt-0_25', 'rh-mb-0', {
                            'rh-text-s': isDialogueBox,
                            'rh-text-m': isCompactPage,
                        })}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: giftOffer.promoDetailsHTML,
                        }}
                    />

                    <Form
                        className="rh-display-flex rh-flex-wrap rh-mt-1"
                        onSubmit={(values) => handleAcceptGiftOffer(values.email, values.usersName)}
                    >
                        <div className="rh-display-flex rh-flex-direction-column rh-align-items-center">
                            <NameAndEmailFields
                                hasAffiliateId={hasAffiliateId}
                                handleAcceptGiftOffer={handleAcceptGiftOffer}
                                incentiveVariant={incentiveVariant}
                            />
                        </div>

                        <div
                            className={classNames('button-wrapper rh-display-flex rh-flex-direction-column rh-mt-2',
                                'rh-width-100p rh-align-items-center rh-gap-0_5')}
                        >
                            <PrimaryButton
                                size={isDialogueBox
                                    ? 'small'
                                    : 'medium'}

                                className="button-get-offer rh-mt-0 rh-mx-1"
                                data-name="gift-card-offer-submit"
                            >
                                <FormattedMessage {...LOCAL_MESSAGES.getThisOffer} />
                            </PrimaryButton>

                            <p
                                className={classNames('rh-m-0', 'rh-fg-blackberry-light', {
                                    'rh-text-xs': isDialogueBox,
                                    'rh-text-s': isCompactPage,
                                })}
                            >
                                <FormattedMessage
                                    id="edb.cc.incentive.proceed-to-provider"
                                    defaultMessage="Proceed to {providerName}’s secure website"
                                    values={{ providerName: cardInfo.providerName }}
                                />
                            </p>
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    );
}

CreditCardIncentive.propTypes = {
    cardInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        giftCardOffer: GiftOfferShape.isRequired,
        cardImageURL: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        providerName: PropTypes.string.isRequired,

        signUpBonusPoints: PropTypes.number,
        signUpBonusDollars: PropTypes.number,
        isFirstYearFeeWaived: PropTypes.bool.isRequired,
        annualFee: PropTypes.number.isRequired,
        productType: PropTypes.string.isRequired,
        earningsDescription: PropTypes.array.isRequired,
    }).isRequired,

    onAcceptOrDecline: PropTypes.func.isRequired,
    userInfo: UserInfoCCPropTypes,

    referrerURL: PropTypes.string,

    className: PropTypes.string,
};


function NameAndEmailFields({
    hasAffiliateId,
    isCompact = false,
    incentiveVariant = INCENTIVE_VARIANTS.dialogueBox,
}) {
    return (
        <CondensedTheme value={incentiveVariant === INCENTIVE_VARIANTS.dialogueBox}>
            <div
                className={classNames('two-up', 'rh-display-flex', 'rh-flex-wrap', 'rh-gap-0_5', 'rh-width-100p', {
                    // we will need more white space below this input field wrapper when the subscribe checkbox is omitted
                    'rh-mb-1_5': hasAffiliateId,
                })}
            >
                <StringInput
                    className="side-by-side rh-my-0"
                    name="usersName"
                    label={(
                        <FormattedMessage
                            id="edb.cc.incentive.usersName.label"
                            defaultMessage="Your name"
                        />
                    )}
                    defaultErrorMessage={(
                        <FormattedMessage
                            id="edb.cc.incentive.name-validation.empty"
                            defaultMessage="Please enter your name"
                        />
                    )}
                />

                <EmailInput
                    className="side-by-side rh-my-0"
                    name="email"
                    placeholder={''}
                    label={(
                        <FormattedMessage
                            id="edb.cc.incentive.email.label"
                            defaultMessage="Your email address"
                        />
                    )}
                />
            </div>

            <If condition={!hasAffiliateId}>
                <div
                    className={classNames('rh-display-flex', 'rh-align-items-center', 'rh-width-100p', {
                        'rh-my-1_5 rh-mx-0_5': !isCompact,
                        'rh-my-1': isCompact,
                    })}
                >
                    <Checkbox
                        className="checkbox-subscribe"
                        name="subscribe"
                        id="subscribe"
                        size={isCompact ? 'small' : undefined}
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label
                        className={classNames('rh-ml-1 rh-my-0', {
                            'rh-text-s': !isCompact,
                            'rh-text-xs': isCompact,
                        })}
                        htmlFor="subscribe"
                    >
                        <FormattedMessage
                            id="edb.cc.incentive.newsletters"
                            defaultMessage="I’d like to receive Ratehub credit card offers and newsletters. I may unsubscribe at any time."
                        />
                    </label>
                </div>
            </If>
        </CondensedTheme>
    );
}

NameAndEmailFields.propTypes = {
    hasAffiliateId: PropTypes.string,
    isCompact: PropTypes.bool,
    incentiveVariant: PropTypes.oneOf(Object.values(INCENTIVE_VARIANTS)),
};


const LOCAL_MESSAGES = defineMessages({
    applyWithout: {
        id: 'edb.cc.incentive.no-thanks',
        defaultMessage: 'Apply without the {offerType}',
    },
    offerEnds: {
        id: 'edb.cc.incentive.offer-ends',
        defaultMessage: 'Offer ends ',
    },
    mainTitle: {
        id: 'edb.cc.incentive.main-title',
        defaultMessage: 'Get an exclusive {offerAmount, number, CAD0} {offerType}',
    },
    claimYourOffer: {
        id: 'edb.cc.incentive.claim-your-offer',
        defaultMessage: 'Claim your offer',
    },
    getThisOffer: {
        id: 'edb.cc.incentive.get-offer',
        defaultMessage: 'Get this offer',
    },
});

const Container = styled.div`
    > .incentive-form {
        max-width: 90%;

        &.dialogue-box {
            width: 26em;
        }
        &.compact-page {
            width: 38em;
        }

        .header-box {
            border: 1px solid ${Colours.GRAPE};

            > .expiry-pill {
                padding-top: 1px;
                top: -0.7em;
                left: 1em;
            }
        }

        .two-up {
            .side-by-side {
                flex: 0 1 100%;
            }
        }

        .button-wrapper {
            .button-get-offer {
                max-width: 15rem;
            }
        }
    }
`;

export default CreditCardIncentive;
