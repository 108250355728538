import React from 'react';

import { useModal } from '../components/ModalProvider';
import ErrorModalDisplay from '../components/ErrorModalDisplay';

/**
 * Use error modal hook
 * Interface for opening an error modal inside a ModalProvider.
 * 
 * @returns - An object containing the error modal interface
 */
function useErrorModal() {
    const modalContext = useModal();

    return {
        /**
         * Open an error modal.
         *  
         * @param {object} errorModalOptions 
         * @param {string} errorModalOptions.title - The title of the error modal (required)
         * @param {string} [errorModalOptions.description] - The description of the error modal
         * @param {string} [errorModalOptions.acceptText] - The text for the accept button; defaults to 'Please try again'
         * @param {function} errorModalOptions.onAccept - The callback for when the accept button is clicked (required)
         * 
         * @returns {string} - The unique ID of the error modal layer which was created; you can use this ID to close the modal via useModal().close(id)
         */
        open({ 
            title, 
            description, 
            acceptText, 
            onAccept, 
            ...modalProps
        } = {}) {
            const modalId = modalContext.open({
                showBranding: false,
                screenReaderLabel: title,
                ...modalProps,
                children: () => (
                    <ErrorModalDisplay
                        title={title}
                        description={description}
                        acceptText={acceptText}
                        onAccept={() => {
                            // allow the caller to prevent the modal from closing
                            if (onAccept() === false) {
                                return false;
                            }
                            modalContext.close(modalId);
                        }}
                    />
                ),
            });

            return modalId;
        },
    };
}

export default useErrorModal;
