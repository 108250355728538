const REGEX = new RegExp(/px|em|rem|%/g);

/**
 *
 * @param {string} string
 * @returns {string}
 */
export default function getValueWithoutUnit(string) {

    if (string && REGEX.test(string)) {
        return string.replace(REGEX, '');
    }

    return string;
}
