import noticeError from './noticeError';


/**
 * Processes options into value/label format which is the standardized structure used by most components.
 * Inputted options can be an array of values or value/label pairs. If not coming in as value/label pairs, and renderOptionLabel is not passed, the value is assumed to be the label. 
 * Therefore, if you have an array of object values, you must pass renderOptionLabel to extract the label from the object.
 * 
 * @typedef {import('react').ReactNode} React.Node
 * 
 * @param {Array<any> | Array<{ value: any, label?: React.Node }} options - array of values or value/label pairs
 * @param {(value: any) => React.Node} renderOptionLabel - if passed, option is always assumed to be a value.
 * @returns {Array<{ value: any, label: React.Node }>} array of value/label pairs
 */
function getValueLabelOptionPairs(options, renderOptionLabel) {  
    if (!Array.isArray(options)) {
        const error = new Error('[getValueLabelOptionPairs] options must be an array of values or value/label pairs');

        noticeError(error, {
            options,
            renderOptionLabel,
        });

        throw error;
    }
    
    return options.map(option => {
        if (typeof renderOptionLabel === 'function') {
            return {
                value: option,
                label: renderOptionLabel(option),
            };
        }

        if (typeof option === 'object') {
            return {
                ...option,
                value: option.value,
                label: option.label ?? option.value,
            };
        }

        // option must be renderable (string, number, etc.)
        return {
            value: option,
            label: option,
        };
    });
}

export default getValueLabelOptionPairs;