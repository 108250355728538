import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import MessagePropType from '../definitions/MessagePropType';
import formatPostalCode from '../functions/formatPostalCode';
import isParsablePostalCode from '../functions/postalCodeValidator';
import InputController from './InputController';
import DirectInput from './DirectInput';


function PostalCodeInput({
    name,
    value,

    isRequired,
    isDisabled,

    onChange, 
    onBlur,
    onValidityChange,
    onValidate,

    label,
    placeholder,

    canShowInvalid,

    onCreateField,
    
    ...otherProps
}) {
    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            canShowInvalid={canShowInvalid}
            isRequired={isRequired}
            isDisabled={isDisabled}
            getParsedValue={getParsedPostalCode}
            getFormattedValue={getFormattedPostalCode}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => (
                <DirectInput
                    {...inputProps}
                    type="text"
                    pattern="[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d"
                    label={label}
                    placeholder={
                        placeholder ?? (
                            <FormattedMessage
                                id="base-ui.postal.code.field.placeholder"
                                defaultMessage="Enter postal code (A1A 1A1)"
                            />
                        )
                    }

                    shouldShowInvalid={inputState.shouldShowInvalid}
                    errorMessage={inputState.errorMessage}
                    isInvalid={inputState.isInvalid}

                    onChange={e => inputProps.onChange(getFormattedPostalCode(e.target.value))}
                />
            )}
        </InputController>
    );
}

PostalCodeInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    canShowInvalid: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,

    onCreateField: PropTypes.func,
};

PostalCodeInput.defaultProps = {
    value: undefined,
    isRequired: undefined,
    isDisabled: false,
    onChange: undefined,
    onBlur: undefined,
    onValidityChange: undefined,
    canShowInvalid: false,
    onValidate: undefined,
    label: undefined,
    placeholder: undefined,
    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.postal-code-input.errors.default"
        defaultMessage="Please match the requested format - A1A 1A1"
    />
);

function getFormattedPostalCode(postalCode) {
    return typeof postalCode === 'string'
        ? formatPostalCode(postalCode.trim())
        : null;
}

function getParsedPostalCode(postalCode) {
    if (typeof postalCode !== 'string') {
        return null;
    }

    const formattedPostalCode = getFormattedPostalCode(postalCode);

    return isParsablePostalCode(formattedPostalCode)
        ? formattedPostalCode
        : null;
}

export default observer(PostalCodeInput);