import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Colours,
    LayoutGlobals,
    ZIndex,
} from '@ratehub/base-ui';

import AdLeaderboard from './AdLeaderboard';


function AdStickyMobile({ className }) {
    return (
        <div className={className}>
            <div className="restrict-width-container rh-mx-auto">
                <AdLeaderboard
                    overrideAdIndex="mobilesticky"
                />
            </div>
        </div>
    );
}

AdStickyMobile.propTypes = {
    className: PropTypes.string,
};

const StyledAdStickyMobile = styled(AdStickyMobile)`
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    background-color: ${Colours.STONE_LIGHTEST};
    z-index: ${ZIndex.HEADER_FOOTER};

    @media screen and (min-width: ${LayoutGlobals.STICKY_MOBILE_AD_SWITCH_WIDTH}) {
        display: none;
    }

    // Restricting the width to be a 320 or 300px ad since
    //  any larger looks weird on tablet.
    > .restrict-width-container {
        max-width: 320px;
    }
`;

export default StyledAdStickyMobile;
