import { useState } from 'react';

/**
 * Use toggle: a hook that models a toggle-able control with a boolean state; enabled or disabled.
 * This returns the state and _two_ functions: the first to toggle the value, the second to set it.
 *
 * @param {boolean} isInitiallyOpen represents the initial state
 * @returns {[ boolean, function, function ]}
 */
export default function useToggle(isInitiallyOpen = false) {
    const [ isEnabled, setEnabled ] = useState(isInitiallyOpen);

    function toggleEnabled() {
        setEnabled(!isEnabled);
    }

    return [
        isEnabled,
        toggleEnabled,
        setEnabled,
    ];
}
