import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconPlus({
    outlineWidth = '2px',
    stroke = Colours.BLACKBERRY,
    strokeWidth = '2px',
    fill = Colours.TRANSPARENT,
    ...otherProps
}) {
    return (
        <IconPlus.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="29"
            />
            <path d="M31 15.5312V46.5313" />
            <path d="M15.5 31.0312L46.5 31.0313" />
        </IconPlus.Svg>
    );
}

IconPlus.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};


IconPlus.Svg = styled.svg`
    stroke: ${props => props.strokeColour};
    stroke-width: ${props => props.strokeWidth};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconPlus;
