import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { v4 as uuid } from 'uuid'

import { Colours } from '@ratehub/base-ui';


const VARIANTS = {
    BEST_RATES_SIDEBAR: 'bestRatesSidebar',
    LIST_OF_LINKS: 'listOfLinks',
};

const ANIMATION_TYPES = {
    PULSE: 'pulse',
    //WAVE: 'wave',
    NONE: 'none',
};

/* NOTE: "wave" animationType temporarily commented out. There are some optimizations
we want to add to make it only necessary to render the gradient once per page.
This will be done in https://ratehub.atlassian.net/browse/FIT-3184 */

function SkeletonPlaceholder({
    primaryColour,
    //secondaryColour,
    variant,
    animationType,
    ...otherProps
}) {
    //const isWave = animationType === ANIMATION_TYPES.WAVE
    const isNone = animationType === ANIMATION_TYPES.NONE;
    const isPulse = animationType === ANIMATION_TYPES.PULSE;

    // const gradientId = `linear-gradient-${uuid().slice(0, 8)}`
    // const keyTimes = '0; 1'
    // const begin = '0s'

    // Keeping these options out of the prop API until it's clear what options
    // are desired from outside of the component.
    const duration = '1700ms';
    //const gradientWidth = 0.5 // Width of the shine/reflection. Any positive integer is valid.

    const svgAttributes = {
        ...(variant === VARIANTS.BEST_RATES_SIDEBAR && { viewBox: '0 0 516 58' }),
        ...(variant === VARIANTS.LIST_OF_LINKS && { viewBox: '0 0 596 270' }),
    };

    const groupAttributes = {
        //...(isWave && { fill: `url(#${gradientId})` }),
        ...((isNone || isPulse) && { fill: primaryColour }),
        className: 'shape-container',
    };

    return (
        <Container
            width="100%"
            height="100%"
            $duration={duration}
            className={classNames({
                'is-pulse': isPulse,
            })}
            {...svgAttributes}
            {...otherProps}
        >
            <Choose>
                <When condition={variant === VARIANTS.LIST_OF_LINKS}>
                    <g {...groupAttributes}>
                        <path d="M519.423,129.986C519.423,126.889 516.909,124.374 513.812,124.374L47.672,124.374C44.575,124.374 42.061,126.889 42.061,129.986L42.061,141.209C42.061,144.306 44.575,146.821 47.672,146.821L513.812,146.821C516.909,146.821 519.423,144.306 519.423,141.209L519.423,129.986Z" />
                        <path d="M344.252,191.541C344.252,188.444 341.737,185.929 338.64,185.929L47.672,185.929C44.575,185.929 42.061,188.444 42.061,191.541L42.061,202.764C42.061,205.861 44.575,208.376 47.672,208.376L338.64,208.376C341.737,208.376 344.252,205.861 344.252,202.764L344.252,191.541Z" />
                        <path d="M409.968,252.483C409.968,249.386 407.454,246.871 404.357,246.871L47.672,246.871C44.575,246.871 42.061,249.386 42.061,252.483L42.061,263.706C42.061,266.803 44.575,269.318 47.672,269.318L404.357,269.318C407.454,269.318 409.968,266.803 409.968,263.706L409.968,252.483Z" />
                        <path d="M298,6.428C298,3.331 295.486,0.816 292.388,0.816L47.672,0.816C44.575,0.816 42.061,3.331 42.061,6.428L42.061,17.651C42.061,20.748 44.575,23.263 47.672,23.263L292.388,23.263C295.486,23.263 298,20.748 298,17.651L298,6.428Z" />
                        <path d="M448.612,67.37C448.612,64.273 446.098,61.758 443.001,61.758L47.672,61.758C44.575,61.758 42.061,64.273 42.061,67.37L42.061,78.593C42.061,81.69 44.575,84.205 47.672,84.205L443.001,84.205C446.098,84.205 448.612,81.69 448.612,78.593L448.612,67.37Z" />
                    </g>
                </When>
                <When condition={variant === VARIANTS.BEST_RATES_SIDEBAR}>
                    <g {...groupAttributes}>
                        <path d="M98,20C98,14.481 93.519,10 88,10L10,10C4.481,10 0,14.481 0,20L0,40C0,45.519 4.481,50 10,50L88,50C93.519,50 98,45.519 98,40L98,20Z" />
                        <path d="M128,13.25C128,9.801 125.199,7 121.75,7L109.25,7C105.801,7 103,9.801 103,13.25L103,26.75C103,30.199 105.801,33 109.25,33L121.75,33C125.199,33 128,30.199 128,26.75L128,13.25Z" />
                        <path d="M239,15.75C239,13.68 237.32,12 235.25,12L148.75,12C146.68,12 145,13.68 145,15.75L145,23.25C145,25.32 146.68,27 148.75,27L235.25,27C237.32,27 239,25.32 239,23.25L239,15.75Z" />
                        <path d="M239,36.75C239,34.68 237.32,33 235.25,33L148.75,33C146.68,33 145,34.68 145,36.75L145,44.25C145,46.32 146.68,48 148.75,48L235.25,48C237.32,48 239,46.32 239,44.25L239,36.75Z" />
                        <path d="M356,20C356,14.481 351.519,10 346,10L268,10C262.481,10 258,14.481 258,20L258,40C258,45.519 262.481,50 268,50L346,50C351.519,50 356,45.519 356,40L356,20Z" />
                        <path d="M386,13.25C386,9.801 383.199,7 379.75,7L367.25,7C363.801,7 361,9.801 361,13.25L361,26.75C361,30.199 363.801,33 367.25,33L379.75,33C383.199,33 386,30.199 386,26.75L386,13.25Z" />
                        <path d="M497,15.75C497,13.68 495.32,12 493.25,12L406.75,12C404.68,12 403,13.68 403,15.75L403,23.25C403,25.32 404.68,27 406.75,27L493.25,27C495.32,27 497,25.32 497,23.25L497,15.75Z" />
                        <path d="M497,36.75C497,34.68 495.32,33 493.25,33L406.75,33C404.68,33 403,34.68 403,36.75L403,44.25C403,46.32 404.68,48 406.75,48L493.25,48C495.32,48 497,46.32 497,44.25L497,36.75Z" />
                    </g>
                </When>
            </Choose>

            {/* <If condition={isWave}>
                <defs>
                    <linearGradient
                        id={gradientId}
                    >
                        <stop
                            offset="0%"
                            stopColor={secondaryColour}
                        >
                            <animate
                                attributeName="offset"
                                values={`${-gradientWidth}; 1`}
                                keyTimes={keyTimes}
                                dur={duration}
                                repeatCount="indefinite"
                                begin={begin}
                            />
                        </stop>

                        <stop
                            offset="50%"
                            stopColor={primaryColour}
                        >
                            <animate
                                attributeName="offset"
                                values={`${-gradientWidth / 2}; ${(gradientWidth / 2) + 1}`}
                                keyTimes={keyTimes}
                                dur={duration}
                                repeatCount="indefinite"
                                begin={begin}
                            />
                        </stop>

                        <stop
                            offset="100%"
                            stopColor={secondaryColour}
                        >
                            <animate
                                attributeName="offset"
                                values={`0; ${gradientWidth + 1}`}
                                keyTimes={keyTimes}
                                dur={duration}
                                repeatCount="indefinite"
                                begin={begin}
                            />
                        </stop>
                    </linearGradient>
                </defs>
            </If> */}
        </Container>
    );
}

SkeletonPlaceholder.propTypes = {
    primaryColour: PropTypes.oneOf(Object.values(Colours)),
    secondaryColour: PropTypes.oneOf(Object.values(Colours)),
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    animationType: PropTypes.oneOf(Object.values(ANIMATION_TYPES)),
};

SkeletonPlaceholder.defaultProps = {
    primaryColour: Colours.STONE_LIGHT,
    secondaryColour: Colours.STONE,
    variant: VARIANTS.BEST_RATES_SIDEBAR,
    animationType: ANIMATION_TYPES.NONE,
};

const pulse = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const Container = styled.svg`
    display: block;

    &.is-pulse {
        > .shape-container {
            animation: ${props => props.$duration} ${pulse} ease alternate infinite;
        }
    }
`;

export default SkeletonPlaceholder;
