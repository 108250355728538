import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, defineMessages } from 'react-intl';

import StepButtonStyles from '../styles/StepButtonStyles';


function IncreaseButton({ onClick, ...otherProps }) {
    const intl = useIntl();

    return (
        <button
            type="button"
            onClick={onClick}
            aria-label={intl.formatMessage(MESSAGES.INCREASE)}
            {...otherProps}
        >
            <svg viewBox="0 0 40 40">
                <rect
                    x=".5"
                    y=".5"
                    width="39"
                    height="39"
                    rx="1"
                />
                <circle
                    cx="20"
                    cy="20"
                    r="19"
                />
                <path d="M20 12v16M28 20H12" />
            </svg>
        </button>
    );
}

IncreaseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const StyledIncreaseButton = styled(IncreaseButton)`
    ${StepButtonStyles}
`;

const MESSAGES = defineMessages({
    INCREASE: {
        id: 'base-ui.IncreaseButton.IncreaseLabel',
        defaultMessage: 'Increase',
    },
});

export default StyledIncreaseButton;