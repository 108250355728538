import noticeError from './noticeError';


// Traverses up the DOM tree hierarchy and returns false if element or any of
// it's parents are styled with display: none. Returns true otherwise.
//
// DOES NOT check for other forms of visibility such as the visibility property,
// element clipping or width/height=0, but this is a performant way to check
// for a very common method of hiding elements.
function isElementVisible(elementOrSelector) {
    const element = typeof elementOrSelector === 'string'
        ? document.querySelector(elementOrSelector)
        : elementOrSelector;

    if (!element) {
        noticeError(new Error('isElementVisible -- could not find element matching elementOrSelector argument'), { 
            elementOrSelector,
        });
        
        return false; // element is not visible if it doesn't exist!
    }

    // element.offsetParent always returns *some* parent element or returns
    // null if it or one of it's parent elements is display: none.
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
    //
    // CAVEAT:
    // element cannot be position: fixed or this will return null, despite
    // it still being visible.
    if (element.offsetParent === null) {
        return false;
    }

    return true;
}

export default isElementVisible;
