import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    IconCheckmark,
    Colours,
} from '@ratehub/base-ui';


const VARIANTS = {
    LARGE: 'large',
    SMALL: 'small',
};

const ICON_WIDTHS = {
    WITH_FILL: {
        LARGE: '1.5em',
        SMALL: '1.25em',
    },
    WITHOUT_FILL: {
        LARGE: '3em',
        SMALL: '2em',
    },
};

const DEFAULT_ICON_PROPS = {
    stroke: Colours.BLUEBERRY_DARK,
    strokeWidth: '4px',
    fill: Colours.TRANSPARENT,
    outlineWidth: '0px',
};

function Checklist({
    title,
    checkListMessages,
    iconProps = {},
    variant = VARIANTS.LARGE,
    ...otherProps
}) {
    const isLarge = variant === VARIANTS.LARGE;

    const mergedIconProps = {
        ...DEFAULT_ICON_PROPS,
        ...iconProps,
    };

    const hasFill = mergedIconProps.fill
        && mergedIconProps.fill !== Colours.TRANSPARENT;

    const iconWidths = hasFill
        ? ICON_WIDTHS.WITH_FILL
        : ICON_WIDTHS.WITHOUT_FILL;

    return (
        <Container {...otherProps}>
            <If condition={title}>
                <h3 className={
                    classNames('rh-text-align-left rh-mt-0 rh-mb-0_75', {
                        'rh-title-2xs': isLarge,
                        'rh-title-3xs': !isLarge,
                    })}
                >
                    {title}
                </h3>
            </If>

            <If condition={checkListMessages.length}>
                <ul className="list rh-text-align-left rh-m-0 rh-p-0">
                    <For
                        each="checkListMessage"
                        of={checkListMessages}
                    >
                        <li
                            className={classNames('listItem rh-mb-0_75', {
                                'rh-text-m': isLarge,
                                'rh-text-s': !isLarge,
                            })}
                            key={checkListMessage}
                        >
                            <span className="checkmark-container rh-display-flex rh-align-items-center">
                                {/* non-breaking space allows us to align space * icon to their centers.
                                align-items: baseline on <li> allows the "icon container" and <p> to
                                align to each others baseline so icon & text can scale together. */}
                                &nbsp;
                                <IconCheckmark
                                    className="checkmark rh-display-inline-block"
                                    {...mergedIconProps}
                                    width={isLarge ? iconWidths.LARGE : iconWidths.SMALL}
                                />
                            </span>

                            <p className={
                                classNames('rh-my-0')}
                            >
                                {checkListMessage}
                            </p>
                        </li>
                    </For>
                </ul>
            </If>
        </Container>
    );
}

Checklist.propTypes = {
    title: PropTypes.node,
    checkListMessages: PropTypes.arrayOf(PropTypes.node).isRequired,
    iconProps: PropTypes.shape({
        stroke: PropTypes.oneOf(Object.values(Colours)),
        strokeWidth: PropTypes.string, // CSS length, typically in px
        fill: PropTypes.oneOf(Object.values(Colours)),
        outlineWidth: PropTypes.string,  // CSS length, typically in px
    }),
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

const Container = styled.div`
    > .list {
        list-style-type: none;

        > .listItem {
            position: relative;

            display: flex;
            align-items: baseline;

            > .checkmark-container {
                align-items: center;

                > .checkmark {
                    margin-right: 0.3em; // add space between icon & text
                    margin-top: -0.1em; // for vertical optical balance with text
                }
            }
        }
    }
`;

export default Checklist;
