/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/

function ContentAreaContents() {
    return null;
}

ContentAreaContents.blockKey = 'rh/content-area-contents';

export default ContentAreaContents;
