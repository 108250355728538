import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import Input from './Input';
import withErrorMessageContainer from './withErrorMessageContainer';


function DirectInput({ type, placeholder, ...otherProps }) {
    const intl = useIntl();

    return (
        <DirectInput.Input
            type={type}
            placeholder={messageToString(placeholder, intl)}
            {...otherProps}
        />
    );
}

DirectInput.propTypes = {
    type: PropTypes.string,
    placeholder: MessagePropType,
};

DirectInput.defaultProps = {
    type: undefined,
    placeholder: undefined,
};

DirectInput.Input = styled(Input)`
    /* Remove step controls (AKA spin buttons) from desktop browsers */
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
        display: none;
    }
    &[type="number"] {
        -moz-appearance: textfield;
    }

    /* email addresses should be case-insensitive */
    &[type="email"]:not(::placeholder) {
        text-transform: lowercase;
    }
`;

export default withErrorMessageContainer(DirectInput);
