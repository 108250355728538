import React from 'react';

import BLOCK_ANIMATION_COMPONENT_MAP from '../definitions/BlockAnimationComponentMap';


function getBlockAnimationComponent(blockAnimation) {
    return blockAnimation && BLOCK_ANIMATION_COMPONENT_MAP[blockAnimation]
        ? BLOCK_ANIMATION_COMPONENT_MAP[blockAnimation]
        : React.Fragment;
}

export default getBlockAnimationComponent;
