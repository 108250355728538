import { rgba } from 'polished';

import Colours from './Colours';
import Sizes from './Sizes';


export const BORDER_RADIUS = '2px';
export const BOX_SHADOW_STONE = `0 2px 7px 0 ${rgba(Colours.STONE, 0.6)}`;
export const BOX_SHADOW_BLUEBERRY = `0px 0px 2px 2px ${rgba(Colours.BLUEBERRY, 0.4)}`;
export const ACTIVE_BACKGROUND_COLOUR = rgba(Colours.BLUEBERRY_LIGHTEST, 0.5);

export const INPUT_HORIZONTAL_PADDING = '1.125rem';
export const INPUT_HORIZONTAL_PADDING_CONDENSED = '0.875rem';

export const INPUT_HEIGHT = Sizes.SPACING.FOUR;
export const INPUT_HEIGHT_CONDENSED = '3.5rem';

export default {
    BORDER_RADIUS,
    BOX_SHADOW_STONE,
    BOX_SHADOW_BLUEBERRY,
    ACTIVE_BACKGROUND_COLOUR,
    INPUT_HORIZONTAL_PADDING,
    INPUT_HORIZONTAL_PADDING_CONDENSED,
    INPUT_HEIGHT,
    INPUT_HEIGHT_CONDENSED,
};
