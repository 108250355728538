import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import MessagePropType from '../definitions/MessagePropType';
import MessageStyles from '../definitions/MessageStyles';
import messageToString from '../functions/messageToString';
import ErrorMessage from './ErrorMessage';


function ErrorMessageContainer({
    children,
    message,
    messageStyle,
    isInvalid,
    isInline,
    className,
    ...otherProps
}) {
    const intl = useIntl();

    // If we don't have an error message, none of these should be visible.
    const isTitleVisible = !!message && (messageStyle === MessageStyles.MINIMAL && isInvalid);
    const isElementVisible = !!message && (messageStyle === MessageStyles.DEFAULT && isInvalid);
    const errorText = message ? messageToString(message, intl) : undefined;

    return (
        <OuterContainer
            title={(isTitleVisible ? errorText : undefined)}
            className={classNames(className, 'error-message-container')}
            isInline={isInline}
            {...otherProps}
        >
            <If condition={isInvalid}>
                <ErrorMessage
                    className={classNames('error-message', {
                        'rh-display-none': !isElementVisible,
                    })}
                >
                    {errorText}
                </ErrorMessage>
            </If>
            {children}
        </OuterContainer>
    );
}

ErrorMessageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    message: MessagePropType,
    messageStyle: PropTypes.oneOf(Object.values(MessageStyles)),
    isInline: PropTypes.bool,
    isInvalid: PropTypes.bool,
    className: PropTypes.string,
};

ErrorMessageContainer.defaultProps = {
    message: undefined,
    messageStyle: MessageStyles.DEFAULT,
    isInline: false,
    isInvalid: true,
    className: undefined,
};

const OuterContainer = styled.span`
    display: ${props => props.isInline ? 'inline-block' : 'block'};
    
    position: relative;

    & ${ErrorMessage} {
        position: absolute;

        bottom: 0;
        right: 0;
        transform: translateY(100%) translateY(6px);

        z-index: 1;
    }

`;

export default ErrorMessageContainer;
