import React from 'react';
import PropTypes from 'prop-types';

import { AccordionBasicItem } from '@ratehub/base-ui';


const WORDPRESS_EMPTY_CONTENT = '\n<div></div>\n';

function AccordionItem({ content, children,...otherProps }) {
    return (
        <AccordionBasicItem
            {...otherProps}
        >
            {/* Deprecated - This is to support existing use cases where we only allowed a single rich text field
                Using an IF block here because content gets set to an empty DIV with inconsistent white space */}
            <If condition={content && content !== WORDPRESS_EMPTY_CONTENT}>
                <div
                    className="rich-text"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </If>
            {/* END Deprecated */}

            {children}
        </AccordionBasicItem>
    );
}

AccordionItem.propTypes = {
    content: PropTypes.string, // Deprecated - see note above
    children: PropTypes.any,
};

AccordionItem.defaultProps = {
    content: undefined,
    children: undefined,
};

AccordionItem.blockKey = 'rh/accordion-item';

export default AccordionItem;
