import { defineMessages } from 'react-intl';


export default defineMessages({

    // Address Search
    SEARCH_PLACEHOLDER: {
        id: 'base-ui.address.search.placeholder',
        defaultMessage: '# street name, city, province',
    },
    SEARCH_INVALID: {
        id: 'base-ui.address.search.error',
        defaultMessage: 'Please enter an address',
    },

    // Combined Street
    STREET_COMBINED_LABEL: {
        id: 'base-ui.address.street.label',
        defaultMessage: 'Please enter your address',
    },
    STREET_COMBINED_PLACEHOLDER: {
        id: 'base-ui.address.street.placeholder',
        defaultMessage: '# street name type direction',
    },

    // Street Number
    STREET_NUMBER_LABEL: {
        id: 'base-ui.address.streetNumber.label',
        defaultMessage: 'Street number',
    },
    STREET_NUMBER_PLACEHOLDER: {
        id: 'base-ui.address.streetNumber.placeholder',
        defaultMessage: 'Enter number',
    },
    STREET_NUMBER_PLACEHOLDER_WIDE: {
        id: 'base-ui.address.streetNumberWide.placeholder',
        defaultMessage: 'Street #',
    },
    STREET_NUMBER_INVALID: {
        id: 'base-ui.address.streetNumber.error',
        defaultMessage: 'Please enter a valid value',
    },

    // Street Name
    STREET_NAME_LABEL: {
        id: 'base-ui.address.streetName.label',
        defaultMessage: 'Street name',
    },
    STREET_NAME_PLACEHOLDER: {
        id: 'base-ui.address.streetName.placeholder',
        defaultMessage: 'Enter name',
    },
    STREET_NAME_INVALID: {
        id: 'base-ui.address.streetName.error',
        defaultMessage: 'Please enter a valid value',
    },

    // Street Type
    STREET_TYPE_LABEL: {
        id: 'base-ui.address.streetType.label',
        defaultMessage: 'Type',
    },
    STREET_TYPE_PLACEHOLDER: {
        id: 'base-ui.address.streetType.placeholder',
        defaultMessage: 'Select',
    },

    // Street Direction
    STREET_DIRECTION_LABEL: {
        id: 'base-ui.address.streetDirection.label',
        defaultMessage: 'Direction',
    },
    STREET_DIRECTION_PLACEHOLDER: {
        id: 'base-ui.address.streetDirection.placeholder',
        defaultMessage: 'Select',
    },

    // City
    CITY_LABEL: {
        id: 'base-ui.address.city.label',
        defaultMessage: 'City',
    },
    CITY_PLACEHOLDER: {
        id: 'base-ui.address.city.placeholder',
        defaultMessage: 'City',
    },
    CITY_INVALID: {
        id: 'base-ui.address.city.error',
        defaultMessage: 'Please enter a valid value',
    },

    // Province
    PROVINCE_LABEL: {
        id: 'base-ui.address.province.label',
        defaultMessage: 'Province',
    },
    PROVINCE_PLACEHOLDER: {
        id: 'base-ui.address.province.placeholder',
        defaultMessage: 'Select',
    },
    PROVINCE_INVALID: {
        id: 'base-ui.address.province.error',
        defaultMessage: 'Please select an option',
    },

    // Postal Code
    POSTAL_CODE_LABEL: {
        id: 'base-ui.address.postalCode.label',
        defaultMessage: 'Postal Code',
    },
    POSTAL_CODE_PLACEHOLDER: {
        id: 'base-ui.address.postalCode.placeholder',
        defaultMessage: 'Postal code',
    },
    POSTAL_CODE_INVALID: {
        id: 'base-ui.address.postalCode.error',
        defaultMessage: 'Please match the requested format - A1A 1A1',
    },

    // Unit #
    UNIT_LABEL: {
        id: 'base-ui.address.unit.label',
        defaultMessage: 'Unit',
    },
    UNIT_PLACEHOLDER: {
        id: 'base-ui.address.unit.placeholder',
        defaultMessage: 'Unit #',
    },

    // Mode change buttons
    MODE_BASIC_BUTTON: {
        id: 'base-ui.address.mode.redoAddressSearch',
        defaultMessage: 'Clear address and start over',
    },
    MODE_FORM_BUTTON: {
        id: 'base-ui.address.mode.enterManually',
        defaultMessage: 'Enter manually',
    },

    // Errors
    ERROR_OUTSIDE_ALLOWED_PROVINCE: {
        id: 'base-ui.address.error.outsideAllowedProvince',
        defaultMessage: 'Sorry, {province} is not supported',
    },
    ERROR_OUTSIDE_ALLOWED_POSTAL_CODE: {
        id: 'base-ui.address.error.outsideAllowedPostalCode',
        defaultMessage: 'Sorry, this region is not supported',
    },
    ERROR_POSTAL_CODE: {
        id: 'base-ui.address.error.validPostalCode',
        defaultMessage: 'Please enter a valid postal code',
    },
    FAILED_PLACE_FETCH: {
        id: 'base-ui.address.error.failedDetailsFetch',
        defaultMessage: 'Failed to retrieve address details',
    },
    FAILED_AUTOCOMPLETE_FETCH: {
        id: 'base-ui.address.error.failedAutocompleteFetch',
        defaultMessage: 'Failed to retrieve address predictions',
    },
    FORM_SINGLE_ERROR_MESSAGE: {
        id: 'base-ui.address.error.formSingleErrorMessage',
        defaultMessage: 'Please enter a valid value',
    },
});
