import Locales from '../definitions/Locales';


const BASE_PATH_EN = '/dashboard';
const BASE_PATH_FR = '/tableau-de-bord';

/**
 * @public
 * Get the base path to the dashboard for a locale
 * @param {string} locale 
 * @returns {string}
 */
function getDashboardBasePath(locale) {
    switch (locale) {
        case Locales.FRENCH:
            return BASE_PATH_FR;

        default:
            return BASE_PATH_EN;
    }
}

export default getDashboardBasePath;
