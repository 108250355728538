import { Languages, getLanguageFromLocale } from '@ratehub/base-ui';


export default function getLanguageCodeFromOptions(options) {
    if (options.language) {
        return options.language;
    }

    if (options.locale) {
        return getLanguageFromLocale(options.locale);
    }

    return Languages.ENGLISH;
}
