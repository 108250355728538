import { observable, when } from 'mobx';


const YOUTUBE_SCRIPT_ID = 'ytScriptElement';
const YOUTUBE_SCRIPT_URL = 'https://www.youtube.com/iframe_api';

/**
 * Observable flag we will toggle when the async load is finished.
 * @type {boolean}
 */
const isScriptLoadComplete = observable.box(false);

// Make YT API available at window.YT
async function mountYouTubeAPI() {
    if (typeof window === 'undefined') {
        return;
    }

    // Only mount it once
    if (!document.getElementById(YOUTUBE_SCRIPT_ID)) {
        // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
        const ytScriptTag = document.createElement('script');
        ytScriptTag.setAttribute('id', YOUTUBE_SCRIPT_ID); // so we can check if it exists
        ytScriptTag.async = true;
        ytScriptTag.src = YOUTUBE_SCRIPT_URL;

        document.head.appendChild(ytScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            isScriptLoadComplete.set(true);
        };
    }

    //  Wait here until it's completed loading.
    return when(() => isScriptLoadComplete.valueOf());
}

export default mountYouTubeAPI;
