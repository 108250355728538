/**
 * @deprecated - Please migrate to the new format. Should be deleted in https://ratehub.atlassian.net/browse/FIT-3936
 * 
 * @param {{ city: string, province: string } | null} location - new format
 * @returns {{ name: string, province: { code: string } } | null} - old format
 */
function getCityFromLocation(location) {
    if (!location) {
        return null;
    }
    
    return {
        name: location.city,
        province: {
            code: location.province,
        },
    };
}

export default getCityFromLocation;