/**
 * Checks whether we inside a widget, based on the existence of
 * 'parentIFrame' which is part of the iframeResizer library
 * 
 * @returns {boolean}
 */
export default function isWidget() {
    if (typeof window === 'undefined') {
        return false;
    } else {
        // NOTE: this is just a custom object, not the actual <iframe> element.
        return 'parentIFrame' in window;
    }
}
