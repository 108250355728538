import { useEffect } from 'react';
import { trackHeapEvent, Products } from '@ratehub/base-ui';


const HEAP_EVENT_NAMES = {
    [Products.CREDIT_CARDS]: 'credit_card_sponsored_cards',
    [Products.GICS]: 'gics_sponsored_accounts',
    [Products.CHEQUING]: 'chequing_sponsored_accounts',
    [Products.SAVINGS]: 'savings_sponsored_accounts',
    [Products.ROBO_ADVISORS]: 'robo_advisors_sponsored_accounts',
};

/**
 * Custom hook to track sponsored products in heap.
 *
 * @param {Array} products - The array of products to track.
 * @param {string} productType - The type of product to track from Products in @ratehub/base-ui.
 */
export default function useTrackSponsoredProductsHeapEvent(products = [], productType) {
    useEffect(() => {
        if (Object.prototype.hasOwnProperty.call(HEAP_EVENT_NAMES, productType) && products.length > 0) {
            trackHeapEvent(HEAP_EVENT_NAMES[productType], {
                sponsoredSlugs: products
                    .filter(product => product.isSponsored)
                    .map(product => product.id),
                url: window.location.href,
            });
        }
    }, []);
}
