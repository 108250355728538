import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import CHECKBOX_VARIANTS from '../definitions/CheckboxVariants';
import MessagePropType from '../definitions/MessagePropType';
import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import Paragraph from '../components/Paragraph';

import CheckboxStyle from '../styles/CheckboxStyle';
import InputController from './InputController';
import TransparentInput from './TransparentInput';


function Checkbox({
    id,
    name,
    value,

    label,
    className,

    size = SIZES.LARGE,
    variant = CHECKBOX_VARIANTS.single,

    isDisabled = false,

    onChange,
    onValidate,
    onValidityChange,
    onFocus,
    onBlur,

    onCreateField,

    ...otherProps
}) {
    if (typeof onValidate === 'function') {
        throw new Error('Checkbox does not support custom validation (onValidate)');
    }

    const [ isFocused, setFocused ] = useState(false);

    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onBlur={onBlur}
            getParsedValue={getBooleanValue}
            isDisabled={isDisabled}
            onCreateField={onCreateField}
            className={className}
            {...otherProps}
        >
            {inputProps => {
                // If the user passes null, we need to default to false to avoid warnings about being uncontrolled changing to controlled
                const checked = inputProps.value ?? false;

                function handleFocus(e) {
                    setFocused(true);
                    onFocus?.(e);
                }

                function handleBlur(e) {
                    setFocused(false);
                    inputProps.onBlur(e);
                }

                function handleClick({ target }) {
                    /* Safari iOS does not focus checkbox inputs on click.
            This way we can ensure this element has the focus
            once clicked and if not focused already. */
                    if (document.activeElement !== target) {
                        target.focus();
                        setFocused(true);
                    }
                }

                function handleChange(event) {
                    inputProps.onChange(event.target.checked);
                }

                const checkboxProps = {
                    ...inputProps,
                    className: 'hidden-input',
                    id: id ?? name,

                    onFocus: handleFocus,
                    onBlur: handleBlur,
                    onClick: handleClick,
                    onChange: handleChange,

                    type: 'checkbox',
                    checked,
                    value: inputProps.value ?? '',
                };

                return (
                    <Checkbox.Container
                        rootSize={getRootSize(size)}
                        className={inputProps.className}
                        isFocused={isFocused}
                        isDisabled={isDisabled}
                        checked={checked}
                        variant={variant}
                    >
                        <Checkbox.VisualElement
                            className="checkbox-visual"
                            checked={checked}
                            isFocused={isFocused}
                            isDisabled={isDisabled}
                        >
                            <If condition={variant === CHECKBOX_VARIANTS.single}>
                                <TransparentInput
                                    {...checkboxProps}
                                />
                            </If>
                        </Checkbox.VisualElement>

                        {/* i.e. multiple or expandable */}
                        <If condition={variant !== CHECKBOX_VARIANTS.single}>
                            <TransparentInput
                                {...checkboxProps}
                            />
                        </If>

                        <If condition={label}>
                            <Checkbox.LabelText
                                forwardedAs="span"
                                className="checkbox-label"
                                message={label}
                                isDisabled={isDisabled}
                                size={size}
                            />
                        </If>
                    </Checkbox.Container>
                );
            }}
        </InputController>

    );
}

const SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

Checkbox.propTypes = {
    id: PropTypes.string,
    label: MessagePropType,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,

    size: PropTypes.oneOf(
        Object.values(SIZES),
    ),
    variant: PropTypes.oneOf(Object.values(CHECKBOX_VARIANTS)),
    isDisabled: PropTypes.bool,

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    onCreateField: PropTypes.func,
};


function getRootSize(size) {
    switch (size) {
        case SIZES.SMALL:
            return Sizes.FONTS['2XS'];

        case SIZES.MEDIUM:
            return Sizes.FONTS['XS'];

        case SIZES.LARGE:
        default:
            return Sizes.FONTS.S;
    }
}

Checkbox.Container = styled.label`
    display: inline-flex;
    align-items: center;
    text-align: left;

    flex-grow: 1;
    flex-shrink: 1;

    /* Establish sizing context for sub-elements */
    font-size: ${props => props.rootSize};

    .hidden-input {
        z-index: 1;
    }

    ${CheckboxStyle};
`;


Checkbox.VisualElement = styled.span`
    flex-shrink: 0;
    flex-grow: 0;
`;


Checkbox.LabelText = styled(Paragraph)`
    /* When used from CheckboxGroup we need different margins */
    margin: ${props => props.size === SIZES.MEDIUM
        ? `auto ${Sizes.SPACING.ONE} auto ${Sizes.SPACING.ONE}`
        : '0 0 0 1em'};
    font-size: 1em;

    color: ${props => props.isDisabled ? Colours.STONE : Colours.BLACKBERRY};

    line-height: ${Sizes.LINE_HEIGHT.S};   /* yes, checkboxes may wrap */
`;

function getBooleanValue(value) {
    return typeof value === 'boolean'
        ? value
        : false; // default to un-checked
}

export { CHECKBOX_VARIANTS, SIZES as CHECKBOX_SIZES };
export default observer(Checkbox);
