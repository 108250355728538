import { observable, when } from 'mobx';

import noticeError from './noticeError';


const GOOGLE_IDENTITY_SERVICE_SCRIPT_ID = 'rh-google-identity-service-script';
const GOOGLE_IDENTITY_SERVICE_URL = 'https://accounts.google.com/gsi/client';

// 0 = has not tried
// -1 = error occurred while loading
// 1 = successfully loaded
const isScriptLoadComplete = observable.box(0);

/**
 * Mount Google API script and register the callback function
 * @returns {boolean} true if loaded successfully
 */
async function mountGoogleIdentityService() {
    if (typeof window === 'undefined') {
        return false;
    }

    // mount only once
    if (!document.getElementById(GOOGLE_IDENTITY_SERVICE_SCRIPT_ID)) {
        const createdElement = document.createElement('script');
        createdElement.src = GOOGLE_IDENTITY_SERVICE_URL;
        createdElement.async = true;
        createdElement.defer = true;
        createdElement.setAttribute('id', GOOGLE_IDENTITY_SERVICE_SCRIPT_ID);
    
        document.body.appendChild(createdElement);
    
        createdElement.onload = () => {
            isScriptLoadComplete.set(1);
        };
        createdElement.onerror = (event) => {
            // onerror returns an Event, not an Error so we cannot pass it along to noticeError
            //  directly as an Error object. There is some information we can get from the event,
            //  but we can't get the actual error that caused this to fire. 
            noticeError(new Error ('[mountGoogleIdentityService] Error while loading Google Identity Service JavaScript API'), {
                // Passing the whole Event object removes some of these keys
                eventType: event.type,
                src: event.target.src,
                outerHTML: event.target.outerHTML, // Maybe having the HTML visible in the log will help debug?
                targetId: event.target.id,
                eventError: event.error, // This is very likely to be undefined, but it could contain the actual error if this is an ErrorEvent
            });
            isScriptLoadComplete.set(-1);
        };
    }

    // Wait until it's loaded successfully or failed
    await when(() => isScriptLoadComplete.get() !== 0);

    return isScriptLoadComplete.get() === 1;
}

export default mountGoogleIdentityService;
