import { useState } from 'react';
import { observable } from 'mobx';

import useForm from './useForm';


function useFieldsetState({ isAutoFocusEnabled }) {
    const form = useForm();
    const [ state, setState ] = useState(() => createFieldsetState({ form, isAutoFocusEnabled }));

    return [
        state, 
        (params) => setState(createFieldsetState(params)),
    ];
}

function createFieldsetState({ form, isAutoFocusEnabled = false }) {
    const state = observable({
        form,
        ourFields: [],

        fieldsets: [], // Any nested fieldsets
        isAutoFocusEnabled,

        get fields() {
            return state.fieldsets.reduce(
                // Map over the fields to convert the mobx object into an array
                (result, fieldset) => result.concat(fieldset.fields.map(f => f)),
                state.ourFields,
            );
        },
        get visibleFields() {
            return state.fields.filter(field => state.form.visibleFields.includes(field));
        },

        get isValid() {
            return state.visibleFields.every(field => field.isValid);
        },
        get shouldAnyFieldShowInvalid() {
            return state.fields.some(field => field.shouldShowInvalid);
        },
        get areAllFieldsOptional() {
            return state.fields.every(field => !field.required);
        },
        
        getFieldNamed(name) {
            return state.fields.find(f => f.name === name) ?? null;
        },
        getValue(name) {
            if (!state.getFieldNamed(name)) {
                return null;
            }
            
            return state.form.getValue(name);
        },

        addField(field) {
            if (!state.ourFields.includes(field)) {
                state.ourFields.push(field);
            }
        },
        removeField(field) {
            return state.ourFields.remove(field);
        },

        addFieldset(fieldset) {
            if (!state.fieldsets.includes(fieldset)) {
                state.fieldsets.push(fieldset);
            }
        },
        removeFieldset(fieldset) {
            return state.fieldsets.remove(fieldset);
        },
        
        touchAllFields() {
            // Used to show invalidity within a fieldset
            state.fields.forEach(field => field.setTouched(true));
        },
    });

    return state;
}

export default useFieldsetState;
