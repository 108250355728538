import React from 'react';
import PropTypes from 'prop-types';

import MessagePropType from '../definitions/MessagePropType';
import NumberInput from './NumberInput';


function PercentageInput({
    id,
    name,
    value,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    decimalScale,
    allowNegative,

    label,
    placeholder,

    isPercentSymbolVisible,

    onCreateField,

    ...otherProps
}) {
    return (
        <NumberInput
            id={id}
            name={name}
            value={value}
            
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            
            label={label}
            placeholder={placeholder}
            suffix={isPercentSymbolVisible ? '%' : undefined}
            
            onCreateField={onCreateField}
            
            {...otherProps}
        />
    );
}

PercentageInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string, // legacy support; will be parsed to a number
    ]),

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    decimalScale: PropTypes.number,
    allowNegative: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,
    isPercentSymbolVisible: PropTypes.bool,
    
    onCreateField: PropTypes.func,
};

PercentageInput.defaultProps = {
    id: undefined,
    value: undefined,
    
    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,

    decimalScale: undefined,
    allowNegative: false,
    
    label: undefined,
    placeholder: undefined,
    isPercentSymbolVisible: true,
    
    onCreateField: undefined,
};

export default PercentageInput;
