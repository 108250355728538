/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/

function MasterPageStringReplacer() {
    return null;
}

MasterPageStringReplacer.blockKey = 'rh/master-page-string-replacer';

export default MasterPageStringReplacer;
