import React from 'react';

import Text from './Text';


function TextHeading({ ...otherProps }) {
    return (
        <Text
            {...otherProps}
        />
    );
}

TextHeading.blockKey = 'rh/text-heading';
TextHeading.alignmentOptions = Text.alignmentOptions;
TextHeading.hasSpacingOptions = Text.hasSpacingOptions;

export default TextHeading;
