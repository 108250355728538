import { navigateWindowTo } from '@ratehub/base-ui';

/**
 * Opens the Ratehub card details page or the provider’s own page in a new window based on if
 * the card is monetized.
 *
 * @param {CreditCardSynopsisShape} cardInfo
 * @param {boolean} cardInfo.isMonetized
 * @param {string} cardInfo.applyRedirectURL
 * @param {string} cardInfo.detailsURL
 * @param {string} cardInfo.applyRedirectURLOverride
 * @param {string} cardInfo.queryParams
 */
function navigateNewTabToMoreDetails({
    isMonetized,
    applyRedirectURL,
    detailsURL,
    applyRedirectURLOverride,
    queryParams,
}) {
    navigateWindowTo(
        isMonetized
            ? applyRedirectURLOverride ?? applyRedirectURL
            : applyRedirectURLOverride ?? detailsURL,
        { isNewTab: true, queryParams },
    );
}

export default navigateNewTabToMoreDetails;
