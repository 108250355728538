import REWARD_TYPES from './RewardTypes';


/**
 * Defines the types of credit cards we offer.
 */
export default {
    TRAVEL: REWARD_TYPES.TRAVEL,
    CASH_BACK: REWARD_TYPES.CASH_BACK,
    STORE_CREDIT: REWARD_TYPES.STORE_CREDIT,

    // NOT a product type for a given card, but equates to TRAVEL + CASH_BACK, & sometimes STORE_CREDIT
    ANY_REWARDS: REWARD_TYPES.ALL,

    LOW_INTEREST: 'low-interest',
    BALANCE_TRANSFER: 'balance-transfer',

    SECURED: 'secured',
};
