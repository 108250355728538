import { observable, when } from 'mobx';
import queryString from 'query-string';


const GOOGLE_PLACES_SCRIPT_ID = 'googlePlacesScriptElement';
const GOOGLE_PLACES_SCRIPT_URL = 'https://maps.googleapis.com/maps/api/js';
const GOOGLE_PLACES_DEV_KEY = 'AIzaSyAvBjvuqLxYE_45Rgf7hykrvgUEgwd_ZiE';
// const GOOGLE_PLACES_PROD_KEY = "AIzaSyBJxz04g2iWoWuUU187XrNbPoI0aJSv95g";

const SCRIPT_LOADED_CALLBACK_NAME = 'googlePlacesScriptIsLoadedCallback';


/**
 * Observable flag we will toggle when the async load is finished.
 * @type {boolean}
 */
const isScriptLoadComplete = observable.box(false);

/**
 * Ensure the Google Places API script is available in the document.
 * @async
 */
async function mountGooglePlacesApi() {
    if (typeof window === 'undefined') {
        return;
    }

    // Only mount it once.
    if (!document.getElementById(GOOGLE_PLACES_SCRIPT_ID)) {

        // Callback has to be mounted to the window for the script to trigger it.
        window[SCRIPT_LOADED_CALLBACK_NAME] = () => {
            isScriptLoadComplete.set(true);
        };

        const queryParams = queryString.stringify({
            key: GOOGLE_PLACES_DEV_KEY,    
            libraries: 'places',
            callback: SCRIPT_LOADED_CALLBACK_NAME,
        });

        const createdElement = document.createElement('script');
        createdElement.setAttribute('id', GOOGLE_PLACES_SCRIPT_ID);
        createdElement.async = true;
        createdElement.src = `${GOOGLE_PLACES_SCRIPT_URL}?${queryParams}`;

        document.body.appendChild(createdElement);
    }

    //  Wait here until it's completed loading.
    return when(() => isScriptLoadComplete.valueOf());
}

export default mountGooglePlacesApi;