function getAdSizeForContainer(width, adSizes) {
    // Identify the largest size that will fit the available space.
    const allSizesThatFit = adSizes
        .filter(adSize => adSize[0] <= width); // eliminate ad sizes that are too big for the available space

    // SPECIAL CASE: if the width is less than the smallest one, just use the smallest one.
    // REASON: Ads always render with a placeholder even when there is insufficient space.
    // We can't really avoid that so might as well show an ad instead of just a placeholder.
    if (allSizesThatFit.length === 0) {
        return adSizes[0];
    }

    const largestWidth = allSizesThatFit
        .map(adSize => adSize[0])
        .reduce((largest, currentSize) => largest >= currentSize ? largest : currentSize);

    // Returns the largest size that fits
    return adSizes.find(adSize => adSize[0] === largestWidth);
}

export default getAdSizeForContainer;