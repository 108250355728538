import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import toKebabCase from '../functions/toKebabCase';
import TabListButton from './TabListButton';


function TabList({ tabs, initialTabIndex, onTabChange, tabIdPrefix, tabPanelIdPrefix, variant, size, ...otherProps }) {
    const [ activeTab, setActiveTab ] = useState(initialTabIndex);

    function handleTabClick(index) {
        setActiveTab(index);
        onTabChange && onTabChange(index);
    }

    function getTabId(tab) {
        return tabIdPrefix && tab.title ? `${tabIdPrefix}_${toKebabCase(tab.title)}` : undefined;
    }

    function getTabPanelId(tab) {
        return tabPanelIdPrefix && tab.title ? `${tabPanelIdPrefix}_${toKebabCase(tab.title)}` : undefined;
    }

    return (
        <Container
            role="tablist"
            {...otherProps}
        >
            <For
                each="tab"
                index="index"
                of={tabs}
            >
                <li
                    key={`${index}-${toKebabCase(tab.title)}`}
                    role="presentation"
                >
                    <TabListButton
                        id={getTabId(tab)}
                        onClick={() => handleTabClick(index)}
                        label={tab.title}
                        isSelected={index === activeTab}
                        role="tab"
                        aria-selected={index === activeTab}
                        aria-controls={getTabPanelId(tab)}
                        variant={variant}
                        size={size}
                    />
                </li>
            </For>
        </Container>
    );
}

TabList.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        }),
    ),
    initialTabIndex: PropTypes.number,
    onTabChange: PropTypes.func,
    tabIdPrefix: PropTypes.string,
    tabPanelIdPrefix: PropTypes.string,
    variant: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
};

TabList.defaultProps = {
    tabs: [],
    initialTabIndex: 0,
    onTabChange: undefined,
    tabIdPrefix: undefined,
    tabPanelIdPrefix: undefined,
    variant: 'dark',
    size: 'medium',
};

const Container = styled.ul`
    margin: 0;
    padding: 0;

    > li {
        list-style: none;
    }
`;

export default observer(TabList);
