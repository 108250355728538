import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useForm from '../hooks/useForm';
import MessagePropType from '../definitions/MessagePropType';
import AlternateButton from './AlternateButton';
import PrimaryButton from './PrimaryButton';


const ASYNC_BUTTON_STATUS = {
    READY: 'ready',
    IN_PROGRESS: 'in-progress',
    COMPLETE: 'complete',
};

const BUTTON_COMPONENTS = {
    PRIMARY: 'primary',
    ALTERNATE: 'alternate',
};

const PRIMARY_VARIANTS = {
    BLUEBERRY_DARK: 'blueberry-dark',
    MINT_DARK: 'mint-dark',
    COCONUT: 'coconut',

    // DEPRECATED - do not use, will be updating usages of these to valid ones soon.
    // Please delete once all usages are using one of the valid variants above.
    BLUEBERRY: 'blueberry',
    DARK: 'dark',
    LIGHT: 'light',
};

const ALT_VARIANTS = {
    BLUEBERRY_DARK: 'blueberry-dark',
    DARK: 'dark',
    LIGHT: 'light',
};

const VARIANTS = {
    [BUTTON_COMPONENTS.PRIMARY]: Object.values(PRIMARY_VARIANTS),
    [BUTTON_COMPONENTS.ALTERNATE]: Object.values(ALT_VARIANTS),
};

function AsyncButton({
    onClick,
    messages,
    buttonComponent = BUTTON_COMPONENTS.PRIMARY,
    variant = PRIMARY_VARIANTS.BLUEBERRY_DARK,
    disabled,
    type = 'button',
    ...otherProps
}) {
    const form = useForm();

    const [ isMounted, setIsMounted ] = useState(true);
    const [ status, setStatus ] = useState(ASYNC_BUTTON_STATUS.READY);

    const Button = buttonComponent === 'alternate' ? AlternateButton : PrimaryButton;

    /* Default to *no* variant if we are being asked to render a variant that is
    from the wrong buttonComponent (eg. AlternateButton/MINT_DARK). This ensures
    the button component will render its default variant. */
    const computedVariant = VARIANTS[buttonComponent]
        .find(componentVariant => componentVariant === variant);

    // Since our state changes rely on an async onClick, we need
    //  to keep track of whether or not we're mounted so we
    //  don't attempt state changes after unmount.
    useEffect(
        () => {
            return () => setIsMounted(false);
        },
        [],
    );

    // Update our status if pur props change
    useEffect(
        () => {
            /* Presently this check is needed due to a memory leak issue based on the
                setStatus calls in tests, I'm unsure of why this is needed, more investigation
                is required at a later point. */
            if (!isMounted) {
                return;
            }

            if (disabled === true) {
                setStatus(ASYNC_BUTTON_STATUS.COMPLETE);
            } else if (disabled === false) {
                setStatus(ASYNC_BUTTON_STATUS.READY);
            }
        },
        [ disabled, isMounted ],
    );

    async function handleClick(e) {
        setStatus(ASYNC_BUTTON_STATUS.IN_PROGRESS);

        try {
            let result = onClick ? await onClick(e) : true;

            if (!isMounted) {
                return;
            }

            if (type === 'submit' && form && result !== false) {
                result = await form.submit();
            }

            if (!isMounted) {
                return;
            }

            result === false
                ? setStatus(ASYNC_BUTTON_STATUS.READY)
                : setStatus(ASYNC_BUTTON_STATUS.COMPLETE);
        } catch {
            if (!isMounted) {
                return;
            }

            setStatus(ASYNC_BUTTON_STATUS.READY);
        }
    }

    return (
        <Button
            type="button"
            onClick={(e) => handleClick(e)}
            disabled={status === ASYNC_BUTTON_STATUS.COMPLETE}
            message={messages[status]}
            variant={computedVariant}
            {...otherProps}
        />
    );
}

AsyncButton.propTypes = {
    onClick: PropTypes.func,
    messages: PropTypes.shape({
        [ASYNC_BUTTON_STATUS.READY]: MessagePropType.isRequired,
        [ASYNC_BUTTON_STATUS.IN_PROGRESS]: MessagePropType.isRequired,
        [ASYNC_BUTTON_STATUS.COMPLETE]: MessagePropType.isRequired,
    }).isRequired, // Can add defaults in the future
    buttonComponent: PropTypes.oneOf(Object.values(BUTTON_COMPONENTS)),
    variant: PropTypes.oneOf([
        ...Object.values(PRIMARY_VARIANTS),
        ...Object.values(ALT_VARIANTS),
    ]),
    disabled: PropTypes.bool,
    type: PropTypes.oneOf([ 'submit', 'button' ]),
};

export default AsyncButton;
export { ASYNC_BUTTON_STATUS };
