// Supports upper & lower case, and an optional space in the middle
export const TEST_REGEX = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;

/**
 * Determine if a string is a valid postal code
 * @param {string} value 
 * @return {bool}
 */
export default function postalCodeValidator(value) {    
    return TEST_REGEX.test(value);
}
