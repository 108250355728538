import { Config, fetchJSON, noticeError } from '@ratehub/base-ui';
import { PRODUCT_TYPES } from '../definitions/CurrentDocument';


const API_ENDPOINT = `${Config.CC_API_URL}/card-queue` ;


/**
 * Fetch credit cards for featured showcase/list according to params
 * @param {string} params.province - Province code
 * @param {string} params.language - Language code
 * @param {Object[]} params.products - An array of requested products ordered by priority
 * @return {Promise<Object[]>} array of credit card objects
 */
async function fetchCreditCardsFeatured(params) {
    // Backend expects a different fallback type value for “all” reward type
    const placements = params.products.map(product => {
        return {
            ...product,
            fallback: {
                type: product.fallback?.type === PRODUCT_TYPES.ANY_REWARDS
                    ? BACKEND_ANY_REWARDS
                    : product.fallback?.type ?? BACKEND_ANY_REWARDS,
            },
        };
    });

    const queryParams = {
        province: params.province,
        language: params.language,
        placements,
    };

    const json = await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method:  'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify(queryParams),
        },
    }, 'fetchCreditCardsFeatured');

    // Featured credit card sponsored field is dependent on a global field
    // instead of individual cards
    json?.data?.creditCards?.forEach(creditCard => {
        // Credit card always expected to return valid object shape
        // but sometimes if API is called with bad params it can cause unexpected return
        if (!creditCard) {
            noticeError(
                new Error(`[fetchCreditCardsFeatured] Error unexpected value for ${creditCard}`),
                {
                    creditCard: creditCard,
                    queryParams: queryParams,
                },
            );
        } else {
            creditCard.sponsored = creditCard.sponsored?.global ?? creditCard.sponsored;
        }
    });

    return Array.isArray(json?.data?.creditCards)
        ? json.data.creditCards
        : [];
}

const BACKEND_ANY_REWARDS = 'rewards';


export default fetchCreditCardsFeatured;
