/* eslint-disable */
module.exports = Object.freeze({
    blogGeneric: 'blog-generic',
    calculatorsBanking: 'calculators-banking',
    calculatorsMtg: 'calculators-mtg',
    ccDetails: 'credit-card-details',
    chequing: 'chequing',
    corporate: 'corporate',
    creditCards: 'credit-cards',
    gics: 'gics',
    insurance: 'insurance',
    mortgages: 'mortgages',
    mtgAgentsList: 'mortgage-agents-list',
    sandbox: 'sandbox',
    savings: 'savings',
    scenarioChooser: 'scenario-chooser',
    spa: 'spa',
})
