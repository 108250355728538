import { replaceShortcodes } from '@ratehub/base-ui';


/**
 * Replaces recognized shortcodes within the Ratehub review into their text equivalents, supporting either the
 * legacy slug-based data source or the preferred 'credit-card' data source for the card in question.
 *
 * @param {Object} card API credit response
 * @return {string} the Ratehub review with shortcodes replaced by their card values
 */
function replaceShortcodesInRatehubReview(card) {
    return replaceShortcodes(
        card.ratehub_review?.content,   // warning: may have a relatively large byte count
        [
            // support old-style data source that had to reference the card’s slug
            {
                key: 'creditCards',
                data: {
                    [card.ids?.primary]: card,
                },
            },

            // preferred data source for Ratehub reviews
            {
                key: 'credit-card',
                data: card,
            },
        ],
    );
}

export default replaceShortcodesInRatehubReview;
