import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { comparer, observable, reaction, toJS } from 'mobx';

import hasSameValues from '../functions/hasSameValues';
import useForm from '../hooks/useForm';
import useFieldset from '../hooks/useFieldset';
import useIsMounted from '../hooks/useIsMounted';
import AnimatedContainer, { ANIMATION_TYPES } from './AnimatedContainer';



const InputContext = React.createContext();

function InputController({
    id,
    name,
    value,
    formula,
    onChange,

    // validation
    onValidityChange,
    onValidate,
    onBlur,

    isVisible,
    isRequired,
    isDisabled,

    canShowInvalid,
    getErrorMessageByValue,
    defaultErrorMessage = null, // some inputs might not have a default error message

    // typing
    getParsedValue = defaultGetValue,
    getFormattedValue = defaultGetValue,

    children,

    // form-specific
    onCreateField,

    // container props
    className,
    // animated container props (only used if isVisible is defined)
    animationType,

    ...otherProps
}) {
    const getIsMounted = useIsMounted();

    const inputState = useInputState({
        id,
        name,
        parsedValue: value,
        onValidityChange,
        onValidate,
        isRequired,
        isVisible: isVisible ?? true,   // if we're not told, assume it's always visible.
        canShowInvalid,
        getErrorMessageByValue,
        defaultErrorMessage,
        getParsedValue,
        getFormattedValue,
        onCreateField,
        formula,
    });

    function handleChange(newValue) {
        inputState.hasChangedSinceBlur = true;

        const currentParsedValue = inputState.parsedValue;
        const newParsedValue = inputState.getParsedValue(newValue);

        //! We need to update the formatted value before raising onChange;
        // Some users might want to override the value as a result of the onChange handler.
        inputState.formattedValue = newValue;

        // We only want to raise onChange once per unique parsed value.
        if (!hasSameValues(currentParsedValue, newParsedValue)) {
            onChange?.(newParsedValue, {
                isValid: !inputState.isInvalid,
                errorMessage: inputState.errorMessage,
            });
        }
    }

    function handleBlur(event) {
        inputState.hasChangedSinceBlur = false;

        onBlur?.(event);

        // The timeout of 200ms is tied to how long it takes another element
        // to focus. Any value smaller than this doesn't seem to work
        // for trackpads and some devices.
        setTimeout(() => {
            // element might be re-focused before the timeout expires in which case we'll cancel showing validity.
            if (document.activeElement?.name !== inputState.name && getIsMounted()) {
                inputState.canShowInvalid = true;
            }
        }, 200);
    }

    const inputProps = {
        ...otherProps,
        id: inputState.id,
        name: inputState.name,
        value: inputState.formattedValue,
        onChange: handleChange,
        onBlur: handleBlur,
        required: inputState.isRequired,
        disabled: isDisabled,
        // boolean attributes should only be set if true
        'data-invalid': inputState.isInvalid ? true : undefined,
        'data-computed': inputState.isValueComputed ? true : undefined,
        // we only have a container element if isVisible is passed; these props would normally be spread on the input
        ...(typeof isVisible === 'undefined' && {
            className,
        }),
    };

    return (
        <InputContext.Provider value={inputState}>
            <Choose>
                <When condition={typeof isVisible === 'boolean'}>
                    <AnimatedContainer
                        isVisible={inputState.isVisible}
                        className={className}
                        type={animationType}
                        alwaysRenderChildMarkup
                    >
                        {children(inputProps, inputState)}
                    </AnimatedContainer>
                </When>
                <Otherwise>
                    {children(inputProps, inputState)}
                </Otherwise>
            </Choose>
        </InputContext.Provider>
    );
}

InputController.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,

    // validation
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isVisible: PropTypes.bool,
    canShowInvalid: PropTypes.bool,
    getErrorMessageByValue: PropTypes.func,
    defaultErrorMessage: PropTypes.node,

    // typing
    getParsedValue: PropTypes.func,
    getFormattedValue: PropTypes.func,

    onCreateField: PropTypes.func,
    formula: PropTypes.func,

    // render prop
    children: PropTypes.elementType.isRequired,

    // animated container props
    className: PropTypes.string,
    animationType: PropTypes.oneOf(Object.values(ANIMATION_TYPES)),
};

InputController.defaultProps = {
    id: undefined,
    value: undefined,
    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,
    isRequired: true,
    isDisabled: false,
    isVisible: undefined,
    canShowInvalid: undefined,
    getErrorMessageByValue: undefined,
    defaultErrorMessage: null,
    getParsedValue: undefined,
    getFormattedValue: undefined,
    onCreateField: undefined,
    formula: undefined,
    className: undefined,
    animationType: ANIMATION_TYPES.FADE,
};

/**
 * Get input state from the closest context provider; useful for composite inputs.
 *
 * @returns {InputState}
 */
function useInput() {
    return useContext(InputContext);
}

/**
 * A hook that handles the logic for a self-validating input, transient state and implements rendition logic for the ErrorMessageContainer.
 * Most inputs will generally use this hook, but if your input does not have validation there is no need to use this hook.
 *
 * This hook is observable, so you must wrap your component with `observer` to consume it.
 *
 * @typedef {import ('react')} React
 * @template T - the type of the parsed value
 * @template F - the type of the formatted value
 *
 * @param {object} args
 * @param {string?} args.id - useful when there are multiple inputs with the same name
 * @param {string} args.name - we check if the activeElement is the same as this name to determine if we should fire onBlur asynchronously
 * @param {T | null} args.parsedValue
 * @param {(validityState: { isValid: boolean, errorMessage: React.ReactNode | null, isShowingInvalid: boolean }) => void} args.onValidityChange - subscribe to validity state changes (called on first render)
 * @param {(value: T) => React.ReactNode | boolean} args.onValidate - value passed has passed internal validation and is not empty
 * @param {boolean} args.canShowInvalid - if false, we might still show invalid if the user has blurred the input
 * @param {boolean} args.isRequired - if true, we will show an error message if the value is empty (see isValueEmpty below)
 * @param {(value: F | null) => T | null} args.getParsedValue - we will raise the onChange handler with the parsed value; if not provided, we will raise the onChange handler with the value as-is. null means the value is not parseable or is empty.
 * @param {(value: T | null) => F | null} args.getFormattedValue - we will format the value before rendering it in the input; if not provided, we will render the value as-is.
 * @param {(formattedValue: F) => React.ReactNode | null} args.getErrorMessageByValue - implement this if you want to do contextual validation inside your component
 * @param {React.ReactNode} args.defaultErrorMessage - if getErrorMessageByValue returns false or the value is required and empty, we will show this error message
 * @param {(field: Field) => void} args.onCreateField - invoked with field object so parent components can manipulate the field state
 * @param {boolean} args.isVisible - if our values are visible in form.values and we participate in validity checks
 * @param {(values: any, form: Form) => T} args.formula - formula to compute default value; only works if we're in a form
 *
 * @returns state - passed to input so you can render errors, query validity state, etc.
 */
function useInputState(args) {
    const {
        id,
        name,
        parsedValue,
        onValidityChange,
        onValidate,

        canShowInvalid,

        isRequired,
        isVisible,

        getParsedValue = defaultGetValue,
        getFormattedValue = defaultGetValue,

        getErrorMessageByValue,
        defaultErrorMessage = null, // some inputs might not have a default error message

        onCreateField,
        formula,
    } = args; // keeping as a separate symbol so we can use the useSyncedProp hook

    /**
     * @typedef {any} Form - I'm too lazy to type this out
     * @type {Form?}
     */
    const form = useForm();
    const fieldset = useFieldset();

    // Composite input support.
    // If there's a parent input, attach our input to it so the parent input can interact with our input.
    const parentInput = useInput();

    // This is the state of the input; state, parsing/formatting, and validation.
    const [ inputState ] = useState(() => observable({
        _formattedValue: getFormattedValue(parsedValue),
        parentInput,
        subInputs: [],

        // Tracked in order to determine if we should compute the value via formula;
        // If the user focuses the input, we don't want to stop computing the value unless they make a change;
        // If the user makes a change, we don't want to re-compute the value until they blur to prevent clobbering their changes- even if they empty the value.
        // Once they blur, we can re-compute the value if it's empty.
        hasChangedSinceBlur: false,

        // props are bound to state so they can be observable dependencies
        id,
        name,
        isRequired,
        isVisible,
        defaultErrorMessage,
        _canShowInvalid: canShowInvalid,
        getErrorMessageByValue,
        onValidate,
        getParsedValue,
        getFormattedValue,
        onValidityChange,
        formula,

        // formula support
        get computedValue() {
            return this.formula?.(form?.values, form) ?? null;
        },
        get isEmpty() {
            return isValueEmpty(this.parsedValue);
        },
        // our explicit value is empty
        get isValueEmpty() {
            return isValueEmpty(toJS(this._formattedValue));
        },
        get isValueComputed() {
            if (typeof this.formula !== 'function') {
                return false;
            }

            // While a user is actively working with the input, we don't want to replace empty values with computations.
            return !this.hasChangedSinceBlur && this.isValueEmpty;
        },

        // formula gets implemented here so that the input and the form both know the computed value;
        // additionally, existing usages of formula return the formatted value so the formula result will get parsed if necessary
        get formattedValue() {
            return this.isValueComputed
                ? this.getFormattedValue(this.computedValue)
                : toJS(this._formattedValue);
        },
        set formattedValue(value) {
            this._formattedValue = value;
        },

        get parsedValue() {
            return this.getParsedValue(this.formattedValue);
        },
        set parsedValue(newValue) {
            this._formattedValue = this.getFormattedValue(newValue);
        },

        get canShowInvalid() {
            return this._canShowInvalid || this.parentInput?.canShowInvalid || (form?.hasAttemptedSubmit ?? false);
        },
        set canShowInvalid(newCanShowInvalid) {
            this._canShowInvalid = newCanShowInvalid;
        },

        // validation state

        get errorMessage() {
            return this.getErrorMessage(this.formattedValue) ?? this.subInputs.find(input => input.errorMessage)?.errorMessage ?? null;
        },
        get shouldShowInvalid() {
            return (this.canShowInvalid && !!this.errorMessage) || this.subInputs.some(input => input.shouldShowInvalid);
        },
        get isInvalid() {
            return this.errorMessage != null || this.subInputs.some(input => input.isInvalid);
        },

        addInput(input) {
            this.subInputs.push(input);
        },
        removeInput(input) {
            this.subInputs.splice(this.subInputs.indexOf(input), 1);
        },
        /**
         * @template F - formatted value
         * @param {F} formattedValue
         * @returns {React.ReactNode | null}
         */
        getErrorMessage(formattedValue) {
            // Assert required-ness; bails so we don't attempt to validate an empty value.
            if (isValueEmpty(formattedValue)) {
                return this.isRequired
                    ? this.defaultErrorMessage
                    : null;
            }

            // Validation should be done on the parsed value.
            const validatedValue = this.getParsedValue(formattedValue);

            // Detect if the value was not parseable; if so, we'll show the default error message.
            if (isValueEmpty(validatedValue)) {
                return this.defaultErrorMessage;
            }

            // Internal validation takes precedence over custom validation.
            const internalErrorMessage = this.getErrorMessageByValue?.(validatedValue);

            if (internalErrorMessage !== true && internalErrorMessage != null) {
                return internalErrorMessage === false
                    ? this.defaultErrorMessage
                    : internalErrorMessage;
            }

            // Custom validation
            const customErrorMessage = this.onValidate?.(validatedValue);

            if (customErrorMessage !== true && customErrorMessage != null) {
                return customErrorMessage === false
                    ? this.defaultErrorMessage
                    : customErrorMessage;
            }

            return null;
        },
    }, undefined, { name: `InputController.inputState: ${name}` }));

    // Sync props with state.
    // TODO: Not all of these need to be synced; so maybe we can reduce the number of useEffects that could impact performance.
    useSyncedProp(inputState, args, 'id', id);
    useSyncedProp(inputState, args, 'name', name);
    useSyncedProp(inputState, args, 'parsedValue', parsedValue);
    useSyncedProp(inputState, args, 'isRequired', isRequired);
    useSyncedProp(inputState, args, 'defaultErrorMessage', defaultErrorMessage);
    useSyncedProp(inputState, args, 'canShowInvalid', canShowInvalid);
    useSyncedProp(inputState, args, 'getErrorMessageByValue', getErrorMessageByValue);
    useSyncedProp(inputState, args, 'onValidate', onValidate);
    useSyncedProp(inputState, args, 'getParsedValue', getParsedValue);
    useSyncedProp(inputState, args, 'getFormattedValue', getFormattedValue);
    useSyncedProp(inputState, args, 'onValidityChange', onValidityChange);
    useSyncedProp(inputState, args, 'isVisible', isVisible);
    useSyncedProp(inputState, args, 'formula', formula);

    // Because we depend on `canShowInvalid`, this effect will handle all cases where we need to communicate the validity state.
    useEffect(
        () => reaction(
            () => ({
                isValid: !inputState.isInvalid,
                errorMessage: inputState.errorMessage,
                isShowingInvalid: inputState.shouldShowInvalid,
            }),
            validityState => inputState.onValidityChange?.(validityState),
            { fireImmediately: true, name: 'useInput.onValidityChange', equals: comparer.structural },
        ),
        [ inputState ],
    );

    useEffect(
        () => {
            if (!parentInput) {
                return;
            }

            parentInput.addInput(inputState);
            return () => {
                parentInput.removeInput(inputState);
            };
        },
        [ parentInput, inputState ],
    );

    // TODO: One field can be associated with many inputs; this implementation has a 1:1 relationship and should be refactored. We also probably want to attach input state directly to form without creating a field object.
    // Optional form/fieldset integration. If there's a parent form, we'll attach our field to it.
    // Field allows us to prevent the form from accessing internal implementation details and support the existing API.
    /**
      * @template T = any
      * @typedef {{
      *  name: string,
      *  value: T,
      *  typedValue: T,
      *  setValue: (value: T) => void,
      *  isValid: boolean,
      *  setTouched: (isTouched: boolean) => void,
      *  setForm: (form: Form) => void,
      * }} Field
      *
      * @type {Field<T>}
      */
    const field = useMemo(
        () => observable({
            get name() {
                return inputState.name;
            },
            set name(newName) {
                inputState.name = newName;
            },
            setValue(newValue) {
                inputState.parsedValue = newValue;
            },
            get isValid() {
                return !inputState.isInvalid;
            },
            get isValueComputed() {
                return inputState.isValueComputed;
            },
            get isValueEmpty() {
                return inputState.isValueEmpty;
            },

            get value() {
                return inputState.parsedValue;
            },
            get typedValue() {
                return this.value;
            },
            // Stubbed methods we don't use.
            setTouched() {},
            setForm() {},
        }, undefined, { name: `InputController.field: ${inputState.name}` }),
        [ inputState ],
    );

    // Invoke callback so parent components can manipulate the field state.
    useEffect(
        () => {
            onCreateField?.(field);
        },
        [ field ],
    );

    // Attach field to form.
    //! Don't attach to the form if we're a child input (ie. part of a composite input). Only the parent input should attach to the form.
    useEffect(
        () => {
            if (parentInput || !form) {
                return; // we don't want to attach to the form if we're a child field
            }

            if (!form.getFieldNamed(field.name)) {
                form.addField(field);

                // When we unmount, remove the field.
                return () => form.removeField(field);
            }
        },
        [ form, inputState, field ],
    );
    useEffect(() => {
        if (parentInput || !fieldset) {
            return;
        }

        // Only add if it's not already in the Fieldset.
        if (!fieldset.getFieldNamed(field.name)) {
            fieldset.addField(field);

            return () => fieldset.removeField(field);
        }
    }, [ fieldset, inputState, field ]);
    useEffect(() => {
        if (!form) {
            return;
        }

        form.setFieldVisibility(field, isVisible);
    }, [ form, field, isVisible ]);

    return inputState;
}

/**
 * Convenience hook to sync a prop with state.
 *
 * @param {object} state
 * @param {object} props
 * @param {string} key
 * @param {any?} defaultValue
 */
// eslint-disable-next-line max-params
function useSyncedProp(state, props, key, defaultValue) {
    useEffect(
        () => {
            state[key] = props[key] ?? defaultValue;
        },
        [ state, props[key] ],
    );
}

// Simple heuristic to determine if a value is empty used for required-ness checks.
// If you are dealing with objects, your input should prefer null to indicate an empty value.
// We are not expecting boxed types (ie. observables) so please un-wrap them before passing them to this function.
function isValueEmpty(value) {
    // Null or undefined
    if (value == null) {
        return true;
    }

    if (typeof value === 'string') {
        return value.length === 0;
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    //! NOTE: Any non-null object is considered non-empty
    return false;
}

/**
 * If the user doesn't specify a parse/format function, we'll just return the value as-is.
 * This was originally an inline lambda but it must be a stable reference to prevent infinite re-renders;
 *
 * @param {T} value
 * @returns {T}
 */
function defaultGetValue(value) {
    return value;
}

export default observer(InputController);
export { useInput };

