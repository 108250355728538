import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import classNames from 'classnames';

import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import MessagePropType from '../definitions/MessagePropType';
import CHECKBOX_VARIANTS from '../definitions/CheckboxVariants';
import getValueLabelOptionPairs from '../functions/getValueLabelOptionPairs';
import messageToString from '../functions/messageToString';
import useToggle from '../hooks/useToggle';
import Checkbox from './Checkbox';
import PageBehaviourButton from './PageBehaviourButton';
import AnimatedFadeContainer from './AnimatedFadeContainer';
import IconMinus from './icons/IconMinus';
import IconPlus from './icons/IconPlus';
import InputController from './InputController';
import ErrorMessageContainer from './ErrorMessageContainer';


function CheckboxGroupExpandable({
    id,
    name,
    value,

    options,
    maximumSelectable,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,
    
    isRequired,
    isDisabled,
    canShowInvalid,

    className,

    onCreateField,

    ...otherProps
}) {
    const intl = useIntl();

    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            canShowInvalid={canShowInvalid}
            isRequired={isRequired}
            isDisabled={isDisabled}
            getParsedValue={(newValue) => getParsedValue(newValue, maximumSelectable)}
            getFormattedValue={getArrayValue}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            className={className}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                const formattedOptions = getValueLabelOptionPairs(options).map(option => {
                    const isChecked = inputProps.value.includes(option.value);
                    const optionLabelText = messageToString(option.label ?? option.value, intl);

                    return {
                        ...option,
                        id: `${name}_${optionLabelText}`.replace(/\s/g, '_'), // ids can’t have spaces
                        value: option.value,
                        checked: isChecked,
                        label: optionLabelText,
                        children: option.children,
                    };
                });
    
                const checkedCount = formattedOptions.reduce((accumulator, optionValue) => {
                    return accumulator + (inputProps.value.includes(optionValue.value) ? 1 : 0);
                }, 0);

                const isMaximumReached = checkedCount >= maximumSelectable;

                function handleChange(option, isChecked) {
                    if (maximumSelectable === 1) { // Radio button selection logic
                        if (isChecked) {
                            inputProps.onChange([ option.value ]);
                        }
                    } else { // multiple-checkbox selection logic
                        const updated = isChecked
                            ? [ ...inputProps.value, option.value ] // add item to array
                            : inputProps.value.filter(currentValue => currentValue !== option.value); // remove item from array

                        inputProps.onChange(updated);
                    }
                }

                function handleBlur(evt) {
                    // We only want to fire the blur event when we focus anything outside our children.
                    // We do not want it to fire when one of our checkboxes is blurred to focus another checkbox in this group.
                    if (evt.relatedTarget?.name !== inputProps.name) {
                        inputProps.onBlur(evt);
                    }
                }

                return (
                    <ErrorMessageContainer
                        message={inputState.errorMessage}
                        isInvalid={inputState.shouldShowInvalid}
                        id={id}
                        className={inputProps.className}
                    >
                        <Container
                            className="rh-bg-coconut"
                        >
                            <For
                                each="option"
                                of={formattedOptions}
                            >
                                <SingleExpandableCheckbox
                                    key={option.id}
                                    
                                    {...inputProps}

                                    value={option.checked}

                                    option={option}
                                    isDisabled={isDisabled || (!option.checked && isMaximumReached && maximumSelectable !== 1)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </For>
                        </Container>
                    </ErrorMessageContainer>
                );
            }}
        </InputController>
    );
}


CheckboxGroupExpandable.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,   // usually and array (or object for mobx) but could be a string for maxSelectable=1
    
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                label: MessagePropType,
                value: PropTypes.any.isRequired,

                children: PropTypes.node,
            }),
        ),
        PropTypes.array, // likely used with renderOptionLabel if non-string values
    ]).isRequired,
    maximumSelectable: PropTypes.number,
    
    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    canShowInvalid: PropTypes.bool,

    className: PropTypes.string,

    onCreateField: PropTypes.func,
};

CheckboxGroupExpandable.defaultProps = {
    id: undefined,
    value: undefined,
    
    maximumSelectable: Infinity,
       
    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,

    isRequired: true,
    isDisabled: false,
    canShowInvalid: false,

    className: undefined,

    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.checkbox-group-expandable.errors.default"
        defaultMessage="Please choose an option"
    />
);

function getArrayValue(value) {
    if (Array.isArray(value)) {
        return value;
    }

    // We might get passed a single value if we're
    //  using maximumSelectable of 1 - change this into an array
    return value != null ? [ value ] : [];
}

function getParsedValue(newValue, maximumSelectable) {
    // maximumSelectable of 1 just wants a single value
    if (maximumSelectable === 1) {
        return Array.isArray(newValue) ? newValue[0] : newValue;
    }

    return getArrayValue(newValue);
}

function SingleExpandableCheckbox({ option, onChange, ...otherProps }) {
    const [ isExpanded, toggleIsExpanded ] = useToggle(false);

    return (
        <div
            className="expandable-checkbox-row"
        >
            <div className="checkbox-controls rh-pr-1_5 rh-pl-1">
                <Checkbox
                    {...otherProps}

                    id={option.id}

                    className="checkbox rh-my-0_5 rh-mr-1"

                    label={option.label}
                    
                    onChange={isChecked => onChange?.(option, isChecked)}
                    
                    size="large"
                    variant={CHECKBOX_VARIANTS.expandable}
                />
                <PageBehaviourButton
                    className={classNames('rh-my-0_5', {
                        'make-invisible': !option.children,     // leaving a placeholder preserves row layout
                    })}
                    includeIconOutline={false}
                    iconComponent={isExpanded ? IconMinus : IconPlus}
                    onClick={toggleIsExpanded}
                    data-name="expand-checkbox-children"
                />
            </div>
            <If condition={option.children}>
                <AnimatedFadeContainer
                    className="children rh-mb-2"
                    isVisible={isExpanded}
                    withColour={false}
                >
                    {option.children}
                </AnimatedFadeContainer>
            </If>
        </div>
    );
}


SingleExpandableCheckbox.propTypes = {
    option: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.node, // String or array since messageToString can return arrays
        checked: PropTypes.bool,
        children: PropTypes.node,
    }).isRequired,
    onChange: PropTypes.func,
};

SingleExpandableCheckbox.defaultProps = {
    onChange: undefined,
};

const Container = styled.div`
    border: 1px solid ${Colours.STONE};
    border-radius: ${Sizes.SPACING.HALF};

    > .expandable-checkbox-row {
        border-bottom: 1px solid ${Colours.STONE};
        &:last-child {
            border-bottom: none;
        }

        > .checkbox-controls {
            display: flex;
            flex-wrap: nowrap;

            > .checkbox {
                flex-shrink: 1;
                margin-left: 0;
            }

            .make-invisible {
                visibility: hidden;
            }
        }

        > .children {
            width: calc(100% - 8rem);
            margin-left: 4.5rem;
        }
    }
`;

export default observer(CheckboxGroupExpandable);
