/**
 * @param pageSettings {object} pageSettingsContext for current page
 * @param tableOfContentsExclude {boolean} determines if block should be included in Table Of Contents menu.
 * @param tableOfContentsTitle {string} provides custom link text for the menu (overrides text from block heading).
 * @returns an object containing the data- attributes needed for the Table of Contents. Can be spread onto a component.
 */
function getTableOfContentsDataAttributes(pageSettings, tableOfContentsExclude, tableOfContentsTitle) {
    return {
        // Only include attribute if we actually want to exclude this block.
        ...(pageSettings.hasSidebar && tableOfContentsExclude
            ? { 'data-toc-exclude': tableOfContentsExclude }
            : {}
        ),

        // Only include attribute if title is not excluded & title contains real string.
        ...(pageSettings.hasSidebar && !tableOfContentsExclude && tableOfContentsTitle
            ? { 'data-toc-title': tableOfContentsTitle }
            : {}
        ),
    };
}

export default getTableOfContentsDataAttributes;
