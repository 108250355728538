import { Config } from '@ratehub/base-ui';

import getGICsProductShape from './getGICsProductShape';
import getSharedDepositShape from './getSharedDepositShape';
import fetchDeposit from './fetchDeposit';


const API_ENDPOINT = `${Config.GICS_API_URL}/rate-queue`;


/**
 * Fetch GICs according to options
 * @param {string} options.province - Province code
 * @param {string} options.language - Language code
 * @param {object[]} options.products - An array of requested products ordered by priority
 */
async function fetchGICs(options) {
    const response = await fetchDeposit(options, API_ENDPOINT);

    return {
        data: {
            products: response.data?.products?.map(getProductShape) || [],
        },
    };
}

function getProductShape(product) {
    return product
        ? {
            // Get ProductSingle display specific fields
            ...getGICsProductShape(product),

            // Get slots/queue specific fields
            ...getSharedDepositShape(product),

            // Shared fields that overlap between ProductSingle and slots are:
            // provider, isSponsored, detailsURL, and applyURL
        }
        : undefined;
}

export default fetchGICs;
