import { Config } from '@ratehub/base-ui';

import getSharedDepositShape from './getSharedDepositShape';
import fetchDeposit from './fetchDeposit';


const API_ENDPOINT = `${Config.CHEQUING_API_URL}/account-queue`;

/**
 * Fetch chequing products according to options
 * @param {string} options.province - Province code
 * @param {string} options.language - Language code
 * @param {Object[]} options.products - An array of requested products ordered by priority
 */
async function fetchChequing(options) {
    const response = await fetchDeposit(options, API_ENDPOINT);

    return {
        data: {
            products: response.data?.products?.map(getProductShape) || [],
        },
    };
}

function getProductShape(product) {
    return product
        ? getSharedDepositShape(product)
        : undefined;
}

export default fetchChequing;
