import { css } from 'styled-components';

import Sizes from '../definitions/Sizes';
import Typography from '../definitions/Typography';
import Colours from '../definitions/Colours';


const defaultStyle = css`
    table {
        border-collapse: collapse;
        margin-left: auto;
        margin-right: auto;

        a {
            text-decoration: none;
            outline: none;
            box-shadow: inset 0 -2px ${Colours.BLACKBERRY};
            transition:
                color 0.3s,
                box-shadow 0.3s;

            color: ${Colours.BLACKBERRY};

            &:hover,
            &:focus {
                color: ${Colours.BLUEBERRY};
                box-shadow: inset 0 -2px ${Colours.BLUEBERRY};
            }

            &:active {
                color: ${Colours.BLUEBERRY_DARK};
            }
        }
    }

    th,
    td  {
        line-height: 20px; /* I'd like to put this elsewhere, but we don't have as standard right now */
        vertical-align: middle;

        padding-left: ${Sizes.SPACING.TWO};
        padding-right: ${Sizes.SPACING.TWO};

        &:first-child {
            padding-left: ${Sizes.SPACING.ONE_AND_A_HALF};
        }

        &:last-child {
            padding-right: ${Sizes.SPACING.ONE_AND_A_HALF};
        }
    }

    th {
        font-size: ${Sizes.FONTS.XS};
        font-weight: ${Typography.WEIGHTS.MEDIUM};

        padding-top: ${Sizes.SPACING.THREE};
        padding-bottom: ${Sizes.SPACING.ONE};

        border-bottom: 1px solid ${Colours.STONE};
    }

    td {
        padding-top: ${Sizes.SPACING.ONE_AND_A_HALF};
        padding-bottom: ${Sizes.SPACING.ONE_AND_A_HALF};

        border-bottom: 1px solid ${Colours.STONE};
    }

    /* Style these th's more like td's */
    tbody th {
        padding-top: ${Sizes.SPACING.ONE_AND_A_HALF};
    }
`;

// "Compact" style is intended for backwards compatibility with classic table content.
const compactStyle = css`
    ${defaultStyle};

    th {
        text-align: left;
        padding-top: ${Sizes.SPACING.ONE};
    }
`;

// "Minimal" style intended to show tables that *almost* fit on mobile but just need a bit of scaling down.
// This is considered an alternative to overflow scrolling.
const minimalStyle = css`
    ${defaultStyle};

    th,
    td  {
        padding-left: ${Sizes.SPACING.ONE};
        padding-right: ${Sizes.SPACING.ONE};

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    th {
        font-size: ${Sizes.FONTS['2XS']};
    }

    td {
        font-size: ${Sizes.FONTS['2XS']};

        padding-top: ${Sizes.SPACING.ONE};
        padding-bottom: ${Sizes.SPACING.ONE};
    }
`;

const TABLE_STYLES = {
    DEFAULT: 'default',
    COMPACT: 'compactStyle',
    MINIMAL: 'minimal',
};

const TABLE_STYLES_CSS_MAP = {
    [TABLE_STYLES.DEFAULT]: defaultStyle,
    [TABLE_STYLES.COMPACT]: compactStyle,
    [TABLE_STYLES.MINIMAL]: minimalStyle,
};


export { TABLE_STYLES, TABLE_STYLES_CSS_MAP };
