import Languages from '../definitions/Languages';


/**
 * Returns a relative path to the ratehub.ca home page URL. Accepts intl, languageCode, or locale.
 *
 * @param {(Intl|string)} value expecting either Intl or the language code 'en' || 'fr' or the locale (e.g. 'en_CA')
 * @return {string} '/' || '/comparez'
 */
export default function getRatehubHomePageURL(value = '') {
    const languageCode = (typeof value === 'object'
        ? value.locale
        : value
    ).slice(0, 2);

    switch (languageCode) {
        case Languages.ENGLISH:
            return '/';

        case Languages.FRENCH:
            return '/comparez';

        default:
            throw new RangeError(`getRatehubHomePageURL: unknown language “${languageCode}”`);
    }
}
