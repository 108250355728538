import { STREET_DIRECTIONS } from '@ratehub/documents/common/stable';


const MESSAGES = {
    [STREET_DIRECTIONS.NORTH]: 'North',
    [STREET_DIRECTIONS.SOUTH]: 'South',
    [STREET_DIRECTIONS.EAST]: 'East',
    [STREET_DIRECTIONS.WEST]: 'West',
    [STREET_DIRECTIONS.NORTHEAST]: 'Northeast',
    [STREET_DIRECTIONS.NORTHWEST]: 'Northwest',
    [STREET_DIRECTIONS.SOUTHEAST]: 'Southeast',
    [STREET_DIRECTIONS.SOUTHWEST]: 'Southwest',
    [STREET_DIRECTIONS.EST]: 'Est',
    [STREET_DIRECTIONS.NORD]: 'Nord',
    [STREET_DIRECTIONS.NORDEST]: 'Nord-est',
    [STREET_DIRECTIONS.NORDOUEST]: 'Nord-ouest',
    [STREET_DIRECTIONS.SUD]: 'Sud',
    [STREET_DIRECTIONS.SUDEST]: 'Sud-est',
    [STREET_DIRECTIONS.SUDOUEST]: 'Sud-ouest',
    [STREET_DIRECTIONS.OUEST]: 'Ouest',
};

// REQUIREMENT: sort them alphabetically.
const STREET_DIRECTION_OPTIONS = Object.values(STREET_DIRECTIONS)
    .map(value => ({
        value,
        label: MESSAGES[value],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export default STREET_DIRECTION_OPTIONS;
