import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import { toKebabCase, scrollIntoView, noticeError } from '@ratehub/base-ui';

import getSafeHTMLName from '../functions/getSafeHTMLName';
import SiteSettings from '../definitions/SiteSettings';
import LinkBlockList from './LinkBlockList';
import LinkBlockAnchor, { MENU_PADDING } from './LinkBlockAnchor';
import SkeletonPlaceholder from './SkeletonPlaceholder';


const MAX_CHILDREN = 5;
const NUM_COLLAPSED_ITEMS_HIDDEN = 1;

function TableOfContents({ onAnchorClick, className }) {
    const [ activeElementId, setActiveElementId ] = useState(null);
    const [ menuItems, setMenuItems ] = useState(null);

    const isReady = Array.isArray(menuItems); // is not ready when it's building menu

    // Build the menu & watch for added/removed blocks
    useEffect(() => {
        const generatedMenuItems = getMenuItemsFromDocument();

        // Trigger re-render with generated menu items.
        setMenuItems(generatedMenuItems);

        // **** INTERSECTION OBSERVER ****

        if (!window.IntersectionObserver) {
            // Do not perform scroll-tracking if browser doesn't support IntersectionObserver.
            return;
        }

        // Watch for intersections to these elements.
        const intersectionObserver = new IntersectionObserver(
            entries => {
                // We only want ONE activeElementId at a time so use .find()
                // to take the first match only.
                entries.find(entry => {
                    if (!entry.isIntersecting) {
                        return;
                    }

                    const id = entry.target.id;

                    if (id == null) {
                        return;
                    }

                    setActiveElementId(id);
                });
            },
            {
                // We only want to consider tracked elements to be "in view"
                // when they are 95% within the top half of the viewport.
                // Not expecting 100% because base-ui's scrollIntoView()
                // doesn't always 100% scroll the element into view on some
                // browsers – it occasionally leaves a px or two off screen.
                threshold: .95,
                rootMargin: '0px 0px -50% 0px',
            },
        );

        const HEADINGS_TO_REAPPLY = {};

        // **** MUTATION OBSERVER ****

        // Watch for component re-renders. These usually appear as childList
        // mutations where the entire block's outer container is removed/added.

        // When a block re-renders, grab any ID that has been assigned to
        // primary headings (h1/h2) in the removed block and re-apply them on
        // headings in the newly-added block (otherwise they will be lost and
        // the TOC links will stop working).

        // RE-FACTOR WARNING: This entire MutationObserver is essentially only
        // here to make up for the fact that TableOfContents assigns IDs to DOM
        // elements belonging to block components. Would like to re-factor this
        // so IDs can never be lost when a block re-renders.

        const mutationObserver = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                // Process REMOVED nodes
                if (mutation.removedNodes?.length) {
                    mutation.removedNodes.forEach(removedNode => {
                        // Find headings with IDs that have been removed in this mutation
                        const removedHeadingsWithId = removedNode.querySelectorAll('h1[id], h2[id]');

                        // Find headings with IDs that match hashes from the menu and save for re-adding later.
                        removedHeadingsWithId.forEach(removedHeading => {
                            if (generatedMenuItems.find(menuItem => menuItem.href === removedHeading.id)) {
                                HEADINGS_TO_REAPPLY[removedHeading.innerText] = removedHeading.id;

                                // unobserve() doesn't seem strictly necessary
                                // on removed DOM nodes and the DOM element is
                                // already gone at this point anyways - but
                                // calling it on already-unobserved elements is
                                // a noop so...
                                intersectionObserver.unobserve(removedHeading);
                            }
                        });
                    });
                }

                // Process ADDED nodes
                if (mutation.addedNodes?.length) {
                    mutation.addedNodes.forEach(addedNode => {
                        const primaryHeading = getPrimaryHeading(addedNode);

                        // no need to continue if no headings found
                        if (!primaryHeading) {
                            return;
                        }

                        // Was heading previously removed?
                        const removedHeadingId = HEADINGS_TO_REAPPLY[primaryHeading.innerText];

                        if (removedHeadingId) {
                            // Re-assign old ID to new heading
                            primaryHeading.id = removedHeadingId;

                            // Re-observe intersection of new headings
                            intersectionObserver.observe(primaryHeading);

                            // No longer need to track this removed ID
                            delete HEADINGS_TO_REAPPLY[addedNode.innerText];
                        }
                    });
                }
            });
        });

        // Observe removal/addition of child blocks of LayoutSelector
        mutationObserver.observe(document.getElementById(SiteSettings.LAYOUT_SELECTOR_ID), {
            childList: true,
        });

        // Observe intersection of detected headings in page content
        generatedMenuItems.forEach(menuItem => {
            const trackableHeading = document.getElementById(menuItem.href);
            if (trackableHeading) {
                intersectionObserver.observe(trackableHeading);
            }
        });

        return () => {
            mutationObserver?.disconnect();
            intersectionObserver?.disconnect();
        };
    }, []);

    function handleAnchorClick(e, targetElementId) {
        e.preventDefault();

        if (targetElementId) {
            const targetElement = document.getElementById(targetElementId);

            if (targetElement) {
                // ACCESSIBILITY: Users should be able to click the menu link,
                // get taken to the target heading and then be able to tab to
                // the next focusable item in the document's main content.
                // Headings are not focusable by default but tabindex='-1' makes
                // them focusable by JavaScript only.
                targetElement.setAttribute('tabindex', '-1');
                targetElement.focus({ preventScroll: true });

                // Using base-ui's version of this function instead of the
                // native one as the native one had problems on pages with
                // lazy-loaded images. The scroll would sometimes get cancelled
                // as the image loads in. This seems to work more consistently.
                scrollIntoView(targetElement, {
                    behavior: 'smooth',
                    scrollMode: 'always',
                });
            }
        }

        onAnchorClick?.(e);
    }

    return (
        <Choose>
            <When condition={!isReady}>
                <StyledSkeletonPlaceholder
                    className="placeholder"
                    variant="listOfLinks"
                />
            </When>
            <Otherwise>
                <LinkBlockList
                    variant="sidebar"
                    hasOuterPadding={false}
                    maxChildren={MAX_CHILDREN}
                    numCollapsedItemsHidden={NUM_COLLAPSED_ITEMS_HIDDEN}
                    className={classNames(
                        'tableOfContentsList', // For testing
                        'rh-mt-0_875',
                        className,
                    )}
                >
                    <For
                        each="item"
                        of={menuItems}
                    >
                        <LinkBlockAnchor
                            variant="sidebar"
                            onClick={(e) => handleAnchorClick(e, item.href)}
                            isActive={activeElementId === item.href}
                            key={item.href}
                            url={`#${item.href}`}
                            title={item.title}
                        />
                    </For>
                </LinkBlockList>
            </Otherwise>
        </Choose>
    );
}

TableOfContents.propTypes = {
    onAnchorClick: PropTypes.func,
    className: PropTypes.string,
};

TableOfContents.defaultProps = {
    onAnchorClick: PropTypes.func,
    className: undefined,
};

/**
 * Finds the primary headings in all top-level blocks on the page and builds a
 * list of menu links out of them.
 * @return {Array} menuItems - A list of menu links; each includes title & href.
 */
function getMenuItemsFromDocument() {
    const menuItems = [];

    // Select DOM elements which are marked as top-level blocks.
    // ASSUMPTION: We expect SiteSettings.TOP_LEVEL_BLOCK_DATA_ATTRIBUTE_NAME
    // to *only* be put on top-level blocks. Using a decendant selector to also
    // allow detecting blocks that are inside a wrapper such as blocks at the root
    // of in-page experiments.
    const blocks = document.querySelectorAll(
        `#${SiteSettings.LAYOUT_SELECTOR_ID} [${SiteSettings.TOP_LEVEL_BLOCK_DATA_ATTRIBUTE_NAME}]`,
    );

    // Read headings out of eligible blocks and build a list of menu links out
    // of them.
    blocks.forEach(block => {
        // Does block have > 0 qualifying headings?

        const primaryBlockHeading = getPrimaryHeading(block);

        if (primaryBlockHeading) {
            // Always show in the TOC unless the data-toc-exclude attribute is present/true.

            const isExcludedFromTableOfContents = block.dataset.tocExclude === 'true' || false;
            const tableOfContentsTitle = block.dataset.tocTitle ?? primaryBlockHeading?.innerText;

            // No need to continue building menu items in these two cases.
            if (!primaryBlockHeading || isExcludedFromTableOfContents) {
                return;
            }

            let newId;

            if (primaryBlockHeading.id) {
                // Use the heading's existing ID as the fragment in the menu link
                newId = primaryBlockHeading.id;
            } else {
                // Create a *new* ID for the heading. Menu item will link to this.
                newId = getIdFromHeadingText(tableOfContentsTitle);

                // Check for headings that might already have this ID... Make newId
                // unique if necessary.
                if (document.getElementById(`${newId}`)) {
                    noticeError(new Error('[TableOfContents] Generated menu ID already exists on page.'), {
                        generatedId: newId,
                        page: window.location.href,
                    });

                    newId = `${newId}-${uuid().slice(0, 8)}`;
                }

                primaryBlockHeading.id = newId;
            }

            // Generate menu items based on headings found
            //  title - The text content used for the menu link
            //  href  - A human-readable fragment ID that matches the block the
            //          menu item is linking to (ID derived from title)
            menuItems.push({
                title: tableOfContentsTitle,
                href: newId,
            });
        }
    });

    return menuItems;
}

function getPrimaryHeading(block) {
    const headings = block.querySelectorAll('h1, h2');

    // Detect only the first heading that has actual text content.
    return headings.length
        ? Array.from(headings).find(heading => heading.innerText.trim())
        : undefined;
}

// Sanitize the heading text
function getIdFromHeadingText(headingText) {
    let id = getSafeHTMLName(toKebabCase(headingText));

    // Remove leading/trailing hyphens, if they are present.
    if (id.substring(0, 1) === '-') {
        id = id.substring(1);
    }
    if (id.slice(-1) === '-') {
        id = id.slice(0, -1);
    }

    return id.toLowerCase();
}

const StyledSkeletonPlaceholder = styled(SkeletonPlaceholder)`
    &.placeholder {
        margin-top: ${MENU_PADDING};
    }
 `;

export default TableOfContents;
