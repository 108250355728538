import { PROVINCE_VALID_POSTAL_FIRST_CHARS } from '../definitions/Provinces';

/**
 * Check if postal code is allowed for an array of province(s) 
 * @param {Array<string>} allowedProvinces allowed province(s)
 * @param {string} postalCode
 * @returns {boolean}
 */
function isPostalCodeAllowed(allowedProvinces, postalCode) {
    if (!postalCode) {
        return false;
    }

    const validPostalCodeFirstChars = allowedProvinces.reduce((validChars, p) =>
        validChars.concat(PROVINCE_VALID_POSTAL_FIRST_CHARS[p]), []);

    return validPostalCodeFirstChars.includes(postalCode[0].toUpperCase());
}


export default isPostalCodeAllowed;
