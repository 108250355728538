import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { Sizes } from '@ratehub/base-ui';


ColumnContainer.WRAP_BEHAVIOUR = {
    LEFT_TO_RIGHT: 'wrap',
    RIGHT_TO_LEFT: 'wrap-reverse',
};

function ColumnContainer({ children, wrapDirection, className, ...otherProps }) {
    return (
        <Container
            wrapDirection={wrapDirection}
            className={classNames(
                'rh-display-flex rh-justify-content-space-between',
                {
                    'rh-flex-wrap': wrapDirection === ColumnContainer.WRAP_BEHAVIOUR.LEFT_TO_RIGHT,
                    'rh-flex-wrap-reverse': wrapDirection === ColumnContainer.WRAP_BEHAVIOUR.RIGHT_TO_LEFT,
                },
                className,
            )}
            {...otherProps}
        >
            {children}
        </Container>
    );
}

ColumnContainer.propTypes = {
    children: PropTypes.any.isRequired,
    wrapDirection: PropTypes.oneOf(Object.values(ColumnContainer.WRAP_BEHAVIOUR)),
    className: PropTypes.string,
};

ColumnContainer.defaultProps = {
    wrapDirection: ColumnContainer.WRAP_BEHAVIOUR.LEFT_TO_RIGHT,
    className: undefined,
};

const Container = styled.div`
    /* Column blocks have L & R margin of 1em. We want to create space
    between each column, but not the edges of the container. */
    margin-left: -${Sizes.SPACING.ONE};
    margin-right: -${Sizes.SPACING.ONE};
`;

ColumnContainer.blockKey = 'rh/column-container';
ColumnContainer.requiresLayoutRow = true;

export default ColumnContainer;
