import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react-lite';

import getComponentDisplayName from '../functions/getComponentDisplayName';
import useForm from '../hooks/useForm';
import FieldPropType from '../definitions/FieldPropType';
import INPUT_TYPES from '../definitions/InputTypes';
import FIELD_TYPES from '../definitions/FieldTypes';


// Translates field prop into input props.
function withField(Input) {
    function WrappedComponent({
        id,
        field,
        // TODO: Remove type prop after legacy fields are removed; type is passed from InputSelector to inputs wrapped with withLegacyField but we don't need it.
        // eslint-disable-next-line no-unused-vars
        type,
        isVisible,
        onValidate,
        onCreateField,
        ...componentProps 
    }) {
        const form = useForm();

        function handleValidate(value) {
            const firstFailedValidator = field.validators?.find(validator => validator.fn(value, form?.values, form) === false);

            if (firstFailedValidator) {
                return firstFailedValidator.message ?? false; // false means use default error message
            }

            return onValidate?.(value, form?.values) ?? null;
        }

        // Some usages of field include input-specific props (Eg. options) which we want to pass directly to the input;
        // But other parts of the field descriptor we don't want to pass to the input.
        // eslint-disable-next-line no-unused-vars
        const { type: fieldType, validators, required, disabled, ...fieldProps } = field;

        // Needs to observe dependencies when onValidate is called; so we can re-run validation when the form values change.
        // Using <Observer> instead of HOC because you can't double wrap an observer component; if the input is already wrapped in an observer, we can't wrap it again.
        return (
            <Observer> 
                {() => (
                    <Input 
                        {...componentProps} 
                        {...fieldProps}
                        id={id ?? field.name}
                        name={field.name}
                        value={field.value} // (semi-)uncontrolled input; we'll interact with input state directly to propagate external updates.
                        isRequired={typeof field.required === 'function' ? field.required(form?.values, form) : field.required}
                        isDisabled={typeof field.disabled === 'function' ? field.disabled(form?.values, form) : field.disabled}
                        isVisible={isVisible}
                        formula={field.formula}
                        // Some inputs don't support onValidate so only pass if necessary
                        {...((Array.isArray(validators) || typeof onValidate === 'function') && { onValidate: handleValidate })}
                        {...(fieldType === FIELD_TYPES.INTEGER && {
                            decimalScale: 0,
                        })}
                        onCreateField={onCreateField}
                    />
                )}
            </Observer>
        );
    }

    // Helpful for debugging stack traces
    WrappedComponent.displayName = `withField(${getComponentDisplayName(Input)})`;

    WrappedComponent.propTypes = {
        id: PropTypes.string,
        field: FieldPropType.isRequired, // Field "descriptor" that has all the field's metadata.
        type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
        isVisible: PropTypes.bool,
        onChange: PropTypes.func,
        onValidityChange: PropTypes.func,
        onValidate: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onCreateField: PropTypes.func,
        canShowInvalid: PropTypes.bool,
    };

    WrappedComponent.defaultProps = {
        id: undefined,
        type: undefined,
        isVisible: undefined,
        onChange: undefined,
        onValidityChange: undefined,
        onValidate: undefined,
        onFocus: undefined,
        onBlur: undefined,
        onCreateField: undefined,
        canShowInvalid: undefined,
    };

    return observer(WrappedComponent);
}

export default withField;