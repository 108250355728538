import { css } from 'styled-components';

import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import {
    BORDER_RADIUS,
    BOX_SHADOW_STONE,
    BOX_SHADOW_BLUEBERRY,
    INPUT_HORIZONTAL_PADDING,
    INPUT_HORIZONTAL_PADDING_CONDENSED,
    INPUT_HEIGHT,
    INPUT_HEIGHT_CONDENSED,
} from '../definitions/SharedInputStyles';


const TEXT_COLOUR = Colours.BLACKBERRY;

const CARET_COLOUR = Colours.BLUEBERRY_DARK;
const DISABLED_COLOUR = Colours.STONE;

const BORDER_COLOUR = Colours.STONE_DARK;
const HOVER_BORDER_COLOUR = Colours.BLUEBERRY_DARK;

const PLACEHOLDER_TEXT_COLOUR = Colours.STONE_DARKEST;

const INVALID_NORMAL_COLOUR = Colours.STRAWBERRY;
const INVALID_ACTIVE_COLOUR = Colours.STRAWBERRY_DARK;

const TRANSITION_DURATION = '300ms';

export default css`
    width: 100%;
    height: ${INPUT_HEIGHT};
    line-height: ${INPUT_HEIGHT}; /* line-height equal to input height ensures Gordita isn't clipped in some cases */

    outline: none;
    box-sizing: border-box;

    /* 1px padding-bottom to make text feel optically vertically centered,
    otherwise sits just a tad too close towards the bottom */
    padding: 0 ${INPUT_HORIZONTAL_PADDING} 1px;

    border-style: solid;
    border-width: 1px;
    border-radius: ${BORDER_RADIUS};
    border-color: ${BORDER_COLOUR};

    font-family: ${Typography.FONT_FAMILY_STACK};
    font-weight: ${Typography.WEIGHTS.REGULAR};
    font-size: ${Sizes.FONTS.S};

    color: ${TEXT_COLOUR};
    background-color: ${Colours.COCONUT};
    caret-color: ${CARET_COLOUR};
    appearance: none;

    transition:
        color ${TRANSITION_DURATION},
        border-color ${TRANSITION_DURATION},
        background-color ${TRANSITION_DURATION},
        box-shadow ${TRANSITION_DURATION};

    &:hover,
    &:focus,
    &:active {
        border-color: ${HOVER_BORDER_COLOUR};
    }

    &:hover {
        box-shadow: ${BOX_SHADOW_STONE};

        &::placeholder {
            color: ${Colours.BLUEBERRY_DARK};
        }
    }

    &:focus {
        box-shadow: ${BOX_SHADOW_BLUEBERRY};
    }

    &::placeholder {
        color: ${PLACEHOLDER_TEXT_COLOUR};
        transition: color ${TRANSITION_DURATION};
        line-height: ${Sizes.SPACING.FOUR}; /* line-height equal to input height ensures Gordita isn't clipped in some cases */
    }

    &:disabled {
        color: ${DISABLED_COLOUR};
        border-color: ${DISABLED_COLOUR};

        &::placeholder {
            color: ${DISABLED_COLOUR};
        }
    }

    &[aria-invalid="true"] {
        color: ${INVALID_NORMAL_COLOUR};
        border-color: ${INVALID_NORMAL_COLOUR};

        &::placeholder {
            color: ${INVALID_NORMAL_COLOUR};
        }

        &:hover {
            border-color: ${INVALID_ACTIVE_COLOUR};
            color: ${INVALID_ACTIVE_COLOUR};

            &::placeholder {
                color: ${INVALID_ACTIVE_COLOUR};
            }
        }

        &:focus,
        &:active {
            border-color: ${INVALID_ACTIVE_COLOUR};
            color: ${TEXT_COLOUR};
        }
    }

    &[readonly] {
        color: ${Colours.BLUEBERRY_DARK};
        border-color: ${Colours.TRANSPARENT};
        background-color: ${Colours.TRANSPARENT};
        box-shadow: none;
        font-size: ${Sizes.FONTS.L}
    }

    &[readonly]::placeholder {
        color: ${Colours.BLUEBERRY_DARK};
    }

    /* Condensed style overrides */
    &.is-condensed {
        height: ${INPUT_HEIGHT_CONDENSED};
        line-height: ${INPUT_HEIGHT_CONDENSED};

        padding-left: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
        padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
    }
`;
