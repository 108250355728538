import scrollIntoView from './scrollIntoView';
import focusFirstInputWithin from './focusFirstInputWithin';
import getFirstInvalidInput from './getFirstInvalidInput';


async function scrollToFirstInvalidField(nodeOrSelector = document) {
    const firstInvalidField = getFirstInvalidInput(nodeOrSelector);

    if (!firstInvalidField) {
        return;
    }

    await scrollIntoView(firstInvalidField, { block: 'center' });

    focusFirstInputWithin(firstInvalidField);
}


export default scrollToFirstInvalidField;
