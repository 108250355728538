/**
 * Properties copied from our city API (Config.CITIES_API_URL)
 * Created this to avoid having to look up the same information all the time
 * Complete object is required if one wants to set a CityField to one of these values,
 * but note that you have to break the location array into separate latitude and longitude keys.
**/

const CALGARY = {
    id: 49,
    name: 'Calgary',
    slug: 'calgary',
    latitude: 51.0833015441895,
    longitude: -114.0581012,
    province: {
        code: 'AB',
        full_name: 'Alberta',
        common_name: 'Alberta',
        sort_order: 2,
        slug: 'alberta',
        timezone: 'America/Edmonton',
        principal_city: {
            id: 49,
            name: 'Calgary',
            slug: 'calgary',
        },
    },
};

const YELLOWKNIFE = {
    id: 1179,
    name: 'Yellowknife',
    slug: 'yellowknife',
    latitude: 62.4500007629395,
    longitude: -114.3717886,
    province: {
        code: 'NT',
        full_name: 'Northwest Territories',
        common_name: 'NWT',
        sort_order: 12,
        slug: 'northwest-territories',
        timezone: 'America/Yellowknife',
        principal_city: {
            id: 1179,
            name: 'Yellowknife',
            slug: 'yellowknife',
        },
    },
};

const TORONTO = {
    id: 4190,
    name: 'Toronto',
    slug: 'toronto',
    latitude: 43.6666984558105,
    longitude: -79.4167022705078,
    province: {
        code: 'ON',
        full_name: 'Ontario',
        common_name: 'Ontario',
        sort_order: 5,
        slug: 'ontario',
        timezone: 'America/Toronto',
    },
};

const CHARLOTTETOWN = {
    id: 2176,
    name: 'Charlottetown',
    slug: 'charlottetown',
    latitude: 46.2332992553711,
    longitude: -63.1310704,
    province: {
        code: 'PE',
        full_name: 'Prince Edward Island',
        common_name: 'PEI',
        sort_order: 9,
        slug: 'prince-edward-island',
        timezone: 'America/Montreal',
        principal_city: {
            id: 2176,
            name: 'Charlottetown',
            slug: 'charlottetown',
        },
    },
};

const MONTREAL = {
    id: 2559,
    name: 'Montréal',
    slug: 'montreal',
    latitude: 45.5,
    longitude: -73.5539925,
    province: {
        code: 'QC',
        full_name: 'Quebec',
        common_name: 'Quebec',
        sort_order: 6,
        slug: 'quebec',
        timezone: 'America/Montreal',
        principal_city: {
            id: 2559,
            name: 'Montréal',
            slug: 'montreal',
        },
    },
};

const REGINA = {
    id: 3312,
    name: 'Regina',
    slug: 'regina',
    latitude: 50.4500007629395,
    longitude: -104.616996765137,
    province: {
        code: 'SK',
        full_name: 'Saskatchewan',
        common_name: 'Saskatchewan',
        sort_order: 3,
        slug: 'saskatchewan',
        timezone: 'America/Regina',
        principal_city: {
            id: 3312,
            name: 'Regina',
            slug: 'regina',
        },
    },
};

const VANCOUVER = {
    id: 588,
    name: 'Vancouver',
    slug: 'vancouver',
    latitude: 49.25,
    longitude: -123.1139268,
    province: {
        code: 'BC',
        full_name: 'British Columbia',
        common_name: 'BC',
        sort_order: 1,
        slug: 'british-columbia',
        timezone: 'America/Vancouver',
        principal_city: {
            id: 588,
            name: 'Vancouver',
            slug: 'vancouver',
        },
    },
};

const WINNIPEG = {
    id: 797,
    name: 'Winnipeg',
    slug: 'winnipeg',
    latitude: 49.88330078125,
    longitude: -97.1374937,
    province: {
        code: 'MB',
        full_name: 'Manitoba',
        common_name: 'Manitoba',
        sort_order: 4,
        slug: 'manitoba',
        timezone: 'America/Winnipeg',
        principal_city: {
            id: 797,
            name: 'Winnipeg',
            slug: 'winnipeg',
        },
    },
};

const MONCTON = {
    id: 864,
    name: 'Moncton',
    slug: 'moncton',
    latitude: 46.0833015441895,
    longitude: -64.7782313,
    province: {
        code: 'NB',
        full_name: 'New Brunswick',
        common_name: 'New Brunswick',
        sort_order: 7,
        slug: 'new-brunswick',
        timezone: 'America/Moncton',
        principal_city: {
            id: 864,
            name: 'Moncton',
            slug: 'moncton',
        },
    },
};

const ST_JOHNS = {
    id: 1127,
    name: 'St. John\'s',
    slug: 'st-johns',
    latitude: 47.5605413,
    longitude: -52.7128315,
    province: {
        code: 'NL',
        full_name: 'Newfoundland',
        common_name: 'Newfoundland',
        sort_order: 10,
        slug: 'newfoundland',
        timezone: 'America/St_Johns',
        principal_city: {
            id: 1127,
            name: 'St. John\'s',
            slug: 'st-johns',
        },
    },
};

const HALIFAX = {
    id: 1229,
    name: 'Halifax',
    slug: 'halifax',
    latitude: 44.6500015258789,
    longitude: -63.5753196,
    province: {
        code: 'NS',
        full_name: 'Nova Scotia',
        common_name: 'Nova Scotia',
        sort_order: 8,
        slug: 'nova-scotia',
        timezone: 'America/Halifax',
        principal_city: {
            id: 1229,
            name: 'Halifax',
            slug: 'halifax',
        },
    },
};

const IQALUIT = {
    id: 1328,
    name: 'Iqaluit',
    slug: 'iqaluit',
    latitude: 63.7332992553711,
    longitude: -68.5169669,
    province: {
        code: 'NU',
        full_name: 'Nunavut',
        common_name: 'Nunavut',
        sort_order: 13,
        slug: 'nunavut',
        timezone: 'America/Iqaluit',
        principal_city: {
            id: 1328,
            name: 'Iqaluit',
            slug: 'iqaluit',
        },
    },
};

const WHITEHORSE = {
    id: 3427,
    name: 'Whitehorse',
    slug: 'whitehorse',
    latitude: 60.7211871,
    longitude: -135.0568449,
    province: {
        code: 'YT',
        full_name: 'Yukon',
        common_name: 'Yukon',
        sort_order: 11,
        slug: 'yukon',
        timezone: 'America/Whitehorse',
        principal_city: {
            id: 3427,
            name: 'Whitehorse',
            slug: 'whitehorse',
        },
    },
};

const CITIES = [
    CALGARY,
    CHARLOTTETOWN,
    HALIFAX,
    IQALUIT,
    MONCTON,
    MONTREAL,
    REGINA,
    ST_JOHNS,
    TORONTO,
    YELLOWKNIFE,
    VANCOUVER,
    WHITEHORSE,
    WINNIPEG,
];

export default function getProvincePrincipalCity(province) {
    return CITIES.find(city => city.province.code === province);
}
