import Locales from '../definitions/Locales';


export default function getPrivacyPolicyURL(locale) {
    switch (locale) {
        case Locales.ENGLISH:
            return '/privacy';

        case Locales.FRENCH:
            return '/confidentialite';

        default:
            throw new RangeError(`[getPrivacyPolicyURL] unrecognized locale ${locale}`);
    }
}