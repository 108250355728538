import React from 'react';
import { observer } from 'mobx-react-lite';

import { useBannerContext } from './BannerProvider';
import AnimatedFadeContainer from './AnimatedFadeContainer';


const ANIMATION_DURATION = 300;

function BannerProviderDisplay() {
    const bannerContext = useBannerContext();

    function handleAnimationEnd() {
        if (!bannerContext?.isVisible) {
            bannerContext?.clear();
        }
    }

    return (
        <>
            {/* For sticky banners, the sticky container must always be present otherwise the sticky behaviour will not work */}
            <AnimatedFadeContainer
                isVisible={!!(bannerContext?.isVisible && bannerContext.isSticky)}
                withColour={false}
                duration={ANIMATION_DURATION}
                onEnd={handleAnimationEnd}
                className="rh-position-sticky rh-zindex-header-footer rh-top-0 rh-left-0 rh-right-0"
                data-test-name="sticky-banner"
                data-test-banner-context={bannerContext?.callerName}
            >
                <BannerChildren canShowChildren={bannerContext.isSticky} />
            </AnimatedFadeContainer>

            {/* Rendering non-sticky banners separately */}
            <AnimatedFadeContainer
                isVisible={!!(bannerContext?.isVisible && !bannerContext.isSticky)}
                withColour={false}
                duration={ANIMATION_DURATION}
                onEnd={handleAnimationEnd}
                className="rh-position-absolute rh-zindex-header-footer rh-left-0 rh-right-0"
                data-test-name="banner"
                data-test-banner-context={bannerContext?.callerName}
            >
                <BannerChildren canShowChildren={!bannerContext.isSticky} />
            </AnimatedFadeContainer>
        </>
    );
}

// Creating a reusable component for the children to ensure that children is only
//  rendered once since its used by both the sticky and non-sticky fade containers above. 
//  Also, AnimatedFadeContainer requires children so we return an empty div
//  if we cannot render our children.
// eslint-disable-next-line react/prop-types
const BannerChildren = observer(({ canShowChildren }) => {
    const bannerContext = useBannerContext();

    return (
        <Choose>
            <When condition={bannerContext?.children && canShowChildren}>
                {bannerContext.children()}
            </When>
            <Otherwise>
                <div />
            </Otherwise>
        </Choose>
    );
});

export default observer(BannerProviderDisplay);