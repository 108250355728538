import { fetchJSON } from '@ratehub/base-ui';

/**
 * Fetch deposit products according to options
 * @param {string} options.province - Province code
 * @param {string} options.language - Language code
 * @param {object[]} options.products - An array of requested products ordered by priority
 */
export default async function fetchDeposit(options, apiRoute) {
    const params = {
        province: options.province,
        language: options.language,
        // Products must always have a value of at least [{}]
        placements: options.products,
    };

    const response = await fetchJSON({
        url: apiRoute,
        options: {
            method:  'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify(params),
        },
    }, 'fetchDeposit');

    return response;
}
