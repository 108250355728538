import DefaultHeroImage from '../definitions/DefaultHeroImage';


/**
 * Utility function to extract the featured media from a post
 *
 * @param {object} post WP post
 * @returns {object} Featured media
 */
export default function getFeaturedMedia(post) {
    return post.rh_featured_media || DefaultHeroImage;
}
