import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import isParsableEmail from '../functions/emailValidator';
import MessagePropType from '../definitions/MessagePropType';
import DirectInput from './DirectInput';
import InputController from './InputController';


function EmailInput({
    name,
    value, 
    isRequired,
    isDisabled,
    onChange,
    onBlur,
    onValidityChange,
    onValidate,
    label,
    placeholder,
    canShowInvalid,
    onCreateField,
    ...otherProps
}) {
    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            canShowInvalid={canShowInvalid}
            isRequired={isRequired}
            isDisabled={isDisabled}
            getParsedValue={getParsedEmail}
            getFormattedValue={getFormattedEmail}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => (
                <DirectInput
                    {...inputProps}
                    type="email"
                    label={label}
                    placeholder={
                        placeholder ?? (
                            <FormattedMessage
                                id="base-ui.email.field.placeholder"
                                defaultMessage="Enter email"
                            />
                        )
                    }
                    disabled={isDisabled}

                    onChange={e => inputProps.onChange(getFormattedEmail(e.target.value))}

                    shouldShowInvalid={inputState.shouldShowInvalid}
                    errorMessage={inputState.errorMessage}
                    isInvalid={inputState.isInvalid}
                />
            )}
        </InputController>
    );
}

EmailInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    canShowInvalid: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,

    onCreateField: PropTypes.func,
};

EmailInput.defaultProps = {
    value: undefined,
    isRequired: undefined,
    isDisabled: false,
    onChange: undefined,
    onBlur: undefined,
    onValidityChange: undefined,
    canShowInvalid: false,
    onValidate: undefined,
    label: undefined,
    placeholder: undefined,

    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.email-input.errors.default"
        defaultMessage="Please enter a valid email address"
    />
);

function getFormattedEmail(email) {
    return typeof email === 'string' ? email.toLowerCase().trim() : null;
}

function getParsedEmail(email) {
    const formattedEmail = getFormattedEmail(email);

    return isParsableEmail(formattedEmail) ? formattedEmail : null;
}

export default observer(EmailInput);