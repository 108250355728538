import { types } from 'mobx-state-tree';

import { PURPOSES } from '@ratehub/documents/mtg-journey-document/stable';


// TODO: Should we deprecate this file?

const SCENARIO_TYPE_PURCHASE = PURPOSES.PURCHASE;
const SCENARIO_TYPE_RENEWAL = PURPOSES.RENEWAL;
const SCENARIO_TYPE_REFINANCE = PURPOSES.REFINANCE;
const SCENARIO_TYPE_HELOC = PURPOSES.HELOC;
const SCENARIO_TYPE_PRE_APPROVAL = PURPOSES.PRE_APPROVAL;

const SCENARIO_TYPES = {
    PURCHASE: SCENARIO_TYPE_PURCHASE,
    RENEWAL: SCENARIO_TYPE_RENEWAL,
    REFINANCE: SCENARIO_TYPE_REFINANCE,
    HELOC: SCENARIO_TYPE_HELOC,
    PRE_APPROVAL: SCENARIO_TYPE_PRE_APPROVAL,
};

export {
    SCENARIO_TYPE_PURCHASE,
    SCENARIO_TYPE_RENEWAL,
    SCENARIO_TYPE_REFINANCE,
    SCENARIO_TYPE_HELOC,
    SCENARIO_TYPE_PRE_APPROVAL,
};

export const ScenarioTypesEnumType = types.enumeration(
    'ScenarioType', Object.values(SCENARIO_TYPES),
);

export default SCENARIO_TYPES;
