import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Sizes,
    toKebabCase,
    CircleSpinner,
    Select,
    AnimatedContainer,
    ANIMATION_TYPES,
} from '@ratehub/base-ui';

import useFeaturedProducts from '../hooks/useFeaturedProducts';
import HeadingPropTypes from '../definitions/HeadingPropTypes';
import isFeaturedProductApplicable from '../functions/isFeaturedProductApplicable';
import AdvertisingDisclosure from './AdvertisingDisclosure';
import TabControlsAsList from './TabControlsAsList';
import FeaturedProductGroup from './FeaturedProductGroup';
import HeadingBlock from './HeadingBlock';


function FeaturedProductShowcase({
    heading,
    tabSelectLabel,
    productGroups,
    verifyProvince,
    showAdDisclosure,
    hideCTAIfNotMonetized,
    className,
}) {
    const {
        isLocationVerified,
        provinceCode,
        city,
    } = useFeaturedProducts({
        productGroups,
        verifyLocation: verifyProvince,
        hideCTAIfNotMonetized,
    });

    // TODO: Does this need to be memo-ized?
    const shouldShowSpinner = useMemo(
        () => !isLocationVerified,
        [ isLocationVerified ],
    );

    // valid group has to include at least 1 product with applyHref
    const validProductGroups = productGroups.filter(
        group => group.products?.some(products => products.applyHref)
    );

    const tabNames = validProductGroups
        .map(item => item.name);
    const tabOptions = tabNames.map((name, index) => ({
        value: index,
        label: name,
    }));

    const [ selectedTabIndex, setSelectedTabIndex ] = useState(0);

    function handleSelectedIndexChange(selectedIndex) {
        setSelectedTabIndex(parseInt(selectedIndex));
    }

    return (
        <MyContainer className={classNames(
            'rh-position-relative',
            className,
        )}
        >
            <HeadingBlock
                {...heading}
            />

            <div className="grid-container rh-pt-0_25 rh-position-relative">
                {/* Mobile tab navigation (select) */}
                <div className="tab-controls-as-select rh-display-block rh-position-relative rh-mb-2">
                    <label
                        className="rh-visually-hidden"
                        htmlFor={SELECT_ID}
                    >
                        {tabSelectLabel}
                    </label>

                    <Select
                        id={SELECT_ID}
                        name="tabControls"
                        options={tabOptions}
                        value={selectedTabIndex}
                        onChange={handleSelectedIndexChange}
                    />
                </div>

                <If condition={showAdDisclosure}>
                    <AdvertisingDisclosure
                        className="advertising-disclosure rh-ml-auto rh-mb-1"
                        size="extra-small"
                    />
                </If>

                {/* Desktop tab navigation (button list) */}
                <TabControlsAsList
                    className="tab-controls-as-list rh-display-none"
                    labels={tabNames}
                    selectedIndex={selectedTabIndex}
                    onChangeSelectedIndex={setSelectedTabIndex}
                />


                <AnimatedContainer
                    type={ANIMATION_TYPES.OPACITY}
                    isVisible={!shouldShowSpinner}
                    alwaysRenderChildMarkup
                >
                    <For
                        each="group"
                        index="index"
                        of={validProductGroups}
                    >
                        <FeaturedProductGroup
                            className={classNames(
                                'featured-product-group rh-width-100p rh-pt-0_5',
                                `${toKebabCase(group.name)}`,
                                { 'rh-display-none': selectedTabIndex !== index },
                            )}
                            key={group.name}
                            href={group.href}
                            hrefText={group.hrefText}
                            products={group.products.filter(product => isFeaturedProductApplicable(product, provinceCode))}
                            isCarouselHidden={selectedTabIndex !== index}
                            hideCTAIfNotMonetized={hideCTAIfNotMonetized}
                            city={city}

                            data-test-name="featured-product-group"
                        />
                    </For>
                </AnimatedContainer>
            </div>

            <If condition={shouldShowSpinner}>
                <CircleSpinner />
            </If>
        </MyContainer>
    );
}

FeaturedProductShowcase.propTypes = {
    heading: HeadingPropTypes,
    tabSelectLabel: PropTypes.string.isRequired,
    productGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    verifyProvince: PropTypes.bool,
    showAdDisclosure: PropTypes.bool,
    hideCTAIfNotMonetized: PropTypes.bool,      // this prop is only relevant to credit cards at the moment
    className: PropTypes.string,
};

FeaturedProductShowcase.defaultProps = {
    heading: undefined,
    verifyProvince: true,
    showAdDisclosure: true,
    hideCTAIfNotMonetized: false,
    className: undefined,
};


// Bit of a hack to generate a unique ID which doesn't create a client/server mismatch
// Replace with React useID hook once available
let sequentialId = 0;
const getSequentialId = function() {
    return sequentialId++;
};
const SELECT_ID = `featured-product-showcase-${getSequentialId()}`;

const MIN_WIDTH_BREAKPOINT = '(min-width: 68.75em)';

const MyContainer = styled.div`
    > .grid-container {
        @media all and ${MIN_WIDTH_BREAKPOINT} {
            display: grid;
            grid-template-columns: minmax(200px, 1fr) minmax(0, 4fr);
            grid-template-rows: auto 1fr;
            grid-column-gap: ${Sizes.SPACING.TWO};
        }

        // Would be in favour of simplifying this block as we have multiple media queries and max-widths.
        // We need the display none but I think we could look at the widths.
        > .tab-controls-as-select {
            max-width: 300px;

            @media (max-width: 800px) {
                max-width: 100%;
            }

            @media ${MIN_WIDTH_BREAKPOINT} {
                display: none;
            }
        }

        // Display block needed here due to how we currently style AdvertisingDisclosure
        > .advertising-disclosure {
            @media all and ${MIN_WIDTH_BREAKPOINT} {
                grid-column: 1 / -1;
                grid-row: 1;
            }
        }

        > .tab-controls-as-list {
            @media all and ${MIN_WIDTH_BREAKPOINT} {
                display: block;
                grid-column: 1;
                grid-row: 2;
            }
        }

        > .featured-product-group {
            grid-column: 2;
            grid-row: 2;
        }

        /* Innappropriate familiarity */
        /* Only this component needs to disable overflow so the cards don't
        appear on top of the BU name labels/tabs. In other circumstances (such
        as when appearing next to a sidebar), the PCB is responsible for
        disabling overflow */
        @media ${MIN_WIDTH_BREAKPOINT} {
            .carousel {
                overflow: hidden;
            }
        }
    }
`;

FeaturedProductShowcase.blockKey = 'rh/featured-product-showcase';
FeaturedProductShowcase.hasHeading = true;
FeaturedProductShowcase.requiresLayoutRow = true;

export default FeaturedProductShowcase;
