import { reaction } from 'mobx';

import debounce from './debounce';


/**
 * DebouncedReaction.
 *
 * A wrapper for mobx reaction that attempts to correct behavior around mobx's implementation of delay.
 * A mobx delay will cause the effect to be rate limited, but this implementation of reaction will instead
 * only execute the effect after delay ms if the expression is re-calculated multiple times in succession.
 *
 * @param {function} expression mobx expression
 * @param {function} effect mobx effect
 * @param {object} opts mobx reaction options
 * @param {number} opts.delay a debounce wait period
 *
 * @returns {function} disposer
 */
export default function debouncedReaction(expression, effect, { delay, ...opts } = {}) {
    const debouncedEffect = debounce(effect, delay);
    const disposer = reaction(
        expression,
        debouncedEffect,
        opts,
    );

    return () => {
        disposer();
        debouncedEffect.cancel();
    };
}