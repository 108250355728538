import { useMemo, useEffect } from 'react';

import debounce from '../functions/debounce';


export default function useDebouncedCallback(fn, dependencies = [], delay = 400) {
    const debouncedFunction = useMemo(() => debounce(fn, delay), dependencies);

    // Clean up on unmount
    useEffect(() => {
        return debouncedFunction.cancel;
    }, []);

    return debouncedFunction;
}
