import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import CHECKBOX_VARIANTS from '../definitions/CheckboxVariants';
import messageToString from '../functions/messageToString';
import MessagePropType from '../definitions/MessagePropType';
import Sizes from '../definitions/Sizes';
import getValueLabelOptionPairs from '../functions/getValueLabelOptionPairs';
import Checkbox from './Checkbox';
import { FlowDirections } from './RadioButtonGroup';
import ErrorMessageContainer from './ErrorMessageContainer';
import InputController from './InputController';


function CheckboxGroup({
    name,
    value,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    options,
    renderOptionLabel,
    maximumSelectable,
    isRequired,
    isDisabled,

    className,
    flowDirection,
    
    canShowInvalid,

    onCreateField,

    ...otherProps
}) {
    const intl = useIntl();

    return (
        <InputController 
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            canShowInvalid={canShowInvalid}
            isRequired={isRequired}
            isDisabled={isDisabled}
            getParsedValue={getArrayValue}
            getFormattedValue={getArrayValue}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            className={className}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                const mappedOptions = getValueLabelOptionPairs(options).map(option => {
                    const isChecked = inputProps.value.includes(option.value);
                    const optionLabelText = renderOptionLabel
                        ? renderOptionLabel(option.value)
                        : messageToString(option.label ?? option.value, intl);

                    return {
                        ...option,
                        id: `${name}_${optionLabelText}`.replace(/\s/g, '_'), // ids can’t have spaces
                        value: option.value,
                        checked: isChecked,
                        label: optionLabelText,
                    };
                });
    
                const checkedCount = mappedOptions.reduce((accumulator, optionValue) => {
                    return accumulator + (inputProps.value.includes(optionValue.value) ? 1 : 0);
                }, 0);
                const isMaximumReached = checkedCount >= maximumSelectable;

                function handleChange(option, isChecked) {
                    const updated = isChecked
                        ? [ ...inputProps.value, option.value ] // add item to array
                        : inputProps.value.filter(currentValue => currentValue !== option.value); // remove item from array

                    inputProps.onChange(updated);
                }

                function handleBlur(event) {
                    // We only want to fire the blur event when we focus anything outside of our children.
                    // We do not want it to fire when one of our checkboxes are blurred to focus another checkbox in this group.
                    if (event.relatedTarget?.name !== name) {
                        inputProps.onBlur(event);
                    }
                }
    
                return (
                    <ErrorMessageContainer
                        data-test-name="checkbox-group"
                        isInvalid={inputState.shouldShowInvalid}
                        message={inputState.errorMessage}
                        className={inputProps.className}
                        {...otherProps}
                    >
                        <Container
                            className={classNames({
                                'flow-row': flowDirection === FlowDirections.ROW,
                                'flow-column': flowDirection === FlowDirections.COLUMN,
                            })}
                        >
                            <For
                                each="option"
                                of={mappedOptions}
                            >
                                <Checkbox
                                    className={classNames('checkbox', {
                                        'rh-width-100p rh-my-0_5 rh-mr-2_5': flowDirection === FlowDirections.COLUMN,
                                        'rh-m-0': flowDirection === FlowDirections.ROW,
                                    })}
                                    key={option.id}
                                    id={option.id}
                                    name={name}
                                    // If are at maximum selectable, they need to be able to de-select options
                                    isDisabled={inputProps.disabled || (!option.checked && isMaximumReached)}
                                    label={option.label}
                                    size="large"

                                    value={option.checked}
                                    onChange={isChecked => handleChange(option, isChecked)}
                                    onBlur={handleBlur}

                                    variant={CHECKBOX_VARIANTS.multiple}
                                />
                            </For>
                        </Container>
                    </ErrorMessageContainer>
                );
            }}
        </InputController>
    );
}


CheckboxGroup.propTypes = {
    name: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,

    value: PropTypes.array,
    maximumSelectable: PropTypes.number,
    isRequired: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    flowDirection: PropTypes.oneOf(Object.values(FlowDirections)),

    options: PropTypes.oneOfType([
        // value label pairs
        PropTypes.arrayOf(
            PropTypes.shape({
                label: MessagePropType,
                value: PropTypes.any.isRequired,
            }),
        ),
        PropTypes.array, // likely used with renderOptionLabel if non-string values
    ]).isRequired,
    renderOptionLabel: PropTypes.func,

    canShowInvalid: PropTypes.bool,
    className: PropTypes.string,

    onCreateField: PropTypes.func,
};

CheckboxGroup.defaultProps = {
    isDisabled: false,
    value: [],
    maximumSelectable: Infinity,
    isRequired: undefined,
    onChange: undefined,
    onBlur: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    flowDirection: FlowDirections.COLUMN,
    renderOptionLabel: undefined,
    canShowInvalid: false,
    className: undefined,
    onCreateField: undefined,
};

const MINIMUM_COLUMN_WIDTH = '11em';

// Note: cannot use rh-display classes because it would have lower specificity
const Container = styled.div`
    &.flow-column {
        display: flex;
        flex-wrap: wrap;

        > .checkbox {
            flex-shrink: 0;
        }
    }

    &.flow-row {
        display: grid;
        gap: ${Sizes.SPACING.HALF};
        grid-template-columns: repeat(auto-fit, minmax(${MINIMUM_COLUMN_WIDTH}, 1fr));
        overflow-x: auto;   // necessary only for Firefox
    }
`;

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.checkbox-group.errors.default"
        defaultMessage="Please choose an option"
    />
);

function getArrayValue(value) {
    return Array.isArray(value) ? value : [];
}

export default observer(CheckboxGroup);
