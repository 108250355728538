/**
 * Omit keys from an object.
 * 
 * Useful for omitting props where we don't want to pass them down to a child component;
 * Simply setting the prop to undefined might not be enough, so omit just removes the prop from the object;
 * 
 * @example
 * ```js
 * omitProps(otherProps, [ 'isValid' ]) // not a valid HTML attribute
 * ```
 * 
 * @template {Record<string, any>} T
 * @template {Extract<keyof T, string>} K
 * 
 * @param {T} object 
 * @param {Array<K>} keysToOmit 
 * @returns {Omit<T, K>} updatedObject
 */
function omitKeys(object, keysToOmit) {
    if (!object || typeof object !== 'object') {
        throw new Error('[omitKeys]: first argument must be an object');
    }

    if (!keysToOmit?.length || keysToOmit.some(key => typeof key !== 'string')) {
        throw new Error('[omitKeys]: second argument must be an array of strings');
    }

    return Object.keys(object).reduce((acc, key) => {
        if (!keysToOmit.includes(key)) {
            acc[key] = object[key];
        }
        return acc;
    }, {});
}

export default omitKeys;