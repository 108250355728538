import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import PositionOffscreenStyles from '../styles/PositionOffscreenStyles';
import { FORMAT_SEPARATOR } from '../definitions/DateFormats';
import Sizes from '../definitions/Sizes';
import noticeError from '../functions/noticeError';
import DirectInput from './DirectInput';
import withErrorMessageContainer from './withErrorMessageContainer';


function DateInputSeparateInputs({
    id,
    name,

    value,
    dateFormat,
    disabled,

    onChange,
    onKeyPress,
    onBlur,
    onFocus,

    isInvalid,
    shouldShowInvalid,

    className,
    inputClassName,
}) {
    const dateObject = getDateObjectFromString(value, dateFormat);
    const inputConfigs = getInputConfigs({ name, dateFormat, dateObject, onChange });

    function handleFocus(event) {
        const input = event.target;

        // select entire value on focus
        input.select();

        onFocus?.(event);
    }

    function handleBlur(event) {
        const formattedValue = getDateStringFromObject(dateObject, dateFormat, true);

        // Update the value with padding (if necessary)
        // If they have cleared the current field's value, we don't need to pad it (i.e. leave it as '')
        if (event.target.value && value !== formattedValue) {
            onChange(formattedValue);
        }

        // do not call onBlur (i.e. trigger validation) if our inputs still have focus
        if (inputConfigs.map(input => input.name).includes(event.relatedTarget?.name)) {
            return;
        }

        onBlur?.(event);
    }

    return (
        <Container
            className={className}
            id={id}
            data-test-name="date-input-separate-inputs"
        >
            <For
                each="inputConfig"
                of={inputConfigs}
            >
                <span
                    className="input-container"
                    key={inputConfig.name}
                    style={{ flex: inputConfig.flex }}
                >
                    <label
                        className={classNames('rh-title-xs', 'rh-display-block', 'input-labels')}
                        htmlFor={inputConfig.name}
                    >
                        {inputConfig.label}
                    </label>

                    <DirectInput
                        id={inputConfig.name} // for label
                        name={inputConfig.name}
                        format={inputConfig.format}
                        placeholder={inputConfig.placeholder}
                        value={inputConfig.value}
                        onChange={inputConfig.handleChange}
                        onKeyPress={onKeyPress}
                        type="tel" // show number keyboard
                        // shared props
                        className={inputClassName}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={disabled}
                        isInvalid={isInvalid}
                        shouldShowInvalid={shouldShowInvalid}
                    />
                </span>
            </For>
        </Container>
    );
}

DateInputSeparateInputs.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    dateFormat: PropTypes.string.isRequired,

    // Handlers
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func, // needed for auto-focus
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    // Validation
    isInvalid: PropTypes.bool,
    shouldShowInvalid: PropTypes.bool,

    // Styling
    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

DateInputSeparateInputs.defaultProps = {
    id: undefined,
    value: undefined,
    disabled: false,
    onFocus: undefined,
    onBlur: undefined,
    shouldShowInvalid: false,
    isInvalid: undefined,
    className: undefined,
    inputClassName: undefined,
    onKeyPress: undefined,
};

/**
 * Based on date format, return an array of objects that represent each input.
 * These will be used as props for each input.
 *
 * @param {string} name
 * @param {string} dateFormat e.g. 'MM/DD/YYYY'
 * @param {function} onChange
 * @param {{ month: string, day: string, year: string }} dateObject
 * @returns input props for each date field
 */
function getInputConfigs({ name, dateFormat, onChange, dateObject }) {
    const inputConfigs = [];    // referenced within handleChange()

    dateFormat
        .split(FORMAT_SEPARATOR)
        .forEach(segment => inputConfigs.push(getDateSegment(segment, name)));

    return inputConfigs.map((config, currentInputIndex) => ({
        ...config,
        get value() {
            return dateObject[config.datePartKey];
        },
        handleChange(e) {
            // Due to issues with focus management, we are using DirectInput instead of FormattedNumberInput
            // Therefore we need to manually implement pseudo masking to mimic behavior we'd get with FormattedNumberInput
            const value = e.target.value
                .replace(/[^0-9]/g, '') // remove non-numeric characters
                .slice(0, config.format.length) // limit to length of format
                .trim(); // remove leading/trailing whitespace

            const newDate = {
                ...dateObject,
                [config.datePartKey]: value,
            };

            const dateString = getDateStringFromObject(
                newDate,
                dateFormat,
                false, // important: don't pad while they're typing
            );

            // empty value should be empty string instead of placeholders
            onChange(dateString === dateFormat ? '' : dateString);

            // requirement: should auto-focus if they've entered the entire field
            if (value.length === config.format.length) {
                // focus the next input
                const nextInputName = inputConfigs[currentInputIndex + 1]?.id;
                const nextInput = nextInputName ? document.getElementById(nextInputName) : null;

                if (nextInput) {
                    // Need to wait for re-render so that value prop is updated when onBlur is called
                    setTimeout(() => {
                        nextInput.focus();
                    }, 0);
                }
            }
        },
    }));
}


/**
 * Return a "date input segment" that corresponds to the given placeholder segment string.
 *
 * @param {string} segment, expected to start with one of M, D, or Y
 * @param {string} name, base part of the id and name DOM attributes
 * @returns {Object}
 */
function getDateSegment(segment, name) {
    if (!segment || !name) {
        const badArgsError = new RangeError(`[getDateSegment] invalid arguments (${segment}, ${name})`);
        noticeError(badArgsError);

        throw badArgsError;
    }

    if (segment[0] === 'M') {
        const id = `${name}${DATE_INPUT_SUFFIXES.MONTH}`;

        return {
            id,
            name: id,
            datePartKey: 'month',
            format: 'MM',
            placeholder: (
                <FormattedMessage
                    id="base-ui.date-input.month.placeholder"
                    defaultMessage="MM"
                />
            ),
            label: (
                <FormattedMessage
                    id="base-ui.date-input.month.label"
                    defaultMessage="Month"
                />
            ),
            flex: 3,
        };
    } else if (segment[0] === 'D') {
        const id = `${name}${DATE_INPUT_SUFFIXES.DAY}`;

        return {
            id,
            name: id,
            datePartKey: 'day',
            format: 'DD',
            placeholder: (
                <FormattedMessage
                    id="base-ui.date-input.day.placeholder"
                    defaultMessage="DD"
                />
            ),
            label: (
                <FormattedMessage
                    id="base-ui.date-input.day.label"
                    defaultMessage="Day"
                />
            ),
            flex: 3,
        };
    } else if (segment[0] === 'Y') {
        const id = `${name}${DATE_INPUT_SUFFIXES.YEAR}`;

        return {
            id,
            name: id,
            datePartKey: 'year',
            format: 'YYYY',
            placeholder: (
                <FormattedMessage
                    id="base-ui.date-input.year.placeholder"
                    defaultMessage="YYYY"
                />
            ),
            label: (
                <FormattedMessage
                    id="base-ui.date-input.year.label"
                    defaultMessage="Year"
                />
            ),
            flex: 4,
        };
    } else {
        const badDateSegment = new RangeError(`[getDateSegment] invalid date segment “${segment}”`);
        noticeError(badDateSegment);

        throw badDateSegment;
    }
}

/**
 * Parses date string as object to simplify displaying and interpreting date.
 *
 * @param {string} dateString eg. '01/01/YYYY'
 * @param {string} dateFormat eg. 'MM/DD/YYYY'
 * @returns {{ month: string, day: string, year: string }} dateObject
 */
function getDateObjectFromString(dateString, dateFormat) {
    if (!dateString) {
        // only emit date object parts based on date format
        return getRequiredDatePartKeysFromFormat(dateFormat)
            .reduce((dateObject, datePartKey) => ({
                ...dateObject,
                [datePartKey]: null,
            }), {});
    }

    const dateSegments = dateString.split(FORMAT_SEPARATOR);
    const formatDateParts = dateFormat.split(FORMAT_SEPARATOR);

    return formatDateParts.reduce((dateObject, formatCharacters, index) => {
        const datePartValue = dateSegments[index] !== formatCharacters
            ? dateSegments[index] // don't include format characters in value
            : null;
        const datePartKey = getDateKeyFromFormatCharacter(formatCharacters);

        return {
            ...dateObject,
            [datePartKey]: datePartValue,
        };
    }, {});
}

/**
 * Converts date object into formatted date string.
 *
 * @param {{ month: string, day: string, year: string }} dateObject
 * @param {string} dateFormat eg. 'MM/DD/YYYY'
 * @param {*} shouldPad inserts leading zeroes. (eg. '1/1/2021' -> '01/01/2021')
 *
 * @returns {string} dateString eg. '01/01/YYYY'
 */
function getDateStringFromObject(dateObject, dateFormat, shouldPad = false) {
    const formatDateParts = dateFormat.split(FORMAT_SEPARATOR);

    return formatDateParts
        .map((formatCharacters) => {
            const datePartKey = getDateKeyFromFormatCharacter(formatCharacters);
            // fallback to format characters because date string always includes format characters instead of empty values
            const datePartValue = !dateObject[datePartKey]
                ? formatCharacters
                : dateObject[datePartKey];

            return shouldPad
                ? datePartValue.padStart(formatCharacters.length, '0')
                : datePartValue;
        })
        .join(FORMAT_SEPARATOR);
}

function getRequiredDatePartKeysFromFormat(dateFormat) {
    return dateFormat
        .split(FORMAT_SEPARATOR)
        .map(getDateKeyFromFormatCharacter);
}

/**
 * Determine which date part to use from format character.
 *
 * @param {string} character (eg. 'M')
 * @returns {string} datePart eg. 'month' that represents a key of date object.
 */
function getDateKeyFromFormatCharacter(character) {
    // just the first character if there are multiple format characters provided
    switch (character[0]) {
        case 'M':
            return 'month';
        case 'D':
            return 'day';
        case 'Y':
            return 'year';
        default:
            throw new Error(`Unrecognized date format character: '${character}'`);
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${Sizes.SPACING.HALF};

    /* Counter the margin-top of each input */
    margin: -${Sizes.SPACING.HALF} 0;

    .input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: ${Sizes.SPACING.HALF} 0;

        /* Hide labels */
        > .input-labels {
            ${PositionOffscreenStyles}
        }

        > .error-message-container {
            flex-grow: 1;
        }
    }
`;
const DATE_INPUT_SUFFIXES = {
    MONTH: '-month',
    YEAR: '-year',
    DAY: '-day',
};

export default withErrorMessageContainer(DateInputSeparateInputs);
// Exported so library-testing can detect separated date inputs
export { DATE_INPUT_SUFFIXES };
