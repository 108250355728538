import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import {
    BusinessUnits,
    Sizes,
    Select,
    AlternateAnchor,
    CircleSpinner,
} from '@ratehub/base-ui';

import useBlogSpotlight from '../hooks/useBlogSpotlight';
import HeadingPropTypes from '../definitions/HeadingPropTypes';
import BlogPostPropType from '../definitions/BlogPostPropType';
import BlogSpotlightPostList from './BlogSpotlightPostList';
import HeadingBlock from './HeadingBlock';


const DEFAULT_BLOG_POST_COUNT = 8;

function BlogSpotlight({
    heading,
    filterLabel,
    href,
    hrefText,
    initialPosts,
    initialBusinessUnit,
    blogPostCount,
    hidePostFilter,
    className,
}) {
    const state = useBlogSpotlight({
        initialPosts: initialPosts,
        hidePostFilter: hidePostFilter,
        initialBusinessUnit: initialBusinessUnit,
        blogPostCount: blogPostCount,
    });

    const blogID = `BlogSpotlight-${initialBusinessUnit}`;

    return (
        <BlogSpotlight.Grid
            disabled={state.isFetching}
            className={className}
        >
            <HeadingBlock
                className="top-contents"
                {...heading}
            />

            <If condition={state.businessUnitOptions && filterLabel}>
                <div className="bu-select-container">
                    <label
                        className={classNames('rh-title-xs', 'rh-display-block')}
                        htmlFor={blogID}
                    >
                        {filterLabel}
                    </label>

                    <Select
                        id={blogID}
                        name={blogID}
                        options={state.businessUnitOptions}
                        value={state.businessUnit}
                        onChange={state.setBusinessUnit}
                        disabled={state.isFetching}
                    />
                </div>
            </If>

            <div className="posts-container">
                <If condition={state?.posts?.length}>
                    <BlogSpotlightPostList
                        posts={state.posts}
                        isDisabled={state.isFetching}
                        titleAs={blogPostTitleAs(heading)}
                    />
                </If>

                <If condition={state.isFetching}>
                    <CircleSpinner />
                </If>
            </div>

            <If condition={href && hrefText}>
                <AlternateAnchor
                    className="cta"
                    href={href}
                    data-name="blogSpotlight-cta"
                >
                    {hrefText}
                </AlternateAnchor>
            </If>
        </BlogSpotlight.Grid>
    );
}

BlogSpotlight.propTypes = {
    heading: HeadingPropTypes,
    filterLabel: PropTypes.string,

    href: PropTypes.string,
    hrefText: PropTypes.string,

    initialPosts: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(BlogPostPropType)),
        PropTypes.objectOf(PropTypes.shape(BlogPostPropType)), // mobx observable array
    ]),
    initialBusinessUnit: PropTypes.string,
    blogPostCount: PropTypes.number,
    hidePostFilter: PropTypes.bool,
    className: PropTypes.string,
};

BlogSpotlight.defaultProps = {
    heading: undefined,
    filterLabel: undefined,
    href: undefined,
    hrefText: undefined,
    initialPosts: [],
    initialBusinessUnit: BusinessUnits.ALL,
    hidePostFilter: false,
    blogPostCount: DEFAULT_BLOG_POST_COUNT,
    className: undefined,
};

BlogSpotlight.Grid = styled.div`
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 33.3333%);

    -ms-grid-rows: auto ${Sizes.SPACING.TWO} auto ${Sizes.SPACING.TWO} auto ${Sizes.SPACING.TWO} auto;
    grid-row-gap: ${Sizes.SPACING.TWO};

    > .top-contents {
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        grid-column: 1/4;

        -ms-grid-row: 1;
    }

    > .bu-select-container {
        -ms-grid-column: 1;
        grid-column: 1;

        -ms-grid-row: 3;

        margin-bottom: ${Sizes.SPACING.ONE};
    }

    > .posts-container {
        position: relative;

        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        grid-column: 1/4;

        -ms-grid-row: 5;

        width: 100%;

        /* To ensure spinner shows */
        min-height: 18.75em;

        ${props => props.disabled && 'overflow: hidden;'}
    }

    > .cta {
        -ms-grid-column: 2;
        grid-column: 2;

        -ms-grid-row: 7;

        margin: ${Sizes.SPACING.TWO} auto 0;
    }

    @media (max-width: 50em) {
        -ms-grid-columns: 1fr;
        grid-template-columns: 100%;

        -ms-grid-rows:
            auto ${Sizes.SPACING.ONE_AND_A_HALF}
            auto ${Sizes.SPACING.ONE_AND_A_HALF}
            auto ${Sizes.SPACING.ONE_AND_A_HALF}
            auto;

        grid-row-gap: ${Sizes.SPACING.ONE_AND_A_HALF};

        > .top-contents {
            -ms-grid-column: 1;
            grid-column: 1;
        }

        > .bu-select-container {
            -ms-grid-column: 1;
            grid-column: 1;
        }

        > .posts-container {
            -ms-grid-column: 1;
            grid-column: 1;
        }

        > .cta {
            -ms-grid-column: 1;
            grid-column: 1;

            margin-top: 0;
        }
    }
`;

function blogPostTitleAs(heading) {
    return heading?.title
        ? 'h3'
        : 'h2';
}

BlogSpotlight.blockKey = 'rh/blog-spotlight';
BlogSpotlight.hasHeading = true;
BlogSpotlight.requiresLayoutRow = true;

export default observer(BlogSpotlight);
