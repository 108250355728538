import SCENARIO_TYPES from '../definitions/scenarioTypes';


function getScenarioUrlSlugFromType(scenarioType) {
    switch (scenarioType) {
        case SCENARIO_TYPES.RENEWAL:
            return 'renew';
        case SCENARIO_TYPES.HELOC:
            return 'heloc';
        case SCENARIO_TYPES.REFINANCE:
            return 'refinance';
        case SCENARIO_TYPES.PRE_APPROVAL:
        case SCENARIO_TYPES.PURCHASE:
        default:
            return 'purchase';
    }
}

export default getScenarioUrlSlugFromType;