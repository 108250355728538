import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import {
    Sizes,
    Config,
    isWidget,
    PrimaryButton,
    MessagePropType,
    AlternateAnchor,
    ButtonStateColours,
    getLanguageFromLocale,
    DefaultIntlFormatValues,
    getAffiliateDecoratedURL,
    useExperiment,
    ExperimentSegments,
} from '@ratehub/base-ui';

import MESSAGES from '../definitions/Messages';
import COMMON_MESSAGES from '../definitions/Messages';
import PRODUCT_TYPES from '../definitions/ProductTypes';
import EXPERIMENTS from '../definitions/Experiments';
import CreditCardSynopsisShape from '../definitions/CreditCardSynopsisShape';
import getEligibilityCheckerURL from '../functions/getEligibilityCheckerURL';


function CreditCardCTA({
    onClick,
    buttonText,
    buttonSize = 'medium',

    subtextOptions,
    className,

    cardTypes,
    giftCardOffer,
    cardId,

    hasEligibilityCTA = true,

    children,

    variant = 'dark',
    productType,
    ...otherProps
}) {
    const intl = useIntl();

    const language = getLanguageFromLocale(intl.locale);
    const isAWidget = isWidget();

    const isAltTextCTA = useExperiment(EXPERIMENTS.altTextCTA)?.segment === ExperimentSegments.VARIANT_1
        && !buttonText; // we still want custom text to override the alt text
    buttonText = buttonText || MESSAGES.ctaButtonText; // TODO: move back to default prop with ccdep-3862

    // TODO: Remove comparison to secured card when long term fix is implemented
    const displayEligibilityCTA = hasEligibilityCTA && !cardTypes.includes(PRODUCT_TYPES.SECURED);

    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    const primaryButtonMessage = giftCardOffer && !isAWidget
        ? (
            <FormattedMessage
                id="edb.cc.cta.incentive.2"
                defaultMessage="View offer"
            />
        )
        : isAltTextCTA
            ? MESSAGES.ctaButtonTextAlt
            : buttonText;

    /*
        Atypical Event Clicking
        - when there are children (as for featured products), they render above the CTA(s)
        - in that case, a click anywhere on <Container> is seen as a CTA click on the "go to site" button, handled
          by onClick (which accounts for handling gift card offers)
        - however, when there are two CTAs, the additional one is an anchor and wants to avoid the "go to site"
          logic, so it includes an event.stopPropagation()
    */

    const ButtonApplyNow = (
        <PrimaryButton
            className="cc-cta"
            // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
            message={primaryButtonMessage}
            onClick={children
                ? () => {}
                : onClick
            }
            size={buttonSize}
            data-name="credit-cards.apply-now"
            key="theButton"
        />
    );

    const AnchorEligibilityChecker = (
        <AlternateAnchor
            className="cc-cta"
            message={(
                <FormattedMessage
                    {...COMMON_MESSAGES.checkEligibility}
                    values={{
                        ...DefaultIntlFormatValues,
                    }}
                />
            )}
            onClick={event => event.stopPropagation()}
            href={getAffiliateDecoratedURL(getEligibilityCheckerURL(language, cardId, productType))}
            target={isAWidget ? '_blank' : ''}
            size={buttonSize}
            data-name="credit-cards.check-eligibility"
            key="theAnchor"
        />
    );


    return (
        <>
            <Container
                className={className}
                reducePaddingAbove={subtextOptions?.reducePaddingAbove || false}
                removeIconsLeftMargin={subtextOptions?.removeIconsLeftMargin ?? true}
                variant={variant}

                onClick={children ? onClick : undefined}
                {...otherProps}
            >
                {children}

                {/* always wrap buttons inside a .cta to satisfy grid placement requirements of ProductCardBrief */}
                <div
                    className={classNames(
                        'cta',  // inappropriate familiarity… used by ProductCardBrief for placing element in its grid
                        'cta-wrapper rh-display-flex rh-flex-wrap rh-justify-content-center',
                    )}
                >
                    <Choose>
                        <When condition={displayEligibilityCTA && Config.ENABLE_CC_CHECK_ELIGIBILITY_CTA}>
                            {ButtonApplyNow}
                            {AnchorEligibilityChecker}
                        </When>

                        <Otherwise>
                            {ButtonApplyNow}
                        </Otherwise>
                    </Choose>
                </div>
            </Container>
        </>
    );
}


CreditCardCTA.propTypes = {
    onClick: PropTypes.func,
    buttonText: MessagePropType,
    buttonSize: PropTypes.oneOf([ 'small', 'medium', 'large' ]),

    subtextOptions: PropTypes.shape({
        reducePaddingAbove: PropTypes.bool,
        removeIconsLeftMargin: PropTypes.bool,
    }),

    className: PropTypes.string,

    cardTypes: CreditCardSynopsisShape.cardTypes,
    giftCardOffer: CreditCardSynopsisShape.giftCardOffer,
    cardId: CreditCardSynopsisShape.id,

    children: PropTypes.node,
    variant: PropTypes.oneOf(Object.keys(ButtonStateColours.SECONDARY)),

    hasEligibilityCTA: PropTypes.bool,
    productType: PropTypes.oneOf(Object.values(PRODUCT_TYPES)),
};


const Container = styled.div`
    > .cta-wrapper {
        gap: ${Sizes.SPACING.HALF};

        > .cc-cta {
            // we do _not_ want our text to wrap within the button or link
            white-space: nowrap;
        }
    }
`;


export default observer(CreditCardCTA);
