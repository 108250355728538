import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observable } from 'mobx';

import { BusinessUnits, getLanguageFromLocale } from '@ratehub/base-ui';

import Messages from '../definitions/BusinessUnitsMessages';
import fetchPostsForBlogSpotlight from '../functions/fetchPostsForBlogSpotlight';


function useBlogSpotlight({ initialPosts, initialBusinessUnit, hidePostFilter, blogPostCount }) {
    const intl = useIntl();
    const language = getLanguageFromLocale(intl.locale);

    const [ self ] = useState(() => observable({
        isFetching: false,
        posts: initialPosts,
        businessUnit: initialBusinessUnit,

        get businessUnitOptions() {
            if (hidePostFilter) {
                return undefined;
            }

            return Object.values(BusinessUnits).map(key => ({
                label: intl.formatMessage(Messages[key]),
                value: key,
            }));
        },

        async setBusinessUnit(value) {
            // Storing here incase we need to revert on error
            const previousBU = self.businessUnit;
            self.isFetching = true;
            self.businessUnit = value;

            const fetchOptions = {
                businessUnit: value,
                language: language,
                perPageCount: blogPostCount,
            };

            try {
                self.posts = await fetchPostsForBlogSpotlight(fetchOptions);
            }
            catch (e) {
                self.businessUnit = previousBU;
                // eslint-disable-next-line no-console
                console.error('[ERROR] failed fetching blog posts');
            }

            self.isFetching = false;
        },
    }));

    /**
     * This is mostly so the block in Wordpress will fetch some content on load
     * It should't be run on the site because initialPosts should always have something
    **/
    useEffect(() => {
        if (!initialPosts?.length) {
            self.setBusinessUnit(initialBusinessUnit);
        }
    }, []);

    return self;
}

export default useBlogSpotlight;
