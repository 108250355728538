/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/

function FaqAccordionItem() {
    return null;
}

FaqAccordionItem.blockKey = 'rh/faq-accordion-item';

export default FaqAccordionItem;
