import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import MessagePropType from '../definitions/MessagePropType';
import PrimaryButton from './PrimaryButton';
import Paragraph from './Paragraph';


function ErrorModalDisplay({
    onAccept,

    title,
    description,
    acceptText,
}) {
    return (
        <Container data-test-name="error-modal">
            <h2 className="rh-title-2xl rh-mb-2 title rh-mt-1_5">
                {title}
            </h2>

            <If condition={!!description}>
                <Paragraph
                    className="description"
                    message={description}
                />
            </If>

            <div className="accept-button-container">
                <PrimaryButton
                    onClick={onAccept}
                    data-name="ErrorModal-acceptButton"
                    message={acceptText ?? TRY_AGAIN}
                />
            </div>
        </Container>
    );
}

ErrorModalDisplay.propTypes = {
    title: PropTypes.node.isRequired,
    description: MessagePropType,
    acceptText: MessagePropType,

    onAccept: PropTypes.func.isRequired,
};

const Container = styled.div`
    padding: ${Sizes.SPACING.FOUR} ${Sizes.SPACING.TWO};

    > .title {
        text-align: center;

        .light {
            color: ${Colours.BLUEBERRY_DARK};
        }
    }

    > .description {
        text-align: center;
        margin-bottom: ${Sizes.SPACING.FOUR};
    }

    > .accept-button-container {
        max-width: 400px;
        margin: 0 auto;
    }
`;

const TRY_AGAIN = (
    <FormattedMessage
        id="base-ui.errorModal.tryAgain"
        defaultMessage="Please try again"
    />
);

export default ErrorModalDisplay;
