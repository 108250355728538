import VerticalSpacing from '../definitions/VerticalSpacing';


export default function getVerticalSpacingAboveClassName(value) {
    switch (value) {
        case VerticalSpacing.NONE: return 'rh-pt-0';
        case VerticalSpacing.SMALL: return 'rh-pt-4';
        case VerticalSpacing.MEDIUM: return 'rh-pt-6';
        case VerticalSpacing.LARGE: return 'rh-pt-8';
    }
}

