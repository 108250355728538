import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import InputStyle from '../styles/InputStyle';
import messageToString from '../functions/messageToString';
import MessagePropType from '../definitions/MessagePropType';
import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import { INPUT_HORIZONTAL_PADDING_CONDENSED } from '../definitions/SharedInputStyles';
import { useCondensedTheme } from './CondensedTheme';

/**
 * A wrapped version of the React <input> to be used with our field models.
 */
function Input({
    id,
    className,
    inputClassName,

    name,
    value,

    isInvalid,
    shouldShowInvalid,

    onChange,
    onFocus,
    onBlur,

    'data-computed': dataComputed,

    label,

    disabled,

    ...otherProps
}) {
    const intl = useIntl();
    const isCondensed = useCondensedTheme();

    const displayedValue = value ?? '';

    return (
        <Container
            className={classNames(className, {
                'has-label': !!label,
                'has-value': displayedValue !== '',
                'is-disabled': disabled,
                'is-invalid': shouldShowInvalid,
            })}
        >
            <If condition={label}>
                <label
                    className="label rh-text-m"
                    htmlFor={id ?? name}
                >
                    {messageToString(label, intl)}
                </label>
            </If>
            <input
                id={id ?? name}
                className={classNames(inputClassName, 'input', {
                    'is-condensed': isCondensed,
                })}
                name={name}
                value={displayedValue}

                aria-invalid={shouldShowInvalid ? 'true' : undefined}
                data-invalid={isInvalid ? 'true' : undefined}
                data-computed={dataComputed}

                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}

                disabled={disabled}

                {...otherProps}
            />
        </Container>
    );
}

Input.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,

    disabled: PropTypes.bool,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    isInvalid: PropTypes.bool,
    shouldShowInvalid: PropTypes.bool,

    'data-computed': PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),

    // adds a label inside the input
    label: MessagePropType,
};

Input.defaultProps = {
    id: undefined,
    value: undefined,
    className: undefined,
    inputClassName: undefined,
    onChange: undefined,
    onFocus: undefined,
    onBlur: undefined,
    disabled: undefined,

    isInvalid: undefined,
    shouldShowInvalid: false,

    'data-computed': undefined,

    label: undefined,
};

const TRANSITION_TIME = '150ms';

const Container = styled.div`
    position: relative;
    width: 100%;

    > .input {
        ${InputStyle}
    }

    /* only apply compact label styling if we have a label */
    &.has-label {
        > .input {
            padding: ${Sizes.SPACING.ONE_AND_A_HALF} 1.125rem 0.125rem;

            ::placeholder {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s 100ms, opacity 100ms linear;
            }
        }

        > .label {
            position: absolute;
            top: 50%;
            left: ${Sizes.SPACING.ONE_AND_A_HALF};
            right: ${Sizes.SPACING.ONE_AND_A_HALF}; /* Used to force truncation if text is too long */
            z-index: 10;

            transform: translateY(-50%);
            transform-origin: left top;
            transition: transform ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1), color ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1);
            will-change: transform;

            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;

            text-overflow: ellipsis;
            overflow: hidden;

            color: ${Colours.STONE_DARK};
            white-space: nowrap;

            cursor: text;
            pointer-events: none;
        }

        /* Applying this style as a negation so we don't have to choose a default color
            for when we have a value or are focused */
        /* Some inputs like SelectNative don't have a real placeholder pseudo-element so
            this ensures only the label is visible at this point.
            Other alternatives were to always show the placeholder if its available so
            the label would always show on top, but that would also require us to target
            the placeholder via CSS since some inputs have default placeholders. And since
            somce inputs do not use real placeholders like SelectNative, that would also be insufficient. */
        &:not(.has-value, :focus-within) {
            > .input {
                color: transparent;
                transition: color ${TRANSITION_TIME};
            }
        }

        :focus-within,
        &.has-value {
            > .input {
                ::placeholder {
                    visibility: visible;
                    opacity: 1;
                    transition: opacity ${TRANSITION_TIME} linear;
                }
            }

            > .label {
                /* Unset the right space in case the text was truncated and now can fit */
                right: unset;
                transform: translateX(-0.25rem) translateY(-100%) scale(0.75);
                color: ${Colours.BLACKBERRY};
            }
        }

        :hover {
            > .label {
                color: ${Colours.BLUEBERRY_DARK};
            }
        }

        &.is-invalid {
            > .label,
            > .input::placeholder {
                color: ${Colours.STRAWBERRY_DARK};
            }
        }

        &.is-disabled {
            > .label {
                color: ${Colours.STONE};
            }
        }

        /* Condensed style overrides */
        &.is-condensed {
            > .input {
                padding-left: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
                padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
            }

            > .label {
                left: 1.25rem;
            }

            &:focus-within,
            &.has-value {
                > .label {
                    transform: translateX(-0.25rem) translateY(-95%) scale(0.75);
                }
            }
        }
    }
`;

export default Input;
