/**
 * Regex from https://emailregex.com/
 */
export const TEST_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Determine if a string is a valid e-mail address.
 * @param {string} value 
 * @return {bool}
 */
export default function emailValidator(value) {    
    return TEST_REGEX.test(value);
}
