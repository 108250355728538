import classNames from 'classnames';

import getVerticalSpacingAboveClassName from './getVerticalSpacingAboveClassName';
import getVerticalSpacingBelowClassName from './getVerticalSpacingBelowClassName';


// Convert PageContentBlock attributes found on the block to LayoutRow attributes.
// Needed while PageContentBlock attributes are still in use; we may migrate
// these in the CMS at some point.
function mapPCBtoLayoutRowAttributes(pcbAttrs) {
    const newAttrs = {};

    // Vertical spacing
    const verticalSpacingAboveClassName = (pcbAttrs.verticalPaddingAbove || pcbAttrs.verticalPadding)
        ? getVerticalSpacingAboveClassName(pcbAttrs.verticalPaddingAbove ?? pcbAttrs.verticalPadding)
        : 'rh-pt-6'; // old default from PageContentBlock

    const verticalSpacingBelowClassName = (pcbAttrs.verticalPaddingBelow || pcbAttrs.verticalPadding)
        ? getVerticalSpacingBelowClassName(pcbAttrs.verticalPaddingBelow ?? pcbAttrs.verticalPadding)
        : 'rh-pb-6'; // old default from PageContentBlock

    // Set className - IMPORTANT: preserve any classNames previously applied
    newAttrs.className = classNames(
        verticalSpacingAboveClassName,
        verticalSpacingBelowClassName,
        pcbAttrs.className,
    );

    delete pcbAttrs.verticalPaddingAbove;
    delete pcbAttrs.verticalPaddingBelow;
    delete pcbAttrs.verticalPadding;

    // Position
    if (pcbAttrs.position) {
        // We don't support sticky in LayoutRow, but we did in PageContentBlock.
        // It looks like nothing was using sticky (it's not even available via
        // PageContentBlockControls) but this will force anything using it
        // to static, which is supported by LayoutRow. Because static is the
        // defalt there, this essentially "resets" the position.
        newAttrs.position = pcbAttrs.position === 'sticky'
            ? 'static'
            : pcbAttrs.position;

        delete pcbAttrs.position;
    }

    // Overflow
    if (pcbAttrs.pcbOverflowX) {
        newAttrs.overflow = pcbAttrs.pcbOverflowX;

        delete pcbAttrs.pcbOverflowX;
    }

    // NOTE: As far as I can tell, blockLocation is only used in contexts
    // executed before CMSComponentSelector, so there doesn't seem to be any
    // benefit in including it here.

    return {
        ...pcbAttrs,
        ...newAttrs,
    };
}

export default mapPCBtoLayoutRowAttributes;