/**
 * Return the fields that all deposits (GICs, Chequing, Robo, Savings) queues/slots
 * have in common. This is possible because BE API return shape for deposits overlap.
 * @param {Object} product product object returned by BE API
 * @returns
 */
export default function getSharedDepositShape(product) {
    return {
        id: product.ids?.primary,

        provider: {
            name: product.provider?.name,
            logo: product.provider?.logo,
            slug: product.provider?.slug,
        },

        badgeTitle: product.badge_title,
        badgeDescription: product.badge_description,

        detailsURL: product.urls?.details,
        applyURL: product.urls?.apply_redirect,

        hasPromo: !!product?.gift_cards?.length,
        isMonetized: product.monetized,
        // @TODO: Remove .sponsored after new deposits sponsored BE/CMS fully deployed
        isSponsored: product.sponsored?.global ?? product.sponsored,
    };
}
