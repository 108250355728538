const ZIndex = {
    ELEMENTS: 1000,
    DROP_DOWNS: 2000,
    SECONDARY_NAVIGATION: 3000,
    HEADER_FOOTER: 4000,
    PRIMARY_NAVIGATION: 5000,
    FULL_SCREEN: 6000,
    SPECIAL_CASES: 7000,
    MODALS: 8000,
    NOTIFICATIONS: 9000,
};

export default ZIndex;
