import { getOfferTypeAsStringFromSlug } from '@ratehub/base-ui';


/**
 * Convert a card offer slug into an appropriate string for the user.
 * During transition, offerType might be missing, and we simply return offerTypeName.
 * When it is not, make a string from the slug.
 * TODO https://ratehub.atlassian.net/browse/CCDEP-3774
 *
 * @param {Object} giftCardOffer
 * @param {string} giftCardOffer.offerType
 * @param {string} giftCardOffer.offerTypeName
 * @param {import('react-intl').IntlShape} intl - The internationalization object
 * @return {string}
 */
function getCardOfferTypeAsString(giftCardOffer, intl) {
    return giftCardOffer.offerTypeName
        ? giftCardOffer.offerTypeName
        : getOfferTypeAsStringFromSlug(giftCardOffer.offerType, intl);

}


export default getCardOfferTypeAsString;
