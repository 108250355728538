import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Config, Locales } from '@ratehub/base-ui';

import LinkBlockList from './LinkBlockList';
import LinkBlockAnchor from './LinkBlockAnchor';


function SidebarPopularBlogPosts({ posts }) {
    const intl = useIntl();

    return (
        <LinkBlockList
            variant="sidebar"
            hasOuterPadding={false}
            maxChildren={0} // do not show "view more" button
        >
            <For
                each="post"
                of={posts}
            >
                <LinkBlockAnchor
                    key={post.slug}
                    variant="sidebar"
                    // Trailing slash is desired for /blog or /blogue
                    url={`${Config.RATEHUB_BASE_URL}/${intl.locale === Locales.FRENCH ? 'blogue' : 'blog'}/${post.slug}/`}
                    title={post.title}
                />
            </For>
        </LinkBlockList>
    );
}

SidebarPopularBlogPosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    })).isRequired,
};

export default SidebarPopularBlogPosts;
