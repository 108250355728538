import React from 'react';
import PropTypes from 'prop-types';

import {
    PrimaryAnchor,
    AlternateAnchor,
    TextAnchor,
    AnchorWithIcon,
    IconMapLazy,
} from '@ratehub/base-ui';

import LINK_TARGETS from '../definitions/LinkTargets';


const CTA_TYPES = {
    PRIMARY_ANCHOR: 'primary-anchor',
    ALTERNATE_ANCHOR: 'alternate-anchor',
    ANCHOR_WITH_ICON: 'anchor-with-icon',
    TEXT_ANCHOR: 'text-anchor',
};

const SUPPORTS_SIZE = [
    CTA_TYPES.PRIMARY_ANCHOR,
    CTA_TYPES.ALTERNATE_ANCHOR,
];

function CTAChooser({
    type = CTA_TYPES.PRIMARY_ANCHOR,
    minWidth,
    maxWidth,
    dataName,
    size = 'large',
    variant = 'blueberry-dark',
    iconKey = 'arrow-right',
    target = LINK_TARGETS.NEW_TAB,
    ...otherProps
}) {
    const linkTarget = target === LINK_TARGETS.NEW_TAB ? '_blank' : '_self';
    // Must ensure that minWidth/maxWidth are not empty strings: https://github.com/facebook/react/issues/30163 is a bug in React that causes an error when an empty string is passed to a key in a style object.
    const componentStyles = {
        minWidth: minWidth ? minWidth : undefined,
        maxWidth : maxWidth ? maxWidth : undefined,
    };

    const IconComponent = IconMapLazy[iconKey] ?? 'arrow-right';

    return (
        <Choose>
            <When condition={type === CTA_TYPES.PRIMARY_ANCHOR}>
                <PrimaryAnchor
                    style={componentStyles}
                    data-name={dataName}
                    size={size}
                    variant={variant}
                    target={linkTarget}
                    {...otherProps}
                />
            </When>

            <When condition={type === CTA_TYPES.ALTERNATE_ANCHOR}>
                <AlternateAnchor
                    style={componentStyles}
                    data-name={dataName}
                    size={size}
                    variant={variant}
                    target={linkTarget}
                    {...otherProps}
                />
            </When>

            <When condition={type === CTA_TYPES.ANCHOR_WITH_ICON}>
                <AnchorWithIcon
                    style={componentStyles}
                    data-name={dataName}
                    target={linkTarget}
                    iconComponent={IconComponent}
                    {...otherProps}
                />
            </When>

            <When condition={type === CTA_TYPES.TEXT_ANCHOR}>
                <TextAnchor
                    style={componentStyles}
                    data-name={dataName}
                    target={linkTarget}
                    {...otherProps}
                />
            </When>
        </Choose>
    );
}

CTAChooser.propTypes = {
    type: PropTypes.oneOf(Object.values(CTA_TYPES)),
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    dataName: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    iconKey: PropTypes.string,
    target: PropTypes.string,
};

CTAChooser.CTATypes = CTA_TYPES;
CTAChooser.SupportsSize = SUPPORTS_SIZE;

CTAChooser.blockKey = 'rh/cta-chooser';
CTAChooser.hasSpacingOptions = true;

export default CTAChooser;
