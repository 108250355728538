import navigateTo from './navigateTo';
import getRateUrl from './getRateUrl';
import submitMortgageConversion from './submitMortgageConversion';

/**
 * @async
 * navigate to new sites.
 * @param {object} rate
 * @param {object} city
 * @param {object} locale
 * @returns {object} -conversion id
 */
async function navigateAndConvertLead({ rate, city, locale }) {
    navigateTo(getRateUrl(rate), '_blank');
    return convertTrackedRatesOnClick({ rate, city, locale });
}

/**
 * @async
 * submit mortgage convension if it can navigate to new sites.
 * @param {object} rate
 * @param {object} city
 * @param {object} locale
 * @returns {object} -conversion id
 */
async function convertTrackedRatesOnClick({ rate, city, locale }) {
    if (rate.cpcLink || rate.cpaLink) {
        return submitMortgageConversion({
            rate,
            lead: { city },
            locale,
            experiments: [],
        });
    }
}

export default navigateAndConvertLead;
