import { PROVINCE, Languages } from '@ratehub/base-ui';
import { fetchBestRates, SCENARIO_TYPES } from '@ratehub/mtg-common';

import { Products } from '../definitions/Products';
import { INSURABILITY_VALUES, MORTGAGE_TYPES } from '../definitions/MortgageTerms';


// These are required parameters
const DEFAULT_PARAMS = {
    province: PROVINCE.ONTARIO,
    amortization: 25,
    downPaymentPercent: 0.05,
    homePrice: 400000,
    isOwnerOccupied: 1,
    isPreApproval: 0,
    isCashBack: 0,
    isOpen: 0,
    includeTies: 0,
    type: [ MORTGAGE_TYPES.FIXED ],
    term: [ '60' ], // keeping as a string for type consistency with WP output
    isFeatured: 1,
    language: Languages.ENGLISH,
};

/**
 * The parent function which passes in a valid group of products to our fetchRates function, and then passes that to our enrichment function
 * @param {array} products array of unenriched products, basically search terms to fetch the best rate of this set of params
 * @param {object} config structured object with configuration params
 */
async function enrichMortgageProducts(products, config) {
    if (!Array.isArray(products)) {
        throw new Error(`Incorrect shape for 'products' supplied to enrichMortgageProducts: ${JSON.stringify(products)}`);
    }

    const productsForProvince = products.filter(product => isValidForProvince(product?.provinces, config.province ?? DEFAULT_PARAMS.province));

    /* Separate products with provider */
    const productsWithProvider = products.filter(p => p.provider);
    const productsWithoutProvider = products.filter(p => !p.provider);

    /* Fetch rates */
    const rates = await Promise.all([
        fetchRatesByProvider(productsWithProvider, config),
        fetchRatesByInsurability(productsWithoutProvider, config),
    ]);

    // TODO: Ideally this should be placed into fetchBestRates to eliminate the use of the flatten flag
    /* The rates array above is a little ugly with multiple nested arrays - this cleans that up a little */
    const flattenedRates = rates.reduce((accumulator, currentValue) => {
        if (currentValue) {
            if (Array.isArray(currentValue[0])) {
                currentValue.forEach(group => {
                    group.forEach(rateGroup => {
                        if (rateGroup.insurabilityType) {
                            accumulator.push(rateGroup);
                        }
                    });
                });
            }
            else {
                currentValue.forEach(rateGroup => {
                    if (rateGroup.insurabilityType) {
                        accumulator.push(rateGroup);
                    }
                });
            }
        }

        return accumulator;
    }, []);

    /* Do the actual enriching */
    await Promise.all(productsForProvince.map(product => {
        return enrichProductWithBestRate(product, flattenedRates, productsForProvince, config);
    }));
}

/**
 * Provinces are either explicitly set and therefore valid,
 * or no provinces are set, which means there is no province restriction and therefore valid
 * @param {array} provinces Possibly empty array of provinces
 * @param {string} provinceCode Selected province code
 * @returns {bool}
*/
function isValidForProvince(provinces, provinceCode) {
    return !provinces?.length || provinces.includes(provinceCode);
}


/**
 * FETCHING RATES
 * */

async function fetchRatesByProvider(products, config) {
    const uniqueProviders = [ ...new Set(products.map(item => item.provider)) ];

    return await Promise.all(
        uniqueProviders.map(provider => fetchRatesByInsurability(products, {
            ...config,
            provider: provider,
        })),
    );
}

async function fetchRatesByInsurability(products, config) {
    const uniqueInsurabilityTypes = [ ...new Set(products.map(item => item.insurability)) ];

    return await Promise.all(
        uniqueInsurabilityTypes.map(
            type => fetchRatesForSingleInsurabilityType(type, products, config),
        ),
    );
}

async function fetchRatesForSingleInsurabilityType(type, products, config) {
    let params = { ...config };

    let validProducts = products.filter(product => product.insurability === type);

    // If there is a provider we want to convert the key to the format we need for the API and also refilter our validProducts
    if (params.provider) {
        params.providers = [ params.provider ];
        validProducts = validProducts.filter(product => product.provider === params.provider);

        delete params.provider;
    }

    if (!validProducts.length) {
        return {};
    }

    const insurabilityValues = INSURABILITY_VALUES[type];

    if (!insurabilityValues) {
        throw new Error(`Unable to find insurability type of ${type}`);
    }

    const response = await fetchBestRates(SCENARIO_TYPES.PURCHASE, {
        ...DEFAULT_PARAMS,
        type: getUniqueTypes(validProducts),
        term: getUniqueTerms(validProducts),
        ...insurabilityValues,
        ...params,
    }, false);

    // Rates are returned as structured object, an empty array is no rates
    if (Array.isArray(response?.rates)) {
        return {};
    }

    return {
        insurabilityType: type,
        ratesByType: response?.rates,
        provider: params?.providers?.[0],
    };
}

function getUniqueTerms(products) {
    const terms = products
        .map(product => product.term)
        .filter(term => term !== undefined);

    return [ ...new Set(terms) ]; // Filters out duplicates
}

/**
 * Gets all the mortgage types used {fixed or variable currently}
 * @param {array} products
 * @returns {array}
 */
function getUniqueTypes(products) {
    const types = [];

    products.forEach(product => product.types.forEach(type => {
        if (!types.includes(type)) {
            types.push(type);
        }
    }));

    return types;
}

/**
 * Gets the best non-featured rate for a particular product
 */
async function fetchBestNonFeaturedRate(product, products, config) {
    return await fetchRatesForSingleInsurabilityType(product.insurability, products, {
        ...config,
        type: product.types,
        term: [ product.term ],
        isFeatured: 0,
        provider: product.provider,
    }).then(response => (
        response.ratesByType
            ? findBestMatch(product, [ response ])
            : undefined
    ));
}

/**
 * Gets the best featured rate for a particular product but ignoring its provider
 */
async function fetchBestFeaturedRateWithoutProvider(product, products, config) {
    return await fetchRatesForSingleInsurabilityType(product.insurability, products, {
        ...config,
        type: product.types,
        term: [ product.term ],
        isFeatured: 1,
    }).then(response => {
        // Request product again but without provider
        const adjustedProduct = { ...product };

        delete adjustedProduct.provider;

        return response.ratesByType
            ? findBestMatch(adjustedProduct, [ response ])
            : undefined;
    });
}


/**
 * ENRICH RATES
 */
// Assigns the best rate from matching array
async function enrichProductWithBestRate(product, rateGroups, products, config) {
    const matchedRates = findBestMatch(product, rateGroups);

    // Get best rate out of matches, or find a non-featured rate instead
    const bestRate = matchedRates
        ?? await fetchBestNonFeaturedRate(product, products, config)
        ?? await fetchBestFeaturedRateWithoutProvider(product, products, config);

    // If there's no non-featured rate, that 'slot' won't show
    if (bestRate) {
        enrichProductWithRate(product, bestRate);
    }
}

function findBestMatch(product, rateGroups) {
    // Filter-out any rates not matching the provider
    rateGroups = rateGroups.filter(group => product.provider
        ? group.provider === product.provider
        : !group.provider,
    );

    // Filter-out any rates not matching the insurabilty
    rateGroups = rateGroups.filter(group => product.insurability === group.insurabilityType);

    // Match with product type and term
    // rateGroups here should have a length of 0 or 1 at this point because insurabiltyType should be unique
    const matchedRates = rateGroups.length
        ? product.types.map(
            type => rateGroups[0].ratesByType?.[type]?.[product.term]?.[0],
        ).filter(rate => rate)
        : [];

    return matchedRates.length ? findLowestProductRate(matchedRates) : undefined;
}


function findLowestProductRate(matchedRates) {
    const bestRate = matchedRates.reduce(
        (acc, val) => acc.value > val.value
            ? val
            : acc,
        { value: 100 }, // Need a large number; 100% seems unreasonably high for a mortgage
    );

    return bestRate;
}

function enrichProductWithRate(product, bestRate) {
    if (!bestRate?.provider?.logo || (!bestRate.href && !product.hrefOverride)) {
        throw new Error(`Mortgage rate is missing key information: ${JSON.stringify(bestRate, null, 4)}`);
    }

    let applyHref = product.hrefOverride || bestRate.href;

    if (applyHref.startsWith('https://ad.doubleclick.net')) {
        // btoa is a browser function, but if we're in Nodeland we need to use Buffer
        applyHref = typeof btoa === 'function'
            ? '/go/' + btoa(applyHref)
            : '/go/' + new Buffer.from(applyHref).toString('base64');
    }

    Object.assign(product, {
        title: product.titleOverride || bestRate.description,
        applyHref: applyHref,
        imageSrc: bestRate?.provider?.logo,
        imageAlt: bestRate?.provider?.name,
        rate: bestRate,
        description: product.descriptionOverride || bestRate.description,

        // For heap tracking
        productType: Products.MORTGAGES,
        providerId: bestRate?.provider.id,
        providerSlug: bestRate?.provider.slug,
        providerName: bestRate?.provider.name,
        isMonetized: bestRate.isFeatured,
        detailsHref: bestRate.href,
    });
}


export default enrichMortgageProducts;
