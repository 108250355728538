import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function LinkBlockContainer({ variant, heading, allowHorizontalOverflow, className, children, ...otherProps }) {
    return (
        <div
            className={className}
            {...otherProps}
        >
            <HeadingBlock
                {...heading}
                variant={variant}
            />

            <MyGrid
                allowOverflow={allowHorizontalOverflow}
            >
                {children}
            </MyGrid>
        </div>
    );
}

LinkBlockContainer.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    heading: HeadingPropTypes,
    allowHorizontalOverflow: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

LinkBlockContainer.defaultProps = {
    variant: VARIANTS.DARK,
    heading: undefined,
    allowHorizontalOverflow: false,
    className: undefined,
};

const MyGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

    ${props => props.allowOverflow && `
        display: flex;
        flex-wrap: nowrap;

        overflow-x: scroll;
    `}
`;

LinkBlockContainer.blockKey = 'rh/link-block-container';
LinkBlockContainer.hasHeading = true;
LinkBlockContainer.requiresLayoutRow = true;

export default LinkBlockContainer;
