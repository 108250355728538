import { Languages } from '@ratehub/base-ui';

import getBlogHostname from '../functions/getBlogHostname';
import getHeroDetailsFromPost from '../functions/getHeroDetailsFromPost';
import fetchBlogPosts from './fetchBlogPosts';
import getBlogCategoryPath from './getBlogCategoryPath';
import getLanguage from './getLanguageCodeFromOptions';


/**
 * Fetches blog posts from a service
 * @param {object} options object to extract from.
 * @param {string} options.businessUnit business unit slug
 * @param {string} options.order 'asc' or 'desc'
 * @param {string} options.orderby criteria to sort by https://developer.wordpress.org/reference/classes/wp_query/#order-orderby-parameters
 * @param {number} options.page page number
 * @param {number} options.perPageCount number of items to request per page
 * @param {string} options.language language code to use
 * @param {string} options.categories comma separated list of IDs
 * @param {string} options.tags comma separated list of IDs
 * @param {string} options.include comma separated list of IDs
 * @param {string} options.brand string from BRANDS definition
 * @returns {[object]}
 */
async function fetchPostsForBlogSpotlight(options) {
    const response = await fetchBlogPosts(options);
    const urlRoot = getLanguage(options) === Languages.FRENCH
        ? '/blogue'
        : '/blog';

    return response.posts.map(post => ({
        id:         post.id,
        title:      post.title.rendered,
        url: `${getBlogHostname(options.brand)}${urlRoot}/${post.slug}/`,

        ...getHeroDetailsFromPost(post),

        tags:   post.category_details.map(category => ({
            id:     category.id,
            title:  category.name,
            url:    `${getBlogHostname(options.brand)}${getBlogCategoryPath(category, options.locale, options.language)}`,
        })),
    }));
}



export default fetchPostsForBlogSpotlight;
