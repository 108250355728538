import React, { useState, useEffect, createRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import IconChevronUp from '../graphics/ChevronUp';
import IconChevronDown from '../graphics/ChevronDown';
import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import MessagePropType from '../definitions/MessagePropType';
import debounce from '../functions/debounce';
import toKebabCase from '../functions/toKebabCase';
import messageToString from '../functions/messageToString';
import Paragraph from './Paragraph';


function HorizontalTabs({
    initialTabIndex = 0,
    tabs,
    variant = HORIZONTAL_TAB_VARIANTS.underline,

    onTabChange,

    tabPanelIdPrefix,
    tabIdPrefix,

    isCondensed = false,
    enableAccordionToggle = false,

    className,
    ...otherProps
}) {
    const intl = useIntl();
    const [ activeTabIndex, setActiveTabIndex ] = useState(initialTabIndex);
    const [ activeTabElements ] = useState(Array(tabs.length).fill().map(() => createRef()));
    const [ underlinePositionLeft, setUnderlinePositionLeft ] = useState();
    const [ underlineWidth, setUnderlineWidth ] = useState();

    const isChevronVariant = variant === HORIZONTAL_TAB_VARIANTS.chevrons;

    // not relevant for chevron variant
    const updateActiveTabUnderlineIndicator = debounce(() => {
        const activeTab = activeTabElements?.[activeTabIndex]?.current;

        if (activeTab) {
            setUnderlinePositionLeft(activeTab.offsetLeft);
            setUnderlineWidth(activeTab.clientWidth);
        }
    }, 150);

    // whenever the activeTabIndex changes, recalculate position of bolder underline for active tab
    useEffect(() => {
        if (isChevronVariant) {
            return;
        }

        updateActiveTabUnderlineIndicator();

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', updateActiveTabUnderlineIndicator);
        }

        return () => window?.removeEventListener('resize', updateActiveTabUnderlineIndicator);
    }, [ activeTabIndex ]);

    function handleTabClick(index, value) {
        const newTabIndex = enableAccordionToggle && index === activeTabIndex
            ? null
            : index;
        setActiveTabIndex(newTabIndex);

        const tabChangeValue = newTabIndex === null
            ? null
            : value ?? newTabIndex;

        // if the newTabIndex was null we want to pass that through to onTabChange()
        onTabChange && onTabChange(tabChangeValue);
    }


    return (
        <Container
            className={classNames(className,
                `rh-display-flex rh-position-relative rh-align-items-stretch rh-m-0 rh-pl-0 ${variant}-variant`, {
                    'showing-tab-content': activeTabIndex !== null,
                    'rh-justify-content-space-around': isChevronVariant,
                })
            }
            role="tablist"
            linePositionLeft={underlinePositionLeft}
            lineWidth={underlineWidth}
            {...otherProps}
        >
            <For
                each="tab"
                of={tabs}
                index="tabIndex"
            >
                <li
                    key={`child-${tab.value ?? tab}`}
                    className={classNames('tab rh-display-flex', {
                        'active': tabIndex === activeTabIndex,
                        'rh-px-1': isChevronVariant,
                    })}
                    ref={activeTabElements[tabIndex]}
                    role="presentation"
                >
                    <Paragraph
                        as="a"

                        size={isChevronVariant ? 'small' : 'medium'}
                        className={classNames('tab-selector rh-display-flex rh-align-items-center',
                            'rh-text-align-left leading-s rh-text-decoration-none', {
                                'active': tabIndex === activeTabIndex,
                                'rh-px-0_25 rh-py-1_5': !isCondensed && !isChevronVariant,
                                'rh-px-0_25 rh-py-1': !isCondensed && isChevronVariant,
                                'rh-pb-0_75': isCondensed && !isChevronVariant,
                                'rh-py-0_75': isCondensed && isChevronVariant,
                            },
                        )}
                        onClick={() => handleTabClick(tabIndex, tab.value)}

                        role="tab"
                        // this feels like href should correspond to a valid tab panel id, but you will not want that
                        // because it might cause the browser to suddenly jump to that position, hiding the tab bar
                        href={`#${tabPanelIdPrefix}_${tab.value ?? removeFormatting(tab)}-${tabIndex}`}
                        target="_self"

                        id={`${tabIdPrefix}_${tab.value ?? toKebabCase(tab)}`}
                        data-name={`${tabIdPrefix}_${tab.value ?? toKebabCase(tab)}`}

                        aria-controls={`${tabPanelIdPrefix}_${tab.value ?? toKebabCase(tab)}`}
                        aria-selected={tabIndex === activeTabIndex}
                        data-widget-do-not-adjust-click="true"
                    >
                        {messageToString(tab.label ?? tab, intl)}

                        <If condition={isChevronVariant}>
                            <span className="chevron-wrapper rh-text-xs rh-mx-0_5">
                                <Choose>
                                    <When condition={tabIndex === activeTabIndex}>
                                        <IconChevronUp
                                            className="chevron rh-icon-2xs"
                                        />
                                    </When>

                                    <Otherwise>
                                        {/* includes activeTabIndex === null */}
                                        <IconChevronDown
                                            className="chevron rh-icon-2xs"
                                        />
                                    </Otherwise>
                                </Choose>
                            </span>
                        </If>
                    </Paragraph>
                </li>
            </For>

            <If condition={!isChevronVariant}>
                <div className="selected-line rh-position-absolute rh-bg-blueberry-dark rh-zindex-elements" />
            </If>
        </Container>
    );
}

const HORIZONTAL_TAB_VARIANTS = {
    underline: 'underline',
    chevrons: 'chevrons',
};

HorizontalTabs.propTypes = {
    initialTabIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([ null ]),
    ]),
    tabs: PropTypes.arrayOf(
        PropTypes.oneOfType([
            MessagePropType,
            PropTypes.shape({
                label: PropTypes.node,
                value: PropTypes.any,
            }),
        ])).isRequired,
    variant: PropTypes.oneOf(Object.values(HORIZONTAL_TAB_VARIANTS)),

    onTabChange: PropTypes.func,

    tabPanelIdPrefix: PropTypes.string.isRequired,
    tabIdPrefix: PropTypes.string.isRequired,

    isCondensed: PropTypes.bool,
    enableAccordionToggle: PropTypes.bool,
    className: PropTypes.string,
};


const Container = styled.ul`
    list-style: none;
    overflow: hidden;

    // this provides a separator below the tab bar
    &.underline-variant,      // always present for default visuals
    &.showing-tab-content {   // also show when chevrons variant is displaying a tab panel
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;

            background-color: ${Colours.STONE};

            position: absolute;
            left: 0;
            bottom: 1px;
        }
    }

    &.chevrons-variant.showing-tab-content {
        &::after {
            bottom: 0;  // minimizes a jank when toggling chevrons
        }
    }

    &.underline-variant {
        .tab {
            margin-left: 4%;
            margin-right: 4%;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {    // can’t use :last-child due to .selected-line
                margin-right: 0;
            }
        }
    }

    &.chevrons-variant {
        .tab {
            .tab-selector {
                > .chevron-wrapper {
                    line-height: 0;
                }

                &:hover {
                    color: ${Colours.BLUEBERRY_DARK};

                    .chevron {
                        stroke: ${Colours.BLUEBERRY_DARK};
                    }
                }
            }

        }
    }

    .tab-selector {
        margin-top: 0;      // must migrate away from Paragraph to remove these
        margin-bottom: 0;
        outline: none;

        &.active {
            /* Adds 3.25px to the width - may have to offset if animation looks weird */
            font-weight: ${Typography.WEIGHTS.MEDIUM};

            color: ${Colours.BLUEBERRY_DARK};

            &:hover,
            &:focus {
                color: ${Colours.BLUEBERRY_DARKEST};
            }
        }

        &:hover,
        &:focus {
            color: ${Colours.BLUEBERRY_DARK};
        }

        &:active {
            color: ${Colours.BLUEBERRY_DARKEST};
        }
    }

    .selected-line {
        left: 0;
        bottom: 0;

        width: ${props => props.lineWidth}px;
        height: 3px;

        transform: translateX(${props => props.linePositionLeft}px);

        transition:
            width 0.35s ease,
            transform 0.35s cubic-bezier(0.25, 1, 0.5, 1)
    };
`;

function removeFormatting(label) {
    return label.replace(/\s+/g,'').toLocaleLowerCase();
}

export default HorizontalTabs;
