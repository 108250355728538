import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import MessagePropType from '../definitions/MessagePropType';
import ACCORDION_VARIANTS from '../definitions/AccordionVariants';
import Dot from './Dot';
import IconChooser from './IconChooser';


function AccordionBasicItemHeader({
    isExpanded,
    onClick,

    // Rendered content
    title,

    'data-name': dataName,

    // Customization options for title
    titleColour,
    showDot,
    iconKey,
    disabled,

    // Customization options for content
    variant,

    ...otherProps
}) {
    const isDefaultVariant = variant === ACCORDION_VARIANTS.DEFAULT;
    const isMinimal = variant === ACCORDION_VARIANTS.MINIMAL;

    return (
        <AccordionToggleContainer
            className="rh-bg-transparent rh-position-relative rh-display-flex rh-cursor-pointer"
            onClick={onClick}
            data-name={dataName}
            variant={variant}
            isExpanded={isExpanded}
            isDotShown={showDot}
            {...otherProps}
        >
            <div className="headerContent rh-display-flex">
                <Choose>
                    <When condition={iconKey && !showDot}>
                        <div className="icon-container rh-mr-1 rh-display-flex rh-icon-s">
                            <IconChooser
                                iconKey={iconKey}
                                stroke={Colours.BLUEBERRY}
                            />
                        </div>
                    </When>
                    <When condition={showDot}>
                        <Dot
                            className="selected-dot rh-ml-0_75"
                        />
                    </When>
                </Choose>

                <h3 className={classNames(
                    'accordion-title weight-regular',
                    {
                        'rh-fg-stone': disabled,
                        'rh-mr-1 rh-my-1_5 rh-text-l': isDefaultVariant,
                        'rh-ml-0_75': isDefaultVariant && showDot,
                        'rh-m-0 rh-text-s weight-medium': isMinimal,
                        'rh-fg-blueberry-dark': isMinimal && titleColour === Colours.BLUEBERRY_DARK && !disabled,
                    },
                )}
                >
                    {title}
                </h3>

                {/* Empty div because we're using pseudo-elements */}
                <div className={classNames(
                    'expand-icon rh-mr-0_75 rh-position-relative',
                    isMinimal ? 'rh-ml-0_75' : 'rh-ml-auto',
                )}
                />
            </div>
        </AccordionToggleContainer>
    );
}

AccordionBasicItemHeader.propTypes = {
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,

    title: MessagePropType.isRequired,
    'data-name': PropTypes.string,

    titleColour: PropTypes.oneOf([ Colours.BLACKBERRY, Colours.BLUEBERRY_DARK ]),
    showDot: PropTypes.bool,
    iconKey: PropTypes.string,
    disabled: PropTypes.bool,

    variant: PropTypes.oneOf(Object.values(ACCORDION_VARIANTS)),
};

AccordionBasicItemHeader.defaultProps = {
    isExpanded: undefined,
    onClick: undefined,

    'data-name': undefined,

    titleColour: Colours.BLACKBERRY,
    showDot: PropTypes.bool,
    iconKey: undefined,
    disabled: PropTypes.bool,

    variant: ACCORDION_VARIANTS.DEFAULT,
};


const TRANSITION_TIMING_LONG = '0.5s';
const TRANSITION_EASING_EASE = 'ease';
const TRANSITION_TIMING_SHORT = '0.3s';

const AccordionToggleContainer = styled.div`
    flex: 1;
    flex-direction: column;

    > .headerContent {
        flex: 1;
        align-items: center;

        > .icon-container {
            flex-shrink: 0;
        }

        > .selected-dot {
            flex-shrink: 0;

            transform: scale(0) translateX(-1em);
            transition: transform ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE},
            width ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE},
            height ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE};

            ${props => props.isExpanded && `
                transform: scale(0.5);
            `}
        }

        > .accordion-title {
            ${props => props.variant === ACCORDION_VARIANTS.DEFAULT && `
                transition:
                    transform ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE},
                    color ${TRANSITION_TIMING_SHORT} ${TRANSITION_EASING_EASE};
            `}

            ${props => props.isDotShown && `
                transform: translateX( ${props.isExpanded ? Sizes.SPACING.QUARTER : '-1rem'});
            `}
        }

        > .expand-icon {
            /* need to set width and height to odd pixel value
                to center the +/- icon */
            min-width: 13px;
            height: 13px;

            &::before,
            &::after {
                position: absolute;

                display: block;
                content: '';

                width: 1px;
                background-color: ${Colours.BLACKBERRY};

                top: 0;
                bottom: 0;
                right: 50%;

                transition: background-color ${TRANSITION_TIMING_SHORT} ${TRANSITION_EASING_EASE},
                transform ${TRANSITION_TIMING_SHORT} ease-in-out;
            }

            &::before {
                ${props => props.isExpanded && `
                    transform: rotate(90deg);
                `};
            }

            &::after {
                transform: rotate(90deg);
            }

            ${props => props.variant === ACCORDION_VARIANTS.MINIMAL && `
                width: 9px;
                height: 9px;
            `}
        }
    }

    &:hover {
        ${props => props.variant === ACCORDION_VARIANTS.DEFAULT && `
            .accordion-title {
                color: ${Colours.BLUEBERRY};
            }

            .expand-icon::before,
            .expand-icon::after {
                background-color: ${Colours.BLUEBERRY};
            }
        `};
    }
`;

export default AccordionBasicItemHeader;
