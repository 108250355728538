import queryString from 'query-string';

/**
 * Safely navigate the browser to a location.
 * @param {string} rawUrl
 * @param {string|null} target
 */
export default function navigateTo(rawUrl, target = null) {
    if (typeof window !== 'undefined') {
        const { url, query } = queryString.parseUrl(rawUrl);
        const urlEncodedParams = queryString.stringify(query);
        // Prepend "?" in front of query params if present
        const safelyEncodedUrl = `${url}${urlEncodedParams ? `?${urlEncodedParams}` : ''}`;

        target !== null
            ? window.open(safelyEncodedUrl, target, 'rel=noopener')
            : window.top.location.assign(safelyEncodedUrl);
    }
}
