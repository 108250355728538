/**
 * Formats used to indicate how to format parts of a date in Moment
 * @type {string}
 */
export const MONTH_FORMAT_STRING = 'MM';
export const DAY_FORMAT_STRING = 'DD';
export const YEAR_FORMAT_STRING = 'YYYY';
export const FORMAT_SEPARATOR = '/';

/**
 * Expected date formats
 */
// MM/DD/YYYY
export const MONTH_DAY_YEAR_FORMAT
    = MONTH_FORMAT_STRING + FORMAT_SEPARATOR + DAY_FORMAT_STRING + FORMAT_SEPARATOR + YEAR_FORMAT_STRING;

// preferred format for FR date input: DD/MM/YYYY
export const DAY_MONTH_YEAR_FORMAT
    = DAY_FORMAT_STRING + FORMAT_SEPARATOR + MONTH_FORMAT_STRING + FORMAT_SEPARATOR + YEAR_FORMAT_STRING;

// preferred format by many wise Canadians 🇨🇦: YYYY-MM-DD
export const YEAR_MONTH_DAY_FORMAT
    = YEAR_FORMAT_STRING + FORMAT_SEPARATOR + MONTH_FORMAT_STRING + FORMAT_SEPARATOR + DAY_FORMAT_STRING;


export const MONTH_YEAR_FORMAT = MONTH_FORMAT_STRING + FORMAT_SEPARATOR + YEAR_FORMAT_STRING;
