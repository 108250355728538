import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';

import {
    Paragraph,
    PrimaryAnchor,
    ImageSizes,
    Colours,
} from '@ratehub/base-ui';

import LayoutRow from '../components/LayoutRow';
import ImageCollectionPropType from '../definitions/ImageCollectionPropType';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

const ALIGNMENTS = {
    LEFT: 'left',
    RIGHT: 'right',
};

const BACKGROUND_GRADIENT_LEVELS = {
    DARK: 'dark',
    DARKER: 'darker',
};

function ImageBlock({
    variant,
    alignment,

    imageCollection,
    imagePositionX,
    imagePositionY,

    backgroundGradientLevel,

    title,
    description,
    buttonText,
    buttonHREF,

    className,

    ...otherProps
}) {
    const [ isImageInView, setIsImageInView ] = useState(false);

    const containerRef = useRef(null);

    useEffect(() => {
        if (typeof window === 'undefined') {
            // Statically rendered
            return;
        }
        else if (!window.IntersectionObserver) {
            // IntersectionObserver unsupported, just load the image immediately.
            setIsImageInView(true);
            return;
        }

        const imageBlockContainer = containerRef?.current;

        if (!imageBlockContainer || isImageInView) {
            return;
        }

        // Observe the intersection between imageBlockContainer + viewport.
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    setIsImageInView(true);
                }
            },
            { rootMargin: '150px 0px 150px 0px' },
        );

        observer.observe(imageBlockContainer);

        return () => observer?.disconnect();
    }, [ containerRef?.current, isImageInView ]);

    const gradientAlpha = backgroundGradientLevel === BACKGROUND_GRADIENT_LEVELS.DARKER
        ? .8
        : 0.33;

    return (
        <Container
            ref={containerRef}
            className={classNames(
                {
                    'imageIsInView': isImageInView,
                },
                className,
            )}
            alignment={alignment}
            imageCollection={imageCollection}
            imagePositionX={imagePositionX}
            imagePositionY={imagePositionY}
            gradientAlpha={gradientAlpha}
            {...otherProps}
        >
            <LayoutRow
                className="rh-py-8"
                position="relative"
            >
                <div
                    className="content-container"
                >
                    <div
                        className="content-item"
                    >
                        <h2 className={classNames(
                            'rh-title-4xl',
                            'rh-mb-2',
                            'rh-mt-0',
                            'rh-mx-0',
                            variant === VARIANTS.DARK
                                ? 'rh-fg-blackberry'
                                : 'rh-fg-coconut',
                        )}
                        >
                            {title}
                        </h2>

                        <Paragraph
                            variant={variant}
                            message={description}
                            size="normal"
                            weight="medium"
                        />

                        <If condition={buttonHREF && buttonText}>
                            <PrimaryAnchor
                                className="anchor"
                                data-name="imageBlock-cta"
                                href={buttonHREF}
                                message={buttonText}
                                size="medium"
                                variant="blueberry-dark"
                            />
                        </If>
                    </div>
                </div>
            </LayoutRow>
        </Container>
    );
}

ImageBlock.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    alignment: PropTypes.oneOf(Object.values(ALIGNMENTS)),

    imageCollection: ImageCollectionPropType,
    imagePositionX: PropTypes.string,
    imagePositionY: PropTypes.string,

    backgroundGradientLevel: PropTypes.oneOf(Object.values(BACKGROUND_GRADIENT_LEVELS)),

    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    buttonHREF: PropTypes.string,

    className: PropTypes.string,
};

ImageBlock.defaultProps = {
    variant: VARIANTS.DARK,
    alignment: ALIGNMENTS.LEFT,

    imageCollection: undefined,
    imagePositionX: '50%',
    imagePositionY: '50%',

    backgroundGradientLevel: BACKGROUND_GRADIENT_LEVELS.DARK,

    title: undefined,
    description: undefined,
    buttonText: undefined,
    buttonHREF: undefined,

    className: undefined,
};

const MAX_WIDTH_BREAKPOINT = '65em';

const Container = styled.section`
    ${props => props.imageCollection && props.imagePositionX && props.imagePositionY && `
        background-position: ${props.imagePositionX} ${props.imagePositionY}, center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        background-image: none;

        /* Opaque part of the gradient should be behind the text content to
        help the text stand out. */

        &.imageIsInView {
            ${props.alignment === ALIGNMENTS.LEFT
        ? `background-image:
            url(${extractImageURL(props.imageCollection)}),
            linear-gradient(to right, ${rgba(Colours.BLACKBERRY_LIGHT, props.gradientAlpha)}, transparent);`
        : ''}

            ${props.alignment === ALIGNMENTS.RIGHT
        ? `background-image:
            url(${extractImageURL(props.imageCollection)}),
            linear-gradient(to left, ${rgba(Colours.BLACKBERRY_LIGHT, props.gradientAlpha)}, transparent);`
        : ''}
        }
    `}

    .content-container {
        display: flex;
        flex-direction: row;
        justify-content: ${props => props.alignment === ALIGNMENTS.LEFT ? 'flex-start' : 'flex-end'};

        > .content-item {
            flex-basis: 50%;

            @media (max-width: ${MAX_WIDTH_BREAKPOINT}) {
                flex-basis: 75%;
            }

            flex-grow: 0;
            flex-shrink: 1;

            text-align: ${props => props.alignment};

            > .anchor {
                max-width: 22em;

                margin-left: ${props => props.alignment === ALIGNMENTS.LEFT ? '0' : 'auto'};
                margin-right: ${props => props.alignment === ALIGNMENTS.LEFT ? 'auto' : '0'};
            }
        }
    }
`;

function extractImageURL(imageCollection) {
    const imageURL = imageCollection.sizes?.[ImageSizes.XL]?.url
        || imageCollection.sizes?.[ImageSizes.FULL]?.url;

    return formatURL(imageURL, imageCollection.mime);
}

function formatURL(imageURL, mimeType) {
    return mimeType === 'image/webp'
        ? imageURL.slice(0, imageURL.length - '.webp'.length)
        : imageURL;
}

ImageBlock.blockKey = 'rh/image-block';
ImageBlock.BACKGROUND_GRADIENT_LEVELS = BACKGROUND_GRADIENT_LEVELS;

export default ImageBlock;
