import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component used to accept children from the BlueprintPlacementTool block
 * In this implementation we assume it's a dumb container and don't support any other props.
 */
function BlueprintPlacementTool({ children }) {
    return (
        <>
            {children}
        </>
    );
}

BlueprintPlacementTool.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

BlueprintPlacementTool.blockKey = 'rh/blueprint-placement-tool';

export default BlueprintPlacementTool;
