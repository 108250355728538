import queryString from 'query-string';

import getCreditCardURL, { CC_URL_SLUGS } from './getCreditCardURL';

/**
 * Get the card-specific eligibility checker URL for CardFinder comparison against a given card.
 *
 * @param {string} language
 * @param {string} cardId
 * @param {string} productType
 * @return {string}
 */
function getEligibilityCheckerURL(language, cardId, productType) {
    return getCreditCardURL(language, CC_URL_SLUGS.ELIGIBILITY_CHECKER)
        + `?${queryString.stringify({ cardId, productType })}`;
}

export default getEligibilityCheckerURL;
