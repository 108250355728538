import { defineMessages } from 'react-intl';


export default defineMessages({
    latestPostsTitle: {
        id: 'web-server.blog.blog-home-latest-posts.title',
        defaultMessage: 'Latest posts',
    },
    relatedPostsTitle: {
        id: 'web-server.blog.related-posts.title',
        defaultMessage: 'Related posts',
    },
    newsletterTitle: {
        id: 'web-server.blog.newsletter.title',
        defaultMessage: 'The knowledge bank',
    },
    newsletterDescription: {
        id: 'web-server.blog.newsletter.description',
        defaultMessage: 'A wealth of wealth knowledge delivered right to your inbox.',
    },
    newsletterInputLabel: {
        id: 'web-server.blog.newsletter.inputLabel',
        defaultMessage: 'email address', // should we have a default for this in NewsletterSignUpInlineForm itself?
    },
    newsletterSidebarTitle: {
        id: 'web-server.blog.newsletter.sidebar.title',
        defaultMessage: 'Join our weekly newsletter for tips and deals!',
    },
    newsletterSidebarDescription: {
        id: 'web-server.blog.newsletter.sidebar.description',
        defaultMessage: '1,200,000 Canadians a month make better financial decisions using Ratehub.ca',
    },
});
