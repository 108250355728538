import {
    AnimatedSlideAndFadeContainer,
} from '@ratehub/base-ui';

import BLOCK_ANIMATIONS from './BlockAnimations';


const BLOCK_ANIMATION_COMPONENT_MAP = {
    [BLOCK_ANIMATIONS.SLIDE_AND_FADE]: AnimatedSlideAndFadeContainer,
};

export default BLOCK_ANIMATION_COMPONENT_MAP;
