import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconChevronDown({ outlineWidth, strokeWidth, stroke, fill, ...otherProps }){
    return (
        <IconChevronDown.Svg
            viewBox="0 0 62 62"
            fill="none"
            fillRule="evenodd"
            outlineWidth={outlineWidth}
            strokeWidth={strokeWidth}
            strokeColour={stroke}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="30"
            />
            <path
                fill="none"
                d="M44 27.5L31.5 39.5L19 27.5"
            />
        </IconChevronDown.Svg>
    );
}

IconChevronDown.propTypes = {
    outlineWidth: PropTypes.string,
    strokeWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconChevronDown.defaultProps = {
    outlineWidth: '2px',
    strokeWidth: '2px',
    stroke: Colours.BLACKBERRY,
    fill: Colours.TRANSPARENT,
};

IconChevronDown.Svg = styled.svg`
    stroke-width: ${props => props.strokeWidth};
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconChevronDown;

