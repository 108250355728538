import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import TextAreaComponent from 'react-textarea-autosize';
import styled from 'styled-components';

import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import InputStyle from '../styles/InputStyle';

import InputController from './InputController';
import ErrorMessageContainer from './ErrorMessageContainer';


function TextArea({
    name,
    value,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    isRequired,
    isDisabled,

    canShowInvalid,

    onCreateField,

    placeholder,

    tabIndex,

    ...otherProps
}) {
    const intl = useIntl();

    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            isRequired={isRequired}
            isDisabled={isDisabled}
            canShowInvalid={canShowInvalid}
            getParsedValue={value => value?.toString() ?? ''}
            getFormattedValue={value => value?.toString() ?? ''}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => (
                <ErrorMessageContainer
                    isInvalid={inputState.shouldShowInvalid}
                    message={inputState.errorMessage}
                >
                    <TextArea.Input
                        {...inputProps}

                        placeholder={messageToString(placeholder, intl)}

                        aria-invalid={inputState.shouldShowInvalid ? 'true' : undefined}
                        data-invalid={inputState.isInvalid ? 'true' : undefined}

                        tabIndex={tabIndex.toString()}

                        onChange={e => inputProps.onChange(e.target.value)}
                    />
                </ErrorMessageContainer>
            )}
        </InputController>
    );
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    placeholder: MessagePropType,

    canShowInvalid: PropTypes.bool,

    onCreateField: PropTypes.func,

    tabIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

TextArea.defaultProps = {
    value: undefined,

    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,

    isRequired: undefined,
    isDisabled: false,

    placeholder: undefined,

    canShowInvalid: false,

    onCreateField: undefined,

    tabIndex: 0,
};

TextArea.Input = styled(TextAreaComponent)`
    ${InputStyle}
    
    border: 1px solid ${Colours.STONE_DARK};
    border-radius: 5px;
    
    padding: ${Sizes.SPACING.ONE_AND_A_HALF};

    /* Override line-height set by InputStyle
        to the default for our font size */
    line-height: unset;

    &::placeholder {
        line-height: unset;
    }
`;

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.text-area.error.default"
        defaultMessage="Please enter a valid value"
    />
);

export default TextArea;
