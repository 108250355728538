import { useState } from 'react';
import { observable } from 'mobx';

import { postalCodeValidator, emailValidator } from '@ratehub/base-ui';


const INPUT_TYPES = {
    STRING: 'string',
    EMAIL: 'email',
    POSTALCODE: 'postalcode',
};

function useInlineForm({ inputType, onSubmit, onBlur }) {
    const [ self ] = useState(() => observable({
        value: null,
        isTouched: false,
        isDisabled: false,

        get isValid() {
            if (!self.value) {
                return false;
            }

            if (inputType === INPUT_TYPES.POSTALCODE && !postalCodeValidator(self.value)) {
                return false;
            }

            if (inputType === INPUT_TYPES.EMAIL && !emailValidator(self.value)) {
                return false;
            }

            return true;
        },

        get shouldShowInvalid() {
            return self.isValid === false && self.isTouched;
        },

        setValue(value) {
            self.value = value;
        },

        setIsTouched(value) {
            self.isTouched = value;
        },

        setIsDisabled(value) {
            self.isDisabled = value;
        },

        blur() {
            onBlur && onBlur();
            self.setIsTouched(true);
        },

        async submit() {
            return !!await onSubmit();
        },
    }));

    return self;
}

export { INPUT_TYPES };
export default useInlineForm;

