import Config from '../definitions/Config';
import trackHeapEvent from './trackHeapEvent';
import noticeError from './noticeError';
import fetchWithErrorHandling from './fetchWithErrorHandling';


const API_ENDPOINT = Config.NEWSLETTER_API_URL;

/**
 * Subscribe an email address to the newsletter.
 * @param {object} params
 * @param {string} params.email
 *
 * @returns {object} result of fetch(Config.NEWSLETTER_API_URL)
*/
export default async function subscribeToNewsletter(params) {
    const payload = {
        url: window.location,
        ...params,
    };

    try {
        const response = await fetchWithErrorHandling(API_ENDPOINT, {
            method: 'post',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        trackHeapEvent('Subscribed to newsletter', payload);

        return response;
    } catch (error) {
        noticeError(new Error('Failed to subscribe to newsletter in subscribeToNewsletter.'), {
            payload: {
                ...payload,
                // Email is PII, we don't want this stored in NR
                email: 'REDACTED',
            },
            status: error?.response?.status,
        });

        trackHeapEvent('Failed to subscribe to newsletter', payload);
    }
}

export {
    API_ENDPOINT,
};
