import VerticalSpacing from '../definitions/VerticalSpacing';


export default function getVerticalSpacingBelowClassName(value) {
    switch (value) {
        case VerticalSpacing.NONE: return 'rh-pb-0';
        case VerticalSpacing.SMALL: return 'rh-pb-4';
        case VerticalSpacing.MEDIUM: return 'rh-pb-6';
        case VerticalSpacing.LARGE: return 'rh-pb-8';
    }
}
