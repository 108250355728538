import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconHeart({ outlineWidth, stroke, strokeWidth, fill, ...otherProps }) {
    return (
        <IconHeart.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="29"
            />
            <path d="M33.28 21.46c3.04-3.04 7.97-3.04 11.01 0s3.04 7.97 0 11.01M33.28 21.46L31 23.47M28.72 21.46c-3.04-3.04-7.97-3.04-11.01
                0s-3.04 7.97 0 11.01M28.72 21.46L31 23.47M17.71 32.47l11.9 12.92c.76.85 2.01.85 2.78 0l11.9-12.92"
            />
        </IconHeart.Svg>
    );
}

IconHeart.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconHeart.defaultProps = {
    outlineWidth: '2px',
    stroke: Colours.BLACKBERRY,
    strokeWidth: '2px',
    fill: Colours.TRANSPARENT,
};

IconHeart.Svg = styled.svg`
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: ${props => props.strokeWidth};
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconHeart;
