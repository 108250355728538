/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { PrimaryAnchor, RemoveDefaultButtonStyles, Colours, ImageSizes, useIsMounted } from '@ratehub/base-ui';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import mountYouTubeAPI from '../functions/mountYouTubeAPI';


const VIDEO_STYLES = {
    EMBED: 'embed',
    THUMBNAIL: 'thumbnail',
};

const THUMBNAIL_CLASSES = 'rh-box-sizing-border-box rh-position-absolute rh-top-0 rh-left-0 rh-width-100p rh-height-100p rh-border-width-0';
const LAZY_LOAD_CLASS = 'lazy-load-class';

function EmbeddedVideo({
    style,

    thumbnailImageCollection,
    thumbnailImagePositionX,
    thumbnailImagePositionY,

    videoTitle,
    videoSrc,

    ctaText,
    ctaHref,

    className,
}) {
    // Pull the video id out of the embed URL
    const videoId = videoSrc ? getVideoId(videoSrc) : '';

    // Must be unique on page; assumes there are not multiple players that display the same video
    const playerId = `${PLAYER_PREFIX}${videoId}`;

    const hasValidCta = ctaHref && ctaText;

    const [ player, setPlayer ] = useState(null);
    const [ isPlayerReady, setIsPlayerReady ] = useState(false);
    const [ hasBeenClicked, setHasBeenClicked ] = useState(false);

    const getIsMounted = useIsMounted();

    // Load the YouTube API if needed, create a player for the requested video
    useEffect(() => {
        if (style !== VIDEO_STYLES.THUMBNAIL) {
            // No need to mount the YouTube API or run any other
            // THUMBNAIL-related code
            return;
        }

        // Older browsers and tests don't support IntersectionObserver
        if (!window.IntersectionObserver) {
            createPlayer();
            return;
        }

        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    createPlayer();
                    observer?.disconnect();
                }
            },
            {
                root: null,
                rootMargin: '0px 0px 100px 0px',
                threshold: 0.9,
            },
        );

        observer.observe(document.querySelector(`.${LAZY_LOAD_CLASS}`));

        async function createPlayer() {
            if (!window.YT) {
                await mountYouTubeAPI();

                // Bail if we're not mounted after awaiting
                //  We can't set state (setPlayer below) if we're not mounted
                if (!getIsMounted()) {
                    return;
                }
            }

            const constructedPlayer = new window.YT.Player(playerId, {
                videoId,
                playerVars: {
                    playsinline: 1,
                },
                events: {
                    onReady: handlePlayerReady,
                },
            });

            setPlayer(constructedPlayer);
        }

        return () => observer?.disconnect();
    }, []);

    // Add classNames to iframe once we can get a reference to it
    useEffect(() => {
        if (isPlayerReady) {
            player.getIframe().classList.add(...THUMBNAIL_CLASSES.split(' '));
        }
    }, [ isPlayerReady ]);

    // When the player has loaded and the user has clicked on the thumbnail, start the video
    useEffect(() => {
        if (isPlayerReady && hasBeenClicked) {
            player.playVideo();
        }
    }, [ isPlayerReady, hasBeenClicked, player ]);

    // Called by YouTube API when the player has loaded and can be interacted with
    function handlePlayerReady() {
        setIsPlayerReady(true);
    }

    function handleThumbnailClick() {
        setHasBeenClicked(true);
    }

    const thumbnailProps = style === VIDEO_STYLES.THUMBNAIL
        ? {
            imgSrc: thumbnailImageCollection ? getImageURL(thumbnailImageCollection) : '',
            imagePositionX: thumbnailImagePositionX,
            imagePositionY: thumbnailImagePositionY,
        }
        : null;

    return (
        <div className={className}>
            <Wrapper
                className={classNames('rh-mx-auto rh-position-relative rh-height-0', {
                    'rh-mb-3': hasValidCta,
                })}
                {...thumbnailProps}
            >
                <Choose>
                    <When condition={style === VIDEO_STYLES.THUMBNAIL}>
                        {/*
                            The inner div is replaced with a player once the YouTube API is ready.
                            We need the wrapper div to prevent an error when switching between the embed and thumbnail styles.
                            YouTube needs to be able to detect the direct parent of the player div.
                        */}
                        <div className={classNames(LAZY_LOAD_CLASS, 'rh-bg-blackberry')}>
                            <div id={playerId} />
                        </div>

                        <button
                            data-name="embeddedVideo-thumbail-play"
                            className={classNames(`${THUMBNAIL_CLASSES} thumbnail-image`, {
                                'rh-display-none' : hasBeenClicked,
                            })}
                            onClick={handleThumbnailClick}
                            onKeyPress={handleThumbnailClick}
                            type="button"
                        >
                            <span
                                className="rh-display-block rh-text-align-left rh-my-0 thumbnail-content-wrapper"
                            >
                                <span
                                    className="thumbnail-title rh-fg-coconut rh-title-3xl"
                                >
                                    {videoTitle}
                                </span>

                                <span
                                    className="play-button rh-display-flex rh-fg-coconut rh-mt-1_5 rh-align-items-center"
                                >
                                    <div className="arrow-icon rh-border-radius-100p">
                                        <svg
                                            className="rh-width-100p rh-height-100p rh-border-radius-100p"
                                            width="48"
                                            height="48"
                                            viewBox="0 0 58 58"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g>
                                                <path
                                                    className="play-button-bg"
                                                    d="M29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0C12.9837 0 0 12.9837 0 29C0 45.0163 12.9837 58 29 58Z"
                                                />
                                                <path
                                                    className="play-button-fg"
                                                    d="M21.95 40.37C21.43 40.67 21 40.41 21 39.81V17.84C21 17.24 21.42 16.99 21.93 17.31L40.72 28.66C41.23 28.97 41.23 29.47 40.7 29.76L21.95 40.37Z"
                                                    strokeWidth="2"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </g>
                                        </svg>
                                    </div>

                                    <span className="play-button-text rh-mb-0 rh-text-lowercase weight-medium rh-ml-0_5 rh-text-large">
                                        <FormattedMessage
                                            id="web-components.embeddedVideo.playButtonText"
                                            defaultMessage="Watch video"
                                        />
                                    </span>
                                </span>
                            </span>
                        </button>
                    </When>

                    <Otherwise>
                        <LazyLoadComponent>
                            <iframe
                                className={THUMBNAIL_CLASSES}
                                data-name="embeddedVideo-iframe"
                                title={videoTitle}
                                src={videoSrc}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </LazyLoadComponent>
                    </Otherwise>
                </Choose>
            </Wrapper>

            <If condition={hasValidCta}>
                <div className="video-cta-container">
                    <PrimaryAnchor
                        className="video-cta rh-mx-auto"
                        href={ctaHref}
                    >
                        {ctaText}
                    </PrimaryAnchor>
                </div>
            </If>
        </div>
    );
}

EmbeddedVideo.propTypes = {
    style: PropTypes.oneOf(Object.values(VIDEO_STYLES)),

    thumbnailImageCollection: ImageCollectionPropType,
    thumbnailImagePositionX: PropTypes.string,
    thumbnailImagePositionY: PropTypes.string,

    videoTitle: PropTypes.string.isRequired,
    videoSrc: PropTypes.string.isRequired,

    ctaText: PropTypes.string,
    ctaHref: PropTypes.string,

    className: PropTypes.string,
};

EmbeddedVideo.defaultProps = {
    style: VIDEO_STYLES.EMBED,

    thumbnailImageCollection: undefined,
    thumbnailImagePositionX: 'center',
    thumbnailImagePositionY: 'center',

    ctaText: undefined,
    ctaHref: undefined,

    className: undefined,
};

const PLAYER_PREFIX = 'rh-yt-';

function getVideoId(videoSrc) {
    return videoSrc?.split('https://www.youtube.com/embed/')[1]?.split('?')[0];
}

function getImageURL(imageCollection) {
    const imageURL = imageCollection.sizes?.[ImageSizes.M]?.url
        || imageCollection.sizes?.[ImageSizes.FULL]?.url;

    return getFormattedURL(imageURL, imageCollection.mime);
}

function getFormattedURL(imageURL, mimeType) {
    return mimeType === 'image/webp'
        ? imageURL.slice(0, imageURL.length - '.webp'.length)
        : imageURL;
}

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    max-width: 940px;

    + .video-cta-container > .video-cta {
        max-width: 340px;
    }

    .thumbnail-image {
        ${RemoveDefaultButtonStyles};
        background-image: url( ${props => props.imgSrc} );
        background-size: cover;
        background-position: ${props => props.imagePositionX} ${props => props.imagePositionY};

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .thumbnail-content-wrapper {
            margin-left: 8%;
            margin-right: 8%;
            max-width: 28rem;

            .thumbnail-title {
                font-size: clamp(1.1875rem, 0.8285rem + 1.7674vw, 2.375rem);
            }

            .play-button {
                .arrow-icon {
                    width: calc(2rem + 1vw);
                    height: calc(2rem + 1vw);

                    .play-button-bg {
                        fill: ${Colours.BLUEBERRY};
                    }

                    .play-button-fg {
                        stroke: ${Colours.COCONUT};
                    }
                }

                .play-button-text {
                    font-size: clamp(1.125rem, 0.9738rem + 0.7442vw, 1.25rem);
                }
            }
        }

        /* Group all hover styles together so they are activated when a user hovers over any part of the thumbnail */
        &:hover,
        &:focus,
        &:focus-within {
            cursor: pointer;

            &::before {
                background-color: ${Colours.BLACKBERRY};
                opacity: 20%;
            }

            .thumbnail-content-wrapper .play-button .arrow-icon {
                @media (prefers-reduced-motion: no-preference) {
                    animation: strobe 2s ease-out 1;

                    @keyframes strobe {
                        0% {
                            box-shadow: 0 0 0 0 white;
                        }
                        50% {
                            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
                        }
                        100% {
                            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
                        }
                    }
                }

                .play-button-fg {
                    fill: ${Colours.COCONUT};
                }
            }
        }
    }
`;

EmbeddedVideo.blockKey = 'rh/embedded-video';
EmbeddedVideo.requiresLayoutRow = true;

export default EmbeddedVideo;
