import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';

import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import ChevronUp from '../graphics/ChevronUp';
import ChevronDown from '../graphics/ChevronDown';
import SelectStyle from '../styles/SelectStyle';
import { useCondensedTheme } from './CondensedTheme';


/* Note: This component is separated from the state in Select to make it easier in the future to
    apply changes like using a native select on mobile. When that is in progress, this
    component does not have to be rendered or could be display: none */
function SelectBase({
    isInline = false,
    isOpen = false,

    options,
    optionPlaceholder,
    highlightedIndex,

    disabled = false,
    label,
    placeholder,
    shouldShowInvalid = false,

    comboboxProps,
    inputProps,
    buttonProps,
    menuProps,

    className,

    ...otherProps
}) {
    const intl = useIntl();
    const isCondensed = useCondensedTheme();

    const placeholderMessage = messageToString(placeholder, intl);

    const value = inputProps.value;
    const shouldDisplayValueAsSpan = isInline && value;
    const hasLabel = !!label && !isInline; // inline select doesn't support labels

    return (
        <Container
            className={classNames(className, {
                'is-condensed': isCondensed,
                'is-inline': isInline,
                'is-dropdown': !!buttonProps,
                'is-disabled': disabled,
                'is-open': isOpen,
                'is-invalid': shouldShowInvalid,
                'has-label': hasLabel,
                'has-value': !!value,
            })}

            {...otherProps}
        >
            {/* We need this container since the menu behaves odd if its a sibling with the input  */}
            <span
                className="select-combobox"
                {...comboboxProps}
                // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                role="combobox"
            >
                {/* For our inline display of SelectBase, we're required to display the selection text
                    in a span so that the width of the component adapts to the size of the contents. */}
                <If condition={shouldDisplayValueAsSpan}>
                    <span className="select-value">
                        {value}
                    </span>
                </If>

                <If condition={hasLabel}>
                    <label
                        className="label rh-text-m rh-text-align-left"
                        htmlFor={inputProps.id ?? inputProps.name}
                    >
                        {messageToString(label, intl)}
                    </label>
                </If>

                <input
                    type="text"
                    placeholder={placeholderMessage}

                    {...inputProps}

                    className={classNames(
                        inputProps.className,
                        'select-input',
                        'rh-pr-0_5',
                        'rh-pl-1_125',
                        'rh-py-0',
                        { 'hidden': shouldDisplayValueAsSpan },
                    )}

                    // Defaulting to 1 for inline variant to fall back on defined width styles
                    size={isInline ? 1 : undefined}

                    // Override this value since its passed from getInputProps & we don't apply id's to our labels
                    aria-labelledby={undefined}
                />

                <If condition={buttonProps}>
                    <button
                        type="button"
                        className="select-open-menu-button"
                        data-name="cta-click-toggle-dropdown-menu"
                        aria-label={intl.formatMessage(MESSAGES.BUTTON_ARIA_LABEL)}

                        {...buttonProps}
                    >
                        <Choose>
                            <When condition={isOpen}>
                                <ChevronUp className="icon-chevron" />
                            </When>
                            <Otherwise>
                                <ChevronDown className="icon-chevron" />
                            </Otherwise>
                        </Choose>
                    </button>
                </If>
            </span>

            <ul
                className={classNames('select-menu', {
                    'is-open': isOpen,
                })}
                {...menuProps}
            >
                <Choose>
                    <When condition={optionPlaceholder}>
                        <li className="select-option">
                            {messageToString(optionPlaceholder, intl)}
                        </li>
                    </When>
                    <When condition={options?.length > 0}>
                        <For
                            each="option"
                            of={options}
                        >
                            <Choose>
                                <When condition={!!option.options}>
                                    <li
                                        key={messageToString(option.label, intl)}
                                        className="select-option-group"
                                    >
                                        <span className="select-option-group-title">
                                            {option.label}
                                        </span>
                                        <ul className="select-option-group-list">
                                            <For
                                                each="innerOption"
                                                of={option.options}
                                            >
                                                <li
                                                    key={innerOption.value}
                                                    className={classNames('select-option', {
                                                        'is-highlighted': highlightedIndex === innerOption.index,
                                                        'is-disabled': innerOption.disabled,
                                                    })}
                                                    {...innerOption.otherProps}
                                                >
                                                    {innerOption.label}
                                                </li>
                                            </For>
                                        </ul>
                                    </li>
                                </When>
                                <Otherwise>
                                    <li
                                        key={typeof option.value === 'string' ? option.value : messageToString(option.label, intl)}
                                        className={classNames('select-option', {
                                            'is-highlighted': highlightedIndex === option.index,
                                            'is-disabled': option.disabled,
                                        })}
                                        {...option.otherProps}
                                    >
                                        {option.label}
                                    </li>
                                </Otherwise>
                            </Choose>
                        </For>
                    </When>
                </Choose>
            </ul>
        </Container>
    );
}

SelectBase.propTypes = {
    isInline: PropTypes.bool,
    isOpen: PropTypes.bool,

    options: PropTypes.oneOfType([
        PropTypes.object, // mobx observable array
        PropTypes.array,
    ]).isRequired,
    optionPlaceholder: MessagePropType,
    highlightedIndex: PropTypes.number,

    disabled: PropTypes.bool,
    label: MessagePropType,
    placeholder: MessagePropType,
    shouldShowInvalid: PropTypes.bool,

    comboboxProps: PropTypes.object.isRequired,
    inputProps: PropTypes.object.isRequired,
    buttonProps: PropTypes.object,
    menuProps: PropTypes.object.isRequired,

    className: PropTypes.string,
};


const Container = styled.div`
    ${SelectStyle}
`;


const MESSAGES = defineMessages({
    BUTTON_ARIA_LABEL: {
        id: 'base-ui.select.aria-label',
        defaultMessage: 'Toggle dropdown menu',
    },
});

export default SelectBase;
