import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { Colours, StickyBannerTrigger } from '@ratehub/base-ui';
import SiteSettings from '../definitions/SiteSettings';


/* This component currently is styled to look like our Header,
    additional variants can be added if desired since the state
    is separated in StickyBannerTrigger. */
function StickyBanner({ style, children }) {
    return (
        // Requires the style prop from CMSComponentSelector to position this in the grid correctly
        <StickyBannerTrigger style={style}>
            <ChildContainer
                className={classNames(
                    'rh-width-100p',
                    'rh-bg-coconut',
                    'rh-mb-2',
                    'rh-py-0_5',
                )}
            >
                <div className="sticky-banner-child-container rh-mx-auto rh-px-1 rh-box-sizing-border-box">
                    {children}
                </div>
            </ChildContainer>
        </StickyBannerTrigger>
    );
}

StickyBanner.propTypes = {
    style: PropTypes.string,
    children: PropTypes.node.isRequired,
};

StickyBanner.defaultProps = {
    style: undefined,
};

const ChildContainer = styled.div`
    box-shadow: 0 2px 18px ${Colours.STONE};

    > .sticky-banner-child-container {
        max-width: ${SiteSettings.HEADER_MAX_WIDTH};
    }
`;

StickyBanner.blockKey = 'rh/sticky-banner';

export default StickyBanner;
