import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';

import { Sizes } from '@ratehub/base-ui';

import BlogPostPropType from '../definitions/BlogPostPropType';
import Placeholders from '../definitions/Placeholders';


// We use this one fixed size of placeholder all the time... we almost
// never see this placeholder anyways and when it gets resized, that's done
// via CSS which has no impact on CLS (width and height do that).
const PLACEHOLDER_IMAGE = Placeholders['300x300'];

function BlogSpotlightPost({
    title,
    titleAs,
    url,
    imageUrl,
    imageAlt,
    tags,
    hasMinWidth,
    hasMaxWidth,

    isDisabled,
    ...otherProps
}) {
    const postHREF = isDisabled ? undefined : url;
    const TitleElement = titleAs;

    return (
        <BlogSpotlightPost.Container
            isDisabled={isDisabled}
            hasMinWidth={hasMinWidth}
            hasMaxWidth={hasMaxWidth}
            {...otherProps}
        >
            <a
                className="post-link-container"
                href={postHREF}
            >
                <LazyLoadImage
                    className="image"
                    width="100%"
                    height="auto"

                    placeholderSrc={PLACEHOLDER_IMAGE}
                    src={imageUrl}
                    alt={imageAlt}
                />

                <TitleElement
                    className={classNames('rh-mt-1 rh-mb-1_5 rh-fg-blackberry weight-regular sub-title rh-title-l')}
                >
                    <span
                        className="sub-title-contents"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </TitleElement>
            </a>

            <div className="tags-container">
                <For
                    each="tag"
                    of={tags}
                >
                    <a
                        key={`${tag.title}-${title}`}
                        href={tag.url}
                        className="rh-tag rh-display-block"
                    >
                        {tag.title}
                    </a>
                </For>
            </div>
        </BlogSpotlightPost.Container>
    );
}

BlogSpotlightPost.propTypes = BlogPostPropType;

BlogSpotlightPost.defaultProps = {
    titleAs: 'h4',
    imageAlt: '',
    tags: [],
    isDisabled: false,
    hasMinWidth: false,
    hasMaxWidth: false,
};

BlogSpotlightPost.Container = styled.article`
    user-select: none;

    ${props => props.hasMinWidth && `
        min-width: 300px;
    `}
    ${props => props.hasMaxWidth && `
        max-width: 23rem;
    `}

    > .post-link-container {
        display: block;
        text-decoration: none;
        outline: none;

        &:hover,
        &:focus {
            .sub-title-contents {
                ${props =>!props.isDisabled && `
                    box-shadow: inset 0 -2px black; /* text underline for post title */
                `}
            }
        }

        > .lazy-load-image-background {
            width: 100%;
            height: auto;
            aspect-ratio: 1;

            > .image {
                position: relative;
                object-fit: cover;
                border-radius: 0.5em;

                /* 100% here refers to dimensions given in inline styles on .lazy-load-image-background. */
                width: 100%;
                height: 100%;
            }
        }

        .sub-title {
            pointer-events: none;
            line-height: 32px;
        }
    }

    > .tags-container {
        display: flex;
        flex-wrap: wrap;

        margin: -${Sizes.SPACING.QUARTER} 0 0 -${Sizes.SPACING.QUARTER};
    }
`;

export default BlogSpotlightPost;
