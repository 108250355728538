/**
 * Build nested structure representing a hierarchy of animation components
 * @param {array} blockAnimations list of animation keys (as defined by
 *    `.animations` flag on web-component)
 * @return {object} A nested definition of all animations
 */
function createNestedAnimationsDefinition(blockAnimations) {
    return blockAnimations
        ? blockAnimations
            .reverse()
            .reduce((prevValue, currentValue) => ({
                blockAnimation: currentValue,
                child: prevValue,
            }), undefined)
        : {};
}

export default createNestedAnimationsDefinition;
