import { getVisitorId } from '@ratehub/base-ui';


/**
 * Get the URL to redirect a client to
 * @param {object} args 
 * @param {object} providers
 * @param {string} province
 * @returns {string} the URL to redirect to
 */
function getInsuranceRedirectUrl(providers, province) {
    const provider = getAssignedProvider(providers, province);

    return provider?.redirectUrl || null;
}

function getRegionProviders(providers, province) {
    return providers.reduce((regionProviders, provider) => {
        if (Object.keys(provider.regions).includes(province)) {
            regionProviders.push({
                ...provider,
                trafficPercent: provider.regions[province],
            });
        }

        return regionProviders;
    }, []);
}

// TODO: Generalize this function and move to
// @ratehub/base-ui if we need this functionality
// in other places
function getAssignedProvider(providers, province) {
    const regionProviders = getRegionProviders(providers, province);

    // REQUIREMENT: cannot have more than 100%.
    if (regionProviders.reduce((sum, provider) => sum + provider.trafficPercent, 0) > 100) {
        throw new Error('Sum of variation probability must be <= 100');
    }

    const visitorId = getVisitorId() ?? '0';

    // Assign user into a bucket between 0 and 99
    // visitorId is a 64-bit BigInt (represented as a string);
    // only the least significant digits are relevant for the mod
    let bucket = Number((visitorId).toString().slice(-4)) % 100;

    let assignedProvider;
    
    for (let i = 0; i <= regionProviders.length; i++) {
        const provider = regionProviders[i];

        if (provider?.trafficPercent >= bucket) {
            assignedProvider = provider;
            break;
        }
        else {
            bucket -= provider?.trafficPercent;
        }
    }

    return assignedProvider;
}

export default getInsuranceRedirectUrl;
