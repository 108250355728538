import React from 'react';
import PropTypes from 'prop-types';

import {
    NavigationCardBlock,
    MessagePropType,
    navigateWindowTo,
} from '@ratehub/base-ui';

import PAGE_TEMPLATES from '../definitions/PageTemplates';


function ScenarioChooser({
    cards,
    showThirdCard,
    isOutAnimationEnabled,
    ...otherProps
}) {
    const numberOfVisibleCards = showThirdCard ? 3 : 2;
    const cardsWithOnClicks = addOnClickToCards(cards.slice(cards, numberOfVisibleCards));
    const shouldShowScenarioChooser = allVisibleCardsHaveHREFs(cardsWithOnClicks, numberOfVisibleCards);

    // We don't want to render unless every card has a link. Otherwise users
    // would click a card's button and nothing would happen. It's really bad
    // when the cards animate away and the user is stranded on a blank page.
    if (!shouldShowScenarioChooser) {
        return null;
    }

    return (
        <NavigationCardBlock
            cards={cardsWithOnClicks}
            isAnimated={isOutAnimationEnabled}
            {...otherProps}
        />
    );
}

function addOnClickToCards(cards) {
    return cards
        .map(card => {
            return {
                title: card.title ?? '',
                description: card?.description,
                'data-name': card['data-name'] ?? '',
                href: card?.href,
                onClick: () => {
                    card.href
                        ? navigateWindowTo(card.href)
                        : undefined;
                },
                ctaText: card?.ctaText,
            };
        });
}

function allVisibleCardsHaveHREFs(cards, numberOfVisibleCards) {
    const cardsWithHREFs = cards.filter(card => card.href);

    return cardsWithHREFs.length === numberOfVisibleCards;
}

ScenarioChooser.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        title: MessagePropType.isRequired,
        description: MessagePropType,
        'data-name': PropTypes.string.isRequired,
        ctaText: MessagePropType,
    })).isRequired,
    showThirdCard: PropTypes.bool,
    isOutAnimationEnabled: PropTypes.bool,
};

ScenarioChooser.defaultProps = {
    showThirdCard: false,
    isOutAnimationEnabled: true,
};

ScenarioChooser.blockKey = 'rh/scenario-chooser';
ScenarioChooser.supportedPageTypes = [
    PAGE_TEMPLATES.scenarioChooser,
];

export default ScenarioChooser;
