import { Experiments, getVisitorId } from '@ratehub/base-ui';


/**
 * Constructs the base payload to send to the conversion service
 * @param {Object} obj
 * @param {Object} obj.rate
 * @param {String} obj.cityName
 * @param {String} obj.provinceCode
 * @param {String} obj.locale
 * @param {Object} obj.experiments
 * 
 * @return {Object}
 */
export default function createBaseConversionPayload({ rate, cityName, provinceCode, locale, experiments }) {
    return {
        'productType': 'MORTGAGE',
        'city': cityName,
        'province': provinceCode,
        'language': locale,
        'conversionSourceType': 'MORTGAGE_RATE',
        'conversionSourceId': rate.id,
        'conversionSourceDetails': {
            interestRate: rate.value,
        },
        'provider': {
            slug: rate.provider.slug,
        },
        'experiments': {
            'mortgages': {
                ...Experiments.toJSON(),
                ...experiments,
                'lipstickOnAPig': 1,
            },
        },
        visitor: getVisitorId() ?? '0',
    };
}
