import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';

import { AnimatedOpacityContainer, Sizes, AlternateAnchor, ImageSizes } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import BlogSpotlightPost from './BlogSpotlightPost';
import HeadingBlock from './HeadingBlock';
import Carousel from './Carousel';


const MIN_ARTICLE_WIDTH = 250;
const GAP_WIDTH = '2rem';

function FeaturedArticles({
    size,
    heading,
    href,
    hrefText,
    articles,
}) {
    // Not sure if we need/want this still
    const [ showOverflowControls ] = useState(false);

    const containerWidth = size?.width;

    const isInitialized = !!containerWidth;

    const potentialColumns = !isInitialized
        ? articles.length
        : Math.max(Math.floor(containerWidth / MIN_ARTICLE_WIDTH), 1); // Minimum of 1

    const visibleColumns = Math.max(Math.min(potentialColumns, articles.length), 1);

    const hasMinWidth = visibleColumns === 1;
    const hasMaxWidth = visibleColumns === 1 || potentialColumns > articles.length;

    const blogPostTitleElement = blogPostTitleAs(heading);

    return (
        <Container
            visibleColumns={visibleColumns}
        >
            <If condition={heading}>
                <HeadingBlock
                    {...heading}
                />
            </If>

            <div className="articles-container">
                <If condition={Array.isArray(articles) && articles.length}>
                    <AnimatedOpacityContainer
                        isOpaque={isInitialized}
                        alwaysRenderChildMarkup={true}
                    >
                        <Carousel
                            hideDots
                            draggable={false}
                            hideArrows={showOverflowControls}
                            gap="2rem"
                        >
                            <For
                                each="article"
                                of={articles}
                            >
                                <BlogSpotlightPost
                                    className="article"
                                    key={article.title}
                                    title={article.title}
                                    titleAs={blogPostTitleElement}
                                    url={article.href}
                                    imageUrl={getArticleImage(article)}
                                    imageAlt={article.imageAlt}
                                    // Have to remap href to url as it's what BlogSpotlight expects
                                    tags={article?.tags?.map(tag => ({
                                        title: tag.title,
                                        url: tag.href,
                                    }))}
                                    hasMinWidth={hasMinWidth}
                                    hasMaxWidth={hasMaxWidth}
                                />
                            </For>
                        </Carousel>
                    </AnimatedOpacityContainer>
                </If>
            </div>

            <If condition={href && hrefText}>
                <div className="cta-container">
                    <AlternateAnchor
                        className="cta"
                        href={href}
                    >
                        {hrefText}
                    </AlternateAnchor>
                </div>
            </If>
        </Container>
    );
}

FeaturedArticles.propTypes = {
    size: PropTypes.object.isRequired,
    heading: HeadingPropTypes,
    href: PropTypes.string,
    hrefText: PropTypes.string,
    articles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

FeaturedArticles.defaultProps = {
    heading: undefined,
    href: undefined,
    hrefText: undefined,
};

const Container = styled.section`
    > .articles-container {
        position: relative;
        width: 100%;

        ${props => props.visibleColumns > 1 && `
            .article {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc((100% / ${props.visibleColumns}) - ((${props.visibleColumns - 1} / ${props.visibleColumns}) * ${GAP_WIDTH}));
            }
        `}
    }

    > .cta-container {
        text-align: center;

        > .cta {
            display: inline-flex;
            width: auto;

            margin: ${Sizes.SPACING.TWO} auto 0;
        }
    }
`;

function getArticleImage(article) {
    return article?.imageCollection?.sizes?.[ImageSizes.S]?.url
        || article?.imageCollection?.sizes?.[ImageSizes.M]?.url
        || article?.imageCollection?.sizes?.[ImageSizes.FULL]?.url;
}

function blogPostTitleAs(heading) {
    return heading?.title
        ? 'h3'
        : 'h2';
}

const wrappedFeaturedArticles = withSize({ noPlaceholder: true })(FeaturedArticles);

// withSize() removes these component flags so we need to add them *after* calling withSize()
wrappedFeaturedArticles.blockKey = 'rh/featured-articles';
wrappedFeaturedArticles.hasHeading = true,
wrappedFeaturedArticles.requiresLayoutRow = true;

export default wrappedFeaturedArticles;
