import React from 'react';
import getBlockAnimationComponent from '../functions/getBlockAnimationComponent';
import createNestedAnimationsDefinition from '../functions/createNestedAnimationsDefinition';


/**
 * withAnimations()
 * Recursively wrap a component with multiple animation components
 * @param ComponentToWrap {React.element} The component to wrap with animations
 * @param animations {object} A nested structure that defines the hierarchy
 *    of animation components to wrap.
 *
 * NOTE: If the `animations` definition is omitted, withAnimations() will
 * attempt to construct it based on a `.animations` flag on ComponentToWrap.
 * When wrapping a component that doesn't contain a valid flag (like a fragment),
 * This should silently omit all animation wrappers and just render the plain
 * ComponentToWrap on it's own.
 */
function withAnimations(ComponentToWrap, animations) {
    function WrappedComponent({ ...otherProps }) {
        // Attempt to contruct a hierarchy of animations if one is not included.
        const animationsObj = animations
            ? animations
            : createNestedAnimationsDefinition(ComponentToWrap?.animations);

        let AnimationComponent;
        let animationProps = {};

        if (animationsObj?.blockAnimation) {
            const isAnimationEnabled = otherProps[animationsObj?.blockAnimation]
                && otherProps[animationsObj?.blockAnimation].isEnabled;

            // Attempt to recursively wrap with animation components
            const AnimationComponentToWrap = isAnimationEnabled
                ? getBlockAnimationComponent(animationsObj?.blockAnimation)
                : React.Fragment;

            // Build component(s)
            AnimationComponent = AnimationComponentToWrap && animationsObj?.child
                ? withAnimations(AnimationComponentToWrap, animationsObj?.child)
                : AnimationComponentToWrap;

            // Build props - only include if animation is enabled (otherwise
            // animation component is a fragment and doesn't need props)
            if (isAnimationEnabled) {
                animationProps = otherProps[animationsObj?.blockAnimation];
            }

            // Prevent passing animation props to ComponentToWrap below via otherProps
            delete otherProps[animationsObj?.blockAnimation];
        } else {
            // No animation hierarchy defined - just wrap ComponentToWrap
            // with a fragment and move on.
            AnimationComponent = React.Fragment;
            animationProps = {};
        }

        return (
            <AnimationComponent {...animationProps}>
                <ComponentToWrap {...otherProps} />
            </AnimationComponent>
        );
    }

    return WrappedComponent;
}

export default withAnimations;
