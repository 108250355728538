import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AnimatedFadeContainer from './AnimatedFadeContainer';
import AnimatedOpacityContainer from './AnimatedOpacityContainer';
import AnimatedExpandingContainer from './AnimatedExpandingContainer';
import AnimatedTranslateContainer from './AnimatedTranslateContainer';
import AnimatedSlideAndFadeContainer from './AnimatedSlideAndFadeContainer';


const ANIMATION_TYPES = {
    NONE: 'none',
    FADE: 'fade',
    OPACITY: 'opacity',
    EXPANDING: 'expanding',
    SLIDE_AND_FADE: 'slide-and-fade',
    TRANSLATE: 'translate',
};

function AnimatedContainer({ 
    type, 
    children, 

    isVisible = true,
    alwaysRenderChildMarkup = false,
    className,

    ...otherProps
}) {
    return (
        <Choose>
            <When condition={type === ANIMATION_TYPES.FADE}>
                <AnimatedFadeContainer
                    isVisible={isVisible}
                    alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                    className={className}
                    {...otherProps}
                >
                    {children}
                </AnimatedFadeContainer>
            </When>
            <When condition={type === ANIMATION_TYPES.OPACITY}>
                <AnimatedOpacityContainer
                    isOpaque={isVisible}
                    alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                    className={className}
                    {...otherProps}
                >
                    {children}
                </AnimatedOpacityContainer>
            </When>
            <When condition={type === ANIMATION_TYPES.EXPANDING}>
                <AnimatedExpandingContainer
                    isVisible={isVisible}
                    alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                    className={className}
                    {...otherProps}
                >
                    {children}
                </AnimatedExpandingContainer>
            </When>
            <When condition={type === ANIMATION_TYPES.SLIDE_AND_FADE}>
                <AnimatedSlideAndFadeContainer
                    isVisible={isVisible}
                    alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                    className={className}
                    {...otherProps}
                >
                    {children}
                </AnimatedSlideAndFadeContainer>
            </When>
            <When condition={type === ANIMATION_TYPES.TRANSLATE}>
                <AnimatedTranslateContainer
                    isVisible={isVisible}
                    alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                    className={className}
                    {...otherProps}
                >
                    {children}
                </AnimatedTranslateContainer>
            </When>
            <When condition={type === ANIMATION_TYPES.NONE && alwaysRenderChildMarkup}>
                <div
                    className={classNames(className, { 'rh-display-none': !isVisible })}
                    aria-hidden={!isVisible}
                    {...otherProps}
                >
                    {children}
                </div>
            </When>
            <Otherwise>
                <If condition={isVisible}>
                    <div className={className}>
                        {children}
                    </div>
                </If>
            </Otherwise>
        </Choose>
    );
}

const AnimationTypePropType = PropTypes.oneOf(Object.values(ANIMATION_TYPES));

AnimatedContainer.propTypes = {
    type: AnimationTypePropType.isRequired,
    isVisible: PropTypes.bool,
    alwaysRenderChildMarkup: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default AnimatedContainer;
export { AnimationTypePropType, ANIMATION_TYPES };
