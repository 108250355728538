import { Config } from '@ratehub/base-ui';

import getSharedDepositShape from './getSharedDepositShape';
import fetchDeposit from './fetchDeposit';


const API_ENDPOINT = `${Config.ROBO_API_URL}/robo-queue`; 

/**
 * Fetch robo advisors according to options
 * @param {string} options.province - Province code
 * @param {string} options.language - Language code
 * @param {object[]} options.products - An array of requested products ordered by priority
 */
async function fetchRobo(options) {
    const response = await fetchDeposit(options, API_ENDPOINT);

    return {
        data: {
            products: response.data?.products?.map(getProductShape) || [],
        },
    };
}

function getProductShape(product) {
    return product
        ? getSharedDepositShape(product)
        : undefined;
}

export default fetchRobo;
