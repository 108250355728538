import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import useForm from '../hooks/useForm';
import Formats from '../definitions/Formats';
import getFormattedNumber from '../functions/getFormattedNumber';


const NUMBER_FORMATS = Formats.number;

function FormValue({ name, as, format, children, ...otherProps }) {
    const intl = useIntl();
    const form = useForm();
    
    if (form == null) {
        throw new Error('<FormValue> must be used within a <Form>');
    }
    
    // If we have children, ensure they're the proper type.
    if (children && typeof children !== 'function') {
        throw new Error('<FormValue> only supports render prop children');
    }

    // If we've been told the type of element to use (as prop), use it.
    // Otherwise, use <span> if we have props, React.Fragment otherwise.
    const Element = as 
        ?? (Object.keys(otherProps).length 
            ? 'span' 
            : React.Fragment);

    return (
        <Element {...otherProps}>
            <Choose>
                <When condition={children}>
                    {children(name ? form.getValue(name) : form.values, form)}
                </When>
                <Otherwise>
                    {getDisplayValue(form.getValue(name), format, intl)}
                </Otherwise>
            </Choose>
        </Element>
    );
}

FormValue.propTypes = {
    name: PropTypes.string,    
    as: PropTypes.any,
    format: PropTypes.oneOf(Object.keys(NUMBER_FORMATS)),
    children: PropTypes.func,
};

FormValue.defaultProps = {
    name: undefined,
    as: undefined,
    format: undefined,
    children: undefined,
};

function getDisplayValue(value, format, intl) {
    if (!(format in NUMBER_FORMATS)) {
        return value;
    }

    return getFormattedNumber(value, format, intl);
}

export default observer(FormValue);
