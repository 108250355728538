import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import ZIndex from '../definitions/ZIndex';


/* These styles are used as inline styles for our FullScreenTakeover - the library
    we're using requires this structure for the styles. Please don't use this with
    styled-components. */
export default {
    overlay: {
        backgroundColor: `${Colours.COCONUT}`,
        zIndex: ZIndex.MODALS,
    },
    content: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: `0 ${Sizes.SPACING.TWO}`,
    },
};
