import Colours from '../definitions/Colours';


const GIFT_OFFER_BANNER_COLOURS = [
    {
        background: 'rh-bg-grape-lightest',
        textColour: 'rh-fg-blackberry',
        // iconStroke: Colours.GRAPE_DARK (some for both varieties)
        iconFill: undefined,
    },
    {
        background: 'rh-bg-grape-dark',
        textColour: 'rh-fg-coconut',
        iconFill: Colours.GRAPE_LIGHTEST,
    },
];


export default GIFT_OFFER_BANNER_COLOURS;
