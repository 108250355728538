// NOTE: values are those used by the API
export default {
    BAGGAGE_DELAY:      'Baggage delay',
    BAGGAGE_LOSS:       'Baggage loss',
    CAR_RENTAL:         'Car rental collision / loss damage waiver',
    EXTENDED_WARRANTY:  'Extended warranty',
    FLIGHT_DELAY:       'Flight delay',
    LOUNGE_ACCESS:      'Lounge access',
    MOBILE_INSURANCE:   'Mobile insurance',
    NO_FOREX:           'No foreign exchange fees',
    PRICE_PROTECTION:   'Price protection',
    PURCHASE_ASSURANCE: 'Purchase assurance',
    TRAVEL_ACCIDENT:    'Travel accident insurance',
    TRAVEL_EMERGENCY:   'Travel emergency medical',
    TRIP_CANCELLATION:  'Trip cancellation',
    TRIP_INTERRUPTION:  'Trip interruption',
    HOTEL_MOTEL_BURGLARY:  'Hotel/motel burglary',
};
