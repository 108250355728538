import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import RemoveDefaultButtonStyles from '../styles/RemoveDefaultButtonStyles';
import messageToString from '../functions/messageToString';
import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import Dot from './Dot';


function TabListButton({ id, variant, size, label, isSelected, onClick, ...otherProps }) {
    const intl = useIntl();

    return (
        <Container
            id={id}
            type="button"
            isSelected={isSelected}
            aria-selected={isSelected}
            onClick={() => onClick?.()}
            variant={variant}
            size={size}
            role="tab"
            data-name="tabListButton-showTab"
            {...otherProps}
        >
            <Dot className="dot" />

            <span className="content">
                {messageToString(label, intl)}
            </span>
        </Container>
    );
}

TabListButton.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
};

TabListButton.defaultProps = {
    id: undefined,
    onClick: undefined,
    variant: 'dark',
    size: 'medium',
};

const TRANSITION_DURATION = '300ms';

const Container = styled.button`
    ${RemoveDefaultButtonStyles};

    position: relative;

    /* Using em in this component instead of rem so the
        styles can scale based on which size we're on */
    margin-bottom: 0.75em;
    padding-left: ${props => props.isSelected ? '1.25em' : '0'};

    color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};

    font-family: ${Typography.FONT_FAMILY_STACK};
    text-align: left;
    text-decoration: none;

    cursor: pointer;
    transition:
        padding-left ${TRANSITION_DURATION},
        color ${TRANSITION_DURATION};

    ${props => props.size === 'large' && `
        font-size: ${Sizes.FONTS['3XL']};
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        line-height: 36px;
        color: ${Colours.STONE_DARKEST};
    `}

    ${props => props.size === 'medium' && `
        font-size: ${Sizes.FONTS.XL};
        line-height: 30px;
    `}

    ${props => props.size === 'small' && `
        font-size: ${Sizes.FONTS.M};
        line-height: 24px;
    `}

    &:hover,
    &:focus {
        color: ${Colours.BLUEBERRY};
    }

    .dot {
        position: absolute;
        top: 0.35em;
        left: 0;

        width: 0.6em;
        height: 0.6em;

        transform: scale(0);
        transition: transform ${TRANSITION_DURATION};
    }

    ${props => props.isSelected && `
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};

        .dot {
            transform: scale(1);
        }
    `}
`;

export default TabListButton;
