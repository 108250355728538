import PRODUCT_TYPES from '../definitions/ProductTypes';


const TYPE_NAME_TO_PRODUCT_TYPE = {
    travel: PRODUCT_TYPES.TRAVEL,
    'cash-back': PRODUCT_TYPES.CASH_BACK,
    'low-interest': PRODUCT_TYPES.LOW_INTEREST,
    'balance-transfer': PRODUCT_TYPES.BALANCE_TRANSFER,
    'store-credit': PRODUCT_TYPES.STORE_CREDIT,

    secured: PRODUCT_TYPES.SECURED,

    // this will NOT be an API card comparison value, but this may be used when showing CardFinder results based
    // on a single card eligibility check
    'all': PRODUCT_TYPES.ANY_REWARDS,
};


/**
 * Maps an api product type name (as found in API response) into an entry within PRODUCT_TYPES
 * @param {string} apiTypeName
 * @return {string} a value from PRODUCT_TYPES, or undefined
 */
function getProductType(apiTypeName) {
    return TYPE_NAME_TO_PRODUCT_TYPE[apiTypeName];
}

export default getProductType;
