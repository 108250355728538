import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';

import Paragraph from './Paragraph';


function MagicLinkConfirmationScreen({ email, supportPhone, messages, className }) {
    return (
        <div
            className={className}
            data-name="magicLinkConfirmation"
        >
            <h1 className={classNames('rh-title-2xl', 'showDot', 'rh-my-1_5')}>
                <FormattedMessage
                    {...(
                        messages?.MAGIC_LINK_CONFIRMATION_TITLE
                            ?? MESSAGES.MAGIC_LINK_CONFIRMATION_TITLE
                    )}
                    values={{ email }}
                />
            </h1>

            <Paragraph
                as="h2"
                size="medium"
                message={(
                    <FormattedMessage
                        {...(
                            messages?.MAGIC_LINK_CONFIRMATION_CAPTION ?? MESSAGES.MAGIC_LINK_CONFIRMATION_CAPTION
                        )}
                        values={{ email, supportPhone }}
                    />
                )}
            />
        </div>
    );
}

MagicLinkConfirmationScreen.propTypes = {
    email: PropTypes.string.isRequired,
    supportPhone: PropTypes.string,
    className: PropTypes.string,

    messages: PropTypes.shape({
        MAGIC_LINK_CONFIRMATION_TITLE: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string,
        }),
        MAGIC_LINK_CONFIRMATION_CAPTION: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string,
        }),
    }),
};

MagicLinkConfirmationScreen.defaultProps = {
    className: undefined,
    supportPhone: '1-844-808-3379',
    messages: undefined,
};

const StyledMagicLinkConfirmationScreen = styled(MagicLinkConfirmationScreen)`
    text-align:center;
`;

const MESSAGES = defineMessages({
    MAGIC_LINK_CONFIRMATION_TITLE: {
        id: 'Accounts.MagicLinkConfirmationScreen.Title',
        defaultMessage: 'We have sent an email to {email} with a secure sign-in link',
    },
    MAGIC_LINK_CONFIRMATION_CAPTION: {
        id: 'Accounts.MagicLinkConfirmationScreen.Caption',
        defaultMessage: 'If you are experiencing any issues, please call us at {supportPhone}.',
    },
});

export default StyledMagicLinkConfirmationScreen;
