import { useCallback, useEffect, useRef } from 'react';


/**
 * Small utility hook to tell if a component is currently mounted.
 * Useful within async useEffect blocks and reactions to not make mutations after unmount.
 * @returns {() => boolean}
 */
function useIsMounted() {
    const isMounted = useRef(false);

    // Alter our mounted state when the mount cleanup is run.
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    // NOTE: must be a function to prevent stale references
    return useCallback(() => isMounted.current, []);
}

export default useIsMounted;