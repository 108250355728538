import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Paragraph } from '@ratehub/base-ui';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function HeadingBlock({
    title,
    introduction,
    subheadingAs,
    introductionAs,
    variant,
    isCentered,
    className,
}) {
    const showDot = !title?.slice(-1).match(/[?;!:]/);

    const TextElement = subheadingAs ? subheadingAs : 'h2';

    return (
        <If condition={title || introduction}>
            <header
                className={className}
            >
                <If condition={title}>
                    <TextElement
                        className={classNames(
                            'rh-title-l',
                            'rh-mt-0',
                            'rh-mb-1_5',
                            'rh-mx-0',
                            {
                                showDot: showDot,
                            },
                            isCentered ? 'rh-text-align-center' : 'rh-text-align-left',
                            variant === VARIANTS.DARK ? 'rh-fg-blackberry' : 'rh-fg-coconut',
                        )}
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                </If>

                <If condition={introduction}>
                    <Paragraph
                        variant={variant}
                        forwardedAs={introductionAs}
                        dangerouslySetInnerHTML={{
                            __html: introduction,
                        }}
                    />
                </If>
            </header>
        </If>
    );
}

HeadingBlock.propTypes = {
    title: PropTypes.string,
    introduction: PropTypes.string,
    subheadingAs: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    introductionAs: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    isCentered: PropTypes.bool,
    className: PropTypes.string,
};

HeadingBlock.defaultProps = {
    title: undefined,
    introduction: undefined,
    subheadingAs: undefined,
    introductionAs: 'p',
    variant: VARIANTS.DARK,
    isCentered: false,
    className: undefined,
};

export default HeadingBlock;
