import LayoutWidths from '../definitions/LayoutWidths';


/**
* @param {string} width value should correspond with keys in LayoutWidths
* @returns {string} .rh-layout-* grid column utility class
*/
function getLayoutWidthClassName(width = 'default') {
    return `rh-layout-${LayoutWidths[width]}`;
}

export default getLayoutWidthClassName;
