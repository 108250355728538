import { fetchChequing } from '@ratehub/inv-common';

import { Products } from '../definitions/Products';
import enrichDepositsProduct from './enrichDepositsProduct';


function enrichProduct(cmsEntry, apiEntry, slot) {
    // Enrich common fields with other deposits
    enrichDepositsProduct(cmsEntry, apiEntry, slot);

    Object.assign(slot, {
        productType: Products.CHEQUING,
    });
}


export default async function enrichChequingProducts(slots, options) {
    const response = await fetchChequing({
        products: slots.map(({ requested, fallback }) => ({
            requested: requested.map(product => ({
                id: product.id,
            })),
            fallback: {
                type: fallback?.type,
            },
        })),
        ...options,
    });

    // Support new and legacy return shape
    const products = response.data?.products ?? response.data;

    slots.forEach((slot, index) => {
        let matchedRate;

        for (let i = 0; i < slot.requested.length; i++) {
            const chequing = slot.requested[i];
            matchedRate = products.find(
                product => product?.id === chequing.id,
            );

            // Rates are not always featured, so a matched rate is not guaranteed
            if (matchedRate) {
                enrichProduct(chequing, matchedRate, slot);
                // We only need to enrich the first product we come across.
                break;
            }
        }

        // If no matchedRate is found, use fallback
        if (!matchedRate) {
            const fallbackRate = products[index];

            if (fallbackRate) {
                enrichProduct(slot.fallback, fallbackRate, slot);
            }
        }
    });
}
