import { Languages } from '@ratehub/base-ui';


/**
 * constructs a localized URL path that starts at the credit card home, and optionally appends
 * any additional path provided
 * @param {string} language accepts either languageCode or locale
 * @param {string} [ccSlug=CC_URL_SLUGS.BASE] a recognized key from within CC_URL_SLUGS
 * @param {string} [additionalPath='']
 * @return {string} URL path from root that includes the localized top-level credit card slug
 */
function getCreditCardURL(language, ccSlug = CC_URL_SLUGS.BASE, additionalPath = '') {
    language = language?.slice(0, 2);

    if (![ Languages.ENGLISH, Languages.FRENCH ].includes(language)) {
        throw new RangeError(`getCreditCardURL: unrecognized language code “${language}”`);
    }

    const pathForSlug = CC_URLS[ccSlug]?.[language];
    if (!pathForSlug) {
        throw new RangeError(`getCreditCardURL: unrecognized CC slug “${ccSlug}” • ${typeof ccSlug}`);
    }

    additionalPath = (
        additionalPath && additionalPath.slice(0, 1) !== '/'
            ? '/'
            : ''
    ) + additionalPath;

    return (ccSlug !== CC_URL_SLUGS.BASE
        ? CC_URLS[CC_URL_SLUGS.BASE][language]
        : '')
        + pathForSlug + additionalPath;
}


const CC_URLS = {
    BASE: {
        en: '/credit-cards',
        fr: '/cartes-de-credit',
    },
    REWARDS_CALCULATOR: {
        en: '/rewards-calculator',
        fr: '/calculatrice-de-recompenses',
    },
    SCENARIO_CHOOSER: {
        en: '/scenario-chooser',
        fr: '/selecteur-de-scenario',
    },
    CARDFINDER: {
        en: '/cardfinder',
        fr: '/chercheur-des-cartes',
    },
    ELIGIBILITY_CHECKER: {
        en: '/cardfinder/eligibility-checker',
        fr: '/chercheur-des-cartes/controleur-d-eligibilite',
    },
    APPLY: {
        en: '/apply',
        fr: '/demande',
    },
    DETAILS: {
        en: '/card',
        fr: '/carte',
    },
    CARD_COMPARE: {
        en: '/card-compare',
        fr: '/comparaison-cartes',
    },
};

const CC_URL_SLUGS = Object.keys(CC_URLS).reduce((slugs, thisSlug) => ({
    ...slugs,
    [thisSlug]: thisSlug,
}), {});



export { CC_URL_SLUGS };
export default getCreditCardURL;
