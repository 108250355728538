export default {
    GROCERIES: 'groceries',
    GAS: 'gas',
    RESTAURANTS: 'restaurants',
    BILLS: 'bills',
    TRAVEL: 'travel',
    ENTERTAINMENT: 'entertainment',
    PHARMACY: 'pharmacy',
    OTHER: 'other',
};
