import {
    fetchHistoricalData,
    noticeError,
} from '@ratehub/base-ui';


async function fetchChartSeries({ series, xFormat = 'datetime', xStart, xEnd, language }) {
    if (!hasAtLeastOneValidSeries(series)) {
        return;
    }
    let allSeriesCollections;
    try {
        const seriesSlugs = series.map(({ dataSource }) => dataSource);
        allSeriesCollections = await Promise.all(createSeriesCollections(seriesSlugs).map(async (seriesCollection) => {
            return await fetchHistoricalData({
                series: seriesCollection,
                ...(xStart && { xStart: xStart.trim() }),
                ...(xEnd && { xEnd: xEnd.trim() }),
                ...(xFormat && { xFormat: xFormat.trim() }),
                language,
            });
        }));

        return series.map(({ name, dataSource: slug, stats, yAxis, isVisible, ...others }) => ({
            ...others,
            yAxis: ~~yAxis, // convert to number, [0 | 1]
            visible: isVisible,
            name,
            data: buildSeriesData(allSeriesCollections, slug, xFormat),
        }));

    } catch (error) {
        noticeError(error, {
            message: '[fetchChartProps] Failed to fetch chart data return props for chart',
            series,
            ...(xStart && { xStart: xStart.trim() }),
            ...(xEnd && { xEnd: xEnd.trim() }),
            ...(xFormat && { xFormat: xFormat.trim() }),
            language,
            allSeriesCollections,
        });
        throw error;
    }
}

// Find the series data based on the slug and return the series data and x axis data
function findSeriesData(collections, slug) {
    return collections.reduce((collection, { series, x: { data: xData } }) => (
        Object.prototype.hasOwnProperty.call(series, slug)
            ? { series: series[slug].data, xData }
            : collection
    ), {});
}

// Build the series data based on the series collection found by slug and format the x value
function buildSeriesData(seriesCollection, slug, xFormat){
    const seriesData = findSeriesData(seriesCollection, slug);
    return seriesData.series
        && seriesData.series.map((ySet, index) => (
            [ formatXValue(seriesData?.xData[index], xFormat), ySet ]
        ));
}

// Format the series into an array of arrays based on folder/file structure in GCS
// eg. ['mtg.income.toronto', 'best.5y-fixed', 'best.3y-fixed', mtg.income.vancouver] => [['mtg-income-toronto', 'mtg.income.vancouver'], ['best.5y-fixed', 'best.3y-fixed']]
function createSeriesCollections(series) {
    return Object.values(series.reduce((seriesCollection, value) => {
        if (!value) {
            return seriesCollection;
        }

        // remove the last part of the string
        const seriesKey = value.split('.').slice(0, -1).join('.');

        return Object.prototype.hasOwnProperty.call(seriesCollection, seriesKey)
            // if the key exists, push the value to the array and return the collection
            ? { ...seriesCollection, [seriesKey]: [ ...seriesCollection[seriesKey], value ] }
            // if the key does not exist, create a new key with the value as an array
            : { ...seriesCollection, [seriesKey]: [ value ] };
    }, {}));
}

function formatXValue(value, xFormat) {
    // linear, logarithmic, datetime or category
    switch (xFormat) {
        case 'datetime':
            return Date.parse(value);
        default:
            return value;
    }
}

function hasAtLeastOneValidSeries(series) {
    return !!Array.isArray(series)
        && series.length
        && series.find(seriesItem => seriesItem.dataSource);
}

export default fetchChartSeries;
