import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    StarRating,
    Paragraph,
    Sizes,
    Colours,
    Caption,
} from '@ratehub/base-ui';


const MIN_COLUMN_WIDTH = '12.5rem';
const MAX_COLUMN_WIDTH = '37.5rem';

function CustomerReviewItem({
    rating,
    title,
    content,
    author,
    address,
    ...otherProps
}) {
    return (
        <li {...otherProps}>
            <StarRating
                ratingPercent={rating * 20}
                starSize={Sizes.FONTS.M}
            />
            <h3 className={classNames('rh-title-s', 'rh-mt-1', 'rh-mr-0', 'rh-mb-0', 'rh-ml-0', 'rh-text-l', 'main-title', 'weight-medium')}>
                {title}
            </h3>
            <hr className="split-line" />
            <Paragraph
                className="review-content-article"
                size="medium"
                message={content}
            />
            <h4 className={'rh-title-2xs rh-mt-1_5 rh-mb-0 weight-medium name-title'}>
                {author}
            </h4>
            <Caption
                weight="regular"
                message={address}
            />
        </li>
    );
}

CustomerReviewItem.propTypes = {
    rating: PropTypes.number.isRequired, // The rate should start from 0 to 5
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
};

const StyledCustomerReviewItem = styled(CustomerReviewItem)`
    flex-grow: 1;

    width: 100%;
    min-width: ${MIN_COLUMN_WIDTH};
    max-width: ${MAX_COLUMN_WIDTH};

    > .main-title {
        line-height: ${Sizes.FONTS['2XL']};
    }

    > .split-line {
        margin: ${Sizes.SPACING.TWO} 0 ${Sizes.SPACING.TWO_AND_A_HALF} 0;
        width: 100%;
        background-color: ${Colours.STONE_DARK};
    }

    > .review-content-article {
        margin: 0 auto ${Sizes.SPACING.THREE};
        line-height: 1.75rem;
    }

    > .name-title {
        line-height: 1.25rem;
    }
`;

StyledCustomerReviewItem.blockKey = 'rh/customer-review-item';

export default StyledCustomerReviewItem;
