import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


// The max number of tiles we will try to show in one single row on desktop.
const MAX_TILES_DESKTOP = 6;

const MAX_TILE_WIDTH_DESKTOP = 336;

function StoreFrontContainer({ heading, mainTitle, children, ...otherProps }) {
    // support both heading object or standalone heading props until FIT-1272
    const headingBlockProps = heading ? heading : { title: mainTitle };
    const hasAnyHeading = mainTitle || heading?.mainTitle;

    const numOfChildren = React.Children.count(children);
    const numOfColumns = numOfChildren <= MAX_TILES_DESKTOP
        ? numOfChildren
        : Math.ceil(numOfChildren / 2);

    return (
        <StoreFrontContainer.Container
            {...otherProps}
        >
            <HeadingBlock
                {...headingBlockProps}
            />

            <StoreFrontContainer.List
                alignLeft={hasAnyHeading}
                numOfChildren={numOfChildren}
                numOfColumns={numOfColumns}
            >
                {children}
            </StoreFrontContainer.List>
        </StoreFrontContainer.Container>
    );
}

StoreFrontContainer.propTypes = {
    heading: HeadingPropTypes,
    mainTitle: PropTypes.string,
    children: PropTypes.array.isRequired,
};

const BREAKPOINT_LARGE = '60rem'; // matches breakpoint in StoreFrontItem
const BREAKPOINT_SMALL = '40rem'; // matches breakpoint in StoreFrontItem

StoreFrontContainer.Container = styled.div``;

StoreFrontContainer.List = styled.ul`
    // 1 column
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    justify-content: center;
    grid-auto-rows: 1fr;

    list-style: none;

    margin: 0;
    padding: 0;

    // Tablet
    @media screen and (min-width: ${BREAKPOINT_SMALL}) and (max-width: ${BREAKPOINT_LARGE}) {
        // Two-col layout, displayed as 4 columns in order to allow centering odd item at the end.
        grid-template-columns: repeat(4, 1fr);

        & > li {
            grid-column: span 2;

            // Center dangling last item by offsetting it's grid placement.
            &:nth-last-child(1 of .store-front-item) {
                &:nth-child(odd of .store-front-item) {
                    grid-column: 2 / span 2;
                }
            }
        }
    }

    // Desktop
    @media screen and (min-width: ${BREAKPOINT_LARGE}) {
        // Single row.
        ${props => (props.numOfChildren <= MAX_TILES_DESKTOP) && `
            grid-template-columns: repeat(${props.numOfColumns}, minmax(100px, ${MAX_TILE_WIDTH_DESKTOP}px));
        `}

        // Two rows, with items distributed across the rows as evenly as possible.
        ${props => (props.numOfChildren > MAX_TILES_DESKTOP) && `
            grid-template-columns: repeat(${props.numOfColumns * 2}, minmax(50px, calc(${MAX_TILE_WIDTH_DESKTOP / 2}px - .5rem)));

            & > li {
                grid-column: span 2;

                // Center all second row items by offsetting their grid placement.
                ${!props.alignLeft && `
                    &:nth-last-child(${props.numOfColumns - 1} of .store-front-item) {
                        &:nth-child(${props.numOfColumns % 2 === 0 ? 'odd' : 'even'} of .store-front-item) {
                            grid-column: 2 / span 2;
                        }
                    }
                `}
            }
        `}

        justify-content: ${props => props.alignLeft ? 'flex-start': 'center'};
    }
`;

StoreFrontContainer.blockKey = 'rh/store-front';
StoreFrontContainer.hasHeading = true;
StoreFrontContainer.requiresLayoutRow = true;

export default StoreFrontContainer;
export { BREAKPOINT_LARGE };
