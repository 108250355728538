import CREDIT_SCORES from '../definitions/CreditScores';


const SLUG_TO_CREDIT_SCORE_RANGE = {
    // Note: max for EXCELLENT is NOT very meaningful - @TODO should it be?
    // there are different CS models, but FICO 8 tops out at 850
    [CREDIT_SCORES.EXCELLENT]: { min: 760, max: 760 },
    [CREDIT_SCORES.VERY_GOOD]: { min: 725, max: 759 },
    [CREDIT_SCORES.GOOD]:      { min: 660, max: 724 },
    [CREDIT_SCORES.FAIR]:      { min: 560, max: 659 },
    [CREDIT_SCORES.POOR]:      { min: 300, max: 559 },
    [CREDIT_SCORES.NONE]:      { min: 0, max: 299 },
};

/**
 * Returns the low and high credit score values we use for a given credit score range
 * @param {string} apiSlug as found in CREDIT_SCORES
 * @return {{min: number, max: number}}
 */
function getCreditScoreRange(apiSlug) {
    return SLUG_TO_CREDIT_SCORE_RANGE[apiSlug];
}


export default getCreditScoreRange;
export { SLUG_TO_CREDIT_SCORE_RANGE };
