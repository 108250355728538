/* A list of page content block attributes that are available on blocks */
// NOTE: IF YOU HAPPEN TO UPDATE THIS LIST,
//       PLEASE ALSO UPDATE THE LIST IN packages/wordpress-blocks/src/definitions/pageContentBlockAttrs.js
export default {
    BACKGROUND_COLOUR: 'backgroundColour',
    VERTICAL_PADDING: 'verticalPadding',
    VERTICAL_PADDING_ABOVE: 'verticalPaddingAbove',
    VERTICAL_PADDING_BELOW: 'verticalPaddingBelow',
    POSITION: 'position',
    ID: 'id',
    TABLE_OF_CONTENTS_EXCLUDE: 'tableOfContentsExclude',
    TABLE_OF_CONTENTS_TITLE: 'tableOfContentsTitle',
    INCLUDE_CONTENT_PADDING: 'includeContentPadding',
    BLOCK_LOCATION: 'blockLocation',
    PCB_OVERFLOW_X: 'pcbOverflowX',
    LAYOUT_WIDTH: 'layoutWidth',
};
