import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MessagePropType from '../definitions/MessagePropType';
import Colours from '../definitions/Colours';
import ACCORDION_VARIANTS from '../definitions/AccordionVariants';
import AccordionBase from './AccordionBase';
import AccordionBasicItemHeader from './AccordionBasicItemHeader';
import AccordionBlueberryHeader from './AccordionBlueberryHeader';


function AccordionBasicItem({
    isExpanded,
    onClick,
    isInitiallyExpanded,

    // Rendered content
    title,

    children,
    'data-name': dataName,

    // Customization options for title
    titleColour,

    showDot,
    iconKey,

    // Customization options for content
    alwaysRenderChildMarkup,
    variant,

    ...otherProps
}) {
    const isDefaultVariant = variant === ACCORDION_VARIANTS.DEFAULT;
    const { HeaderComponent, accordionBodyClass } = getHeaderAndBodyCSS(variant);

    return (
        <AccordionBase
            isExpanded={isExpanded}
            isInitiallyExpanded={isInitiallyExpanded}
            onClick={onClick}
            HeaderComponent={HeaderComponent}
            headerProps={{
                title,
                'data-name': dataName,
                titleColour,

                iconKey: isDefaultVariant ? iconKey : undefined,
                showDot: isDefaultVariant ? showDot : false,
                variant,
            }}
            shouldIncludeDivider={isDefaultVariant}
            alwaysRenderChildMarkup={alwaysRenderChildMarkup}
            {...otherProps}
        >
            <div
                className={classNames(accordionBodyClass, {
                    'rh-my-0 rh-pb-1_5 rh-pl-0_75': isDefaultVariant,
                    'rh-pr-1_5': isDefaultVariant && (showDot || iconKey),
                })}
            >
                {children}
            </div>
        </AccordionBase>
    );
}

AccordionBasicItem.propTypes = {
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,
    isInitiallyExpanded: PropTypes.bool,

    title: MessagePropType.isRequired,

    children: PropTypes.node.isRequired,
    'data-name': PropTypes.string,

    titleColour: PropTypes.oneOf([ Colours.BLACKBERRY, Colours.BLUEBERRY_DARK ]),

    showDot: PropTypes.bool,
    iconKey: PropTypes.string,

    alwaysRenderChildMarkup: PropTypes.bool,

    variant: PropTypes.oneOf(Object.values(ACCORDION_VARIANTS)),
};

AccordionBasicItem.defaultProps = {
    isExpanded: undefined,
    onClick: undefined,
    isInitiallyExpanded: false,

    'data-name': 'Accordion',

    titleColour: Colours.BLACKBERRY,

    showDot: false,
    iconKey: undefined,

    alwaysRenderChildMarkup: false,

    variant: ACCORDION_VARIANTS.DEFAULT,
};


const ACCORDION_SETTINGS = {
    [ACCORDION_VARIANTS.DEFAULT]: {
        HeaderComponent: AccordionBasicItemHeader,
        accordionBodyClass: undefined,
    },
    [ACCORDION_VARIANTS.MINIMAL]: {
        HeaderComponent: AccordionBasicItemHeader,
        accordionBodyClass: undefined,
    },
    [ACCORDION_VARIANTS.BLUEBERRY]: {
        HeaderComponent: AccordionBlueberryHeader,
        accordionBodyClass: 'rh-accordion-blueberry-body',
    },
};

function getHeaderAndBodyCSS(variant) {
    if (!ACCORDION_SETTINGS[variant]) {
        throw new RangeError(`AccordionBasicItem - unexpected variant: “${variant}”.
              It should be one of [ “${Object.values(ACCORDION_VARIANTS).join('”, “')}” ].`);
    }

    return ACCORDION_SETTINGS[variant];
}


export default observer(AccordionBasicItem);
