import React from 'react';

import Text from './Text';


function TextBody({ ...otherProps }) {
    return (
        <Text
            {...otherProps}
        />
    );
}

TextBody.blockKey = 'rh/text-body';
TextBody.alignmentOptions = Text.alignmentOptions;
TextBody.hasSpacingOptions = Text.hasSpacingOptions;


export default TextBody;
