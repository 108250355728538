import queryString from 'query-string';

import { Config, BusinessUnits, fetchWithErrorHandling } from '@ratehub/base-ui';

import getLanguage from './getLanguageCodeFromOptions';


/* This maps our BU names to categories and tags which exist in the CMS */
const BUSINESS_UNIT_TO_TERMS_MAP = {
    [BusinessUnits.MORTGAGES]: { en: 250, fr: 282 },
    [BusinessUnits.CREDIT_CARDS]: { en: 226, fr: 266 },
    [BusinessUnits.BANKING]: { en: { tags: [ 3655, 3652, 4457, 4455 ] }, fr: [ 274, 272 ] },
    [BusinessUnits.INVESTING]: { en: { tags: [ 4112, 4685, 3264 ] }, fr: 278 },
    [BusinessUnits.INSURANCE]: { en: 236, fr: 219 },
    [BusinessUnits.CRYPTO]: { en: { tags: [ 2887 ] } },
};

// Blog Test Post ID, purely for testing purposes. We don't want this to ever appear on the site.
const TEST_POST_ID = Config.BLOG_TEST_POST_ID;

/**
 * Fetches blog posts from a service
 * @param {object} options object to extract from.
 * @param {string} options.businessUnit business unit slug
 * @param {string} options.order 'asc' or 'desc'
 * @param {string} options.orderby criteria to sort by https://developer.wordpress.org/reference/classes/wp_query/#order-orderby-parameters
 * @param {number} options.page page number
 * @param {number} options.perPageCount number of items to request per page
 * @param {string} options.language language code to use
 * @param {string} options.categories comma separated list of IDs
 * @param {string} options.tags comma separated list of IDs
 * @param {string} options.include comma separated list of IDs
 * @returns {[object]}
 */
async function fetchBlogPosts(options) {
    const businessUnit = options.businessUnit ?? BusinessUnits.ALL;
    const language = getLanguage(options);

    const params = {
        'status':   'publish',  // WP default
        'order':    options.order ?? 'desc',     // WP default
        'orderby':  options.orderby ?? 'date', // WP default is 'date' which is the published date
        'page': options.page ?? 1,
        'per_page': options.perPageCount ?? 6, // WP default is 10
        'lang': language,
        'include': options.include?.replace(new RegExp(TEST_POST_ID,'g'), ''), // Remove this ID if it exists here
        'exclude': options.postsToExclude
            ? [ TEST_POST_ID, ...options.postsToExclude ]
            : TEST_POST_ID,
    };

    if (options.tags || options.categories) {
        params.tags = options.tags;
        params.categories = options.categories;
    } else if (businessUnit !== BusinessUnits.ALL && BUSINESS_UNIT_TO_TERMS_MAP[businessUnit]) {
        const terms = BUSINESS_UNIT_TO_TERMS_MAP[businessUnit][language];

        if (terms.tags) {
            params.tags = Array.isArray(terms.tags)
                ? terms.tags.join(',')
                : terms.tags;
        } else {
            params.categories = Array.isArray(terms)
                ? terms.join(',')
                : terms;
        }
    }

    const response = await fetchPosts({
        url: Config.BLOG_LATEST_API_URL,
        params: params,
    }, 'fetchBlogPosts');

    return response;
}

// Very similar to fetchJSON but we need to get some information from the header
async function fetchPosts(target, callerName = '') {
    let url = target.url || target;

    if (target.params) {
        url += '?' + queryString.stringify(target.params, {
            arrayFormat: target.arrayFormat || 'bracket',
        });
    }

    try {
        const response = await fetchWithErrorHandling(url);


        const totalPages = response.headers.get('X-WP-TotalPages');
        const text = await response.text();

        try {
            return {
                totalPages: totalPages,
                posts: JSON.parse(text),
            };
        } catch {
            throw new Error(`Response couldn't be parsed as JSON. Received: ${text} from ${url}`);
        }
    } catch (e) {
        // Write a helpful debugging message. Helpful when running minified on the server.
        // eslint-disable-next-line
        console.error(`${callerName} failed when fetching JSON from URL:${url}\nError:`, e);

        // Re-throw to allow for proper handling.
        throw e;
    }
}

export default fetchBlogPosts;
