import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PrimaryAnchor, Sizes } from '@ratehub/base-ui';

import { ORDERED_LIST_VARIANT } from '../definitions/OrderedListType';
import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


const LIST_TYPES = {
    ORDERED: 'ordered',
    UNORDERED: 'unordered',
};

function ListContainer({
    variant,
    listType,
    heading,
    initialListCounterValue,
    ctaHref,
    ctaTitle,
    className,
    children,
    ...otherProps
}) {
    const ListComponent = listType === LIST_TYPES.UNORDERED
        ? 'ul'
        : 'ol';

    return (
        <Container
            variant={variant}
            listType={listType}
            initialListCounterValue={initialListCounterValue}
            className={classNames(
                'rh-m-auto',
                variant === ORDERED_LIST_VARIANT.CIRCLE
                    ? 'rh-max-width-500px'
                    : 'rh-max-width-800px',
                className,
            )}
            {...otherProps}
        >
            <HeadingBlock
                {...heading}
            />

            <ListComponent
                className={classNames(
                    'list rh-pl-0',
                    variant === ORDERED_LIST_VARIANT.CIRCLE
                        ? 'rh-mt-2_5'
                        : 'rh-mt-0',
                )}
            >
                {children}
            </ListComponent>

            <If condition={ctaHref && ctaTitle}>
                <PrimaryAnchor
                    className={classNames('list-btn rh-m-0 rh-mt-3')}
                    size="medium"
                    variant="blueberry-dark"
                    href={ctaHref}
                >
                    {ctaTitle}
                </PrimaryAnchor>
            </If>
        </Container>
    );
}

ListContainer.propTypes = {
    variant: PropTypes.oneOf(Object.values(ORDERED_LIST_VARIANT)),
    listType: PropTypes.oneOf(Object.values(LIST_TYPES)),
    heading: HeadingPropTypes,
    initialListCounterValue: PropTypes.number,
    ctaHref: PropTypes.string,
    ctaTitle: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

ListContainer.defaultProps = {
    variant: ORDERED_LIST_VARIANT.DEFAULT,
    listType: LIST_TYPES.ORDERED,
    heading: undefined,
    initialListCounterValue: 0,
    ctaHref: undefined,
    ctaTitle: undefined,
    className: undefined,
};

ListContainer.LIST_TYPES = LIST_TYPES;

/* The combined width of the marker & the gutter - matches LIST_INDENT in
ListItem component */
const LIST_INDENT = `calc(${ Sizes.SPACING.FOUR } + 4%)`;

const Container = styled.div`
    > .list {
        list-style-type: none;

        ${props => typeof props.initialListCounterValue !== 'undefined' && `
            counter-reset: item ${props.initialListCounterValue};
        `}
    }

    > .list-btn {
        margin-left: ${props => props.listType === LIST_TYPES.ORDERED && `${LIST_INDENT}`};
        max-width: 19rem;
    }
`;

ListContainer.blockKey = 'rh/list-container';
ListContainer.hasHeading = true;
ListContainer.requiresLayoutRow = true;

export default ListContainer;
