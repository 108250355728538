import { Config, ImageSizes } from '@ratehub/base-ui';


export default {
    imageCollection: {
        mime: 'image/jpeg',
        sizes: {
            [ImageSizes.FULL]: {
                url: `${Config.RATEHUB_BASE_URL}/static/FB-opengraph-1200x630.jpg`,
                mime: 'image/jpeg',
            },
        },
    },
    imageAlt: '',
};
