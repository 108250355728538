import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconGarbage({ stroke, fill, ...otherProps }){
    return (
        <IconGarbage.Svg
            viewBox="0 0 18 17"
            strokeColour={stroke}
            fillColour={fill}
            {...otherProps}
        >
            <path
                d="M3.5 3.5v13-13zM7 6v8-8zm4 0v8-8zm3.5-2.5v13-13zM1 3.5h16H1zM4 16h10H4zM7 1.5h4-4z"
                fillRule="evenodd"
            />
        </IconGarbage.Svg>
    );
}

IconGarbage.propTypes = {
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconGarbage.defaultProps = {
    stroke: Colours.BLACKBERRY,
    fill: Colours.TRANSPARENT,
};

IconGarbage.Svg = styled.svg`
    stroke-width: 1px;
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    transition: 
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconGarbage;

