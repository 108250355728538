import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Sizes from '../definitions/Sizes';
import ImageSizesByType from '../definitions/ImageSizesByType';
import { useCondensedTheme } from './CondensedTheme';


function ProviderDetails({
    providerIcon,
    providerName,
    providerDescription,

    isRatehubCompany = false,
    isLogoWrapped = true,
    hasLogoBorder = false,

    size,

    className,
    ...otherProps
}) {
    const isCondensed = useCondensedTheme();
    const isNarrow = size?.width <= MAX_WIDTH;

    return (
        <Container
            {...otherProps}
            className={classNames(className, 'rh-display-flex rh-flex-nowrap rh-align-items-center', {
                'is-narrow': isNarrow,
                'wrap-logo': isLogoWrapped,
            })}
        >
            <Choose>
                <When condition={providerIcon}>
                    <LazyLoadImage
                        className={classNames('image rh-height-auto rh-box-sizing-border-box rh-flex-shrink-0', {
                            'show-border rh-border-width-1px rh-border-style-solid rh-border-color-stone rh-border-radius-3px': hasLogoBorder,
                            'rh-mr-0': hasLogoBorder && isNarrow,
                        })}
                        src={providerIcon}
                        alt={providerName}
                        height={ImageSizesByType.PROVIDERS.BADGE_SMALL.HEIGHT}
                        width={ImageSizesByType.PROVIDERS.BADGE_SMALL.WIDTH}
                    />
                </When>
                <Otherwise>
                    <div className="rh-icon-l" />
                </Otherwise>
            </Choose>

            <div className="main-detail">
                <p
                    className={classNames(
                        'provider-title',
                        'rh-my-0',
                        'weight-medium',
                        'rh-fg-blackberry',
                        getProviderNameTextClass(size, isCondensed),
                    )}
                >
                    {providerName}
                </p>
                <p
                    className={classNames('description', 'rh-my-0', {
                        'rh-text-s': !isCondensed,
                        'rh-text-xs': isCondensed,
                    })}
                >
                    <Choose>
                        <When condition={isRatehubCompany}>
                            <FormattedMessage
                                id="base-ui.provider-details.ratehub-company-byline"
                                defaultMessage="A Ratehub Company"
                            />
                        </When>
                        <When condition={providerDescription}>
                            {providerDescription}
                        </When>
                    </Choose>
                </p>
            </div>
        </Container>
    );
}

ProviderDetails.propTypes = {
    providerIcon: PropTypes.string,
    providerName: PropTypes.string,
    providerDescription: PropTypes.string,

    isRatehubCompany: PropTypes.bool,
    isLogoWrapped: PropTypes.bool,
    hasLogoBorder: PropTypes.bool,

    className: PropTypes.string,

    size: PropTypes.object.isRequired,
};


// max width to change the size of provider detail is 230px
const MAX_WIDTH = 230;

const WEB_WIDTH = 300; // in px
const MOBILE_WIDTH = 200; // in px

function getProviderNameTextClass(size, isCondensed) {
    if (isCondensed) {
        return size.width > WEB_WIDTH
            ? 'rh-text-m'
            : 'rh-text-s';
    }

    return size.width > WEB_WIDTH
        ? 'rh-text-xl'
        : size.width > MOBILE_WIDTH
            ? 'rh-text-m'
            : 'rh-text-s';
}

const Container = styled.div`
    &.wrap-logo {
        flex-wrap: wrap;
    }

    > .image {
        object-fit: contain;

        width: ${Sizes.ICONS.L};
        height: auto;

        margin-right: 0.625rem;

        &.show-border {
            height: ${Sizes.ICONS.L};
            padding: 3px;
        }
    }

    > .main-detail {
        .provider-title {
            line-height: 1.4;
            white-space: break-spaces;
        }
    }

    /* When size <= MAX_WIDTH */
    &.is-narrow {
        > .image {
            width: ${Sizes.ICONS.S};
            margin-right: 0.3125rem;

            &.show-border {
                height: ${Sizes.ICONS.S};
            }
        }
    }
`;

export default withSize({ noPlaceholder: true })(ProviderDetails);
