import { isObservableArray } from 'mobx';


// Deeply compare if strings, numbers, arrays, or objects are the same / have the same values
function hasSameValues(one, two) {
    // Check if values are the same or share the same spot in memory
    // This will catch primitives and functions sharing the same spot in memory
    if (one === two) {
        return true;
    }

    // 'null' is of type object, so a direct assertion here can avoid the later 'if' blocks.
    // Only functions that point to the same spot in memory will return true
    if (one === null || two === null) {
        return false;
    }

    // Check that values are the same type
    if (typeof one !== typeof two) {
        return false;
    }

    // The following 'if' statements are in place because
    // a typeof 'object' could be any of: null, date, array, or object.
    // 'null' is checked above, so the remaining types needs to be checked individually.
    // Order for checks should be: Date > Array > Object

    // Compare values if they are Date objects
    if (one instanceof Date && two instanceof Date) {
        return one.getTime() === two.getTime();
    }

    // Recursively compare values if they are arrays
    if (isArray(one) && isArray(two)) {
        return compareArrays(one, two);
    }

    // Make sure arrays don't get mixed with objects in the next block
    if (isArray(one) || isArray(two)) {
        return false;
    }
    
    // Recursively compare values as objects
    if (typeof one === 'object' && typeof two === 'object') {
        return compareObjects(one, two);
    }
    
    return false;
}

function compareArrays(one, two) {
    // Check arrays have the same length
    if (one.length !== two.length) {
        return false;
    }
    // Check arrays are not empty
    if (one.length === 0) {
        return true;
    }
    // Check items if only one entry
    if (one.length === 1) {
        return hasSameValues(one[0], two[0]);
    }
    // Check the first item, then compare the rest of the array
    return (
        hasSameValues(one[0], two[0])
        && hasSameValues(one.slice(1), two.slice(1))
    );
}

function compareObjects(one, two) {
    // Check list of object keys have the same length
    if (Object.keys(one).length !== Object.keys(two).length) {
        return false;
    }
    // Check objects are not empty
    if (Object.keys(one).length === 0) {
        return true;
    }
    // Check key values are the same if only one entry
    if (Object.keys(one).length === 1) {
        return hasSameValues(one[Object.keys(one)[0]], two[Object.keys(one)[0]]);
    }
    // Prepare new objects for comparison, removing the same key from both
    let newOne = { ...one };
    delete newOne[Object.keys(one)[0]];

    let newTwo = { ...two };
    delete newTwo[Object.keys(one)[0]];

    // Check the same key on both objects, then compare the rest of the object
    return (
        hasSameValues(one[Object.keys(one)[0]], two[Object.keys(one)[0]])
        && hasSameValues(newOne, newTwo)
    );
}

// Mobx observable arrays return false with Array.isArray, so
//  we have to have a custom check
function isArray(value) {
    return Array.isArray(value) || isObservableArray(value);
}

export default hasSameValues;
