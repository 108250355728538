import SPENDING_CATEGORY_DEFAULTS, { TOTAL_DEFAULT_SPENDING } from './SpendingCategoryDefaults';


export default {
    monthlySpendingAverage: TOTAL_DEFAULT_SPENDING,
    monthlySpending: SPENDING_CATEGORY_DEFAULTS,
    lowInterestMonthlyBalance: 500,
    balanceTransferAmount: 3_900,
    securedMonthlyBalance: 500,
    income: 80_000,
    noAnnualFee: false,
    requiresGiftOffer: false,
    features: [],
};
