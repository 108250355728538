import { css } from 'styled-components';
import { rgba } from 'polished';

import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import {
    BORDER_RADIUS,
    BOX_SHADOW_STONE,
    BOX_SHADOW_BLUEBERRY,
    INPUT_HORIZONTAL_PADDING,
    INPUT_HORIZONTAL_PADDING_CONDENSED,
    INPUT_HEIGHT,
    INPUT_HEIGHT_CONDENSED,
} from '../definitions/SharedInputStyles';
import ZIndex from '../definitions/ZIndex';
import RemoveDefaultButtonStyles from './RemoveDefaultButtonStyles';


const TRANSITION_DURATION = '300ms';
const EXTENDED_TRANSITION_DURATION = '500ms';
const TRANSITION_TIME = TRANSITION_DURATION;

export default css`
    position: relative;

    font-family: ${Typography.FONT_FAMILY_STACK};
    font-weight: ${Typography.WEIGHTS.REGULAR};
    font-size: ${Sizes.FONTS.S};

    &.is-dropdown {
        > .select-combobox,
        > .select-menu {
            &, > * {
                cursor: pointer;
            }
        }
    }

    > .select-combobox {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: ${INPUT_HEIGHT};
        box-sizing: border-box;
        outline: none;

        border-style: solid;
        border-width: 1px;
        border-radius: ${BORDER_RADIUS};
        border-color: ${Colours.STONE_DARK};

        background-color: ${Colours.COCONUT}; /* cover the space between input and button in mobile view */

        transition:
            border-color ${TRANSITION_DURATION},
            background-color ${TRANSITION_DURATION},
            box-shadow ${TRANSITION_DURATION};

        > .select-input,
        > .select-value {
            width: 100%;
            height: unset;
            line-height: 1.65; /** set line-height to 1.65 would avoid clip on letter below line */

            border: 0;
            outline: none;
            appearance: none;

            font-family: ${Typography.FONT_FAMILY_STACK};
            font-size: ${Sizes.FONTS.S};

            color: ${Colours.BLACKBERRY};
            background-color: ${Colours.COCONUT};
            caret-color: ${Colours.BLUEBERRY_DARK};

            text-overflow: ellipsis;

            /* color only swaps between disabled and regular states
                this extended duration helps make this change more smooth */
            transition: color ${EXTENDED_TRANSITION_DURATION};

            &::placeholder {
                color: ${Colours.STONE_DARKEST};
                transition: color ${TRANSITION_DURATION};
            }

            &:disabled {
                color: ${Colours.STONE};

                &::placeholder {
                    color: ${Colours.STONE};
                }
            }
        }

        > .select-value {
            display: inline-flex;
            align-items: center;
        }

        > .select-open-menu-button {
            ${RemoveDefaultButtonStyles};

            display: flex;
            justify-content: space-between;
            align-items: center;

            height: 100%;
            padding: 0 ${INPUT_HORIZONTAL_PADDING} 0 0;
            box-sizing: border-box;

            background-color: ${Colours.COCONUT};

            > .icon-chevron {
                stroke: ${Colours.STONE_DARK};
                transition: stroke ${TRANSITION_DURATION};
            }
        }

        /* HOVER, FOCUS, ACTIVE ENABLED STYLES */
        &:hover,
        &:focus-within,
        &:active {
            border-color: ${Colours.BLUEBERRY_DARK};

            > .select-open-menu-button > .icon-chevron {
                stroke: ${Colours.BLUEBERRY_DARK}
            }
        }

        &:hover {
            box-shadow: ${BOX_SHADOW_STONE};

            > .select-input {
                &::placeholder {
                    color: ${Colours.BLUEBERRY_DARK};
                }
            }
        }

        /* Need a focus within check here since we have an input and a button within that
            can be focused */
        &:focus-within {
            box-shadow: ${BOX_SHADOW_BLUEBERRY};
        }
    }

    /* Inline styles */
    &.is-inline {
        font-size: ${Sizes.FONTS.M};

        > .select-combobox {
            border-style: solid;
            border-width: 0;
            border-bottom-width: 1px;
            border-radius: 0;
            border-color: ${Colours.BLACKBERRY};

            background-color: ${Colours.TRANSPARENT};

            cursor: default;

            display: inline-flex;

            width: fit-content;
            height: ${Sizes.SPACING.TWO};
            box-sizing: content-box;

            > .select-input,
            > .select-value {
                padding: 0;
                font-size: ${Sizes.FONTS.M};
                font-weight: ${Typography.WEIGHTS.MEDIUM};

                background-color: ${Colours.TRANSPARENT};
                color: ${Colours.BLUEBERRY_DARK};

                &::placeholder {
                    color: ${Colours.STONE};
                    font-weight: ${Typography.WEIGHTS.REGULAR};
                }
            }

            > .select-input {
                min-width: 6rem;

                &.hidden {
                    width: 0;
                    min-width: initial;
                    height: auto;
                    padding: 0;

                    border: 0;
                    outline: none;
                    appearance: none;
                }
            }

            > .select-open-menu-button {
                padding: 0;
                font-size: ${Sizes.FONTS.M};
                background-color: ${Colours.TRANSPARENT};

                > .icon-chevron {
                    stroke: ${Colours.BLACKBERRY};
                    padding-left: ${Sizes.SPACING.HALF};
                }
            }

            /* HOVER, FOCUS, ACTIVE ENABLED STYLES */
            &:hover,
            &:focus-within,
            &:active {
                box-shadow: none;
                border-color: ${Colours.BLUEBERRY_DARK};

                > .select-input {
                    &::placeholder {
                        color: ${Colours.BLUEBERRY_DARK};
                    }
                }

                > .select-open-menu-button > .icon-chevron {
                    stroke: ${Colours.BLUEBERRY_DARK};
                }
            }
        }

        > .select-menu {
            > .select-option,
            > .select-option-group > .select-option-group-title,
            > .select-option-group > .select-option-group-list > .select-option {
                /* For our inline variants, we want the contents of the menu to fit in one line
                    regardless of the width of the actual select. */
                white-space: nowrap;
            }
        }
    }


    /* Menu styles - same for all variants */
    > .select-menu {
        position: absolute;
        top: 100%;
        left: 0;

        display: none;

        &.is-open {
            display: block;
        }

        min-width: 100%;
        max-height: 14.5rem;

        margin: 0;
        padding: ${Sizes.SPACING.THREE_QUARTERS} 0;
        box-sizing: border-box;

        border: 1px solid ${Colours.BLUEBERRY};
        outline: none;
        background-color: ${Colours.COCONUT};

        list-style: none;

        z-index: ${ZIndex.DROP_DOWNS};

        overflow-y: auto;

        /* Scrollbar is not visible on MacOS until you start scrolling,
            we're adding styles to our scrollbar to ensure it always shows */
        &::-webkit-scrollbar {
            -webkit-appearance: none;

            &:vertical {
                width: 11px;
            }

            &:horizontal {
                height: 11px;
            }
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid ${Colours.COCONUT};
            background-color: rgba(0, 0, 0, .5);
        }

        &::-webkit-scrollbar-track {
            margin-bottom: 1px;
            border-radius: 8px;
            background-color: ${Colours.COCONUT};
        }

        > .select-option,
        > .select-option-group > .select-option-group-title,
        > .select-option-group > .select-option-group-list > .select-option {
            max-width: 100%;

            padding: ${Sizes.SPACING.THREE_QUARTERS} ${INPUT_HORIZONTAL_PADDING};
            color: ${Colours.BLACKBERRY};

            font-weight: ${Typography.WEIGHTS.REGULAR};
            text-align: left;

            &.is-highlighted {
                background-color: ${rgba(Colours.BLUEBERRY_LIGHT, 0.5)};
            }

            &.is-disabled {
                color: ${Colours.STONE};
                cursor: not-allowed;
            }
        }

        > .select-option-group {
            > .select-option-group-title {
                display: block;

                padding-left: ${Sizes.SPACING.ONE};
                padding-right: ${Sizes.SPACING.ONE};

                font-weight: ${Typography.WEIGHTS.MEDIUM};
                color: ${Colours.BLACKBERRY_LIGHT};
            }

            > .select-option-group-list {
                list-style: none;
                margin: 0;
                padding: 0;
            }
        }
    }

    /* Invalid & Disabled styles - same for all variants/themes */
    &.is-invalid {
        > .select-combobox {
            border-color: ${Colours.STRAWBERRY};

            > .select-input,
            > .select-value {
                color: ${Colours.STRAWBERRY};

                &::placeholder {
                    color: ${Colours.STRAWBERRY};
                }
            }

            > .select-open-menu-button > .icon-chevron {
                stroke: ${Colours.STRAWBERRY};
            }

            &:hover,
            &:focus-within,
            &:active {
                border-color: ${Colours.STRAWBERRY_DARK};

                > .select-input {
                    &::placeholder {
                        color: ${Colours.STRAWBERRY_DARK};
                    }
                }

                > .select-open-menu-button > .icon-chevron {
                    stroke: ${Colours.STRAWBERRY_DARK}
                }
            }
        }

        > .select-menu {
            border-color: ${Colours.STRAWBERRY};

            > .select-option,
            > .select-option-group > .select-option-group-list > .select-option {
                color: ${Colours.STRAWBERRY};

                &.is-highlighted {
                    color: ${Colours.STRAWBERRY_DARKEST};
                    background-color: ${Colours.WATERMELON_LIGHTEST};
                }

                &.is-disabled {
                    color: ${Colours.STONE};
                }
            }
        }
    }

    &.is-disabled {
        > .select-combobox {
            &,
            &:hover {
                color: ${Colours.STONE};
                border-color: ${Colours.STONE};
                box-shadow: none;
                cursor: default;

                > .select-input,
                > .select-value {
                    color: ${Colours.STONE};

                    &::placeholder {
                        color: ${Colours.STONE};
                    }
                }

                > .select-open-menu-button > .icon-chevron {
                    stroke: ${Colours.STONE};
                }
            }
        }
    }

    /* Condensed style overrides - inline select excluded */
    &.is-condensed:not(.is-inline) {
        height: ${INPUT_HEIGHT_CONDENSED};

        > .select-combobox {
            height: ${INPUT_HEIGHT_CONDENSED};
        }

        > .select-combobox > .select-input,
        > .select-combobox > .select-value,
        > .select-menu > .select-option,
        > .select-menu > .select-option-group > .select-option-group-title,
        > .select-menu > .select-option-group > .select-option-group-list > .select-option {
            padding-left: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
            padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
        }

        > .select-combobox > .select-open-menu-button {
            padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
        }
    }

    /* only apply compact label styling if we have a label */
    &.has-label {
        > .select-combobox {
            position: relative;

            > .select-input {
                padding: ${Sizes.SPACING.ONE_AND_A_HALF} 1.125rem 0.125rem;

                ::placeholder {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s 100ms, opacity 100ms linear;
                }
            }

            > .label {
                position: absolute;
                top: 50%;
                left: calc(${Sizes.SPACING.ONE_AND_A_HALF} - 1px); /* 1px to make input and label align */
                right: ${Sizes.SPACING.ONE_AND_A_HALF}; /* Used to force truncation if text is too long */
                z-index: 10;

                transform: translateY(-50%);
                transform-origin: left top;
                transition: transform ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1), color ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1);
                will-change: transform;

                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;

                text-overflow: ellipsis;
                overflow: hidden;

                color: ${Colours.STONE_DARK};
                white-space: nowrap;

                cursor: text;
                pointer-events: none;
            }
        }

        :focus-within,
        &.has-value {
            > .select-combobox {
                > .select-input {
                    ::placeholder {
                        visibility: visible;
                        opacity: 1;
                        transition: opacity ${TRANSITION_TIME} linear;
                    }
                }
                > .label {
                    /* Unset the right space in case the text was truncated and now can fit */
                    right: unset;
                    transform: translateX(-0.25rem) translateY(-100%) scale(0.75);
                    color: ${Colours.BLACKBERRY};
                }
            }
        }

        :hover {
            > .select-combobox > .label {
                color: ${Colours.BLUEBERRY_DARK};
            }
        }

        &.is-invalid {
            > .select-combobox {
                > .label,
                > .select-input::placeholder {
                    color: ${Colours.STRAWBERRY_DARK};
                }
            }
        }

        &.is-disabled {
            > .select-combobox > .label {
                color: ${Colours.STONE};
            }
        }

        /* Condensed style overrides */
        &.is-condensed {
            > .select-combobox {
                > .select-input {
                    padding-left: calc(${INPUT_HORIZONTAL_PADDING_CONDENSED} + 1px); /* 1px to make input and label align */
                    padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
                }
                > .label {
                    left: 1.25rem;
                }
            }

            &:focus-within,
            &.has-value {
                > .select-combobox > .label {
                    transform: translateX(-0.25rem) translateY(-95%) scale(0.75);
                }
            }
        }
    }
`;
