import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import FormattedNumberInput from './FormattedNumberInput';


const LETTERS_REGEX = /[a-zA-Z]/g;
const NOT_NUMBERS_REGEX = /[^0-9]/g;

function DateInputMasked({
    dateFormat,
    value,
    placeholder,

    shouldShowInvalid,
    isInvalid,
    errorMessage,
    
    onChange,
    onFocus,
    onBlur,
    
    ...otherProps
}) {
    const intl = useIntl();

    dateFormat = dateFormat.split('/').join(' / ');

    const inputFormat = dateFormat.replace(LETTERS_REGEX, '#');
    const inputMask = dateFormat
        .split('')
        .filter((c) => c.match(LETTERS_REGEX));
    const valueOnlyNumbers = value?.replace(NOT_NUMBERS_REGEX, '') || '';

    // DateInput only requires the formattedValue node off of the values object
    function handleValueChange(values) {
        // EXAMPLE: user has typed 123 -> displays "12 / 3D / YYYY"
        // values.value: "123"
        // values.formattedValue: "12 / 3D / YYYY"
        // values.floatValue: 123
        const formattedValue = values.formattedValue.replace(/\s/g, '');

        onChange?.(formattedValue);
    }

    return (
        <FormattedNumberInput
            data-test-name="date-input-masked"
            value={valueOnlyNumbers}
            placeholder={placeholder ? messageToString(placeholder, intl) : dateFormat}
            format={inputFormat}
            mask={inputMask}

            isNumericString={true}

            isInvalid={isInvalid}
            shouldShowInvalid={shouldShowInvalid}
            errorMessage={errorMessage}

            onChange={handleValueChange}
            onFocus={onFocus}
            onBlur={onBlur}

            {...otherProps}
        />
    );
}

DateInputMasked.propTypes = {
    dateFormat: PropTypes.string.isRequired,
    value: PropTypes.any,
    placeholder: MessagePropType,

    shouldShowInvalid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    errorMessage: MessagePropType,
    
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

DateInputMasked.defaultProps = {
    value: undefined,
    placeholder: undefined,
    
    shouldShowInvalid: false,
    isInvalid: undefined,
    errorMessage: undefined,

    onChange: null,
    onFocus: null,
    onBlur: null,
};

export default DateInputMasked;
