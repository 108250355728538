import styled from 'styled-components';

import SIZES from '../definitions/Sizes';
import COLOURS from '../definitions/Colours';
import TYPOGRAPHY from '../definitions/Typography';
import { BORDER_RADIUS } from '../definitions/SharedInputStyles';
import TextElement from './TextElement';



const ErrorMessage = styled(TextElement).attrs({
    forwardedAs: 'span',
})`
    font-family: ${TYPOGRAPHY.FONT_FAMILY_STACK};
    font-size: ${SIZES.FONTS['2XS']};
    font-weight: ${TYPOGRAPHY.WEIGHTS.MEDIUM};

    padding: 0.25em 1em 0.3em;
    border-radius: ${BORDER_RADIUS};

    color: ${COLOURS.COCONUT};
    background-color: ${COLOURS.STRAWBERRY_DARK};
`;

export default ErrorMessage;
