import { defineMessages } from 'react-intl';

import { PROVINCES } from '@ratehub/documents/common/stable';

import Locales from '../definitions/Locales';
import BRANDS from '../definitions/Brands';

/**
 * getBrandLogo()
 *
 * @param {string} brand - one of the brands from Brands.js
 * @param {object} options - additional specifics needed for certain brands
 * @param {string} options.theme - 'dark' or 'light' - default 'dark'
 * @param {string} options.province - eg. 'ON', 'BC', 'AB', etc. - default 'ON'
 * @param {string} options.locale - eg. 'en-CA' - default 'en-CA'
 * @returns {object} a brand object containing a filePath, intrinsic dimentions & alt text for that brand
 */
function getBrandLogo(brand, options = {}) {
    const theme = options.theme ?? THEMES.DARK;
    const province = options.province ?? PROVINCES.ONTARIO;
    const locale = options.locale ?? Locales.ENGLISH;

    const region = province === PROVINCES.QUEBEC ? REGIONS.QC : REGIONS.NON_QC;

    switch (brand) {
        case BRANDS.RATEHUB:
            return theme === THEMES.DARK
                ? BRAND_LOGOS[BRANDS.RATEHUB][THEMES.DARK]
                : BRAND_LOGOS[BRANDS.RATEHUB][THEMES.LIGHT];
        case BRANDS.RH_INSURANCE:
            return theme === THEMES.DARK
                ? BRAND_LOGOS[BRANDS.RH_INSURANCE][THEMES.DARK]
                : BRAND_LOGOS[BRANDS.RH_INSURANCE][THEMES.LIGHT];

        // Canwise graphics always return "dark" versions despite requested
        // theme - no use for light graphics for now.
        case BRANDS.CANWISE:
            return BRAND_LOGOS[BRANDS.CANWISE][region][locale];

        default:
            return null;
    }
}

const REGIONS = {
    QC: 'qc',
    NON_QC: 'non_qc',
};

const THEMES = {
    DARK: 'dark',
    LIGHT: 'light',
};

const MESSAGES = defineMessages({
    ALT_TEXT_RATEHUB: {
        id: 'base-ui.logoFull.altTextRatehub',
        defaultMessage: 'Ratehub logo',
    },
    ALT_TEXT_RH_INSURANCE: {
        id: 'base-ui.logoFull.altTextRHInsurance',
        defaultMessage: 'RH Insurance logo',
    },
    ALT_TEXT_CANWISE: {
        id: 'base-ui.logoFull.altTextCanwise',
        defaultMessage: 'CanWise Financial logo',
    },
});

const BRAND_LOGOS = {
    [BRANDS.RATEHUB]: {
        [THEMES.DARK]: {
            filePath: '/static/logo_ratehub_full_dark.svg',
            intrinsicWidth: 150.683,
            intrinsicHeight: 24,
            altText: MESSAGES.ALT_TEXT_RATEHUB,
        },
        [THEMES.LIGHT]: {
            filePath: '/static/logo_ratehub_full_light.svg',
            intrinsicWidth: 150.683,
            intrinsicHeight: 24,
            altText: MESSAGES.ALT_TEXT_RATEHUB,
        },
    },

    [BRANDS.RH_INSURANCE]: {
        [THEMES.DARK]: {
            filePath: '/static/logo_rh_insurance_full_dark.svg',
            intrinsicWidth: 157.7,
            intrinsicHeight: 40,
            altText: MESSAGES.ALT_TEXT_RH_INSURANCE,
        },
        [THEMES.LIGHT]: {
            filePath: '/static/logo_rh_insurance_full_light.svg',
            intrinsicWidth: 157.7,
            intrinsicHeight: 40,
            altText: MESSAGES.ALT_TEXT_RH_INSURANCE,
        },
    },

    // NOTE:
    //  1. Canwise logos ONLY have dark variants
    //  1. Unlike other brands, Canwise logos have region and locale variants
    [BRANDS.CANWISE]: {
        [REGIONS.QC]: {
            [Locales.ENGLISH]: {
                filePath: '/static/logo_canwise_qc_en_dark.svg',
                intrinsicWidth: 789,
                intrinsicHeight: 145,
                altText: MESSAGES.ALT_TEXT_CANWISE,
            },
            [Locales.FRENCH]: {
                filePath: '/static/logo_canwise_qc_fr_dark.svg',
                intrinsicWidth: 968,
                intrinsicHeight: 153,
                altText: MESSAGES.ALT_TEXT_CANWISE,
            },
        },
        [REGIONS.NON_QC]: {
            [Locales.ENGLISH]: {
                filePath: '/static/logo_canwise_non_qc_en_dark.svg',
                intrinsicWidth: 786,
                intrinsicHeight: 143,
                altText: MESSAGES.ALT_TEXT_CANWISE,
            },
            [Locales.FRENCH]: {
                filePath: '/static/logo_canwise_non_qc_fr_dark.svg',
                intrinsicWidth: 968,
                intrinsicHeight: 154,
                altText: MESSAGES.ALT_TEXT_CANWISE,
            },
        },
    },
};

export default getBrandLogo;
