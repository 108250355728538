import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { Colours, ZIndex } from '@ratehub/base-ui';


const BOX_SHADOW_OPTIONS = {
    NONE: 'none',
    SMALL: 'small',
    LARGE: 'large',
};

const BOX_SHADOW_MAP = {
    [BOX_SHADOW_OPTIONS.NONE ] : 'none',
    [BOX_SHADOW_OPTIONS.SMALL ] : `0 0 6px 0 ${Colours.STONE}`,
    [BOX_SHADOW_OPTIONS.LARGE ] : `0 0 16px 0 ${Colours.STONE}`,
};


function LayoutRowColumn({
    // Flex item props
    flexBasis,
    flexGrow,
    flexShrink,

    // Flex container props
    flexDirection,
    wrapDirection,
    justifyContent,
    alignItems,

    backgroundColour,
    borderRadius,
    boxShadow,

    mediaQueries,

    children,
    className,
    ...otherProps
}) {
    return (
        <Container
            flexBasis={flexBasis}
            flexGrow={flexGrow}
            flexShrink={flexShrink}
            flexDirection={flexDirection}
            wrapDirection={wrapDirection}
            justifyContent={justifyContent}
            alignItems={alignItems}
            backgroundColour={backgroundColour}
            borderRadius={borderRadius}
            mediaQueries={formatConfig(mediaQueries)}
            className={classNames(className, `box-shadow-${boxShadow}`)}
            {...otherProps}
        >
            {children}
        </Container>
    );
}

LayoutRowColumn.propTypes = {
    flexBasis: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    flexGrow: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    flexShrink: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),

    flexDirection: PropTypes.oneOf([ 'row', 'row-reverse', 'column', 'column-reverse' ]),
    wrapDirection: PropTypes.oneOf([ 'wrap', 'wrap-reverse', 'nowrap' ]),
    justifyContent: PropTypes.oneOf([
        'flex-start',
        'center',
        'flex-end',
        'space-between',
        'space-around',
        'space-evenly',
        'stretch',
    ]),
    alignItems: PropTypes.oneOf([ 'flex-start', 'center', 'flex-end', 'stretch' ]),

    backgroundColour: PropTypes.oneOf(Object.values(Colours)),
    borderRadius: PropTypes.string,
    boxShadow: PropTypes.oneOf(Object.values(BOX_SHADOW_OPTIONS)),

    mediaQueries: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                breakpoint: PropTypes.string,
                rules: PropTypes.object,
            }).isRequired,
        ),
    ]),

    children: PropTypes.any,
    className: PropTypes.string,
};

LayoutRowColumn.defaultProps = {
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 1,

    flexDirection: 'column',
    wrapDirection: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    backgroundColour: Colours.TRANSPARENT,
    borderRadius: undefined,
    boxShadow: BOX_SHADOW_OPTIONS.NONE,

    mediaQueries: undefined,

    children: undefined,
    className: '',
};

const Container = styled.div`
    display: flex;
    box-sizing: border-box;

    flex-basis: ${props => props.flexBasis};
    flex-grow: ${props => (props.flexGrow ? 1 : 0)};
    flex-shrink: ${props => (props.flexShrink ? 1 : 0)};

    flex-direction: ${props => props.flexDirection};
    justify-content: ${props => props.justifyContent};
    flex-wrap: ${props => props.wrapDirection};
    align-items: ${props => props.alignItems};

    ${props =>
        props.mediaQueries
        && `
        ${props.mediaQueries.map(convertArrayToCSSRules)}
    `}

    background-color: ${props => props.backgroundColour};
    border-radius: ${props => props.borderRadius};

    /**
    * We need to put the box shadow on a pseudo element so we can isolate the blend mode to just the shadow, not the inner content of the column.
    * For the pseudo element to be the correct size to match the 'real' element, it must be position: absolute;
    * For position: absolute; to work on the pseudo element, the real element needs to be position: relative;
    */
    position: relative;

    /**
    * The pseudo element is overlaps the real element in the stacking context.
    * There's no visual difference since the pseudo element has no content (just the shadow), but elements in the column will not be clickable.
    * We COULD solve this by setting a z-index on the real element, and a lower z-index on the pseudo element, HOWEVER, then the blend mode will not work.
    * So, set any children of the real element to have a reasonable z-index.
    * We can also explicitly set the pseudo element to have a lower z-index (see StoreFrontItem), but that appears to be unnecessary.
    */
    > * {
        z-index: ${ZIndex.ELEMENTS};
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: ${props => props.borderRadius};
    }

    &.box-shadow-small::before {
        box-shadow: ${BOX_SHADOW_MAP[BOX_SHADOW_OPTIONS.SMALL]};
        mix-blend-mode: multiply;
    }

    &.box-shadow-large::before {
        box-shadow: ${BOX_SHADOW_MAP[BOX_SHADOW_OPTIONS.LARGE]};
        mix-blend-mode: multiply;
    }
`;

function formatConfig(config) {
    if (typeof config === 'string') {
        try {
            config = JSON.parse(config);
        } catch {
            // eslint-disable-next-line no-console
            console.error(`Problem parsing mediaQueries JSON prop in LayoutRowColumn. Value: '${config}'`);
        }
    }

    return Array.isArray(config) ? config : [];
}

/**
 * Takes our config object and returns a formatted block of CSS
 * @param {object} config
 * @param {string} config.type The Media Feature to query (see https://developer.mozilla.org/en-US/docs/Web/CSS/@media#media_features)
 * @param {string} config.breakpoint The value to match. Typically a united length for a width/height-based media query (eg. '50rem')
 * @param {array} config.rules An object containing CSS rules eg. { color: 'red', fontWeight: 'bold' }
 * @returns {string} CSS rules in string format
 */
// Disabling this rule because it's impossible to format this in any sane way with the indent rules
/* eslint-disable indent*/
function convertArrayToCSSRules(config) {
    return `
        @media (${config.type}: ${config.breakpoint}) {
            ${
    config.rules
        ? Object.entries(config.rules)
            .map(([ rule, value ]) => `${rule}: ${value}`)
            .join(';')
        : undefined
};
        }
    `;
}

LayoutRowColumn.blockKey = 'rh/layout-row-column';
LayoutRowColumn.hasSpacingOptions = true;

export default LayoutRowColumn;
