import React from 'react';
import PropTypes from 'prop-types';

import Colours from '../definitions/Colours';


/* Renegade Icon: Use with caution. Currently only used for select / dropdown
components, we may want to keep it that way. */

function ChevronDown({
    widthInPixels = 13,
    heightInPixels = 8,
    stroke = Colours.BLACKBERRY,
    ...otherProps
}) {
    return (
        <svg
            width={`${widthInPixels}px`}
            height={`${heightInPixels}px`}
            stroke={stroke}
            viewBox="0 0 13 8"
            {...otherProps}
        >
            <g
                strokeWidth="1.25"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <polyline points="1 1 6.51129354 7 12 1.02458996" />
            </g>
        </svg>
    );
}

ChevronDown.propTypes = {
    widthInPixels: PropTypes.number,
    heightInPixels: PropTypes.number,
    stroke: PropTypes.string,
};


export default ChevronDown;
