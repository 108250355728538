import React, { createContext, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import useFieldsetState from '../hooks/useFieldsetState';


const Context = createContext();

function Fieldset({
    children,
    onCreateState,
    isAutoFocusEnabled = false,
    className,
    ...otherProps
}) {
    const [ state ] = useFieldsetState({ isAutoFocusEnabled });
    // Fieldsets can be embedded within Fieldsets.
    //  If this Fieldset has a parent fieldset, we want
    //  to ensure the fields added here also apply to the parent.
    const parentFieldset = useContext(Context);

    useEffect(
        () => {
            onCreateState && onCreateState(state);
        },
        [ onCreateState ],
    );

    // If we have a parent fieldset, we want to link
    //  ourself to the parent
    useEffect(
        () => {
            if (parentFieldset && state) {
                parentFieldset.addFieldset(state);

                return () => parentFieldset.removeFieldset(state);
            }
        },
        [ parentFieldset, state ],
    );

    return (
        <fieldset
            className={classNames('rh-p-0 rh-border-width-0', className)}
            {...otherProps}
        >
            <Context.Provider value={state}>
                {typeof children === 'function'
                    ? children(state)
                    : children
                }
            </Context.Provider>
        </fieldset>
    );
}

Fieldset.propTypes = {
    children: PropTypes.any.isRequired,
    onCreateState: PropTypes.func,
    isAutoFocusEnabled: PropTypes.bool,
    className: PropTypes.string,
};


export default observer(Fieldset);
export {
    Context,
};
