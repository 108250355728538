import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.extend(customParseFormat);

/**
 * Tries to make a JS Date from the argument given if it isn’t already a date.
 * If the number (milliseconds since 1970-01-01) or string (multiple formats) can’t be parsed as a date we
 * return null so that it can be detected by an intl formatting select formatter.
 * If the format argument is given it is used by moment to try to parse the given date value.
 *
 * default intl formatting using the "date" type reacts as follows for these date values
 *      null => today
 *      undefined => 1970-01-01
 *      NaN => bad time value; breaks intl formatting
 *
 * @param {Date | dayjs | number | string} value
 * @param {string} [format] optional format string recognized by moment describing value’s string format
 * @return {Date | null} - null if invalid date
 */
function toDate(value, format) {

    // SPECIAL CASE: they've explicitly provided us a format to parse with
    if (typeof value === 'string' && format) {
        const parsedDateWithFormat = dayjs(value, format, true);

        return parsedDateWithFormat.isValid()
            ? parsedDateWithFormat.toDate()
            : null;
    }

    switch (typeof value) {
        case 'object':
            if (value instanceof Date) {
                return value;
            }

            if (dayjs.isDayjs(value)) {
                return value.toDate();
            }
            
            // Some sort of proxy or interface?
            return isNaN(value?.getTime?.()) ? null : value;

        case 'string':      // ASSUMPTION: already in a standard format
        case 'number': {    // ASSUMPTION: UNIX timestamp
            const date = new Date(value);
            
            return Number.isNaN(date.getTime()) 
                ? null // even invalid dates have .getTime(), but it returns NaN
                : date;
        }

        default:
            return null;
    }
}

export default toDate;
