import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';
import ArticleShowcaseItem from './ArticleShowcaseItem';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};
function ArticleShowcase({ heading, variant, articles }) {
    return (
        <>
            <HeadingBlock
                {...heading}
                variant={variant}
            />

            <ArticleShowcase.List>
                <If condition={articles}>
                    <For
                        each="article"
                        of={articles}
                        index="index"
                    >
                        <ArticleShowcaseItem
                            key={index}
                            {...article}
                        />
                    </For>
                </If>
            </ArticleShowcase.List>
        </>
    );
}

ArticleShowcase.propTypes = {
    heading: HeadingPropTypes,
    articles: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        logo: PropTypes.logo,
        logoAlt: PropTypes.string,
        snippet: PropTypes.string,
        href: PropTypes.string,
    })).isRequired,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

ArticleShowcase.defaultProps = {
    heading: undefined,
    variant: VARIANTS.DARK,
};

ArticleShowcase.List = styled.ul`
    /* Flex for IE 11 */
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    /* Grid for normal browsers */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${Sizes.SPACING.TWO};

    padding-left: 0;

    margin-top: 0;
    margin-bottom: 0;

    @media(max-width: 780px) {
        display: block;
    }
`;


export default ArticleShowcase;
