// NOTE: Please keep the keys in this file in sync with the attributes in
// packages/wordpress-blocks/src/blocks/layout-row/attributes.js.

// WHY? This file is used by web-server, whereas the attribute definitions in
// attributes.js are used by wordpress-blocks. We don't want web-server
// importing the attributes from wordpress-blocks as it could cause
// complexity in our releases (blocks are deployed separately from web-server
// code).

export default {
    ID: 'id',

    FLEX_DIRECTION: 'flexDirection',
    WRAP_DIRECTION: 'wrapDirection',
    JUSTIFY_CONTENT: 'justifyContent',
    ALIGN_ITEMS: 'alignItems',

    BOX_SHADOW: 'boxShadow',

    BORDER_RADIUS: 'borderRadius',
    BACKGROUND_COLOUR: 'backgroundColour',
    BACKGROUND_IMAGE: 'backgroundImage',
    BACKGROUND_SIZE: 'backgroundSize',
    BACKGROUND_POSITION_X: 'backgroundPositionX',
    BACKGROUND_POSITION_Y: 'backgroundPositionY',
    INCLUDE_CONTENT_PADDING: 'includeContentPadding',

    BACKGROUND_GRADIENT_LEVEL: 'backgroundGradientLevel',
    BACKGROUND_GRADIENT_DIRECTION: 'backgroundGradientDirection',

    POSITION: 'position',
    PCB_OVERFLOW_X: 'pcbOverflowX',
    AS: 'as',

    TABLE_OF_CONTENTS_EXCLUDE: 'tableOfContentsExclude',
    TABLE_OF_CONTENTS_TITLE: 'tableOfContentsTitle',

    COLUMN_GUTTER_SIZE: 'columnGutterSize',
    ROW_GUTTER_SIZE: 'rowGutterSize',

    BLOCK_LOCATION: 'blockLocation',

    BLOCK_TYPE: 'blockType',

    LAYOUT_WIDTH: 'layoutWidth',

    DATA_NAME: 'dataName',
};
