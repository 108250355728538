import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import toInteger from '../functions/toInteger';
import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import Typography from '../definitions/Sizes';
import DecreaseButton from './DecreaseButton';
import IncreaseButton from './IncreaseButton';
import InputController from './InputController';


function NumberSelector({
    id,
    name,
    value,

    isDisabled,

    min,
    max,
    
    onIncrease,
    onDecrease,

    onChange,
    onBlur,
    onFocus,
    
    onValidityChange,
    onValidate,

    onCreateField,
    
    className,

    ...otherProps
}) {
    // NumberSelector is always considered valid
    if (typeof onValidate === 'function') {
        throw new Error('NumberSelector does not support custom validation (onValidate)');
    }

    if (min >= max) {
        throw new RangeError('NumberSelector: min prop must be less than max value');
    }

    function getValueAsInteger(newValue) {
        const valueAsNumber = Number.isInteger(newValue) 
            ? newValue
            : toInteger(newValue); 
        
        /* If our value is null or cleared, return
            our min value to make sure our value is
            never null or undefined.
            This is only likely to occur programmatically 
            if value is null/undefined */
        if (valueAsNumber == null) {
            return min;
        }
        
        return valueAsNumber;
    }

    return (
        <InputController
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onValidityChange={onValidityChange}
            isDisabled={isDisabled}
            getParsedValue={getValueAsInteger}
            getFormattedValue={getValueAsInteger}
            onCreateField={onCreateField}
            className={className}
            {...otherProps}
        >
            {(inputProps) => {
                function handleIncreaseClick() {
                    if (inputProps.value < max) {
                        onIncrease?.();
                        inputProps.onChange(inputProps.value + 1);
                    }
                }

                function handleDecreaseClick() {
                    if (inputProps.value > min) {
                        onDecrease?.();
                        inputProps.onChange(inputProps.value - 1);
                    }
                }

                return (
                    <Container className={inputProps.className}>
                        <DecreaseButton
                            onClick={handleDecreaseClick}
                            data-name={`${inputProps.name}-decrease`}
                            disabled={inputProps.disabled || inputProps.value <= min}
                            
                            // Our input has negative tab index, we blur when these buttons blur
                            onBlur={inputProps.onBlur}
                            onFocus={onFocus}
                        />
                        <input
                            id={inputProps.id}
                            name={inputProps.name}
                            value={inputProps.value}
                            
                            type="number"
                            min={min}
                            max={max}                
                            
                            placeholder={0}
                            readOnly
                            tabIndex={-1}
                            
                            disabled={inputProps.disabled}
                        />
                        <IncreaseButton
                            onClick={handleIncreaseClick}
                            data-name={`${name}-increase`}
                            disabled={inputProps.disabled || inputProps.value >= max}
                            
                            // Our input has negative tab index, we blur when these buttons blur
                            onBlur={inputProps.handleBlur}
                            onFocus={onFocus}
                        />
                    </Container>
                );
            }}
        </InputController>
    );
}

NumberSelector.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    isDisabled: PropTypes.bool,

    min: PropTypes.number,
    max: PropTypes.number,
    
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    onCreateField: PropTypes.func,
    
    className: PropTypes.string,
};

NumberSelector.defaultProps = {
    id: undefined,
    value: undefined,
    isDisabled: false,

    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    
    onIncrease: undefined,
    onDecrease: undefined,

    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
    
    onValidityChange: undefined,
    onValidate: undefined,

    onCreateField: undefined,

    className: undefined,
};


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: ${Sizes.SPACING.HALF} 0;
    width: 100%;

    > input {
        font-size: ${Sizes.FONTS['3XL']};
        font-family: ${Typography.FONT_FAMILY_STACK};
        font-weight: normal;

        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;

        color: ${Colours.BLACK};
        background-color: transparent;
        caret-color: transparent;

        width: ${Sizes.SPACING.TWO_AND_A_HALF};
        height: ${Sizes.SPACING.TWO_AND_A_HALF};

        padding: 0;

        text-align: center;

        border: none;
        box-shadow: none;

        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }

        &::placeholder {
            color: ${Colours.BLACK};
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            color: ${Colours.STONE};
        }
    }
`;

export default NumberSelector;
