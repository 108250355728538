import Config from './Config';


const EXPERIMENTS = {
    ENABLE_GOOGLE_SIGN_IN: {
        slug: 'enable-google-sign-in',
        segmentOverride: Config.SEGMENT_ENABLE_GOOGLE_SIGN_IN,
        variations: Config.VARIATIONS_ENABLE_GOOGLE_SIGN_IN,
    },

    // added 2024-Apr-09
    darkerOffers: 'cc-darker-offers',                   // to be removed by ccdep-3538
};

export default EXPERIMENTS;
