import Colours from '../definitions/Colours';


const COLOUR_ORDER_KEYS = [
    Colours.BLUEBERRY_DARK,
    Colours.LIME_DARK,
    Colours.YUZU_DARKEST,
    Colours.STRAWBERRY,
    Colours.TANGERINE_DARK,
    Colours.GRAPE_DARK,
    Colours.WATERMELON,
];

function getOrderedColour(index) {
    const orderIndex = index % COLOUR_ORDER_KEYS.length;

    return COLOUR_ORDER_KEYS[orderIndex];
}

export default getOrderedColour;
