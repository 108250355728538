import { PROVINCE } from '@ratehub/base-ui';

import createBaseConversionPayload from './createBaseConversionPayload';


/**
 * Create a payload to send to the Conversion Service for a mortgage CPA click
 * @param {object} params
 * @property {object} params.lead - city object for CPA clicks
 * @property {object} params.rate - current rate being inquired on
 * @property {object} params.experiments - all active FE experiments
 * @property {string} params.locale
 *
 * @returns {object} conversion payload
 */
export default function createCPAPayload({ lead, rate, experiments, locale }) {
    // These are REQUIRED by Conversion Service, but if GeoIP fails, we may not have their information.
    // Better to just include garbage information than lose the conversion.
    // Credit-cards is doing the same - modeling after their approach.
    const cityName = lead?.city?.name ?? 'Toronto';
    const provinceCode = lead?.city?.province?.code ?? PROVINCE.ONTARIO;

    return {
        ...createBaseConversionPayload({ rate, cityName, provinceCode, locale, experiments }),
        'type': 'CPA_CLICK',
        'productType': 'MORTGAGE',
        'conversionSourceType': 'MORTGAGE_RATE',
    };
}
