import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import RemoveDefaultButtonStyles from '../styles/RemoveDefaultButtonStyles';
import PositionOffscreenStyles from '../styles/PositionOffscreenStyles';


function CloseButton({ offscreenText, ...otherProps }){
    return (
        <CloseButton.Button
            {...otherProps}
        >
            <CloseButton.OffScreen>
                {offscreenText}
            </CloseButton.OffScreen>

            <CloseButton.Svg
                viewBox="0 0 42 42"
            >
                <path d="M.91 39.09L39.09.91M.91.91l38.18 38.18" />
            </CloseButton.Svg>
        </CloseButton.Button>
    );
}

CloseButton.propTypes = {
    offscreenText: PropTypes.string.isRequired,
};

CloseButton.Button = styled.button`
    ${RemoveDefaultButtonStyles};

    width: ${Sizes.ICONS.XS};
    height: ${Sizes.ICONS.XS};

    cursor: pointer;

    color: ${Colours.BLACKBERRY};
    &:focus,
    &:hover {
        color: ${Colours.BLUEBERRY};
    }

    &:active {
        color: ${Colours.BLUEBERRY_DARK};
    }
`;

CloseButton.OffScreen = styled.span`
    ${PositionOffscreenStyles}
`;

CloseButton.Svg = styled.svg`
    stroke-width: 2px;
    stroke: currentColor;

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default CloseButton;
