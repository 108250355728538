import { useContext } from 'react';

import { Context } from '../components/Form';


function useForm() {
    return useContext(Context);
}

export default useForm;
