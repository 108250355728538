import React from 'react';
import PropTypes from 'prop-types';

import useFormComputedAsync from '../hooks/useFormComputedAsync';
import FormValue from './FormValue';


function FormComputedAsync({
    name,
    nameOfIsFetching,
    nameOfError,
    nameOfHasFetched,

    initialValue,

    getFormValues,     // Used to pull args out of the Form
    onlyWhen,       // When supplied, only change it when this is true
    onFetchValue,     // Async function to set our computed value

    debounce,           // Milliseconds
    hidden,

    ...otherProps
}) {
    // Inject the fetchable (async value) into the Form
    useFormComputedAsync({
        name,
        nameOfIsFetching,
        nameOfError,
        nameOfHasFetched,

        initialValue,

        getFormValues,
        onlyWhen,
        onFetchValue,

        debounce,
    });

    // The remaining code is only for presentation
    if (hidden) {
        return null;
    }

    return (
        <FormValue
            // If we're rendering children, we likely want all 3 (value + fetching + error) instead of just the primary name.
            // By passing `name={undefined}` our render prop will be passed ALL form.values
            name={otherProps.children ? undefined : name}
            {...otherProps}
        />
    );
}

FormComputedAsync.propTypes = {
    name: PropTypes.string.isRequired,
    nameOfIsFetching: PropTypes.string,
    nameOfError: PropTypes.string,
    nameOfHasFetched: PropTypes.string,

    initialValue: PropTypes.any,

    getFormValues: PropTypes.func,
    onlyWhen: PropTypes.func,
    onFetchValue: PropTypes.func.isRequired,

    debounce: PropTypes.number,
    hidden: PropTypes.bool,
};

FormComputedAsync.defaultProps = {
    nameOfIsFetching: undefined,
    nameOfError: undefined,
    nameOfHasFetched: undefined,

    initialValue: undefined,

    getFormValues: undefined,
    onlyWhen: undefined,

    debounce: undefined,
    hidden: false,
};

export default FormComputedAsync;
