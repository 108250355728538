import { types } from 'mobx-state-tree';


const RATE_TYPES = {
    FIXED: 'fixed',
    VARIABLE: 'variable',
    // Note: please do not add 'heloc' here
};

const RateTypeEnum = types.enumeration('RateType', [ ...Object.values(RATE_TYPES), 'heloc' ]); // heloc is deprecated

export {
    RateTypeEnum,
};
export default RATE_TYPES;
