import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumberParts } from 'react-intl';
import styled from 'styled-components';

import Formats from '../definitions/Formats';


function FormattedNumberRaisedSymbol({ value, format, symbolClassName, ...otherProps }) {
    return (
        <FormattedNumberParts
            value={value}
            format={format}
            {...otherProps}
        >
            {parts => (
                <For
                    each="part"
                    of={parts}
                    index="index"
                >
                    <Fragment key={`${part.type}-${part.value}-${index}`}>
                        <Choose>
                            <When condition={part.type === 'currency' || part.type === 'percentSign'}>
                                <StyledSymbol className={symbolClassName}>
                                    {part.value}
                                </StyledSymbol>
                            </When>
                            <Otherwise>
                                {part.value}
                            </Otherwise>
                        </Choose>
                    </Fragment>
                </For>
            )}
        </FormattedNumberParts>
    );
}

FormattedNumberRaisedSymbol.propTypes = {
    value: PropTypes.number.isRequired,
    format: PropTypes.oneOf(Object.keys(Formats.number)).isRequired,
    symbolClassName: PropTypes.string,
};

FormattedNumberRaisedSymbol.defaultProps = {
    symbolClassName: undefined,
};

/* Styling just the symbol since react-intl doesn't place a className on FormattedNumberParts */
const StyledSymbol = styled.sup`
    vertical-align: text-top;
`;

export default FormattedNumberRaisedSymbol;
