import { css } from 'styled-components';
import { rgba } from 'polished';

import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import {
    BORDER_RADIUS,
    BOX_SHADOW_STONE,
    BOX_SHADOW_BLUEBERRY,
    ACTIVE_BACKGROUND_COLOUR,
} from '../definitions/SharedInputStyles';
import { INPUT_HEIGHT_CONDENSED } from '../definitions/SharedInputStyles';


const TRANSITION_DURATION = '300ms';

export default css`
    position: relative;

    width: 100%;
    min-height: ${Sizes.SPACING.FOUR};
    box-sizing: border-box;

    /* Adding extra padding on the bottom since
    the text is not perfectly vertically centered */
    padding: 0.5em 0.5em calc(0.5em + 3px);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-family: ${Typography.FONT_FAMILY_STACK};
    font-weight: ${Typography.WEIGHTS.REGULAR};
    font-size: ${Sizes.FONTS.S};

    border: 1px solid ${Colours.STONE_DARK};
    border-radius: ${BORDER_RADIUS};

    color: ${Colours.STONE_DARKEST};
    background-color: ${Colours.COCONUT};

    &.is-condensed {
        min-height: ${INPUT_HEIGHT_CONDENSED};
    }

    transition:
        color ${TRANSITION_DURATION},
        background-color ${TRANSITION_DURATION},
        border-color ${TRANSITION_DURATION};

    &:hover {
        color: ${Colours.BLUEBERRY_DARK};
        border-color: ${Colours.BLUEBERRY_DARK};
        box-shadow: ${BOX_SHADOW_STONE};
    }

    ${props => props.isFocused && `
        box-shadow: ${BOX_SHADOW_BLUEBERRY};
    `}

    ${props => props.checked && `
        color: ${Colours.BLUEBERRY_DARK};
        background-color: ${Colours.BLUEBERRY_LIGHTEST};
        border-color: ${Colours.BLUEBERRY_DARK};
    `}

    &:active {
        color: ${Colours.BLUEBERRY_DARKEST};
        border-color: ${Colours.BLUEBERRY_DARKEST};
        background-color: ${ACTIVE_BACKGROUND_COLOUR};
    }

    ${props => props.isInvalid && `
        color: ${Colours.STRAWBERRY};
        border-color: ${Colours.STRAWBERRY};
        background-color: ${Colours.COCONUT};

        &:hover {
            color: ${Colours.STRAWBERRY_DARK};
            border-color: ${Colours.STRAWBERRY_DARK};
        }
    `}

    ${props => props.isInvalid && props.isFocused && `
        color: ${Colours.STRAWBERRY_DARK};
        border-color: ${rgba(Colours.STRAWBERRY_DARK, 0.6)};
    `}

    ${props => props.disabled && `
        &,
        &:hover {
            color: ${Colours.STONE};
            border-color: ${Colours.STONE};
            background-color: ${Colours.COCONUT};

            font-weight: ${Typography.WEIGHTS.REGULAR};
            box-shadow: none;
        }
    `}

    ${props => props.disabled && props.checked && `
        &,
        &:hover {
            color: ${Colours.STONE_DARK};
            border-color: ${Colours.STONE_DARK};
            background-color: ${Colours.STONE_LIGHTEST};
        }
    `}
`;
