import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';


Column.WIDTHS = {
    LARGE: '46em',
    SMALL: '24em',
    SIDEBAR: '10em',
};

Column.VERTICAL_ALIGNMENTS = {
    TOP: 'top',
    CENTRE: 'centre',
    BOTTOM: 'bottom',
    SPACE_BETWEEN: 'space-between',
    SPACE_AROUND: 'space-around',
    SPACE_EVENLY: 'space-evenly',
};

function Column({ defaultWidth, verticalAlignment, children, className, ...otherProps }) {
    return (
        <Container
            defaultWidth={defaultWidth}
            verticalAlignment={verticalAlignment}
            className={classNames('rh-width-100p rh-display-flex rh-mx-1 rh-align-items-center', className)}
            {...otherProps}
        >
            {children}
        </Container>
    );
}

Column.propTypes = {
    defaultWidth: PropTypes.oneOf(Object.values(Column.WIDTHS)),
    verticalAlignment: PropTypes.oneOf(Object.values(Column.VERTICAL_ALIGNMENTS)),
    className: PropTypes.string,

    children: PropTypes.any.isRequired,
};

Column.defaultProps = {
    defaultWidth: Column.WIDTHS.SMALL,
    verticalAlignment: Column.VERTICAL_ALIGNMENTS.TOP,
    className: undefined,
};

const VERTICAL_ALIGNMENT_MAP = {
    [Column.VERTICAL_ALIGNMENTS.TOP]: 'flex-start',
    [Column.VERTICAL_ALIGNMENTS.CENTRE]: 'center',
    [Column.VERTICAL_ALIGNMENTS.BOTTOM]: 'flex-end',
    [Column.VERTICAL_ALIGNMENTS.SPACE_BETWEEN]: 'space-between',
    [Column.VERTICAL_ALIGNMENTS.SPACE_AROUND]: 'space-around',
    [Column.VERTICAL_ALIGNMENTS.SPACE_EVENLY]: 'space-evenly',
};

const Container = styled.div`
    flex-grow: 1;
    flex-basis: ${props => props.defaultWidth};
    flex-direction: column;
    justify-content: ${props => VERTICAL_ALIGNMENT_MAP[props.verticalAlignment]};
`;

Column.blockKey = 'rh/column';

export default Column;
