import MESSAGES from '../definitions/AddressMessages';
import { PROVINCE_OPTIONS } from '../definitions/Provinces';
import messageToString from '../functions/messageToString';
import isPostalCodeAllowed from '../functions/isPostalCodeAllowed';


const INPUT_KEYS = {
    STREET_NUMBER: 'streetNumber',
    UNIT_NUMBER: 'unitNumber',
    STREET_NAME: 'streetName',
    STREET_TYPE: 'streetType',
    STREET_DIRECTION: 'streetDirection',
    CITY: 'city',
    PROVINCE: 'province',
    POSTAL_CODE: 'postalCode',
};

function useAddressFormState({ 
    name, 
    value, 
    onChange,
    isRequired,
    isDisabled,
    allowedProvinces, 
    intl,
}) {
    function handleChange(newValue) {
        // If we have no values, we should raise null.
        const address = Object.entries(newValue).reduce((result, [ key, value ]) => {
            // Filter out empty values so we can assert how many values we have in our address object.
            if (value != null || value != '') {
                result[key] = value;
            }

            return Object.keys(result).length === 0
                ? null
                : result;
        }, {});

        onChange?.(address);
    }

    return {
        [INPUT_KEYS.STREET_NUMBER]: {
            key: INPUT_KEYS.STREET_NUMBER,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.STREET_NUMBER],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.STREET_NUMBER]: newValue,
                });
            },
            isRequired,
            isDisabled,
        },
        [INPUT_KEYS.UNIT_NUMBER]: {
            key: INPUT_KEYS.UNIT_NUMBER,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.UNIT_NUMBER],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.UNIT_NUMBER]: newValue,
                });
            },
            isRequired: false,
            isDisabled,
        },
        [INPUT_KEYS.STREET_NAME]: {
            key: INPUT_KEYS.STREET_NAME,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.STREET_NAME],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.STREET_NAME]: newValue,
                });
            },
            isRequired,
            isDisabled,
        },
        [INPUT_KEYS.STREET_TYPE]: {
            key: INPUT_KEYS.STREET_TYPE,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.STREET_TYPE],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.STREET_TYPE]: newValue,
                });
            },
            isRequired: false,
            isDisabled,
        },
        [INPUT_KEYS.STREET_DIRECTION]: {
            key: INPUT_KEYS.STREET_DIRECTION,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.STREET_DIRECTION],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.STREET_DIRECTION]: newValue,
                });
            },
            isRequired: false,
            isDisabled,
        },
        [INPUT_KEYS.CITY]: {
            key: INPUT_KEYS.CITY,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.CITY],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.CITY]: newValue,
                });
            },
            isRequired,
            isDisabled,
        },
        [INPUT_KEYS.PROVINCE]: {
            key: INPUT_KEYS.PROVINCE,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.PROVINCE],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.PROVINCE]: newValue,
                });
            },
            onValidate(province) {
                return !allowedProvinces.includes(province)
                    ? getOutsideAllowedProvinceErrorMessage(province, intl)
                    : undefined;
            },
            isRequired,
            isDisabled,
        },
        [INPUT_KEYS.POSTAL_CODE]: {
            key: INPUT_KEYS.POSTAL_CODE,
            get name() {
                return `${name}-${this.key}`;
            },
            value: value?.[INPUT_KEYS.POSTAL_CODE],
            onChange(newValue) {
                handleChange({
                    ...value,
                    [INPUT_KEYS.POSTAL_CODE]: newValue,
                });
            },
            onValidate(postalCode) {
                return !isPostalCodeAllowed(allowedProvinces, postalCode)
                    ? MESSAGES.ERROR_OUTSIDE_ALLOWED_POSTAL_CODE
                    : undefined;
            },
            isRequired,
            isDisabled,
        },
    };
}


/* Validation helpers */
function getOutsideAllowedProvinceErrorMessage(province, intl) {
    const provinceOption = PROVINCE_OPTIONS.find(o => o.value === province);

    return {
        descriptor: MESSAGES.ERROR_OUTSIDE_ALLOWED_PROVINCE,
        values: {
            province: messageToString(provinceOption?.label ?? provinceOption?.value, intl),
        },
    };
}


export default useAddressFormState;