/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/

function MasterPagePropReplacer() {
    return null;
}

MasterPagePropReplacer.blockKey = 'rh/master-page-prop-replacer';

export default MasterPagePropReplacer;
