import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
    usePageSettings,
    BusinessUnits,
    FullScreenTakeover,
} from '@ratehub/base-ui';

import SidebarNewsletterModalAll from './SidebarNewsletterModalAll';
import SidebarNewsletterModalMortgages from './SidebarNewsletterModalMortgages';


const CLOSE_DIALOG_TIMEOUT_MS = 3000;

function NewsletterSignUpModal({ isModalOpen, onModalClose }) {
    const { businessUnit } = usePageSettings();

    function handleSignUpComplete() {
        // Close dialog after a short delay (allows time
        // for button animation to complete).
        setTimeout(() => {
            onModalClose();
        }, CLOSE_DIALOG_TIMEOUT_MS);
    }

    return (
        <FullScreenTakeover
            isOpen={isModalOpen}
            onClose={() => {
                onModalClose();
            }}
            showBranding={false}
            screenReaderLabel={(
                <FormattedMessage
                    id="web-components.sidebarNewsletterSignUpSmall.modalAccessibilityLabel"
                    defaultMessage="Newsletter sign up form"
                />
            )}
            isContentFullWidth={true}
            displayMode="lightbox"
            backgroundStyle="two-dots"
        >
            <Choose>
                <When condition={businessUnit === BusinessUnits.MORTGAGES}>
                    <SidebarNewsletterModalMortgages onSignUpComplete={handleSignUpComplete} />
                </When>
                <Otherwise>
                    <SidebarNewsletterModalAll onSignUpComplete={handleSignUpComplete} />
                </Otherwise>
            </Choose>
        </FullScreenTakeover>
    );
}

NewsletterSignUpModal.propTypes = {
    isModalOpen: PropTypes.bool,
    onModalClose: PropTypes.func,
};

export default NewsletterSignUpModal;