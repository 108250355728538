import { defineMessages } from 'react-intl';

import { PROVINCES } from '@ratehub/documents/common/stable';


const PROVINCE_MESSAGES = defineMessages({
    [PROVINCES.ALBERTA]: {
        id: 'base-ui.province.alberta',
        defaultMessage: 'Alberta',
    },
    [PROVINCES.NORTHWEST_TERRITORIES]: {
        id: 'base-ui.province.northwest-territories',
        defaultMessage: 'Northwest Territories',
    },
    [PROVINCES.ONTARIO]: {
        id: 'base-ui.province.ontario',
        defaultMessage: 'Ontario',
    },
    [PROVINCES.PRINCE_EDWARD_ISLAND]: {
        id: 'base-ui.province.prince-edward-island',
        defaultMessage: 'Prince Edward Island',
    },
    [PROVINCES.QUEBEC]: {
        id: 'base-ui.province.quebec',
        defaultMessage: 'Quebec',
    },
    [PROVINCES.SASKATCHEWAN]: {
        id: 'base-ui.province.saskatchewan',
        defaultMessage: 'Saskatchewan',
    },
    [PROVINCES.BRITISH_COLUMBIA]: {
        id: 'base-ui.province.british-columbia',
        defaultMessage: 'British Columbia',
    },
    [PROVINCES.MANITOBA]: {
        id: 'base-ui.province.manitoba',
        defaultMessage: 'Manitoba',
    },
    [PROVINCES.NEW_BRUNSWICK]: {
        id: 'base-ui.province.new-brunswick',
        defaultMessage: 'New Brunswick',
    },
    [PROVINCES.NEWFOUNDLAND_AND_LABRADOR]: {
        id: 'base-ui.province.newfoundland-and-labrador',
        defaultMessage: 'Newfoundland and Labrador',
    },
    [PROVINCES.NOVA_SCOTIA]: {
        id: 'base-ui.province.nova-scotia',
        defaultMessage: 'Nova Scotia',
    },
    [PROVINCES.NUNAVUT]: {
        id: 'base-ui.province.nunavut',
        defaultMessage: 'Nunavut',
    },
    [PROVINCES.YUKON]: {
        id: 'base-ui.province.yukon',
        defaultMessage: 'Yukon',
    },
});

const PROVINCE_OPTIONS = Object.values(PROVINCES).map(value => ({
    value: value,
    label: PROVINCE_MESSAGES[value],
}));

// Valid First character of the postal code for different regions
// source: Statistics Canada
// https://www150.statcan.gc.ca/n1/pub/92-195-x/2011001/other-autre/pc-cp/tbl/tbl9-eng.htm
const PROVINCE_VALID_POSTAL_FIRST_CHARS = {
    [PROVINCES.ALBERTA]: [ 'T' ],
    [PROVINCES.NORTHWEST_TERRITORIES]: [ 'X' ],
    [PROVINCES.ONTARIO]: [ 'K', 'L', 'M', 'N', 'P' ],
    [PROVINCES.PRINCE_EDWARD_ISLAND]: [ 'C' ],
    [PROVINCES.QUEBEC]: [ 'G', 'H', 'J' ],
    [PROVINCES.SASKATCHEWAN]: [ 'S' ],
    [PROVINCES.BRITISH_COLUMBIA]: [ 'V' ],
    [PROVINCES.MANITOBA]: [ 'R' ],
    [PROVINCES.NEW_BRUNSWICK]: [ 'E' ],
    [PROVINCES.NEWFOUNDLAND_AND_LABRADOR]: [ 'A' ],
    [PROVINCES.NOVA_SCOTIA]: [ 'B' ],
    [PROVINCES.NUNAVUT]: [ 'X' ],
    [PROVINCES.YUKON]: [ 'Y' ],
};

/**
 * Get the full name of a province by it's short-form value
 * @param {String} province One of the options from PROVINCES ie. 'ON' or 'AB'
 * @returns {Object} A message object containing the full name of the inputted province
 */
function getProvinceNameMessage(province) {
    return PROVINCE_OPTIONS.find(option => option.value === province).label;
}

export { PROVINCES as PROVINCE, PROVINCE_OPTIONS, PROVINCE_VALID_POSTAL_FIRST_CHARS, getProvinceNameMessage };
