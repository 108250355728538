import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
    Colours,
    Locales,
    getLanguageFromLocale,
    messageToString,
    Config,
    usePageSettings,
} from '@ratehub/base-ui';
import {
    NewsletterSignUpInlineForm,
    AdLeaderboard,
    BlogSpotlight,
    LayoutRow,
    LayoutRowColumn,
    AdBigbox,
    LinkBlockList,
    LinkBlockAnchor,
    getBlogCategoryPath,
} from '@ratehub/web-components';
import { LayoutGlobals } from '@ratehub/base-ui/src/styles';

import styled from 'styled-components';
import MESSAGES from '../definitions/BlogSharedMessages';
import MenuTopicsPropType from '../definitions/MenuTopicsPropType';


function BlogFooter({ blogRelatedPosts, blogTopics, ...otherProps }) {
    const intl = useIntl();
    const languageCode = getLanguageFromLocale(intl.locale);
    const { popularPostsProps } = usePageSettings();

    return (
        <Container {...otherProps}>
            {/* Needs to be mobile only */}
            <AdBigbox className="rh-mb-2 mobile-only rh-layout-default rh-text-align-center" />

            {/* Blog articles by topic */}
            <If condition={blogTopics?.categories}>
                <LayoutRow
                    justifyContent="center"
                    className="rh-mb-2 mobile-only"
                    data-test-name="blog-footer-topics"
                >
                    <LayoutRowColumn>
                        <LinkBlockList
                            className="narrow"
                            title={blogTopics.menuTitle}
                            hasBorder={true}
                            maxChildren={0}
                        >
                            <For
                                each="category"
                                of={blogTopics.categories}
                            >
                                <LinkBlockAnchor
                                    key={category.slug}
                                    url={getBlogCategoryPath(category, intl.locale)}
                                    title={category.title}
                                />
                            </For>
                        </LinkBlockList>
                    </LayoutRowColumn>
                </LayoutRow>
            </If>

            <If condition={popularPostsProps?.posts}>
                <LayoutRow
                    justifyContent="center"
                    className="rh-mb-2 mobile-only"
                    data-test-name="blog-footer-popular-posts"
                >
                    <LayoutRowColumn>
                        <LinkBlockList
                            className="narrow"
                            title={popularPostsProps.menuTitle}
                            hasBorder={true}
                            maxChildren={0}
                        >
                            <For
                                each="post"
                                of={popularPostsProps.posts}
                            >
                                <LinkBlockAnchor
                                    key={post.slug}
                                    // Trailing slash is desired for /blog or /blogue
                                    url={`${Config.RATEHUB_BASE_URL}/${intl.locale === Locales.FRENCH ? 'blogue' : 'blog'}/${post.slug}/`}
                                    title={post.title}
                                />
                            </For>
                        </LinkBlockList>
                    </LayoutRowColumn>
                </LayoutRow>
            </If>

            {/* Needs to be mobile only */}
            <AdBigbox className="rh-mb-2 mobile-only rh-layout-default rh-text-align-center" />

            <If condition={blogRelatedPosts}>
                <LayoutRow
                    className="rh-py-4 rh-layout-pull-right rh-justify-content-flex-start"
                    overflow="hidden"
                    includeContentPadding={false}
                    justifyContent="flex-start"
                    data-test-name="blog-footer-related-posts"
                >
                    <BlogSpotlight
                        hidePostFilter={true}
                        heading={{
                            title: messageToString(MESSAGES.relatedPostsTitle, intl),
                        }}
                        language={languageCode}
                        initialPosts={blogRelatedPosts}
                    />
                </LayoutRow>
            </If>

            <LayoutRow
                backgroundColour={Colours.BLUEBERRY_DARKEST}
                className="rh-py-6 rh-layout-full"
                justifyContent="center"
            >
                <LayoutRowColumn
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    className="rh-max-width-50"
                >
                    <h2 className="rh-title-4xl rh-mx-auto rh-mt-0 rh-mb-1_75 rh-fg-yuzu-light rh-text-align-center">
                        {intl.formatMessage(MESSAGES.newsletterTitle)}
                    </h2>

                    <p
                        className="rh-mx-auto rh-mt-0 rh-mb-2_5 rh-fg-coconut rh-text-l rh-text-align-center"
                    >
                        {intl.formatMessage(MESSAGES.newsletterDescription)}
                    </p>

                    <NewsletterSignUpInlineForm
                        inputLabel={MESSAGES.newsletterInputLabel}
                        disclaimerVariant="light"
                        className="rh-mb-1_75"
                    />
                </LayoutRowColumn>
            </LayoutRow>

            <LayoutRow
                className="rh-py-4 rh-layout-default"
                justifyContent="center"
            >
                <AdLeaderboard
                    className="rh-flex-grow-1"

                    /* TEMPORARY: Set to an unusually high number so we can easily
                    identify this placement in analytics. Trying to ascertain value
                    of this placement / placement's effect on viewability. */
                    overrideAdIndex={20}
                />
            </LayoutRow>
        </Container>
    );
}

BlogFooter.propTypes = {
    blogRelatedPosts: PropTypes.array,
    blogTopics: MenuTopicsPropType,
};

const Container = styled.footer`
    .mobile-only {
        display: none;

        @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
            display: flex;
        }
    }

    .narrow {
        /* so they are the same size as a bigbox ad */
        width: ${LayoutGlobals.SIDEBAR_WIDTH};
    }
`;

export default BlogFooter;
