import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import MessagePropType from '../definitions/MessagePropType';
import ERROR_MESSAGE_STYLES from '../definitions/MessageStyles';
import isMobile from '../functions/isMobile';
import formatSelectOptions from '../functions/formatSelectOptions';
import messageToString from '../functions/messageToString';
import trackHeapEvent from '../functions/trackHeapEvent';
import InputController from './InputController';
import SelectNative from './SelectNative';
import SelectDownshift from './SelectDownshift';
import ErrorMessageContainer from './ErrorMessageContainer';


function Select({
    id,
    name,
    value,
    label,
    placeholder,

    options,
    optionsSort,
    renderOptionLabel,

    isDisabled,
    isRequired,
    isInline,

    onChange,
    onBlur,

    onValidityChange,
    onValidate,
    
    canShowInvalid,
    errorMessageStyle,

    className,
    inputClassName,

    onCreateField,

    ...otherProps
}) {
    const intl = useIntl();
    const [ isNativeSelect, setIsNativeSelect ] = useState(true); // Default to native select for SSR

    // We want to evaluate whether or not we should be using native select
    //  after mount so we don't get any client/server mismatch
    //  This useEffect ensures our first render matches the server.
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsNativeSelect(isMobile());
        }
    }, []);
    
    // Options need to be formatted to 1) ensure we have a valid label and value for each
    //  and 2) set an index value to each option due to Downshift's tracking and option group support.
    //  Flat options are needed so Downshift can query all options without option group titles.
    const {
        formattedOptions,
        flatOptions,
    } = formatSelectOptions({
        options,
        optionsSort,
        includeEmptyOption: !isRequired,
        renderOptionLabel,
        intl,
    });

    // Need to use intl.formatMessage here since NativeSelect
    //  needs a string in its placeholder
    const placeholderText = placeholder 
        ? messageToString(placeholder, intl) 
        : intl.formatMessage(MESSAGES.DEFAULT_PLACEHOLDER);


    return (
        <InputController
            id={id}
            name={name}
            value={value}

            onChange={onChange}
            onBlur={onBlur}
            
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            canShowInvalid={canShowInvalid}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            
            isRequired={isRequired}
            isDisabled={isDisabled}
            
            getParsedValue={(newValue) => getParsedValue(newValue, flatOptions)}

            onCreateField={onCreateField}

            className={className}
            
            {...otherProps}        
        >
            {(inputProps, inputState) => {
                // If our options change and the formattedValue isn't in the list of options, we want to clear it
                useEffect(() => {
                    if (getOptionByValue(inputState.formattedValue, flatOptions) == null) {
                        inputState.formattedValue = null;
                    }
                }, [ getOptionByValue(inputState.formattedValue, flatOptions), inputState.formattedValue ]);

                function handleChange(newValue) {
                    // Since we're rendering 2 different versions of select
                    //  we need to use a Heap event to ensure they're consolidated in Heap
                    //  Additionally, the analytics team would like access to the selected value
                    trackHeapEvent('Select value changed', { name, value: newValue });

                    // If the selected option is our empty options placeholder for 
                    //  optional dropdowns, we want to clear the input value
                    inputProps.onChange(
                        newValue === EMPTY_OPTION.value 
                            ? null 
                            : newValue,
                    );
                }

                const commonProps = {
                    ...inputProps,
                    label,
                    placeholder: placeholderText,
                    isDisabled: inputProps.disabled,
                    isInline,
                    isInvalid: inputState.isInvalid,
                    shouldShowInvalid: inputState.shouldShowInvalid,
                    onChange: handleChange,
                };

                // Only insert the empty option if the user has
                //   selected something and we're optional
                const flatUpdatedOptions = inputProps.value !== null && !isRequired
                    ? [ EMPTY_OPTION, ...flatOptions ]
                    : flatOptions;

                const updatedFormattedOptions = inputProps.value !== null && !isRequired
                    ? [ EMPTY_OPTION, ...formattedOptions ]
                    : formattedOptions;
                
                return (
                    <ErrorMessageContainer
                        message={inputState.errorMessage}
                        isInvalid={inputState.shouldShowInvalid}
                        isInline={isInline}
                        messageStyle={errorMessageStyle}
                        className={inputProps.className}
                    >
                        <Choose>
                            <When condition={isNativeSelect}>
                                <SelectNative
                                    {...commonProps}

                                    options={updatedFormattedOptions}

                                    className={inputClassName}
                                />
                            </When>
                            <Otherwise>
                                <SelectDownshift
                                    {...commonProps}

                                    formattedOptions={updatedFormattedOptions}
                                    flatOptions={flatUpdatedOptions}
                                    inputClassName={inputClassName}
                                />
                            </Otherwise>
                        </Choose>
                    </ErrorMessageContainer>
                );
            }}
        </InputController>
    );
}

Select.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: MessagePropType,
    placeholder: MessagePropType,

    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                label: MessagePropType,
                value: PropTypes.any.isRequired,
                disabled: PropTypes.bool,
            }),
            PropTypes.shape({
                label: MessagePropType,
                options: PropTypes.oneOfType([
                    PropTypes.shape({
                        label: MessagePropType,
                        value: PropTypes.any.isRequired,
                        disabled: PropTypes.bool,
                    }),
                    PropTypes.object, // mobx observable array
                    PropTypes.array,
                ]).isRequired,
            }),
            PropTypes.any, // Cover cases where value/label is the same
        ]),
    ).isRequired,
    optionsSort: PropTypes.func,
    renderOptionLabel: PropTypes.func,

    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isInline: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,

    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    canShowInvalid: PropTypes.bool,
    errorMessageStyle: PropTypes.oneOf(Object.values(ERROR_MESSAGE_STYLES)),

    className: PropTypes.string,
    inputClassName: PropTypes.string,

    onCreateField: PropTypes.func,
};

Select.defaultProps = {
    id: undefined,
    value: undefined,
    label: undefined,
    placeholder: undefined,

    optionsSort: undefined,
    renderOptionLabel: undefined,

    isDisabled: false,
    isRequired: true,
    isInline: false,

    onChange: undefined,
    onBlur: undefined,

    onValidityChange: undefined,
    onValidate: undefined,

    canShowInvalid: false,
    errorMessageStyle: ERROR_MESSAGE_STYLES.DEFAULT,

    className: undefined,
    inputClassName: undefined,

    onCreateField: undefined,
};


function getOptionByValue(value, options) {
    // We're using == here since SelectNative makes all values strings, 
    //  we want to ensure we're selecting the real value stored in options
    return options.find(opt => opt.value == value);
}

function getParsedValue(newValue, options) {
    // Value must be within the list of options or if its our 
    //  empty value we want the parsed value to be null.
    const selectedOption = getOptionByValue(newValue, options);

    return selectedOption
        ? selectedOption.value 
        : null;
}

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage 
        id="base-ui.select.default-error"
        defaultMessage="Please select an option"
    />
);

const MESSAGES = defineMessages({
    DEFAULT_PLACEHOLDER: {
        id: 'base-ui.select.placeholder',
        defaultMessage: 'Select an option',
    },
});

const EMPTY_OPTION = { value: '-', label: '-' }; // TODO: To avoid assumptions, refactor value to be a Symbol?

export default observer(Select);
