import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Colours, Typography, AnchorAnimation } from '@ratehub/base-ui';


const ALIGNMENT_OPTIONS = {
    left: 'left',
    center: 'center',
    right: 'right',
    inherit: 'inherit',
};

function Text({
    textColour,
    textAlign,

    as,
    content,
    dataName,
    ...otherProps
}) {
    /**
     * Default `as` is a p tag, but Next.JS will remove invalid HTML and will trigger a server/client mismatch
     * when you try to insert invalid html, e.g. <p>Woof</p> into a p tag.
     */
    if (isContentRichText(content)) {
        as = 'div';
    }

    return (
        <TextElement
            as={as}
            textColour={getTextColour(textColour)}
            textAlign={getTextAlignment(textAlign)}

            data-name={dataName}

            dangerouslySetInnerHTML={{
                __html: content,
            }}

            {...otherProps}
        />
    );
}

Text.propTypes = {
    textColour: PropTypes.oneOf([ ...Object.keys(Colours), 'inherit' ]),
    textAlign: PropTypes.oneOf([ ...Object.keys(ALIGNMENT_OPTIONS), 'inherit' ]),

    as: PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span' ]),
    content: PropTypes.string.isRequired,
    dataName: PropTypes.string,
};

Text.defaultProps = {
    textColour: 'inherit',
    textAlign: 'inherit',

    as: 'span',
    dataName: undefined,
};

const TextElement = styled.span`
    font-family: ${Typography.FONT_FAMILY_STACK};
    color: ${props => props.textColour};
    text-align: ${props => props.textAlign};

    a {
        line-height: inherit;
        ${AnchorAnimation};
    }

    b,
    strong {
        font-weight: ${Typography.WEIGHTS.MEDIUM};
    }
`;

function getTextAlignment(alignment) {
    return alignment === 'inherit'
        ? 'inherit'
        : ALIGNMENT_OPTIONS[alignment];
}

function getTextColour(colour) {
    return colour === 'inherit'
        ? 'inherit'
        : Colours[colour];
}

// Assumes all rich text is from WP and starts with an HTML tag (which it does)
function isContentRichText(string) {
    return string.charAt(0) === '<';
}

Text.alignmentOptions = ALIGNMENT_OPTIONS;
Text.hasSpacingOptions = true;

export default Text;
