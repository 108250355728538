import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import classNames from 'classnames';

import messageToString from '../functions/messageToString';
import getTermsURL from '../functions/getTermsURL';
import getPrivacyPolicyURL from '../functions/getPrivacyPolicyURL';
import getContactRatehubURL from '../functions/getContactRatehubURL';
import MagicLinkSignInMessagesPropType from '../definitions/MagicLinkSignInMessagesPropType';
import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import useSession from '../hooks/useSessionStore';

import Checkbox from './Checkbox';
import Form from './Form';
import PrimaryButton from './PrimaryButton';
import Paragraph from './Paragraph';
import GoogleSignInButton from './GoogleSignInButton';
import CondensedTheme from './CondensedTheme';
import QuestionCompact from './QuestionCompact';
import AnimatedOpacityContainer from './AnimatedOpacityContainer';


function MagicLinkSignIn({
    email,
    onSubmit,
    messages,
    hideDescription,
    shouldShowNewsletter,
    shouldShowGoogleSignIn,
    onGoogleSignIn,
}) {
    const intl = useIntl();
    const sessionStore = useSession();

    const [ isNewsletterChecked, setIsNewsLetterChecked ] = useState(false);
    const [ isGoogleSignInButtonLoaded, setIsGoogleSignInButtonLoaded ] = useState(false);

    const defaultEmail = sessionStore?.hasFetchedSession && !sessionStore?.isLoggedIn
        ? sessionStore.email
        : undefined;

    function handleGoogleSignIn(providerToken) {
        return onGoogleSignIn?.(providerToken, isNewsletterChecked);
    }

    return (
        <StyledForm
            onSubmit={({ email: submittedEmail }) => onSubmit(submittedEmail, isNewsletterChecked)}
            shouldOnlyButtonSubmit={false}
        >
            <h1 className={classNames('title', 'rh-title-2xl', 'showDot', {
                withoutSubtitle: !messages?.MAGIC_LINK_SIGN_IN_SUBTITLE,
            })}
            >
                {messageToString(messages?.MAGIC_LINK_SIGN_IN_TITLE ?? MESSAGES.MAGIC_LINK_SIGN_IN_TITLE, intl)}
            </h1>

            <If condition={messages?.MAGIC_LINK_SIGN_IN_SUBTITLE}>
                <Paragraph
                    as="h2"
                    className="subtitle"
                    message={messages.MAGIC_LINK_SIGN_IN_SUBTITLE}
                />
            </If>

            <If condition={!hideDescription}>
                <Paragraph
                    className="description"
                    message={messages?.DESCRIPTION ?? MESSAGES.DESCRIPTION}
                />
            </If>

            <If condition={shouldShowGoogleSignIn}>
                <div
                    className="google-sign-in-section"
                >
                    <GoogleSignInButton
                        locale={intl.locale}
                        onSignIn={handleGoogleSignIn}
                        onLoad={() => setIsGoogleSignInButtonLoaded(true)}
                    />
                    <AnimatedOpacityContainer
                        isOpaque={isGoogleSignInButtonLoaded}
                    >
                        <div
                            className={classNames('sign-in-option-divider', { 'with-newsletter-option': shouldShowNewsletter })}
                        >
                            <FormattedMessage
                                id="accounts.magic-sign-in.sign-in-option-divider"
                                defaultMessage="or"
                            />
                        </div>
                    </AnimatedOpacityContainer>
                </div>
            </If>

            <div className="email-input-container rh-mt-0_5">
                <div className="input-wrapper">
                    <CondensedTheme>
                        <QuestionCompact
                            field={{
                                name: 'email',
                                type: 'email',
                                value: email ?? defaultEmail,
                            }}
                            labelMessage={MESSAGES.EMAIL_LABEL}
                            shouldRemoveMargin={true}
                        />
                    </CondensedTheme>
                </div>

                <PrimaryButton
                    type="submit"
                    className="submit"
                    data-name="request-magic-link"
                    size="medium"
                    message={messages?.MAGIC_LINK_SIGN_IN_CTA ?? MESSAGES.MAGIC_LINK_SIGN_IN_CTA}
                />
            </div>
            <If condition={shouldShowNewsletter}>
                <div
                    className="newsletter-subscription-container rh-mt-2"
                >
                    <Checkbox
                        name="newsletter-subscription-checkbox"
                        label={messages?.NEWSLETTER_SUBSCRIPTION_LABEL ?? MESSAGES.NEWSLETTER_SUBSCRIPTION_LABEL}
                        value={isNewsletterChecked}
                        onChange={isChecked => setIsNewsLetterChecked(isChecked)}
                    />
                    <p
                        className="newsletter-subscription-agreement rh-fg-stone-darkest rh-text-align-center rh-mt-1 rh-text-s"
                    >
                        <FormattedMessage
                            id="accounts.magic-sign-in.newsletter-subscription-agreement"
                            defaultMessage={'By submitting your email address, you acknowledge and agree to '
                                + 'Ratehub.ca\'s <terms>Terms of Use</terms> and <privacyPolicy>Privacy Policy</privacyPolicy>. '
                                + '<contactUs>Contact us</contactUs> for more information. You can unsubscribe at any time'
                            }
                            values={{
                                terms: msg => (
                                    <a
                                        href={getTermsURL(intl.locale)}
                                        target="_blank"
                                    >
                                        {msg}
                                    </a>
                                ),
                                privacyPolicy: msg => (
                                    <a
                                        href={getPrivacyPolicyURL(intl.locale)}
                                        target="_blank"
                                    >
                                        {msg}
                                    </a>
                                ),
                                contactUs: msg => (
                                    <a
                                        href={getContactRatehubURL(intl.locale)}
                                        target="_blank"
                                    >
                                        {msg}
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </div>
            </If>
        </StyledForm>
    );
}

MagicLinkSignIn.propTypes = {
    email: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,

    messages: MagicLinkSignInMessagesPropType,
    hideDescription: PropTypes.bool,
    shouldShowNewsletter: PropTypes.bool,
    shouldShowGoogleSignIn: PropTypes.bool,
    onGoogleSignIn: PropTypes.func,
};

MagicLinkSignIn.defaultProps = {
    email: undefined,
    messages: undefined,
    hideDescription: false,
    shouldShowNewsletter: false,
    shouldShowGoogleSignIn: false,
    onGoogleSignIn: undefined,
};

const StyledForm = styled(Form)`

    > .title {
        text-align: center;
    }

    > .title.withoutSubtitle {
        margin-bottom: ${Sizes.SPACING.ONE_AND_A_HALF};
    }

    > .subtitle {
        margin: 0 0 ${Sizes.SPACING.THREE} 0;
        text-align: center;
    }

    > .description {
        margin-bottom: ${Sizes.SPACING.TWO};
        text-align: center;
    }

    > .google-sign-in-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .sign-in-option-divider {
            margin-top: ${Sizes.SPACING.HALF};
        }

        > .sign-in-option-divider.with-newsletter-option {
            margin-bottom: ${Sizes.SPACING.HALF};
        }
    }

    > .email-input-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: ${Sizes.SPACING.ONE_AND_A_HALF};

        > .input-wrapper {
            flex-grow: 1;
            flex-basis: 60%;
            min-width: 300px;
        }

        > .submit {
            flex: 1;
            min-width: 200px;
        }
    }

    > .newsletter-subscription-container {
        > .newsletter-subscription-agreement {
            > a {
                color: ${Colours.STONE_DARKEST};
            }
        }
    }
`;

const MESSAGES = defineMessages({
    MAGIC_LINK_SIGN_IN_TITLE: {
        id: 'Accounts.MagicLinkSignIn.Title',
        defaultMessage: 'Enter your e-mail address to join Ratehub or sign in',
    },
    MAGIC_LINK_SIGN_IN_CTA: {
        id: 'Accounts.MagicLinkSignIn.CTA',
        defaultMessage: 'Continue',
    },
    EMAIL_LABEL: {
        id: 'Accounts.MagicLinkSignIn.EmailLabel',
        defaultMessage: 'Email address',
    },
    EMAIL_PLACEHOLDER: {
        id: 'Accounts.MagicLinkSignIn.EmailPlaceholder',
        defaultMessage: 'Enter email',
    },
    DESCRIPTION: {
        id: 'Accounts.MagicLinkSignIn.Description',
        defaultMessage: 'We will send you an email with a secure sign-in link. Please click on the link to access your account.',
    },
    NEWSLETTER_SUBSCRIPTION_LABEL: {
        id: 'Accounts.MagicLinkSignIn.NewsletterLabel',
        defaultMessage: 'sign up for our newsletter',
    },
});

export default observer(MagicLinkSignIn);
