import { useIntl } from 'react-intl';

import { getSortedProvinceOptions } from '@ratehub/base-ui';

/**
 * Parse out the region keys from each quote start slug and combine the list into a 
 * unique list of available provinces
 * 
 * @param {Array} providerRedirects
 */
function getProvincesFromProviders(providerRedirects) {
    const allProvinces = Object.values(providerRedirects).reduce((allProvinces, providerRedirect) => {
        let productProvinces = providerRedirect.reduce((regionProviders, provider) => {
            return [
                ...regionProviders, 
                ...Object.keys(provider.regions),
            ];
        }, []);
      
        return [ ...allProvinces, ...productProvinces ];
    }, []);
  
    return [ ...new Set(allProvinces) ];
}

/**
 * From all of the provinces supported, narrow the list down from the provider 
 * data from the quote-start faas
 * 
 * @param {*} providerRedirects ProviderRedirects is data that is returned back 
 *   from the enrichHomeInsuranceQuoteLauncher props. This data is formatted 
 *   data from the quote-start faas
 * @returns 
 */
export default function getProvinceOptions(providerRedirects) {
    const intl = useIntl();
    const provinces = getSortedProvinceOptions(intl);
    const providerProvinces = getProvincesFromProviders(providerRedirects);

    return provinces.reduce((results, sortedProvince) => {
        if (providerProvinces.includes(sortedProvince.value)) {
            results.push(sortedProvince);
        }    

        return results;
    }, []);
}
