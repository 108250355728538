import { defineMessages } from 'react-intl';


export default defineMessages({
    ratesUpdated: {
        id: 'web-components.rates-updated',
        defaultMessage: 'Rates updated: <longDate>{timestamp, date, long}, {timestamp, time, short}</longDate>',
    },
    chooseLocation: {
        id: 'web-components.location-selector.label',
        defaultMessage: 'Choose location',
    },
    insuranceType: {
        id: 'web-components.insuranceType',
        defaultMessage: 'Insurance type',
    },
    province: {
        id: 'web-components.province',
        defaultMessage: 'Province',
    },
});
