import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';

import PositionOffscreenStyles from '../styles/PositionOffscreenStyles';
import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import toKebabCase from '../functions/toKebabCase';
import HorizontalTabs from './HorizontalTabs';
import TabList from './TabList';
import TabListButton from './TabListButton';
import Select from './Select';


const ORIENTATIONS = {
    HORIZONTAL: 'horizontal',
    VERTICAL_ACCORDION: 'vertical', // vertical orientation with accordion compact form
    VERTICAL_DROPDOWN: 'verticalDropdown', // vertical orientation with dropdown compact form
};

const TAB_PANEL_ID_PREFIX = 'panel';
const TAB_ID_PREFIX = 'tab';

function TabContainer({
    orientation,
    initialTabIndex,
    forceRenderTabs,
    tabListVariant,
    tabListSize,
    children,
    size,
    onTabChange,
    dropdownText,
    ...otherProps
}) {
    const [ activeTab, setActiveTab ] = useState(initialTabIndex);

    const tabs = React.Children.map(children, child => {
        const tab = {
            title: child?.props?.title ?? '',
            body: child?.props?.children,
        };

        if (!tab.title || !tab.body) {
            return; // tab is invalid, skip it
        }

        return tab;
    });
    const tabOptions = tabs.map((tab, index) => ({
        value: index,
        label: tab.title,
    }));

    const COMPACT_MODE_BREAKPOINT = 600;

    const isCompact = size.width < COMPACT_MODE_BREAKPOINT;
    const isHorizontal = orientation === ORIENTATIONS.HORIZONTAL;

    // We want the button & list variants to match
    const tabListButtonVariant = tabListVariant;
    const tabListButtonSize = tabListSize;

    function handleTabChange(selectedIndex) {
        const tabIndex = parseInt(selectedIndex);
        onTabChange?.(tabIndex);
        setActiveTab(tabIndex);
    }

    return (
        <Container
            isHorizontal={isHorizontal}
            isCompact={isCompact}
            tabListSize={tabListSize}
            {...otherProps}
        >
            <Choose>
                <When condition={isHorizontal}>
                    <HorizontalTabs
                        className="tab-horizontal-container"
                        tabs={tabs.map(tab => tab.title)}
                        initialTabIndex={initialTabIndex}
                        onTabChange={handleTabChange}
                        tabPanelIdPrefix={TAB_PANEL_ID_PREFIX}
                        tabIdPrefix={TAB_ID_PREFIX}
                    />
                </When>

                <When condition={isCompact && orientation === ORIENTATIONS.VERTICAL_DROPDOWN}>
                    <div className="tab-control-dropdown">
                        <label
                            className="tab-controls-label"
                            htmlFor="tabControls"
                        >
                            {dropdownText}
                        </label>

                        <Select
                            name="tabControls"
                            options={tabOptions}
                            value={activeTab}
                            onChange={handleTabChange}
                        />
                    </div>
                    <div className="dropdown-separator" />
                </When>

                <Otherwise>
                    <If condition={!isCompact}>
                        <TabList
                            className="tab-vertical-container"

                            tabs={tabs}
                            initialTabIndex={initialTabIndex}
                            onTabChange={handleTabChange}
                            tabIdPrefix={TAB_ID_PREFIX}
                            tabPanelIdPrefix={TAB_PANEL_ID_PREFIX}

                            variant={tabListVariant}
                            size={tabListSize}
                        />
                    </If>
                </Otherwise>
            </Choose>

            <For
                each="tab"
                of={tabs}
                index="index"
            >
                <If condition={!isHorizontal && isCompact && orientation === ORIENTATIONS.VERTICAL_ACCORDION}>
                    <TabListButton
                        id={`${TAB_ID_PREFIX}_${toKebabCase(tab.title)}`}
                        className="tab-list-button"
                        onClick={() => handleTabChange(index)}
                        label={tab.title}
                        isSelected={index === activeTab}
                        variant={tabListButtonVariant}
                        size={tabListButtonSize}
                    />
                </If>

                <If condition={forceRenderTabs || index === activeTab}>
                    <div
                        className="tab-panel-container"
                        role="tabpanel"
                        aria-hidden={forceRenderTabs ? activeTab !== index : false}
                        key={tab.title}
                        id={`${TAB_PANEL_ID_PREFIX}_${toKebabCase(tab.title)}`}
                        aria-labelledby={`${TAB_ID_PREFIX}_${toKebabCase(tab.title)}`}
                    >
                        {tab.body}
                    </div>
                </If>
            </For>
        </Container>
    );
}

TabContainer.propTypes = {
    orientation: PropTypes.oneOf(Object.values(ORIENTATIONS)),
    initialTabIndex: PropTypes.number,
    forceRenderTabs: PropTypes.bool,
    tabListVariant: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    tabListSize: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
    // children: expects 0 or more Tab components, each with a title prop and children of its own
    children: PropTypes.any,
    size: PropTypes.object.isRequired,
    onTabChange: PropTypes.func,
    dropdownText: PropTypes.string,     // only relevant for narrow devices and ORIENTATION.VERTICAL_DROPDOWN
};

TabContainer.defaultProps = {
    orientation: ORIENTATIONS.HORIZONTAL,
    initialTabIndex: 0,
    forceRenderTabs: false,
    tabListVariant: 'dark',
    tabListSize: 'medium',
    children: undefined,
    onTabChange: undefined,
    dropdownText: undefined,
};

const Container = styled.div`
    display: flex;
    flex-direction: ${props => props.isHorizontal ? 'column' : 'row'};

    ${props => !props.isHorizontal && props.isCompact && `
        flex-wrap: wrap;
    `}

    .tab-horizontal-container {
        flex-basis: auto;
    }

    .tab-vertical-container {
        ${props => props.tabListSize === 'small' && `
            flex-basis: 25%;
        `}
        ${props => props.tabListSize !== 'small' && `
            flex-basis: 35%;
        `}
        margin-right: ${Sizes.SPACING.TWO};
    }

    > .tab-control-dropdown {
        flex-basis: 100%;

        display: block;
        position: relative;
        max-width: 100%;
        margin-bottom: ${Sizes.SPACING.TWO};

        > .tab-controls-label {
            ${PositionOffscreenStyles};
        }
    }

    > .dropdown-separator {
        flex-basis: 100%;
        border-bottom: 1px solid ${Colours.STONE};
        margin-bottom: ${Sizes.SPACING.TWO_AND_A_HALF};
    }

    > .tab-list-button {
        flex-basis: 100%;
        flex-shrink: 0;
    }

    > .tab-panel-container {
        ${props => props.isHorizontal && `
            flex-basis: auto;
        `}
        ${props => !props.isHorizontal && props.isCompact && `
            flex-basis: 100%;
        `}
        ${props => !props.isHorizontal && !props.isCompact && props.tabListSize === 'small' && `
            flex-basis: 75%;
        `}
        ${props => !props.isHorizontal && !props.isCompact && props.tabListSize !== 'small' && `
            flex-basis: 65%;
        `}
        display: block;
        /* matches default block-margin-start on paragraphs inside RichTextBlocks */
        margin-bottom: .83em;
        &[aria-hidden=true] {
            display: none;
        }
    }
`;

export default withSize({ noPlaceholder: true })(TabContainer);
