import React, { Suspense, useRef, useState, useEffect } from 'react';

import getComponentDisplayName from '../functions/getComponentDisplayName';
import CircleSpinner from './CircleSpinner';


// Ideally LazyComponent would be using React.lazy so it aligns with our Suspense usage
function withSuspenseIntersectionObserver(LazyComponent, { 
    fallback = <CircleSpinner />, 
    containerAs = 'div', 

    root, 
    rootMargin, 
    threshold = 0,
} = {}) {
    function WrapperComponent(componentProps) {
        const containerRef = useRef();
        const [ hasLoaded, setHasLoaded ] = useState(false);

        // Once the ref is set up, we want to create an IntersectionObserver
        //  on the container element to track when its in view.
        //  When the container is in view, we can render our LazyComponent
        useEffect(() => {
            const targetElement = containerRef?.current;

            if (!targetElement) {
                return;
            }
            
            const observer = new IntersectionObserver(
                ([ entry ]) => {
                    if (entry.isIntersecting) {
                        setHasLoaded(true);
                    }
                },
                {
                    root,
                    rootMargin,
                    threshold,
                },
            );

            observer.observe(targetElement);

            return () => observer.disconnect();
        }, [ containerRef?.current ]);

        const ContainerElement = containerAs;

        return (
            <Suspense fallback={fallback}>
                <ContainerElement ref={containerRef}>
                    <If condition={hasLoaded}>
                        <LazyComponent {...componentProps} />
                    </If>
                </ContainerElement>
            </Suspense>
        );
    }

    WrapperComponent.displayName = `withSuspenseIntersectionObserver(${getComponentDisplayName(LazyComponent)})`;

    return WrapperComponent;
}

export default withSuspenseIntersectionObserver;