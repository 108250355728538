import { css } from 'styled-components';

import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import {
    ACTIVE_BACKGROUND_COLOUR,
    BOX_SHADOW_BLUEBERRY,
    BOX_SHADOW_STONE,
} from '../definitions/SharedInputStyles';


export default css`
    max-width: ${Sizes.ICONS.L};
    max-height: ${Sizes.ICONS.L};
    
    padding: 0;

    outline: none;
    border: none;
    
    background: transparent;

    cursor: pointer;

    border-radius: 50%;

    > svg {
        width: ${Sizes.ICONS.L};
        height: ${Sizes.ICONS.L};

        stroke-width: 1;
        stroke: ${Colours.BLACKBERRY};
        fill: transparent;

        transition:
            stroke 300ms,
            fill 300ms;

        > rect {
            display: none;
        }

        > circle {
            display: block;
        }
    }

    &:hover,
    &:focus,
    &:active {
        > svg {
            stroke: ${Colours.BLUEBERRY_DARK};
        }
    }

    &:hover {
        box-shadow: ${BOX_SHADOW_STONE};
    }

    &:focus {
        box-shadow: ${BOX_SHADOW_BLUEBERRY};
    }

    &:active {
        > svg {
            fill: ${ACTIVE_BACKGROUND_COLOUR};
            stroke: ${Colours.BLUEBERRY_DARKEST};
        }
    }

    &:disabled {
        > svg {
            cursor: default;
            fill: none;
            stroke: ${Colours.STONE_DARK};
        }
    }

`;