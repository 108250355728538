import { Languages } from '@ratehub/base-ui';
import { fetchCryptoProducts } from '@ratehub/inv-common';

import { Products } from '../definitions/Products';
import enrichDepositsProduct from './enrichDepositsProduct';


/**
 *
 * @param {Array<object>} products
 * @property {Array<object>} requested Contains the ID of the requested product slot
 * @param {Object} options
 * @property {string} options.language Language code
 */
async function enrichCryptoProducts(productSlots, options) {
    // Build list of IDs from the products added in the CMS
    const productIDs = productSlots.reduce((acc, cur) => {
        cur.requested.forEach(product => acc.push(product.id));

        return acc;
    }, []);

    const productDetails = await fetchCryptoProducts(productIDs);

    // Attempt to match the cmsEntry productID with the fetched productID
    // to grab the additional API product data.
    productSlots.forEach(slot => {
        slot.requested.forEach(requestedProduct => {
            const matchedProduct = productDetails.find(
                detail => detail?.id == requestedProduct.id, // Using == because the id may be a string or int
            );

            return enrichProduct(requestedProduct, matchedProduct, slot, options);
        });
    });
}

function enrichProduct(cmsEntry, apiEntry, slot, { language }) {
    const normalizedAPIEntry = normalizeWordpressAPIEntry(apiEntry, language);
    enrichDepositsProduct(cmsEntry, normalizedAPIEntry, slot);

    Object.assign(slot, {
        providerSlug: language === Languages.ENGLISH
            ? apiEntry.rh_provider.slug.en
            : apiEntry.rh_provider.slug.fr,
    });
}

// Shapes the wordpress response in a way enrichDepositsProduct expects.
function normalizeWordpressAPIEntry(apiEntry, language) {
    const isFrench = language === Languages.FRENCH;

    return {
        id: parseInt(apiEntry.id),
        title: apiEntry.title.rendered,
        apply_url: isFrench
            ? apiEntry.acf.apply_url.fr
            : apiEntry.acf.apply_url.en,
        badge_description: isFrench
            ? apiEntry.acf.badge_description?.fr
            : apiEntry.acf.badge_description?.en,
        monetized: apiEntry.acf.monetized,
        // Sponsored isn't returned in the API response,
        // but enrichDepositsProduct will check for the cmsEntry override.
        sponsored: false,
        provider: {
            name: isFrench
                ? apiEntry.rh_provider.name.fr
                : apiEntry.rh_provider.name.en,
            logo: isFrench
                ? apiEntry.rh_provider.logo.fr
                : apiEntry.rh_provider.logo.en,
            slug: apiEntry.acf.provider,
        },
        productType: Products.CRYPTO,
    };
}

export default enrichCryptoProducts;
