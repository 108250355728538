// Note that there are two places where a type is set for a post.
// One is content.type, which is set by Wordpress and is typically either just 'page' or 'post'.
// The other is Ratehub's type, which is an ACF field (page_settings_page_type).
// These page types represent our ACF field, not Wordpress' post type.

const PageTypes = {
    RATES: 'rates',
    HOME: 'home',
    CALCULATOR: 'calculator',
    EDUCATION: 'education',
    BLOG: 'blog',
    BLOG_POST: 'blog-post',
};

export default PageTypes;
