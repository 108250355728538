import { css } from 'styled-components';

import { Colours, Sizes, Typography } from '@ratehub/base-ui';

import { ORDERED_LIST_VARIANT } from '../../src/definitions/OrderedListType';


const CIRCLE_ORDERED_ITEM_COUNTER_STYLE = css`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    flex-shrink: 0;

    width: ${Sizes.ICONS.L};
    height: ${Sizes.ICONS.L};

    font-size: ${Sizes.FONTS['M']};
    font-weight: ${Typography.WEIGHTS.MEDIUM};

    border-radius: 100%;

    box-sizing: border-box;

    color: ${Colours.BLUEBERRY_DARK};
    background-color: ${Colours.BLUEBERRY_LIGHTEST};
`;

const DEFAULT_ORDERED_ITEM_COUNTER_STYLE = css`
    font-size: ${Sizes.ICONS['2XL']};
    font-weight: ${Typography.WEIGHTS.MEDIUM};
    line-height: 0.95; /* Adjustment to align top of the pseudo element with the title */

    color: ${Colours.BLUEBERRY_DARK};
`;

const CIRCLE_ORDERED_ITEM_TITLE_STYLE = css`
    margin-bottom: ${Sizes.SPACING.THREE_QUARTERS};
    font-size: ${Sizes.FONTS.L};
    line-height: ${Sizes.LINE_HEIGHT.L};
`;

const DEFAULT_ORDERED_ITEM_TITLE_STYLE = css`
    margin-bottom: ${Sizes.SPACING.THREE_QUARTERS};
`;

const CIRCLE_ORDERED_ITEM_PARAGRAPH_STYLE = css`
    font-size: ${Sizes.FONTS.S};
    line-height: calc(${Sizes.SPACING.ONE} + ${Sizes.SPACING.THREE_QUARTERS});
`;

const ITEM_COUNTER_STYLE = {
    [ ORDERED_LIST_VARIANT.CIRCLE ]: CIRCLE_ORDERED_ITEM_COUNTER_STYLE,
    [ ORDERED_LIST_VARIANT.DEFAULT ]: DEFAULT_ORDERED_ITEM_COUNTER_STYLE,
};

const ITEM_TITLE_STYLE = {
    [ ORDERED_LIST_VARIANT.CIRCLE ]: CIRCLE_ORDERED_ITEM_TITLE_STYLE,
    [ ORDERED_LIST_VARIANT.DEFAULT ]: DEFAULT_ORDERED_ITEM_TITLE_STYLE,
};

export {
    ITEM_COUNTER_STYLE,
    ITEM_TITLE_STYLE,
    CIRCLE_ORDERED_ITEM_PARAGRAPH_STYLE,
};
