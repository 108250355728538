import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Config from '../definitions/Config';
import areFirstPartyCookiesEnabled from '../functions/areFirstPartyCookiesEnabled';
import getPrivacyPolicyURL from '../functions/getPrivacyPolicyURL';
import getRatehubHomePageURL from '../functions/getRatehubHomePageURL';
import useMessageBannerContext from './useMessageBannerContext';
import useErrorModal from './useErrorModal';


const COOKIE_DISPLAY_MODE = {
    BANNER: 'banner',
    MODAL: 'modal',
};

/**
 * Detect if first party cookies are enabled and show a prompt if they are not.
 * Don't use this hook if you don't want to show a prompt. 🤷
 * 
 * @param {object} options
 * @param {'banner' | 'modal'} options.display 
 */
function useCookiePrompt({
    display = COOKIE_DISPLAY_MODE.BANNER,
} = {}) {
    const intl = useIntl();
    const bannerContext = useMessageBannerContext();
    const errorModalState = useErrorModal();

    const [ areCookiesEnabled ] = useState(() => areFirstPartyCookiesEnabled());

    useEffect(
        () => {
            if (areCookiesEnabled || !Config.ENABLE_COOKIE_PROMPT) {
                return; // do nothing
            }

            switch (display) {
                case COOKIE_DISPLAY_MODE.BANNER:
                    return void bannerContext.show({
                        callerName: 'useCookiePrompt',
                        variant: 'warning',
                        message: DEFAULT_COOKIE_BANNER_MESSAGE,
                        isCloseVisible: true, //! must be dismissable because it can cover content
                    });

                case COOKIE_DISPLAY_MODE.MODAL: {
                    return void errorModalState?.open({
                        title: DEFAULT_COOKIE_MODAL_TITLE,
                        description: <ModalDescription />,
                        acceptText: DEFAULT_COOKIE_MODAL_CTA,
                        onAccept() {
                            location.reload();

                            return false; // prevent the modal from closing
                        },
                        onClose() {
                            location.replace(new URL(getRatehubHomePageURL(intl), location.origin));

                            return false; // prevent the modal from closing
                        },
                        isCloseVisible: false,
                    });
                }
                default:
                    throw new Error(`[useCookiePrompt]: Invalid display mode: "${display}"; must be one of ${Object.keys(COOKIE_DISPLAY_MODE).join(', ')}`);
            }
        },
        [ areCookiesEnabled, display ]
    );
}

const DEFAULT_COOKIE_BANNER_MESSAGE = (
    <FormattedMessage
        id="base-ui.useCookiePrompt.banner.message"
        defaultMessage="<strong>Cookie notice:</strong> Ratehub.ca uses essential cookies to offer you a better browsing experience and personalized content. Please enable first party cookies for an optimized experience."
    />
);

const DEFAULT_COOKIE_MODAL_TITLE = (
    <FormattedMessage
        id="base-ui.useCookiePrompt.modal.title"
        defaultMessage="Cookie notice"
    />
);

function ModalDescription() {
    const { locale } = useIntl();
    const privacyPolicyURL = getPrivacyPolicyURL(locale);

    return (
        <FormattedMessage
            id="base-ui.useCookiePrompt.modal.description"
            defaultMessage="Ratehub.ca uses essential cookies necessary for navigating the page and providing product recommendations. In order for this section of the website to function properly, please update your browser settings and enable first party cookies. <a>Read our privacy and cookie policy.</a>"
            values={{
                a: (...chunks) => (
                    <a href={privacyPolicyURL}>
                        {chunks}
                    </a>
                ),
            }}
        />
    );
}

const DEFAULT_COOKIE_MODAL_CTA = (
    <FormattedMessage
        id="base-ui.useCookiePrompt.modal.cta"
        defaultMessage="Yes, I’ve enabled cookies"
    />
);

export { COOKIE_DISPLAY_MODE };
export default useCookiePrompt;