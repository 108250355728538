import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import MessagePropType from '../definitions/MessagePropType';
import Colours from '../definitions/Colours';
import renderMessage from '../functions/renderMessage';
import getFGColourClassName from '../functions/getFGColourClassName';
import IconMinus from './icons/IconMinus';
import IconPlus from './icons/IconPlus';
import IconArrow from './icons/IconArrow';
import IconArrowLeft from './icons/IconArrowLeft';
import IconArrowRight from './icons/IconArrowRight';

import TextElement from './TextElement';


function PageBehaviourButton({
    onClick,
    children,

    iconComponent,
    iconLocation = ICON_LOCATIONS.RIGHT,
    includeIconOutline = true,

    variant = VARIANTS.DARK,
    target = TARGETS.SELF,
    disabled,
    size = SIZES.LARGE,

    message,
    className,

    shouldLowerCaseMessage = true,

    ...otherProps
}) {
    const intl = useIntl();
    const Icon = iconComponent;
    const isIconArrow = iconComponent === IconArrow;
    const isIconLocatedLeft = iconComponent ? iconLocation === ICON_LOCATIONS.LEFT: undefined;
    const isIconLocatedRight = iconComponent ? iconLocation === ICON_LOCATIONS.RIGHT: undefined;
    const hasReducedSpacing = isIconArrow || !includeIconOutline;
    const isSmallWithIconOutline = size === SIZES.SMALL && includeIconOutline;

    if (iconComponent === IconArrowLeft || iconComponent === IconArrowRight) {
        // eslint-disable-next-line no-console
        console.warn('[PageBehaviourButton] IconArrowRight and IconArrowLeft are deprecated. Please use IconArrow instead.');
    }

    return (
        <Button
            type="button"
            onClick={onClick}
            variant={variant}
            target={target}
            disabled={disabled}
            className={classNames(className,
                'rh-box-sizing-border-box rh-display-flex rh-border-width-0 rh-p-0 rh-outline-none rh-font-family-gordita',
                disabled
                    ? getFGColourClassName(GLAMAZON_STATE_COLOURS[variant][target][STATES.DISABLED])
                    : getFGColourClassName(GLAMAZON_STATE_COLOURS[variant][target][STATES.DEFAULT]),
                {
                    'rh-title-l rh-mb-0 weight-medium': size === SIZES.EXTRA_LARGE,
                    'rh-text-xl weight-regular': size === SIZES.LARGE,
                    'rh-text-l weight-regular': size === SIZES.MEDIUM,
                    'rh-text-m weight-regular': size === SIZES.SMALL,
                    'rh-text-s weight-regular': size === SIZES.EXTRA_SMALL,
                    'is-icon-arrow': isIconArrow && !disabled,
                    'is-icon-left': isIconLocatedLeft,
                    'is-icon-right': isIconLocatedRight,
                    'rh-text-lowercase': shouldLowerCaseMessage,
                    [`hover--${getFGColourClassName(GLAMAZON_STATE_COLOURS[variant][target][STATES.HOVER])} focus--${getFGColourClassName(GLAMAZON_STATE_COLOURS[variant][target][STATES.HOVER])} active--${getFGColourClassName(GLAMAZON_STATE_COLOURS[variant][target][STATES.ACTIVE])}`]: !disabled,
                },
            )}
            {...otherProps}
        >
            <If condition={isIconLocatedLeft}>
                <Icon
                    className={getIconClassName(iconComponent, isIconArrow)}
                    outlineWidth={
                        isIconArrow || !includeIconOutline ? '0' : '3px'
                    }
                    strokeWidth={hasReducedSpacing ? '2px' : '3px'}
                    width={getIconSize({ isIconArrow, size, includeIconOutline })}
                    height={getIconSize({ isIconArrow, size, includeIconOutline })}
                    direction={isIconArrow ? 'left' : undefined}
                />
            </If>

            <TextElement
                as="span"
                className={classNames('text-wrapper', {
                    'rh-ml-1': isIconLocatedLeft && (!hasReducedSpacing && !isSmallWithIconOutline),
                    'rh-ml-0_75': isIconLocatedLeft && isSmallWithIconOutline,
                    'rh-ml-0_125': isIconLocatedLeft && hasReducedSpacing,
                    'rh-mr-1': isIconLocatedRight && (!hasReducedSpacing && !isSmallWithIconOutline),
                    'rh-mr-0_75': isIconLocatedRight && isSmallWithIconOutline,
                    'rh-mr-0_125': isIconLocatedRight && hasReducedSpacing,
                })}
                {...renderMessage(message, intl, children, {
                    shouldLowerCaseMessage,
                })}
            />

            <If condition={isIconLocatedRight}>
                <Icon
                    className={getIconClassName(iconComponent, isIconArrow)}
                    outlineWidth={
                        isIconArrow || !includeIconOutline ? '0' : '3px'
                    }
                    strokeWidth={hasReducedSpacing ? '2px' : '3px'}
                    width={getIconSize({ isIconArrow, size, includeIconOutline })}
                    height={getIconSize({ isIconArrow, size, includeIconOutline })}
                    direction={isIconArrow ? 'right' : undefined}
                />
            </If>
        </Button>
    );
}

function getIconClassName(iconComponent, isIconArrow) {
    return classNames(
        {
            'rh-position-relative': isIconArrow,
            icon: true,
            invertable: iconComponent === IconMinus
                || iconComponent === IconPlus
                || iconComponent === IconArrowLeft
                || iconComponent === IconArrowRight,
        },
    );
}

function getIconSize({ isIconArrow, size, includeIconOutline }) {
    if (isIconArrow) {
        return '50';
    }

    if (size === SIZES.MEDIUM || size === SIZES.LARGE) {
        return '25';
    }

    return includeIconOutline ? '20' : '40';
}

const ICON_LOCATIONS = {
    LEFT: 'left',
    RIGHT: 'right',
};
const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};
const TARGETS = {
    SELF: 'self',
    BLANK: 'blank',
};
const SIZES = {
    EXTRA_SMALL: 'extra-small',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    EXTRA_LARGE: 'extra-large',
};
const STATES = {
    DEFAULT: 'default',
    HOVER: 'hover',
    ACTIVE: 'active',
    DISABLED: 'disabled',
};

PageBehaviourButton.propTypes = {
    onClick: PropTypes.func.isRequired,

    iconComponent: PropTypes.oneOfType([
        PropTypes.element, // TODO: Should be elementType??
        PropTypes.func,
        PropTypes.elementType,
    ]),
    iconLocation: PropTypes.oneOf(Object.values(ICON_LOCATIONS)),
    includeIconOutline: PropTypes.bool,

    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    target: PropTypes.oneOf(Object.values(TARGETS)),
    size: PropTypes.oneOf(Object.values(SIZES)),
    disabled: PropTypes.bool,
    // Either one can be passed, children will always take precedence
    message: MessagePropType,
    className: PropTypes.string,
    // PropTypes.node is incorrect, using any as temporary fix.
    // See https://ratehub.atlassian.net/browse/PINGU-254
    // Prefer to use PropTypes.elementType which is not available without update.
    children: PropTypes.any,

    shouldLowerCaseMessage: PropTypes.bool,
};

const GLAMAZON_STATE_COLOURS = {
    [VARIANTS.LIGHT]: {
        [TARGETS.SELF]: {
            [STATES.DEFAULT]: Colours.BLUEBERRY_LIGHT,
            [STATES.HOVER]: Colours.BLUEBERRY_LIGHTEST,
            [STATES.ACTIVE]: Colours.BLUEBERRY,
            [STATES.DISABLED]: Colours.STONE_DARKEST,
        },
        [TARGETS.BLANK]: {
            [STATES.DEFAULT]: Colours.COCONUT,
            [STATES.HOVER]: Colours.BLUEBERRY_LIGHT,
            [STATES.ACTIVE]: Colours.BLUEBERRY,
            [STATES.DISABLED]: Colours.STONE_DARKEST,
        },
    },
    [VARIANTS.DARK]: {
        [TARGETS.SELF]: {
            [STATES.DEFAULT]: Colours.BLUEBERRY_DARK,
            [STATES.HOVER]: Colours.BLUEBERRY,
            [STATES.ACTIVE]: Colours.BLUEBERRY_DARKEST,
            [STATES.DISABLED]: Colours.STONE_DARKEST,
        },
        [TARGETS.BLANK]: {
            [STATES.DEFAULT]: Colours.BLACKBERRY,
            [STATES.HOVER]: Colours.BLUEBERRY,
            [STATES.ACTIVE]: Colours.BLUEBERRY_DARK,
            [STATES.DISABLED]: Colours.STONE_DARKEST,
        },
    },
};

const Button = styled.button`
    align-items: center;

    transition: border-color 300ms, background-color 300ms;

    &:enabled {
        > .icon {
            stroke: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.DEFAULT]};
        }
        &:hover,
        &:focus {
            > .icon {
                stroke: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.HOVER]};

                &.invertable {
                    fill: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.HOVER]};
                    stroke: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.HOVER]};

                    path {
                        stroke: ${props => props.variant === VARIANTS.LIGHT ? Colours.BLACKBERRY : Colours.COCONUT};
                    }
                }
            }
        }

        &:active {
            > .icon {
                stroke: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.ACTIVE]};

                &.invertable {
                    fill: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.ACTIVE]};
                    stroke: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.ACTIVE]};

                    path {
                        stroke: ${props => props.variant === VARIANTS.LIGHT ? Colours.BLACKBERRY : Colours.COCONUT};
                    }
                }
            }
        }

        /* Arrow Animation */
        &.is-icon-arrow {
            > .icon {
                /* transitioning left/right instead of transform so we don't break
                the rotate() transform on the arrow */
                transition: left 200ms ease-out;

                left: 0;
            }

            &.is-icon-left:hover > .icon {
                left: -10px;
            }

            &.is-icon-right:hover > .icon {
                left: 10px;
            }
        }
    }

    &:disabled {
        > .icon {
            stroke: ${props => GLAMAZON_STATE_COLOURS[props.variant][props.target][STATES.DISABLED]};
        }
    }
`;

export default observer(PageBehaviourButton);
