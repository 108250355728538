import INPUT_TYPES from '../definitions/InputTypes';
import FIELD_TYPES from '../definitions/FieldTypes';


const MAX_RADIO_BUTTONS = 6;

/**
 * Based on field and input type override, return the input type to use.
 * 
 * @param {{ type: FIELD_TYPES[keyof FIELD_TYPES], options: Array<any> }} field 
 * @param {INPUT_TYPES[keyof INPUT_TYPES]} inputTypeOverride - if not passed, defaults based on field type. may be ignored if mismatched with field type.
 * @returns {INPUT_TYPES[keyof INPUT_TYPES]} inputType
 */
function getInputTypeByField({ type, options }, inputTypeOverride) {
    // TODO: Current usages are mismatching the type and component. We should not allow this, but as a workaround I'll reproduce the old behavior.
    if ([ INPUT_TYPES.CURRENCY, INPUT_TYPES.PERCENT, INPUT_TYPES.SELECTOR ].includes(inputTypeOverride)) {
        return inputTypeOverride;
    }

    switch (type) {
        case FIELD_TYPES.ADDRESS:
            return INPUT_TYPES.ADDRESS;
        case FIELD_TYPES.BOOLEAN: {
            // Allow sane override of component
            if ([ INPUT_TYPES.RADIO, INPUT_TYPES.DROPDOWN ].includes(inputTypeOverride)) {
                return inputTypeOverride;
            }

            return INPUT_TYPES.CHECKBOX;
        }
        case FIELD_TYPES.CHOICE: {
            // Allow sane override of component
            if ([ INPUT_TYPES.RADIO, INPUT_TYPES.DROPDOWN ].includes(inputTypeOverride)) {
                return inputTypeOverride;
            }

            return areOptionsGrouped(options) || options.length > MAX_RADIO_BUTTONS
                ? INPUT_TYPES.DROPDOWN
                : INPUT_TYPES.RADIO;
        }
        case FIELD_TYPES.CITY:
            return INPUT_TYPES.CITY;
        case FIELD_TYPES.DATE:
            return INPUT_TYPES.DATE;
        case FIELD_TYPES.EMAIL:
            return INPUT_TYPES.EMAIL;
        case FIELD_TYPES.MULTIPLE_CHOICE: {
            // Allow sane override of component
            if ([ INPUT_TYPES.CHECKBOX_EXPANDABLE ].includes(inputTypeOverride)) {
                return inputTypeOverride;
            }

            return INPUT_TYPES.CHECKBOX_GROUP;
        }
        case FIELD_TYPES.INTEGER:
        case FIELD_TYPES.FLOAT: {
            // Allow sane override of component
            if ([ INPUT_TYPES.CURRENCY, INPUT_TYPES.PERCENT, INPUT_TYPES.SELECTOR ].includes(inputTypeOverride)) {
                return inputTypeOverride;
            }

            return INPUT_TYPES.NUMBER;
        }
        case FIELD_TYPES.POSTAL_CODE:
            return INPUT_TYPES.POSTAL_CODE;
        case FIELD_TYPES.PHONE:
            return INPUT_TYPES.PHONE;
        case FIELD_TYPES.STRING: {
            // Allow sane override of component
            if ([ INPUT_TYPES.TEXTAREA ].includes(inputTypeOverride)) {
                return inputTypeOverride;
            }
            return INPUT_TYPES.TEXT;
        }
        // Default string to a text input if component is not specified.
        default:
            return INPUT_TYPES.TEXT;
    }
}

function areOptionsGrouped(options) {
    return !!options.find(o => o.options);
}

export default getInputTypeByField;