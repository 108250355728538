import { Config, BRANDS } from '@ratehub/base-ui';


const RH_DEV = 'https://www.ratehub.dev';
const RH_QA = 'https://qa.ratehub.ca';
const RH_PROD = 'https://www.ratehub.ca';

export default function getBlogHostname(brand) {
    // If no brand, assume ratehub.ca
    if (!brand) {
        return '';
    }

    const hostname = Config.RATEHUB_BASE_URL;

    if (brand === BRANDS.RH_INSURANCE) {
        if (hostname.includes('dev')) {
            return RH_DEV;
        }
        else if (hostname.includes('qa')) {
            return RH_QA;
        }
        else {
            return RH_PROD;
        }
    }

    // If some rando brando, assume it's not supported
    return '';
}
