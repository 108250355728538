import React, { useState } from 'react';
import styled from 'styled-components';

import {
    RemoveDefaultButtonStyles,
    Colours,
    Sizes,
} from '@ratehub/base-ui';

import NewsletterIconLabel from './NewsletterIconLabel';
import NewsletterSignUpModal from './NewsletterSignUpModal';


function SidebarNewsletterSignUpSmall() {
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    return (
        <>
            <NewsletterSignUpButton
                type="button"
                onClick={() => setIsModalOpen(true)}
                aria-haspopup="dialog"
                aria-pressed={isModalOpen}
                data-name="sidebarNewsletterSignupSmall-open-modal"
            >
                <NewsletterIconLabel hasHoverState={true} />
            </NewsletterSignUpButton>

            <NewsletterSignUpModal
                isModalOpen={isModalOpen}
                onModalClose={() => {
                    setIsModalOpen(false);
                }}
            />
        </>
    );
}

const NewsletterSignUpButton = styled.button`
    ${RemoveDefaultButtonStyles};

    margin: 0 auto;
    padding: ${Sizes.SPACING.ONE} ${Sizes.SPACING.THREE_QUARTERS};
    width: 100%;

    /* prevent browser styles from resetting font-size context in case any children use ems */
    font-size: inherit;

    transition: color 200ms ease-out;

    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        color: ${Colours.GRAPE_DARKEST};
    }
`;

export default SidebarNewsletterSignUpSmall;
