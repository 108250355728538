import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    Colours,
    Sizes,
    IconMinus,
    IconPlus,
    RemoveDefaultButtonStyles,
} from '@ratehub/base-ui';


function CollapsibleContainerButton({
    isExpanded,
    onClick,
    className,

    children,

    ...otherProps 
}) {
    const Icon = isExpanded ? IconMinus : IconPlus;

    return (
        <button
            onClick={onClick}
            type="button"
            className={classNames(
                className, 
                'rh-position-relative', 
                'rh-width-100p', 
                'rh-height-100p', 
                'weight-medium', 
                'rh-text-s', 
                'rh-text-align-left', 
                'rh-cursor-pointer',
                'hover--rh-fg-blueberry',
                'focus--rh-fg-blueberry',
            )}
            {...otherProps}
        >
            {children}

            <Icon
                className="icon rh-position-absolute rh-right-0"
                outlineWidth="0"
                stroke={Colours.STONE_DARKEST}
                strokeWidth="1px"
            />
        </button>
    );
}

CollapsibleContainerButton.propTypes = {
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    
    children: PropTypes.node.isRequired,
};

CollapsibleContainerButton.defaultProps = {
    isExpanded: false,
    className: undefined,
};

const StyledCollapsibleContainerButton = styled(CollapsibleContainerButton)`    
    ${RemoveDefaultButtonStyles};

    /* These styles were being overrided in RemoveDetaultButtonStyles
        so we cannot use atomic classes */
    padding-top: ${Sizes.SPACING.ONE};
    padding-bottom: ${Sizes.SPACING.ONE};
    padding-right: ${Sizes.SPACING.TWO_AND_A_HALF};
    color: ${Colours.STONE_DARKEST};

    transition: color 300ms ease;

    > .icon {
        top: 50%;
        transform: translateY(-50%);

        width: 30px;
        height: 30px;
    }

    &:hover,
    &:focus {
        > .icon {
            stroke: ${Colours.BLUEBERRY};
        }
    }

    &:active {
        /* This style was being overrided when using the atomic class */
        color: ${Colours.BLUEBERRY_DARK};

        > .icon {
            stroke: ${Colours.BLUEBERRY_DARK};
        }
    }
`;

export default StyledCollapsibleContainerButton;
