import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


/**
 * Submits to FaaS conversion service
 * 
 * @param {object} payload 
 * @returns {Promise<object>} conversion id
 */
export default function submitConversionPayload(payload) {
    return fetchJSON({
        url: Config.CONVERSION_SUBMIT_URL, 
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        },
    }, 'submitConversionPayload');
}
