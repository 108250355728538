import React from 'react';
import PropTypes from 'prop-types';


/** This component replaces nodes in our statically rendered tree which we want to supress the hydration of */
function ServerOnlyReplacementBlock({ As }) {
    return (
        <As
            suppressHydrationWarning
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: '' }}
        />
    );
}

ServerOnlyReplacementBlock.propTypes = {
    As: PropTypes.string,
};

ServerOnlyReplacementBlock.defaultProps = {
    As: 'div',
};

ServerOnlyReplacementBlock.blockKey = 'rh/server-rendered-replacement';
// ServerOnlyReplacementBlock.hasHeading = false;
ServerOnlyReplacementBlock.requiresLayoutRow = true;

export default ServerOnlyReplacementBlock;
