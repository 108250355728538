/* eslint-disable max-len */
/* THIS IS GENERATED BY generateBlockMaps.js - PLEASE DO NOT EDIT */
import {
    HomeInsuranceQuoteLauncher,
    LifeInsuranceQuoteLauncher,
} from '@ratehub/web-components';


const BLOCK_TO_COMPONENT_MAP = {
    [HomeInsuranceQuoteLauncher.blockKey]: { render: HomeInsuranceQuoteLauncher },
    [LifeInsuranceQuoteLauncher.blockKey]: { render: LifeInsuranceQuoteLauncher },
};

export default BLOCK_TO_COMPONENT_MAP;
