import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


function PercentageDialContainer({ heading, className, children }) {
    const hasAtLeastOneHeading = !!(heading?.title || heading?.introduction);

    return (
        <Container
            $hasAtLeastOneHeading={hasAtLeastOneHeading}
            className={className}
        >
            <HeadingBlock {...heading} />

            <div className="flex-container">
                {children}
            </div>
        </Container>
    );
}

PercentageDialContainer.propTypes = {
    heading: HeadingPropTypes,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

PercentageDialContainer.defaultProps = {
    heading: undefined,
    className: undefined,
};

const Container = styled.div`
    .flex-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;

        /* Add space between headings and dials if headings are present. */
        ${props => props.$hasAtLeastOneHeading && `
            margin-top: ${Sizes.SPACING.TWO};
        `}
    }
`;

PercentageDialContainer.blockKey = 'rh/percentage-dial-container';
PercentageDialContainer.hasHeading = true;
PercentageDialContainer.requiresLayoutRow = true;

export default PercentageDialContainer;
