
const ORDERED_LIST_ITEM_TYPE = {
    DECIMAL: 'decimal',
    UPPER_ALPHA: 'upper-alpha',
};

const ORDERED_LIST_VARIANT = {
    DEFAULT: 'default',
    CIRCLE: 'circle',
};

export {
    ORDERED_LIST_VARIANT,
    ORDERED_LIST_ITEM_TYPE,
};