import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import RadioButtonStyle from '../styles/RadioButtonStyle';
import TransparentInput from './TransparentInput';
import { useCondensedTheme } from './CondensedTheme';


function RadioButton({
    name,
    value,
    checked,
    disabled,
    shouldShowInvalid,
    text,
    onFocus,
    onBlur,
    ...otherProps
}) {
    const isCondensed = useCondensedTheme();
    const [ isFocused, setFocused ] = useState(false);

    const inputId = `${name}-${value}`;

    function handleFocus(e) {
        setFocused(true);
        onFocus?.(e);
    }

    function handleBlur(e) {
        setFocused(false);
        onBlur?.(e);
    }

    return (
        <RadioButton.ButtonVisual
            checked={checked}
            isFocused={isFocused}
            disabled={disabled}
            isInvalid={shouldShowInvalid}
            className={classNames({
                'is-condensed': isCondensed,
            })}
        >
            <label htmlFor={inputId}>
                <span>
                    {text}
                </span>
                <TransparentInput
                    {...otherProps}
                    id={inputId}
                    name={name}
                    disabled={disabled}
                    value={value}
                    checked={checked}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type="radio"
                />
            </label>
        </RadioButton.ButtonVisual>
    );
}

RadioButton.ButtonVisual = styled.div`
    ${RadioButtonStyle}
`;

RadioButton.propTypes = {
    value: PropTypes.any,
    text: PropTypes.node.isRequired, // Can be JSX
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    shouldShowInvalid: PropTypes.bool,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

RadioButton.defaultProps = {
    value: undefined,
    shouldShowInvalid: false,
    disabled: false,
    onFocus: undefined,
    onBlur: undefined,
};

export default RadioButton;
