import { AdTypes } from '@ratehub/base-ui';


// This defines the set of sizes which we support for each adType. We *used* to
// use Google's Size Mapping functionality to determine the most appropriate
// size to load* but we've since rolled our own solution since Google's works by
// measuring the viewport and we care more about how much space is available to
// the container.
//
// * old size mapping described here:
//   https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads)

// ASSUMPTION: Ad sizes are ordered smallest to largest width
// (index 0 = smallest width, last index = largest width)
const adSizes = {
    [AdTypes.Bigbox]: [
        [ 300, 250 ],
    ],
    [AdTypes.Leaderboard]: [
        [ 300, 50 ],
        [ 320, 50 ],
        [ 728, 90 ],
    ],
};

export default adSizes;