import scrollIntoViewPony from 'smooth-scroll-into-view-if-needed';

import isWidget from './isWidget';
import noticeError from './noticeError';
import sendMessageToParentIframe from './sendMessageToParentIframe';


/**
 * This thin wrapper around the https://www.npmjs.com/package/scroll-into-view-if-needed ponyfill enhances
 * it by providing a couple of default option values, and by accepting either a CSS string selector or a
 * DOM element as the first parameter.
 *
 * @param {string | object } nodeOrSelector valid CSS selector (suitable for use with
 *                           document.querySelector), or an HTMLElement
 * @param {object} [options={}] corresponds to scrollIntoView ponyfill’s options
 * @return {Promise<void>}
 */
async function scrollIntoView(nodeOrSelector, options = {}) {
    if (typeof window === 'undefined') {
        return;
    }

    const targetElement = typeof nodeOrSelector === 'string'
        ? document.querySelector(nodeOrSelector)
        : nodeOrSelector;

    if (!targetElement) {
        noticeError(new Error('[scrollIntoView] could not find target element'), {
            ...(typeof nodeOrSelector === 'string' && { targetSelector: `“${nodeOrSelector}”` }),

            // it should be quite unlikely for a node to not be found
            ...(typeof nodeOrSelector !== 'string' && {
                targetTagName: nodeOrSelector?.tagName,
                targetClassName: nodeOrSelector?.className,
            }),

            options,
        });

        return;
    }

    options = Object.assign(DEFAULT_OPTIONS, options);

    /**
     * If we're a widget (i.e. inside an iframe) we need to use
     * a different method for calculating the scrollTo value
     */
    if (isWidget()) {
        sendMessageToParentIframe({
            requestScrollTo: true,
            iframeScrollY: targetElement.getBoundingClientRect().top,
            position: 'center',
        });
    } else {
        await scrollIntoViewPony(targetElement, options);
    }
}


// ponyfill defaults: { block: 'center', inline: 'nearest', scrollMode: 'always' }
const DEFAULT_OPTIONS = {
    scrollMode: 'if-needed',    // don’t scroll if already in view
    block: 'start',             // go to top of element, ignoring vertical height
};

export default scrollIntoView;
