import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import EASINGS from '../definitions/Easings';
import AnimatedOpacityContainer from './AnimatedOpacityContainer';


/* @TECH_DEBT: Please rename to ViewBasedOpacityContainer */
function OpacityContainer({
    isOpaque,
    isViewBased,
    duration,
    minOpacity,
    delay,
    easing,
    children,
    ...otherProps
}) {
    const containerRef = useRef(null);

    const [ isInView, setIsInView ] = useState(true);

    useEffect(
        () => {
            let observer;
            const targetElement = containerRef?.current;

            if (!window.IntersectionObserver) {
                // Browser doesn't support IntersectionObserver, just show the
                // target element regardless of scroll position.
                setIsInView(true);
                return;
            }

            if (isViewBased && !!targetElement) {
                /* Identify container is in view when at least 25% of the element (threshold)
                    is available in the top 55% of the viewport (rootMargin).
                    For any updates to this, I found adding a fixed position div
                    placed in the root margin area (in this case, bottom/left/right 0 top 55%)
                    helps visualize change made. */
                observer = new IntersectionObserver(
                    (entries) => setIsInView(entries[0].intersectionRatio >= 0.25),
                    {
                        root: null,
                        rootMargin: '0px 0px -45% 0px',
                        threshold: 0.25,
                    },
                );

                observer.observe(targetElement);
            }

            return () => observer?.disconnect();
        },
        [ containerRef?.current, isViewBased ],
    );

    // when isViewBased === true, ignore isOpaque
    // when isViewBased === false, listen to isOpaque
    const isOpaqueComputed = isViewBased
        ? isInView
        : isOpaque;

    return (
        <AnimatedOpacityContainer
            ref={containerRef}

            isOpaque={isOpaqueComputed}
            duration={duration}
            minOpacity={minOpacity}
            delay={delay}
            easing={easing}

            {...otherProps}
        >
            {children}
        </AnimatedOpacityContainer>
    );
}

OpacityContainer.propTypes = {
    isOpaque: PropTypes.bool,
    isViewBased: PropTypes.bool,
    duration: PropTypes.number, // in milliseconds
    minOpacity: PropTypes.number,
    delay: PropTypes.number, // in milliseconds
    easing: PropTypes.oneOf(Object.values(EASINGS)),
    children: PropTypes.node.isRequired,
};

OpacityContainer.defaultProps = {
    isOpaque: true,
    isViewBased: true,
    duration: 600,
    minOpacity: 0.2,
    delay: 0,
    easing: EASINGS.EASE,
};

export default OpacityContainer;
