import { STREET_TYPES } from '@ratehub/documents/common/stable';


const MESSAGES = {
    [STREET_TYPES.ABBEY]: 'Abbey',
    [STREET_TYPES.ACRES]: 'Acres',
    [STREET_TYPES.ALLEE]: 'Allée',
    [STREET_TYPES.ALLEY]: 'Alley',
    [STREET_TYPES.AUTOROUTE]: 'Autoroute',
    [STREET_TYPES.AVENUE]: 'Avenue',
    [STREET_TYPES.BAY]: 'Bay',
    [STREET_TYPES.BEACH]: 'Beach',
    [STREET_TYPES.BEND]: 'Bend',
    [STREET_TYPES.BOULEVARD]: 'Boulevard',
    [STREET_TYPES.BYPASS]: 'By-pass',
    [STREET_TYPES.BYWAY]: 'Byway',
    [STREET_TYPES.CAMPUS]: 'Campus',
    [STREET_TYPES.CAPE]: 'Cape',
    [STREET_TYPES.CARRE]: 'Carré',
    [STREET_TYPES.CARREFOUR]: 'Carrefour',
    [STREET_TYPES.CENTRE]: 'Centre',
    [STREET_TYPES.CERCLE]: 'Cercle',
    [STREET_TYPES.CHASE]: 'Chase',
    [STREET_TYPES.CHEMIN]: 'Chemin',
    [STREET_TYPES.CIRCLE]: 'Circle',
    [STREET_TYPES.CIRCUIT]: 'Circuit',
    [STREET_TYPES.CLOSE]: 'Close',
    [STREET_TYPES.COMMON]: 'Common',
    [STREET_TYPES.CONCESSION]: 'Concession',
    [STREET_TYPES.CORNERS]: 'Corners',
    [STREET_TYPES.COTE]: 'Côte',
    [STREET_TYPES.COUR]: 'Cour',
    [STREET_TYPES.COURS]: 'Cours',
    [STREET_TYPES.COURT]: 'Court',
    [STREET_TYPES.COVE]: 'Cove',
    [STREET_TYPES.CRESCENT]: 'Crescent',
    [STREET_TYPES.CROISSANT]: 'Croissant',
    [STREET_TYPES.CROSSING]: 'Crossing',
    [STREET_TYPES.CULDESAC]: 'Cul-de-sac',
    [STREET_TYPES.DALE]: 'Dale',
    [STREET_TYPES.DELL]: 'Dell',
    [STREET_TYPES.DIVERSION]: 'Diversion',
    [STREET_TYPES.DOWNS]: 'Downs',
    [STREET_TYPES.DRIVE]: 'Drive',
    [STREET_TYPES.ECHANGEUR]: 'Échangeur',
    [STREET_TYPES.END]: 'End',
    [STREET_TYPES.ESPLANADE]: 'Esplanade',
    [STREET_TYPES.ESTATES]: 'Estates',
    [STREET_TYPES.EXPRESSWAY]: 'Expressway',
    [STREET_TYPES.EXTENSION]: 'Extension',
    [STREET_TYPES.FARM]: 'Farm',
    [STREET_TYPES.FIELD]: 'Field',
    [STREET_TYPES.FOREST]: 'Forest',
    [STREET_TYPES.FREEWAY]: 'Freeway',
    [STREET_TYPES.FRONT]: 'Front',
    [STREET_TYPES.GARDENS]: 'Gardens',
    [STREET_TYPES.GATE]: 'Gate',
    [STREET_TYPES.GLADE]: 'Glade',
    [STREET_TYPES.GLEN]: 'Glen',
    [STREET_TYPES.GREEN]: 'Green',
    [STREET_TYPES.GROUNDS]: 'Grounds',
    [STREET_TYPES.GROVE]: 'Grove',
    [STREET_TYPES.HARBOUR]: 'Harbour',
    [STREET_TYPES.HEATH]: 'Heath',
    [STREET_TYPES.HEIGHTS]: 'Heights',
    [STREET_TYPES.HIGHLANDS]: 'Highlands',
    [STREET_TYPES.HIGHWAY]: 'Highway',
    [STREET_TYPES.HILL]: 'Hill',
    [STREET_TYPES.HOLLOW]: 'Hollow',
    [STREET_TYPES.ILE]: 'Île',
    [STREET_TYPES.IMPASSE]: 'Impasse',
    [STREET_TYPES.INLET]: 'Inlet',
    [STREET_TYPES.ISLAND]: 'Island',
    [STREET_TYPES.KEY]: 'Key',
    [STREET_TYPES.KNOLL]: 'Knoll',
    [STREET_TYPES.LANDING]: 'Landing',
    [STREET_TYPES.LANE]: 'Lane',
    [STREET_TYPES.LIMITS]: 'Limits',
    [STREET_TYPES.LINE]: 'Line',
    [STREET_TYPES.LINK]: 'Link',
    [STREET_TYPES.LOOKOUT]: 'Lookout',
    [STREET_TYPES.LOOP]: 'Loop',
    [STREET_TYPES.MALL]: 'Mall',
    [STREET_TYPES.MANOR]: 'Manor',
    [STREET_TYPES.MAZE]: 'Maze',
    [STREET_TYPES.MEADOW]: 'Meadow',
    [STREET_TYPES.MEWS]: 'Mews',
    [STREET_TYPES.MONTEE]: 'Montée',
    [STREET_TYPES.MOOR]: 'Moor',
    [STREET_TYPES.MOUNT]: 'Mount',
    [STREET_TYPES.MOUNTAIN]: 'Mountain',
    [STREET_TYPES.ORCHARD]: 'Orchard',
    [STREET_TYPES.PARADE]: 'Parade',
    [STREET_TYPES.PARC]: 'Parc',
    [STREET_TYPES.PARK]: 'Park',
    [STREET_TYPES.PARKWAY]: 'Parkway',
    [STREET_TYPES.PASSAGE]: 'Passage',
    [STREET_TYPES.PATH]: 'Path',
    [STREET_TYPES.PATHWAY]: 'Pathway',
    [STREET_TYPES.PINES]: 'Pines',
    [STREET_TYPES.PLACE]: 'Place',
    [STREET_TYPES.PLATEAU]: 'Plateau',
    [STREET_TYPES.PLAZA]: 'Plaza',
    [STREET_TYPES.POINT]: 'Point',
    [STREET_TYPES.POINTE]: 'Pointe',
    [STREET_TYPES.PORT]: 'Port',
    [STREET_TYPES.PRIVATE]: 'Private',
    [STREET_TYPES.PROMENADE]: 'Promenade',
    [STREET_TYPES.QUAI]: 'Quai',
    [STREET_TYPES.QUAY]: 'Quay',
    [STREET_TYPES.RAMP]: 'Ramp',
    [STREET_TYPES.RANG]: 'Rang',
    [STREET_TYPES.RANGE]: 'Range',
    [STREET_TYPES.RIDGE]: 'Ridge',
    [STREET_TYPES.RISE]: 'Rise',
    [STREET_TYPES.ROAD]: 'Road',
    [STREET_TYPES.RONDPOINT]: 'Rond-point',
    [STREET_TYPES.ROUTE]: 'Route',
    [STREET_TYPES.ROW]: 'Row',
    [STREET_TYPES.RUE]: 'Rue',
    [STREET_TYPES.RUELLE]: 'Ruelle',
    [STREET_TYPES.RUN]: 'Run',
    [STREET_TYPES.SENTIER]: 'Sentier',
    [STREET_TYPES.SQUARE]: 'Square',
    [STREET_TYPES.STREET]: 'Street',
    [STREET_TYPES.SUBDIVISION]: 'Subdivision',
    [STREET_TYPES.TERRACE]: 'Terrace',
    [STREET_TYPES.TERRASSE]: 'Terrasse',
    [STREET_TYPES.THICKET]: 'Thicket',
    [STREET_TYPES.TOWERS]: 'Towers',
    [STREET_TYPES.TOWNLINE]: 'Townline',
    [STREET_TYPES.TRAIL]: 'Trail',
    [STREET_TYPES.TURNABOUT]: 'Turnabout',
    [STREET_TYPES.VALE]: 'Vale',
    [STREET_TYPES.VIA]: 'Via',
    [STREET_TYPES.VIEW]: 'View',
    [STREET_TYPES.VILLAGE]: 'Village',
    [STREET_TYPES.VILLAS]: 'Villas',
    [STREET_TYPES.VISTA]: 'Vista',
    [STREET_TYPES.VOIE]: 'Voie',
    [STREET_TYPES.WALK]: 'Walk',
    [STREET_TYPES.WAY]: 'Way',
    [STREET_TYPES.WHARF]: 'Wharf',
    [STREET_TYPES.WOOD]: 'Wood',
    [STREET_TYPES.WYND]: 'Wynd',
};

const STREET_TYPE_OPTIONS = Object.values(STREET_TYPES).map(value => ({
    value: value,
    label: MESSAGES[value],
}));

export default STREET_TYPE_OPTIONS;
