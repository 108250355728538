import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { PROVINCES } from '@ratehub/documents/common/stable';

import AddressPropType from '../definitions/AddressPropType';
import Messages from '../definitions/AddressMessages';
import Sizes from '../definitions/Sizes';
import { PROVINCE_OPTIONS } from '../definitions/Provinces';
import STREET_TYPE_OPTIONS from '../definitions/StreetTypes';
import STREET_DIRECTION_OPTIONS from '../definitions/StreetDirections';
import MessageStyles from '../definitions/MessageStyles';
import messageToString from '../functions/messageToString';
import PositionOffscreenStyles from '../styles/PositionOffscreenStyles';
import useAddressFormState from '../hooks/useAddressFormState';

import Select from './Select';
import PageBehaviourButton from './PageBehaviourButton';
import ErrorMessage from './ErrorMessage';
import StringInput from './StringInput';
import PostalCodeInput from './PostalCodeInput';


function AddressInputForm({
    name, // prefix for sub-fields
    value,

    allowedProvinces,
    shouldShowInvalid, // (has submitted)
    isRequired,
    isDisabled,

    onChangeMode,
    onChange,
    onBlur,

    tabIndex,

    ...otherProps
}) {
    /**
     * |  Number  |   Unit   |
     * |        Name         |
     * |  Type    |   Dirn   |
     * |        City         |
     * | Province | Postal   |
     */

    const intl = useIntl();
    const state = useAddressFormState({
        name,
        value,
        onChange,
        isRequired,
        isDisabled,
        allowedProvinces,
        intl,
    });

    return (
        <div {...otherProps}>
            {/* Street Number */}
            <div className="input-container half-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.streetNumber.name}
                >
                    <FormattedMessage {...Messages.STREET_NUMBER_LABEL} />
                </label>
                <StringInput
                    {...state.streetNumber}
                    key={state.streetNumber.key} // can't be in the spread
                    placeholder={Messages.STREET_NUMBER_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* Unit Number */}
            <div className="input-container half-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.unitNumber.name}
                >
                    <FormattedMessage {...Messages.UNIT_LABEL} />
                </label>
                <StringInput
                    {...state.unitNumber}
                    key={state.unitNumber.key} // can't be in the spread
                    placeholder={Messages.UNIT_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* Street Name */}
            <div className="input-container full-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.streetName.name}
                >
                    <FormattedMessage {...Messages.STREET_NAME_LABEL} />
                </label>
                <StringInput
                    {...state.streetName}
                    key={state.streetName.key} // can't be in the spread
                    placeholder={Messages.STREET_NAME_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* Street Type */}
            <div className="input-container half-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.streetType.name}
                >
                    <FormattedMessage {...Messages.STREET_TYPE_LABEL} />
                </label>
                <Select
                    {...state.streetType}
                    key={state.streetType.key} // can't be in the spread
                    options={STREET_TYPE_OPTIONS}
                    placeholder={Messages.STREET_TYPE_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* Street Direction */}
            <div className="input-container half-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.streetDirection.name}
                >
                    <FormattedMessage {...Messages.STREET_DIRECTION_LABEL} />
                </label>
                <Select
                    {...state.streetDirection}
                    key={state.streetDirection.key} // can't be in the spread
                    options={STREET_DIRECTION_OPTIONS}
                    placeholder={Messages.STREET_DIRECTION_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* City  */}
            <div className="input-container full-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.city.name}
                >
                    <FormattedMessage {...Messages.CITY_LABEL} />
                </label>
                <StringInput
                    {...state.city}
                    key={state.city.key} // can't be in the spread
                    placeholder={Messages.CITY_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* Province */}
            <div className="input-container half-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.province.name}
                >
                    <FormattedMessage {...Messages.PROVINCE_LABEL} />
                </label>
                <Select
                    {...state.province}
                    key={state.province.key} // can't be in the spread
                    options={PROVINCE_OPTIONS.map(o => ({
                        value: o.value,
                        label: messageToString(o.label ?? o.value, intl),
                    }))}
                    optionsSort={(first, second) => first.label.localeCompare(second.label)}
                    placeholder={Messages.PROVINCE_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            {/* Postal Code */}
            <div className="input-container half-width">
                <label
                    className={classNames('rh-title-xs', 'rh-display-block', 'address-label')}
                    htmlFor={state.postalCode.name}
                >
                    <FormattedMessage {...Messages.POSTAL_CODE_LABEL} />
                </label>
                <PostalCodeInput
                    {...state.postalCode}
                    key={state.postalCode.key} // can't be in the spread
                    placeholder={Messages.POSTAL_CODE_LABEL}
                    errorMessageStyle={MessageStyles.MINIMAL}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                />
            </div>

            <If condition={shouldShowInvalid}>
                <div className="form-error-message-container">
                    <ErrorMessage
                        className="form-error-message"
                        message={Messages.FORM_SINGLE_ERROR_MESSAGE}
                    />
                </div>
            </If>

            <If condition={onChangeMode}>
                <div className="mode-change-container">
                    <PageBehaviourButton
                        className="mode-change-button"
                        data-name="redo-address-search"
                        onClick={onChangeMode}
                        size="small"
                        message={Messages.MODE_BASIC_BUTTON}
                    />
                </div>
            </If>
        </div>
    );
}

AddressInputForm.propTypes = {
    name: PropTypes.string.isRequired,

    value: AddressPropType,
    tabIndex: PropTypes.number,
    shouldShowInvalid: PropTypes.bool.isRequired,
    allowedProvinces: PropTypes.arrayOf(PropTypes.string),
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
    onChangeMode: PropTypes.func,
    onBlur: PropTypes.func,
};

AddressInputForm.defaultProps = {
    value: undefined,
    allowedProvinces: Object.values(PROVINCES),
    tabIndex: 0,
    onChangeMode: undefined,
    onBlur: undefined,
    isRequired: undefined,
    isDisabled: undefined,
};

const StyledAddressInputForm = styled(observer(AddressInputForm))`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 0 -${Sizes.SPACING.HALF}; /* Negates the outer margin*/

    .address-label {
        ${PositionOffscreenStyles}
    }

    > .form-error-message-container {
        flex-basis: 100%;

        margin: ${Sizes.SPACING.HALF} ${Sizes.SPACING.ONE};

        > .form-error-message {
            display: block;
            width: fit-content;
            margin-left: auto;
        }
    }

    > .input-container {
        &.half-width {
            flex-basis: calc(50% - ${Sizes.SPACING.ONE});
            flex-grow: 1;
            min-width: unset;
        }

        &.full-width {
            min-width: calc(100% - ${Sizes.SPACING.ONE});
        }

        margin: ${Sizes.SPACING.HALF};
    }

    > .mode-change-container {
        width: 100%;
        padding: ${Sizes.SPACING.QUARTER} 0;
        margin: 0;

        > .mode-change-button {
            margin-left: auto;
            margin-right: ${Sizes.SPACING.ONE};
        }
    }
`;

export default StyledAddressInputForm;
