import React from 'react';

import CTAChooser from './CTAChooser';


function CTAChooserWithProduct({ ...otherProps }) {
    return (
        <CTAChooser
            {...otherProps}
        />
    );
}

CTAChooserWithProduct.blockKey = 'rh/cta-chooser-with-product';
CTAChooserWithProduct.hasSpacingOptions = true;

export default CTAChooserWithProduct;
