export const DASHBOARD_BASE_PATH = '/dashboard';
export const MY_QUOTE_ROUTE = '/my-quotes';
export const PROFILE_DETAILS_ROUTE = '/about-me';
export const SWITCH_EMAIL_PAGE_ROUTE = '/change-email';

export default {
    DASHBOARD_BASE_PATH,
    MY_QUOTE_ROUTE,
    PROFILE_DETAILS_ROUTE,
    SWITCH_EMAIL_PAGE_ROUTE,
};
