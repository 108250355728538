import { types } from 'mobx-state-tree';


const PURPOSES = {
    PURCHASE: 'purchase',
    PURCHASE_FOUND_PROPERTY: 'ive-found-a-property',
    PURCHASE_STILL_LOOKING: 'im-still-looking',
    PRE_APPROVAL: 'pre-approval', // potentially collapse into still looking
    RENEWAL: 'renewal',
    HELOC: 'heloc',
    REFINANCE: 'refinance',
    REFINANCE_ACCESS_EQUITY: 'access-home-equity',
    REFINANCE_LOWER_INTEREST: 'lower-interest-rate',
    REFINANCE_CONSOLIDATE_DEBT: 'consolidate-debt',
    REFINANCE_OTHER: 'other', // potentially remove
};


const PurposeEnum = types.enumeration('Purpose', Object.values(PURPOSES));

export {
    PurposeEnum,
};
export default PURPOSES;
