import styled from 'styled-components';


export default styled.input`
    position: absolute; 
    
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0;

    width: 100%;
    height: 100%;
    margin: 0;

    cursor: pointer;
    opacity: 0;
`;
