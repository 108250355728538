import { css } from 'styled-components';

import Colours from '../definitions/Colours';


export default css`
    appearance: none;

    color: ${Colours.BLACKBERRY};
    background-color: transparent;

    border-width: 0;
    padding: 0;

    font-family: inherit;

    outline: none;

    &::-moz-focus-inner {
        border-width: 0;
    }

    &:focus,
    &:active {
        /* remove highlight of tap */
        -webkit-tap-highlight-color: ${Colours.TRANSPARENT};
        tap-highlight-color: ${Colours.TRANSPARENT};
        outline: none;
    }
`;
