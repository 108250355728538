
import { RATE_TYPES } from '@ratehub/documents/mtg-journey-document/stable';


export const MORTGAGE_RATE_TYPES = RATE_TYPES;

export const MORTGAGE_EXTENDED_RATE_TYPES = {
    ...RATE_TYPES,
    FIXED_OPEN: 'fixed-open',
    VARIABLE_OPEN: 'variable-open',
    CASHBACK: 'cashback',
};