/**
 * Filters out featured products not valid for the given provinceCode.
 * This also rejects the product if the imageAlt property is empty.
 *
 * @param {Object} product
 * @param {string} product.imageAlt
 * @param {string[]} product.provinces
 * @param {string} provinceCode from Object.values(PROVINCES)
 * @return {boolean}
 */
function isFeaturedProductApplicable(product, provinceCode) {
    return !!product.imageAlt
        && (!product.provinces?.length || product.provinces.includes(provinceCode));
}

export default isFeaturedProductApplicable;
