import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import CircleSpinner from './CircleSpinner';


function ComponentSelector({ componentId, componentMap, ...otherProps }) {
    const Component = componentMap[componentId];

    return (
        <Choose>
            <When condition={Component}>
                <Component {...otherProps} />
            </When>
            <Otherwise>
                <CircleSpinner />
            </Otherwise>
        </Choose>
    );
}

ComponentSelector.propTypes = {
    componentId: PropTypes.string.isRequired,
    componentMap: PropTypes.object.isRequired,
};

export default observer(ComponentSelector);