import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { Sizes, ImageSizes } from '@ratehub/base-ui';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import Placeholders from '../definitions/Placeholders';
import VisualElement from './VisualElement';
import withLink from './withLink';


const FLEX_BASIS_BREAKPOINT = '700';
const PLACEHOLDER_IMAGE = Placeholders['400x400'];
const VisualElementWithLink = withLink(VisualElement);

function ImageGridItem({ href, imageSrc, imageAlt, imageCollection }) {
    return (
        <ImageGridItem.Item>
            <LazyLoadComponent
                placeholder={(
                    <img
                        src={PLACEHOLDER_IMAGE}
                        alt=""
                    />
                )}
            >
                <ImageGridItem.VisualElementWithLink
                    data-name="imageGridItem-visitImageLink"
                    imageCollection={imageCollection
                        ? {
                            sizes: [
                                {
                                    url: imageCollection.sizes?.[ImageSizes.M]?.url || imageCollection.sizes[ImageSizes.FULL].url,
                                    breakpoint: `(min-width: ${FLEX_BASIS_BREAKPOINT}px) and (min-resolution: 192dpi)`,
                                },
                                {
                                    url: imageCollection.sizes?.[ImageSizes.S]?.url || imageCollection.sizes[ImageSizes.FULL].url,
                                    breakpoint: `(min-width: ${FLEX_BASIS_BREAKPOINT}px)`,
                                },
                            ],
                            fallback: imageCollection.sizes?.[ImageSizes.M]?.url || imageCollection.sizes[ImageSizes.FULL].url,
                            alt: imageAlt || imageCollection.alt || imageCollection.title,
                            mime: imageCollection.mime,
                        }
                        : undefined
                    }
                    imageUrl={imageSrc}
                    alt={imageAlt}
                    href={href}
                />
            </LazyLoadComponent>
        </ImageGridItem.Item>
    );
}

ImageGridItem.propTypes = {
    href: PropTypes.string.isRequired,
    imageSrc: PropTypes.string, // Deprecated - use imageCollection when possible
    imageAlt: PropTypes.string,
    imageCollection: ImageCollectionPropType,
};

ImageGridItem.defaultProps = {
    imageAlt: undefined,
    imageSrc: undefined,
    imageCollection: undefined,
};



ImageGridItem.Item = styled.li`
    flex-basis: calc(33% - ${Sizes.SPACING.ONE_AND_A_HALF});
    /* Stops images getting too large when there are less than three to a row */
    max-width: calc(33% - ${Sizes.SPACING.ONE_AND_A_HALF});

    margin-bottom: ${Sizes.SPACING.ONE_AND_A_HALF};
    margin-left: ${Sizes.SPACING.THREE_QUARTERS};
    margin-right: ${Sizes.SPACING.THREE_QUARTERS};

    @media (max-width: ${FLEX_BASIS_BREAKPOINT}px) {
        flex-basis: calc(50% - ${Sizes.SPACING.ONE_AND_A_HALF});
        max-width: none;
    }

    img {
        max-width: 100%;
    }
`;

ImageGridItem.VisualElementWithLink = styled(VisualElementWithLink)`
    width: 100%;
`;

ImageGridItem.blockKey = 'rh/image-grid-item';

export default ImageGridItem;
