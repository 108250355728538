import { useEffect, useState } from 'react';

import useForm from './useForm';
import useIsMounted from './useIsMounted';


function useFormComputed({ name, value, dependencies }) {
    const form = useForm();
    const getIsMounted = useIsMounted();

    if (form == null) {
        throw new Error('[useFormComputed]: must be used within a <Form>');
    }

    if (!Array.isArray(dependencies) && typeof dependencies !== 'undefined') {
        throw new RangeError(`[useFormComputed]: dependencies must be either an array or undefined. Received ${dependencies}`);
    }

    const [ computed, setComputed ] = useState(null);

    // Register and un-register the computed to the form
    useEffect(
        () => {
            if (!getIsMounted()) {
                return; // No point in registering computed if we are not mounted.
            }

            // Making a reference for effect cleanup; setComputed is async so we can't rely on 'computed'.
            const registeredComputed = computed ?? form.addComputed(name, value);

            if (!computed) {
                setComputed(registeredComputed);
            }

            return () => {
                if (!getIsMounted()) {
                    form.removeComputed(registeredComputed.name);
                }
            };
        },
        // Explicitly left `value` out to avoid re-initializing the computed anytime the value changes.
        // value is updated in a separate useEffect below.
        [ form, getIsMounted, computed, name ],
    );

    // Update computed name via props
    useEffect(
        () => {
            if (!computed) {
                return;
            }

            if (computed.name !== name) {
                computed.name = name;
            }
        },
        [ name, computed ],
    );

    // Update computed value from props
    useEffect(
        () => {
            if (!computed) {
                return;
            }

            computed.setValue(value);
        },
        // If we have explicitly passed external dependencies, only re-assign our value when they change.
        // RATIONALE: the vast majority of the time, authors are wanting something like:
        //          <FormComputed name="foo" value={(values) => values * 2} dependencies={[]} />
        //          The contents of the function is the same every time, but it's seen as a "new" value each frame (because it builds a new lambda).
        //          This allows developers to write code in the most clear way, without easily causing infinite re-renders
        Array.isArray(dependencies)
            ? [ computed, ...dependencies ]
            : [ computed, value ],
    );
}

export default useFormComputed;
