import React from 'react';

import PAGE_TEMPLATES from '../definitions/PageTemplates';
import ArticleShowcase from './ArticleShowcase';


function NewsShowcase({ ...otherProps }) {
    return (
        <ArticleShowcase
            {...otherProps}
        />
    );
}

NewsShowcase.blockKey = 'rh/news-showcase';
NewsShowcase.hasHeading = true;
NewsShowcase.requiresLayoutRow = true;
NewsShowcase.supportedPageTypes = [
    PAGE_TEMPLATES.corporate,
];

export default NewsShowcase;
