import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import EASINGS from '../definitions/Easings';
import AnimatedTranslateContainer from './AnimatedTranslateContainer';
import OpacityContainer from './OpacityContainer';
import { DIRECTIONS, DISTANCES } from './AnimatedTranslateContainer';


function AnimatedSlideAndFadeContainer({
    isVisible,
    isViewBased = false,
    isMountAnimated = false,

    direction = DIRECTIONS.DOWN,
    duration = 300,
    distance = DISTANCES.SHORT,
    delay = 0,
    easing = EASINGS.OUT,

    onEnter,
    onEntered,
    onExit,
    onExited,

    className,

    children,
}) {
    const [ isInView, setIsInView ] = useState(false);
    const containerRef = useRef();

    useEffect(() => {
        let observer;
        const container = containerRef?.current;

        if (isViewBased && container) {
            if (!window.IntersectionObserver) {
                // Browser doesn't support IntersectionObserver, just show the
                // target element regardless of scroll position.
                setIsInView(true);
                return false;
            }

            observer = new IntersectionObserver(
                entries => {
                    if (entries[0].isIntersecting) {
                        setIsInView(true);
                    }
                },
                {
                    root: null,
                    rootMargin: '0px 0px -100px 0px',
                    threshold: 0.9,
                },
            );

            observer.observe(container);
        }

        return () => observer?.disconnect();

    }, [ containerRef?.current, isViewBased ]);

    return (
        <AnimatedTranslateContainer
            ref={containerRef}

            isVisible={isViewBased ? isInView : isVisible}
            isMountAnimated={isMountAnimated}

            direction={direction}
            duration={duration}
            easing={easing}
            delay={delay}
            distance={distance}

            onEnter={onEnter}
            onEntered={onEntered}
            onExit={onExit}
            onExited={onExited}

            className={className}
        >
            <OpacityContainer
                isOpaque={isViewBased ? isInView : isVisible}
                isViewBased={false} // Use our own observer above to trigger both animations.

                duration={duration}
                minOpacity={0}
                delay={delay}
                easing={easing}
            >
                {children}
            </OpacityContainer>
        </AnimatedTranslateContainer>
    );
}

AnimatedSlideAndFadeContainer.propTypes = {
    isVisible: PropTypes.bool,
    isViewBased: PropTypes.bool,
    isMountAnimated: PropTypes.bool,

    direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
    duration: PropTypes.number, // in milliseconds
    distance: PropTypes.oneOf(Object.values(DISTANCES)),
    delay: PropTypes.number, // in milliseconds
    easing: PropTypes.oneOf(Object.values(EASINGS)),

    onEnter: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExited: PropTypes.func,

    className: PropTypes.string,

    children: PropTypes.node.isRequired,
};

export default AnimatedSlideAndFadeContainer;
