import { GIFT_OFFER_TYPES_MESSAGES } from '../definitions/GiftOfferTypes';
import noticeError from './noticeError';


/**
 * Convert an enumerated type for a gift offer type into a localized string.
 *
 * @param {string} offerType - enumerated type describing a gift offer
 * @param {import('react-intl').IntlShape} intl - the internationalization object
 * @return {string} - the localized offer type
 */
function getOfferTypeAsStringFromSlug(offerType, intl) {
    const offerTypeDescriptor = GIFT_OFFER_TYPES_MESSAGES[offerType];
    if (offerTypeDescriptor) {
        return intl.formatMessage(offerTypeDescriptor);
    }

    noticeError(new RangeError(`[getOfferTypeAsStringFromSlug] unexpected offer type: “${offerType}”.`), {
        expecting: `Expecting one of “${Object.values(GIFT_OFFER_TYPES_MESSAGES).join('”, “')}”.`,
    });
    return offerType;
}


export default getOfferTypeAsStringFromSlug;
