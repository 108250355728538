import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AccordionBasicItem, Colours } from '@ratehub/base-ui';


function MinimalAccordion({
    title,
    titleColour,
    isInitiallyExpanded,
    alwaysRenderChildMarkup,
    className,
    children,
}) {
    return (
        <MyMinimalAccordion
            isInitiallyExpanded={isInitiallyExpanded}
            title={title}
            variant="minimal"
            titleColour={titleColour}
            alwaysRenderChildMarkup={alwaysRenderChildMarkup}
            className={className}
        >
            {children}
        </MyMinimalAccordion>
    );
}

MinimalAccordion.propTypes = {
    title: PropTypes.string,
    titleColour: PropTypes.oneOf([ Colours.BLACKBERRY, Colours.BLUEBERRY_DARK ]),
    isInitiallyExpanded: PropTypes.bool,
    alwaysRenderChildMarkup: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

MinimalAccordion.defaultProps = {
    title: undefined,
    titleColour: Colours.BLACKBERRY,
    isInitiallyExpanded: false,
    alwaysRenderChildMarkup: true,
    className: undefined,
};

const MyMinimalAccordion = styled(AccordionBasicItem)`
    /* Matching rich text block - will be removed when we implement proper block width sizing */
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
`;

MinimalAccordion.blockKey = 'rh/minimal-accordion';
MinimalAccordion.requiresLayoutRow = true;

export default MinimalAccordion;
