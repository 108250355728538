import deepClone from 'clone-deep';


function getDeepClone(object) {
    if (object == null) {
        return object;
    }
  
    if (typeof window !== 'undefined' && typeof window.structuredClone !== 'undefined') {
        try {
            return structuredClone(object);
        } catch {
            // structuredClone does not support all types detailed in this link: https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#things_that_dont_work_with_structured_clone
            // In this case, we will use the deepClone method to clone the target object
            return deepClone(object);
        }
    }
  
    return deepClone(object);
}

export default getDeepClone;
