import { parse } from 'query-string';

import AFFILIATE_QUERY_PARAM_KEYS from '../definitions/AffiliateQueryParamKeys';
import isWidget from './isWidget';


/**
 * Gets the referrer URL from the referrer query param if it exists, otherwise returns the current URL.
 * @returns URL string
 */
export default function getReferrerURL() {
    // SPECIAL CASE: if we're running as a widget, then window.location isn't where we're ACTUALLY being run from.
    //   We want to include the actual referrer source URL so we can do conversion optimization in places like MoneySense.
    if (isWidget()) {
        const referrer = decodeURIComponent(parse(window.location.search)?.[AFFILIATE_QUERY_PARAM_KEYS.conversionReferrer]) ?? '';

        if (referrer.length > 0) {
            return referrer;
        }
    }

    return window?.location?.href;
}
