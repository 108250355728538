import isElementVisible from './isElementVisible';


/**
 * Sets the focus to the given DOM element if it is an INPUT or SELECT, or more commonly, find the first
 * visible INPUT or SELECT contained by the element. The single argument could also be a query selector string.
 *
 * @param {HTMLElement|string} element DOM element to search within, or the element itself, or a query selector string
 * @returns undefined
 */
function focusFirstInputWithin(element) {
    if (!element) {
        throw new ReferenceError('focusFirstInputWithin requires an argument', 'focusFirstInputWithin.js');
    }

    // assumes that if `element` is a string, it’s a query selector
    if (typeof element === 'string') {
        const queryString = element;
        element = document.querySelector(queryString);

        if (!element) {
            // eslint-disable-next-line no-console
            console.warn(`focusFirstInputWithin: could not find element for “${queryString}”`);
            return;
        }
    }

    const inputElement = element.tagName === 'INPUT' || element.tagName === 'SELECT'
        ? element
        // find the first visible `input` or `select`
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
        // : Array.from(element.querySelectorAll('input, select')).find(el => el.offsetParent !== null);
        : Array.from(element.querySelectorAll('input, select')).find(el => isElementVisible(el));

    if (!inputElement) {
        // nothing visible within `element`
        return;
    }

    if (inputElement.getAttribute('type') === 'radio') {
        const selectedRadio = document.querySelector(
            `input[type='radio'][name='${inputElement.getAttribute('name')}']:checked`,
        );

        const focusedRadio = selectedRadio ?? inputElement;

        focusedRadio.focus();
        return;
    }

    inputElement.focus();
    inputElement.select?.();
}


export default focusFirstInputWithin;
