import { defineMessages } from 'react-intl';


const NEWSLETTER_MESSAGES = defineMessages({
    SIGN_UP_BUTTON_READY: {
        id: 'web-components.SidebarNewsletter.signUpButtonReady',
        defaultMessage: 'Subscribe',
    },
    SIGN_UP_BUTTON_IN_PROGRESS: {
        id: 'web-components.SidebarNewsletter.signUpButtonInProgress',
        defaultMessage: 'Subscribing...',
    },
    SIGN_UP_BUTTON_COMPLETE: {
        id: 'web-components.SidebarNewsletter.signUpButtonSigningUp',
        defaultMessage: 'Thanks for signing up!',
    },

    // Custom disclaimer that doesn't include the usual "Contact Us" bit.
    DISCLAIMER: {
        id: 'web-components.SidebarNewsletter.disclaimer',
        defaultMessage: 'By submitting your email address, you acknowledge and agree to Ratehub.ca’s <terms>Terms of Use</terms> and <privacy>Privacy Policy</privacy>. You can unsubscribe at any time.',
    },
});

const SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_ALL = defineMessages({
    CHECKLIST_ITEM_1: {
        id: 'web-components.SidebarNewsletterSignupLargeAll.checklistItem1',
        defaultMessage: 'Get today’s best mortgage, savings, & GIC rates in your inbox',
    },
    CHECKLIST_ITEM_2: {
        id: 'web-components.SidebarNewsletterSignupLargeAll.checklistItem2',
        defaultMessage: 'Receive financial tips, news, and exclusive offers',
    },
});
const SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_MORTGAGES = defineMessages({
    CHECKLIST_ITEM_1: {
        id: 'web-components.SidebarNewsletterSignupLargeMortgages.checklistItem1',
        defaultMessage: 'Our prediction ahead of BOC announcements',
    },
    CHECKLIST_ITEM_2: {
        id: 'web-components.SidebarNewsletterSignupLargeMortgages.checklistItem2',
        defaultMessage: 'Rate changes out of Bank of Canada announcements',
    },
});

export default NEWSLETTER_MESSAGES;
export {
    SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_ALL,
    SIDEBAR_NEWSLETTER_LARGE_CHECKLIST_MESSAGES_MORTGAGES,
};
