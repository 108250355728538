import { BLOCK_LOCATIONS } from '@ratehub/web-components';

/**
 * Checks if the block is destined to be rendered in the body
 *
 * @param {object} block
 *
 * @returns {boolean}
 */
function isBodyBlock(block) {
    return !block?.attrs?.blockLocation || block?.attrs?.blockLocation === BLOCK_LOCATIONS.BODY;
}

export default isBodyBlock;
