import { trackHeapEvent } from '@ratehub/base-ui';


/**
 * 'CC Web Click'-specific call to `trackHeapEvent`.
 *
 * @param {CreditCardSynopsisShape} cardInfo
 * @param {string} cardInfo.detailsURL
 * @param {string} cardInfo.bank
 * @param {boolean} cardInfo.isMonetized
 * @param {boolean} cardInfo.isSponsored
 */
function trackHeapEventCCWebClick({ detailsURL, bank, isMonetized, isSponsored }) {
    trackHeapEvent('CC Web Click', {
        href: detailsURL,
        provider: bank,
        monetized: isMonetized.toString(),
        sponsored: isSponsored.toString(),
    });
}

export default trackHeapEventCCWebClick;
