export default {
    AUTHOR_FR: '/blogue/author',
    AUTHOR: '/blog/author',
    AUTHORS_FR: '/blogue/authors',
    AUTHORS: '/blog/authors',
    BLOG_FR: '/blogue',
    BLOG: '/blog',
    CATEGORY_FR: '/blogue/category',
    CATEGORY: '/blog/category',
    TAG_FR: '/blogue/tag',
    TAG: '/blog/tag',
    WIZARD_MTG_QUOTER_FR: '/hypotheque/quotes',
    WIZARD_MTG_QUOTER_EN: '/mortgages/quotes',
    AGENT_LIST_FR: '/courtier-hypothecaire-canada',
    AGENT_LIST: '/mortgage-brokers-canada',
    AGENT_DETAILS: '/mortgage-agent-at-ratehub',
    AGENT_DETAILS_FR: '/courtier-hypothecaire-a-ratehub',
};
