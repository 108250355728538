import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import { FormattedMessage } from 'react-intl';
import MessagePropType from '../definitions/MessagePropType';
import InputController from './InputController';
import DirectInput from './DirectInput';


function StringInput({
    name,
    value,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    isRequired,
    isDisabled,

    canShowInvalid,

    label,
    placeholder,

    onCreateField,

    ...otherProps
}) {
    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            isRequired={isRequired}
            isDisabled={isDisabled}
            canShowInvalid={canShowInvalid}
            getParsedValue={unparsedValue => unparsedValue?.toString()?.trim() ?? ''}
            getFormattedValue={unformattedValue => unformattedValue?.toString() ?? ''}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => (
                <DirectInput
                    {...inputProps}
                    type="text"
                    label={label}
                    placeholder={placeholder}

                    onChange={e => inputProps.onChange(e.target.value)}

                    errorMessage={inputState.errorMessage}
                    isInvalid={inputState.isInvalid}
                    shouldShowInvalid={inputState.shouldShowInvalid}
                />
            )}
        </InputController>
    );
}

StringInput.propTypes = {
    name: PropTypes.string.isRequired,

    value: PropTypes.string,

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,

    canShowInvalid: PropTypes.bool,

    onCreateField: PropTypes.func,
};

StringInput.defaultProps = {
    value: undefined,

    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,

    isRequired: undefined,
    isDisabled: false,

    label: undefined,
    placeholder: undefined,

    canShowInvalid: false,

    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.text-input.error.default"
        defaultMessage="Please enter a valid value"
    />
);

export default observer(StringInput);