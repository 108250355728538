export default {
    ALL: 'all',
    AEROPLAN_MILES: 'aeroplan-miles',
    AIR_MILES: 'air-miles',
    AVENTURA_POINTS: 'aventura-points',
    HOME_TRUST: 'home-trust',
    MARRIOT_BONVOY: 'marriot-bonvoy',
    SCENE: 'scene',
    WESTJET_DOLLARS: 'westjet-dollars',
};
