import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl, defineMessages } from 'react-intl';


const VIEW_MORE_BTN_DATA_NAME = 'rhTable-viewMore-cta';

function RichTextContent({ className, variant, content, hasVerticalMargins }) {
    const intl = useIntl();

    // Wrap all tables in divs (for horizontal scrollbars)
    const regexTable = new RegExp(/<table.*?>([\s\S]*?).*<\/table>/gi);
    const tableWithWrapper = content.replace(regexTable, '<div class="overflow">$&</div>');

    // REQUIREMENT: Sticky row is only possible when...
    // 1. table IS NOT expandable
    // 2. table IS expandable, but is not in collapsed state (table is expanded)
    // Therefore, you can enable "sticky row" and "is expandable" at the same time,
    // but the first row should only be sticky when the table is expanded.
    // If the table is not expandable at all, sticky row should be applied all the
    // time (although you will need a tall enough table to trigger it).

    // REQUIREMENT: Add has-max-height class to overflow container when:
    // 1. is-sticky-row is ENABLED
    // 2. is-expandable is DISABLED

    // Find all overflow containers with tables containing the is-sticky-row class.
    const regexIsStickyRow = new RegExp(/<div class="overflow"><table.*?class=".*?is-sticky-row.*?".*?>([\s\S]*?).*<\/table><\/div>/gi);

    // Inject has-max-height class on overflow container ONLY if table has no is-expandable class.
    const newContent = tableWithWrapper.replace(regexIsStickyRow, (stickyTableWithWrapper) => {
        return !stickyTableWithWrapper.match(new RegExp(/table.*?class=".*?is-expandable.*?"/i))
            ? stickyTableWithWrapper.replace('overflow', 'overflow has-max-height')
            : stickyTableWithWrapper; // do nothing, return what was previously matched unchanged.
    });

    const containerRef = useRef(null);

    // Add scroll listeners to rh-tables. Allows CSS to know when a table
    // has been scrolled.
    useEffect(() => {
        const richTextContainer = containerRef?.current;
        if (!richTextContainer) {
            return;
        }

        const overflowContainers = richTextContainer.querySelectorAll('.overflow');
        if (!overflowContainers.length) {
            return;
        }

        function handleScroll(e) {
            const table = e.target.querySelector('.rh-table');

            if (!table) {
                return;
            }

            if (e.target.scrollLeft === 0) {
                table.classList.remove('is-scrolled');
            } else {
                table.classList.add('is-scrolled');
            }
        }

        overflowContainers.forEach(overflowContainer => {
            overflowContainer.addEventListener('scroll', handleScroll);
        });

        return () => {
            overflowContainers.forEach(overflowContainer => {
                overflowContainer.removeEventListener('scroll', handleScroll);
            });
        };
    }, [ content, containerRef?.current ]);


    // Inject buttons after expandable tables.
    // Note: expandable tables always start in collapsed state.
    useEffect(() => {
        const richTextContainer = containerRef?.current;
        if (!richTextContainer) {
            return;
        }

        const expandableTables = richTextContainer.querySelectorAll('.rh-table.is-expandable');
        if (!expandableTables) {
            return;
        }

        function handleButtonClick(e) {
            const button = e.target.closest(`[data-name="${VIEW_MORE_BTN_DATA_NAME}"]`);
            if (!button) {
                return;
            }

            // The overflow container should always appear immediately
            // before the view more button.
            const overflowContainer = button.previousElementSibling;
            const buttonLabel = button.querySelector('.button-label');
            if (!overflowContainer || !buttonLabel) {
                return;
            }

            const table = overflowContainer.querySelector('.rh-table');
            const icon = button.querySelector('.icon');

            table.classList.toggle('is-expanded');

            if (table.classList.contains('is-expanded')) {
                buttonLabel.innerText = intl.formatMessage(Messages.SHOW_LESS);
                icon.innerHTML = ICON_MINUS_SHAPES;
            } else {
                buttonLabel.innerText = intl.formatMessage(Messages.SHOW_MORE);
                icon.innerHTML = ICON_PLUS_SHAPES;
            }
        }

        // Add a button to any expandable tables:
        // Note that we have to do this using DOM manipulation rather than
        // state / JSX because the rhTables appear inside of a `content`
        // string prop, which is simply dangerouslySetInnerHTML'd into
        // this react component. So basically the markup is already
        // constructed by the time it reaches this component.
        function addButton(expandableTable) {
            const overflowContainer = expandableTable.closest('.overflow');
            if (!overflowContainer) {
                return;
            }

            const showMoreBtn = document.createElement('button');
            showMoreBtn.setAttribute('data-name', VIEW_MORE_BTN_DATA_NAME);
            showMoreBtn.classList.add('rh-ml-auto', 'rh-mr-auto', 'rh-my-0_75', 'rh-box-sizing-border-box', 'rh-display-flex', 'rh-align-items-center', 'rh-border-width-0', 'rh-p-0', 'rh-outline-none', 'rh-font-family-gordita', 'rh-fg-blueberry-dark', 'rh-text-m', 'weight-regular', 'is-icon-right', 'rh-text-lowercase', 'hover--rh-fg-blueberry', 'focus--rh-fg-blueberry', 'active--rh-fg-blueberry-darkest', 'rh-stroke-blueberry-dark', 'hover--rh-stroke-blueberry', 'focus--rh-stroke-blueberry', 'active--rh-stroke-blueberry-darkest');

            showMoreBtn.innerHTML = `
                <span class="button-label rh-mr-0_75">${intl.formatMessage(Messages.SHOW_MORE)}</span>
                <svg
                    viewBox="0 0 62 62"
                    class="icon rh-fill-transparent"
                    stroke-width="3px"
                    width="20"
                    height="20"
                >
                    ${ICON_PLUS_SHAPES};
                </svg>
            `;

            showMoreBtn.addEventListener('click', handleButtonClick);

            overflowContainer.parentNode.insertBefore(showMoreBtn, overflowContainer.nextSibling);
        }

        function removeButton(button) {
            button.removeEventListener('click', handleButtonClick);
        }

        expandableTables.forEach(expandableTable => {
            addButton(expandableTable);
        });

        return () => {
            const allButtons = richTextContainer.querySelectorAll(`[data-name="${VIEW_MORE_BTN_DATA_NAME}"]`);

            allButtons.forEach(button => {
                removeButton(button);
            });
        };
    }, [ content, containerRef?.current ]);

    return (
        <div
            ref={containerRef}
            className={classNames(
                'rich-text',
                { 'no-vertical-margins': !hasVerticalMargins },
                { 'light-variant': variant === 'light' },
                className,
            )}

            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: newContent,
            }}
        />
    );
}

const Messages = defineMessages({
    SHOW_MORE: {
        id: 'web-components.rich-text-block.btnShowMore',
        defaultMessage: 'show more',
    },
    SHOW_LESS: {
        id: 'web-components.rich-text-block.btnShowLess',
        defaultMessage: 'show less',
    },
});

RichTextContent.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([ 'dark', 'light' ]),
    content: PropTypes.string,
    hasVerticalMargins: PropTypes.bool,
};

RichTextContent.defaultProps = {
    className: undefined,
    variant: 'dark',
    content: undefined,
    hasVerticalMargins: true,
};


const ICON_PLUS_SHAPES = `
    <circle cx="31" cy="31" r="29" />
    <path d="M31 15.5312V46.5313" />
    <path d="M15.5 31.0312L46.5 31.0313" />
`;
const ICON_MINUS_SHAPES = `
    <circle cx="31" cy="31" r="29" />
    <path d="M15.5 31.0312L46.5 31.0313" />
`;

export default RichTextContent;
