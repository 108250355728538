import { Locales } from '@ratehub/base-ui';
import { PATHS } from '@ratehub/web-components';


export default function getBlogTagPath(tag, locale) {
    const tagPath = locale === Locales.FRENCH ? PATHS.TAG_FR : PATHS.TAG;

    const slug = locale === Locales.FRENCH && tag.slug.endsWith('-fr')
        ? tag.slug.slice(0, tag.slug.length - 3)
        : tag.slug;

    return `${tagPath}/${slug}/`; // Trailing slash is desired for /blog or /blogue
}
