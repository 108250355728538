/*
 *  Before removing an experiment from this file, read
 *  https://github.com/ratehub/front-end/blob/main/docs/experiments.md#how-to-remove-an-experiment
 */


// https://cms.ratehub.dev/wp-admin/edit.php?post_type=experiments
// https://cms.ratehub.ca/wp-admin/edit.php?post_type=experiments

const EXPERIMENTS = {
    // added 2023-Oct-24
    mobileCTA: 'cc-mobile-cta',                         // to be removed by ccdep-3118

    // added 2024-Apr-29
    openedDetails: 'cc-opened-details',                 // to be removed by ccdep-3569

    // added 2024-Aug-08
    incentiveCompactPage: 'cc-incentive-compact-page',  // to be removed by ccdep-37xx

    // added 2024-Oct-17
    altTextCTA: 'cc-alt-text-cta',                      // to be removed by ccdep-3862
};

export default EXPERIMENTS;
