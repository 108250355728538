import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import {
    Config,
    PrimaryButton,
    Form,
    generateDropdownOptions,
    navigateWindowTo,
    Locales,
    QuestionCompact,
    Sizes,
    getSortedProvinceOptions,
} from '@ratehub/base-ui';
import { LifeInsuranceTypeMessages } from '@ratehub/ins-common';
import { InsLifeWizardInsuranceTypes } from '@ratehub/documents/ins-life-document/stable';

import PAGE_TEMPLATES from '../definitions/PageTemplates';
import CommonMessages from '../definitions/CommonMessages';


const InsuranceTypeOptions = generateDropdownOptions(
    InsLifeWizardInsuranceTypes,
    LifeInsuranceTypeMessages,
);

function LifeInsuranceQuoteLauncher() {
    const intl = useIntl();

    function handleSubmit({ lifeInsuranceType }) {
        const BASE_ROUTE = intl.locale === Locales.FRENCH
            ? Config.INS_LIFE_WIZARD_BASE_ROUTE_FR
            : Config.INS_LIFE_WIZARD_BASE_ROUTE_EN;
        
        navigateWindowTo(`${BASE_ROUTE}/${lifeInsuranceType}`);
    }

    return (
        <StyledForm
            onSubmit={handleSubmit}
        >
            <QuestionCompact
                field={{
                    name: 'lifeInsuranceType',
                    type: 'choice',
                    options: InsuranceTypeOptions,
                }}
                labelMessage={<FormattedMessage {...CommonMessages.insuranceType} />}
                shouldRemoveMargin
            />

            <QuestionCompact
                field={{
                    name: 'province',
                    type: 'choice',
                    options: getSortedProvinceOptions(intl),
                }}
                labelMessage={<FormattedMessage {...CommonMessages.province} />}
                shouldRemoveMargin
            />

            <PrimaryButton
                data-name="life-insurance-quote-launcher-submit"
                message={(
                    <FormattedMessage
                        id="WebComponents.LifeInsuranceQuoteLauncher.submit"
                        defaultMessage="Continue"
                    />
                )}
            />
        </StyledForm>
    );
}

const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    gap: ${Sizes.SPACING.ONE};
    /*
        This is to fix an issue in MacOS/Chromium based browsers where the submit button would stretch
        to fill the height of the container
    */
    align-items: flex-start;

    /* Using immediate child selector because class names are not applied to the root of QuestionCompact */
    > * {
        flex: 1 0 200px;
    }
`;

LifeInsuranceQuoteLauncher.blockKey = 'rh/life-ins-inline-form';
LifeInsuranceQuoteLauncher.supportedPageTypes = [ PAGE_TEMPLATES.insurance ];

export default observer(LifeInsuranceQuoteLauncher);
