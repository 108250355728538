/**
 * Determine if a string is a valid phone number in North America (NANPA).
 * 
 * Based on a rudimentary set of rules; we are not checking for number assignments. See ATIS-0300055 for more information.
 * 
 * @param {string} formattedPhoneNumber - formatted or unformatted phone number (ie. only numbers)
 * @return {boolean} is valid
 */
function isPhoneNumberValid(formattedPhoneNumber) {
    const unformattedPhoneNumber = getUnformattedPhoneNumber(formattedPhoneNumber);

    // Basic format of phone number is NXX-NXX-XXXX where N is [2-9] and X is [0-9] according to ATIS-0300055 1.0
    if (!(/^[2-9]\d{2}[2-9]\d{6}$/.test(unformattedPhoneNumber))) {
        return false; // fails basic validation
    }

    const areaCode = unformattedPhoneNumber.slice(0, 3);
    const exchange = unformattedPhoneNumber.slice(3, 6);

    // Area codes that are not assigned because of potential conflicts according to ATIS-0300055 4.2
    if (UNASSIGNABLE_AREA_CODES.includes(areaCode)) {
        return false;
    }

    // These area code prefixes have been reserved for future use; according to ATIS-0300055 4.3
    if (areaCode.startsWith('37') || areaCode.startsWith('96')) {
        return false;
    }

    // These area codes are reserved for future expansion; according to ATIS-0300055 4.1.2
    if (/^\d9\d$/.test(areaCode)) {
        return false;
    }

    // Disallow N11 codes according to ATIS-0300055 4.2.1
    if (areaCode.endsWith('11') || exchange.endsWith('11')) {
        return false;
    }

    return true;
}

// Excludes N11 codes which are asserted separately
const UNASSIGNABLE_AREA_CODES = [ '950', '555', '988' ];

/**
 * Removing formatting (ie. (###) ###-#### to ##########) from a phone number.
 * @param {string} formattedPhoneNumber 
 * @returns {string}
 */
function getUnformattedPhoneNumber(formattedPhoneNumber) {
    return formattedPhoneNumber.replace(/[^0-9]/g, '');
}

export default isPhoneNumberValid;
