import BlueprintPlacementTool from '../components/BlueprintPlacementTool';
import ExperimentSegment from '../components/ExperimentSegment';
import WordpressCoreGroup from '../components/WordpressCoreGroup';
import ContentAreaContents from '../components/ContentAreaContents';
import BlueprintInnerBlocks from '../components/BlueprintInnerBlocks';


// Blocks which are not considered "real" blocks in that they do not produce any
// useful user interface for the user, but they wrap other blocks which do. We
// treat blocks inside of these wrappers in special ways sometimes. For example,
// the sidebar will treat blocks at the root of these wrappers as "top-level"
// blocks when considering them for inclusion in the TableOfContents.
const CONTAINER_BLOCKS = [
    BlueprintPlacementTool.blockKey,
    ExperimentSegment.blockKey,
    WordpressCoreGroup.blockKey,
    ContentAreaContents.blockKey,
    BlueprintInnerBlocks.blockKey,
];

export default CONTAINER_BLOCKS;
