import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Sizes,
    Colours,
    IconArrow,
} from '@ratehub/base-ui';

import {
    ITEM_COUNTER_STYLE,
    ITEM_TITLE_STYLE,
    CIRCLE_ORDERED_ITEM_PARAGRAPH_STYLE,
} from '../styles/OrderedListStyles';
import { ORDERED_LIST_ITEM_TYPE, ORDERED_LIST_VARIANT } from '../definitions/OrderedListType';
import LINK_TARGETS from '../definitions/LinkTargets';
import ListContainer from './ListContainer';
import withLink from './withLink';


const LIST_TYPES = ListContainer.LIST_TYPES;

function ListItem({ variant, listType, title, linkHref, linkTarget, body, listStyleType, ...otherProps }) {
    const hasLink = linkHref && linkTarget;
    const Title = hasLink ? MainTitleWithLink : 'div';

    return (
        <Container
            variant={variant}
            listType={listType}
            listStyleType={listStyleType}
            {...otherProps}
        >
            <Title
                href={linkHref}
                target={hasLink ? linkTarget : undefined}
                className={classNames('rh-title-m', 'list-item-title', 'rh-mt-1', 'rh-fg-blueberry-dark', 'weight-regular')}
            >
                {title}

                <If condition={hasLink}>
                    <IconArrow
                        direction="right"
                        className="icon"
                        outlineWidth={0}
                        stroke={Colours.BLUEBERRY_DARK}
                        strokeWidth="3px"
                        viewBox="21 15 19.5 32" /* custom viewBox to trim to artwork */
                    />
                </If>
            </Title>

            <p className="list-item-paragraph rh-text-l">
                {body}
            </p>
        </Container>
    );
}

ListItem.propTypes = {
    variant: PropTypes.oneOf(Object.values(ORDERED_LIST_VARIANT)),
    listType: PropTypes.oneOf(Object.values(LIST_TYPES)),
    title: PropTypes.string.isRequired,
    linkHref: PropTypes.string,
    linkTarget: PropTypes.oneOf(Object.values(LINK_TARGETS)),
    body: PropTypes.string.isRequired,
    listStyleType: PropTypes.oneOf(Object.values(ORDERED_LIST_ITEM_TYPE)),
};

ListItem.defaultProps = {
    variant: ORDERED_LIST_VARIANT.DEFAULT,
    listType: LIST_TYPES.ORDERED,
    linkHref: undefined,
    linkTarget: LINK_TARGETS.CURRENT_TAB,
    listStyleType: ORDERED_LIST_ITEM_TYPE.DECIMAL,
};

const MainTitleWithLink = withLink('div');


/* The combined width of the marker & the gutter - matches LIST_INDENT in
ListItem component */
const LIST_INDENT = `calc(${ Sizes.SPACING.FOUR } + 4%)`;
const TRANSITION_DURATION = '300ms';
const TRANSITION_EASING = 'ease-out';

const Container = styled.li`
    position: relative;

    ${props => props.listType === LIST_TYPES.ORDERED && `
        padding-left: ${LIST_INDENT};
    `}

    margin-bottom: ${props => props.variant === ORDERED_LIST_VARIANT.CIRCLE
        ? Sizes.SPACING.TWO
        : Sizes.SPACING.FOUR};

    .list-item-title {
        ${props => ITEM_TITLE_STYLE[props.variant]}

        > .icon {
            position: relative;
            top: 0.3rem;
            left: ${Sizes.SPACING.ONE};
            width: ${Sizes.ICONS['2XS']};
        }
    }

    > a {
        text-decoration: underline;
        text-decoration-color: ${Colours.TRANSPARENT};
        transition: text-decoration-color ${TRANSITION_DURATION} ${TRANSITION_EASING};

        > .list-item-title {
            > .icon {
                transition: left ${TRANSITION_DURATION} ${TRANSITION_EASING};
            }
            margin-bottom: ${Sizes.SPACING.ONE_AND_AN_EIGHTH};
        }

        &:hover {
            text-decoration-color: ${Colours.BLUEBERRY};

            > .list-item-title {
                > .icon {
                    left: ${Sizes.SPACING.ONE_AND_A_HALF};
                }
                color: ${Colours.BLUEBERRY};
            }
        }

        &:active {
            text-decoration-color: ${Colours.BLUEBERRY_DARKEST};
            > .list-item-title {
                color: ${Colours.BLUEBERRY_DARKEST};
            }
        }
    }

    > .list-item-paragraph {
        margin-top: 0;
        ${props => props.variant === ORDERED_LIST_VARIANT.CIRCLE && CIRCLE_ORDERED_ITEM_PARAGRAPH_STYLE};
    }

    &::before {
        ${props => props.listType === LIST_TYPES.ORDERED && `
            content: counter(item, ${props.listStyleType});

            counter-increment: item 1;

            position: absolute;
            left: 0;
            top: 0;
        `}

        ${props => props.listType === LIST_TYPES.ORDERED && ITEM_COUNTER_STYLE[props.variant]};
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

ListItem.blockKey = 'rh/list-item';

export default ListItem;
