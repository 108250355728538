import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';

import {
    Sizes,
    AnimatedFadeContainer,
    PageBehaviourButton,
    Colours,
} from '@ratehub/base-ui';

import SiteSettings from '../definitions/SiteSettings';
import { MENU_PADDING } from './LinkBlockAnchor';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
    SIDEBAR: 'sidebar',
};

function LinkBlockList({
    className,
    variant,
    title,
    hasBorder,
    hasOuterPadding,
    maxChildren,
    numCollapsedItemsHidden,
    children,
    ...otherProps
}) {
    const isExpandable = maxChildren && maxChildren > 0 ? React.Children.count(children) > maxChildren : false;

    const numCollapsedChildren = maxChildren > numCollapsedItemsHidden
        ? maxChildren - numCollapsedItemsHidden
        : 0;

    const visibleChildren = React.Children.toArray(children).slice(0, numCollapsedChildren); // first X children
    const expandableChildren = React.Children.toArray(children).slice(numCollapsedChildren); // ... and the rest

    const [ isExpanded, setIsExpanded ] = useState(false);

    const toggleButtonVariant = variant === VARIANTS.SIDEBAR ? 'dark' : variant;

    return (
        <Container
            className={classNames(className, {
                'variant-dark': variant === VARIANTS.DARK,
                'variant-light': variant === VARIANTS.LIGHT,
                'variant-sidebar': variant === VARIANTS.SIDEBAR,

                'has-border': hasBorder,
                'has-outer-padding': hasOuterPadding,
            })}
            {...otherProps}
        >
            <If condition={title}>
                <h3 className="heading rh-title-s rh-my-2 rh-mx-0">
                    {title}
                </h3>
            </If>

            <Choose>
                <When condition={isExpandable}>
                    <ul 
                        className={classNames({
                            'rh-my-0': variant === VARIANTS.SIDEBAR,
                        })}
                    >
                        {visibleChildren}

                        <AnimatedFadeContainer
                            className={SiteSettings.LINK_BLOCK_LIST_EXPANDABLE_CONTAINER_CLASSNAME}
                            as="span"
                            isVisible={isExpanded}
                            withColour={false}
                            alwaysRenderChildMarkup={true} // For SEO benefit
                        >
                            {expandableChildren}
                        </AnimatedFadeContainer>
                    </ul>

                    <PageBehaviourButton
                        className={SiteSettings.TOGGLE_BUTTON_LINK_BLOCK_LIST}
                        data-name={SiteSettings.TOGGLE_BUTTON_LINK_BLOCK_LIST}
                        variant={toggleButtonVariant}
                        message={isExpanded ? Messages.viewLess : Messages.viewMore}
                        size={variant === VARIANTS.TABLE_OF_CONTENTS ? 'extra-small' : 'small'}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </When>

                <Otherwise>
                    <ul
                        className={classNames({
                            'rh-my-0': variant === VARIANTS.SIDEBAR,
                        })}
                    >
                        {children}
                    </ul>
                </Otherwise>
            </Choose>
        </Container>
    );
}

LinkBlockList.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    title: PropTypes.string,
    hasBorder: PropTypes.bool,
    hasOuterPadding: PropTypes.bool,
    maxChildren: PropTypes.number,
    numCollapsedItemsHidden: PropTypes.number,
    children: PropTypes.any.isRequired,
};

LinkBlockList.defaultProps = {
    className: undefined,
    variant: VARIANTS.DARK,
    title: undefined,
    hasBorder: false,
    hasOuterPadding: true,
    maxChildren: 5,
    numCollapsedItemsHidden: 0,
};

const Container = styled.div`
    /* Flex item values for when this appears in a flex context (See LinkBlockContainer with allowOverflow={true}) */
    flex-basis: 310px;
    flex-shrink: 0;
    flex-grow: 1;

    box-sizing: border-box;

    &.has-border {
        border: 1px solid ${Colours.STONE};
        border-radius: 2px;
        padding: 0 ${Sizes.SPACING.TWO} ${Sizes.SPACING.ONE};
    }

    &.has-outer-padding {
        padding-bottom: ${Sizes.SPACING.TWO};
        padding-right: ${Sizes.SPACING.TWO_AND_A_HALF};
    }

    > ul {
        padding-left: 0;

        > .isExpandable.hidden {
            display: none;
        }
    }

    &.variant-dark,
    &.variant-sidebar {
        > .heading {
            color: ${Colours.BLACKBERRY};
        }
    }
    &.variant-light {
        > .heading {
            color: ${Colours.COCONUT};
        }
    }

    &.variant-sidebar {
        > .heading {
            font-size: ${Sizes.FONTS.M};
            margin: ${Sizes.SPACING.ONE_AND_A_QUARTER} 0;
        }

        > .heading,
        > .${SiteSettings.TOGGLE_BUTTON_LINK_BLOCK_LIST} {
            padding-left: ${MENU_PADDING};
            padding-right: ${MENU_PADDING};
        }

        > .${SiteSettings.TOGGLE_BUTTON_LINK_BLOCK_LIST} {
            font-size: ${Sizes.FONTS.XS};
        }
    }
`;

LinkBlockList.blockKey = 'rh/link-block-list';

const Messages = defineMessages({
    viewMore: {
        id: 'web-components.LinkBlockList.viewMore',
        defaultMessage: 'View more',
    },
    viewLess: {
        id: 'web-components.LinkBlockList.viewLess',
        defaultMessage: 'View less',
    },
});

export default LinkBlockList;
