import HERO_BLOCK_KEYS from '../definitions/HeroBlockKeys';
import ExperimentSegment from '../components/ExperimentSegment';
import BlockTypes from '../definitions/BlockTypes';
import LayoutRow from '../components/LayoutRow';


/**
 * Extracts designated hero blocks from an array of blocks
 *
 * @param {array} blocks
 * @returns {array} Array with one hero block or an array of experiment segments featuring heroes
 */
function getHeroBlocks(blocks) {
    // @todo refactor this function to return only a single hero block
    const heroExperimentBlocks = blocks.filter(block => doesBlockOfTypeContainHero(block, ExperimentSegment.blockKey));

    if (heroExperimentBlocks.length > 0) {
        return heroExperimentBlocks;
    }

    // @todo this does not currently work because at this point all we have is the blueprint id and not the internal content
    // const heroBlueprintBlocks = blocks.filter(block => doesBlockOfTypeContainHero(block, BlueprintPlacementTool.blockKey));

    // if (heroBlueprintBlocks.length > 0) {
    //     return heroBlueprintBlocks
    // }

    const topLevelHero = blocks.find(isHeroBlock);

    return topLevelHero
        ? [ topLevelHero ]
        : [];
}

function doesBlockOfTypeContainHero(block, blockKeyToMatch) {
    return block.blockName === blockKeyToMatch && block.innerBlocks.some(isHeroBlock);
}

function isHeroBlock(block) {
    return (block.blockName === LayoutRow.blockKey && block.attrs.blockType === BlockTypes.HERO)
        || HERO_BLOCK_KEYS.includes(block.blockName);
}

export default getHeroBlocks;

export {
    isHeroBlock,
};
