import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { FormattedMessage } from 'react-intl';
import AddressPropType from '../definitions/AddressPropType';
import { PROVINCE } from '../definitions/Provinces';
import AddressSearchResultTypesPropType from '../definitions/AddressSearchResultTypesPropType';
import MessagePropType from '../definitions/MessagePropType';
import AddressInputModes from '../definitions/AddressInputModes';
import isAddressComplete from '../functions/isAddressComplete';
import Messages from '../definitions/AddressMessages';
import InputController from './InputController';
import AddressInputForm from './AddressInputForm';
import AddressInputFormWide from './AddressInputFormWide';
import AddressInputBasic from './AddressInputBasic';
import AnimatedFadeContainer from './AnimatedFadeContainer';


const ANIMATION_MS = 250;

const ADDRESS_VARIANTS = {
    WIDE: 'wide',
    DEFAULT: 'default',
};

function AddressInput({
    id,
    name,
    value,

    tabIndex,
    allowedProvinces,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    isRequired,
    isDisabled,
    isVisible,

    variant,

    searchResultTypes,
    placeholder,

    canShowInvalid,

    onCreateField,

    ...otherProps
}) {
    const AddressFormComponent = variant === ADDRESS_VARIANTS.WIDE
        ? AddressInputFormWide
        : AddressInputForm;
    const containerId = id ?? name;

    return (
        <InputController
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            getParsedValue={address => isAddressComplete(address) ? address : null}
            getFormattedValue={address => isAddressComplete(address) ? address : null}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isVisible={isVisible}
            canShowInvalid={canShowInvalid}
            onCreateField={onCreateField}
            defaultErrorMessage={(
                <FormattedMessage
                    {...Messages.SEARCH_INVALID}
                />
            )}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                const [ mode, setMode ] = useState(() => isAddressComplete(inputProps.value) 
                    ? AddressInputModes.FORM 
                    : AddressInputModes.BASIC,
                );

                const [ transitionToMode, setTransitionToMode ] = useState(null);

                function handleAnimationEnd() {
                    if (!transitionToMode) {
                        return;
                    }

                    setMode(transitionToMode === AddressInputModes.BASIC
                        ? AddressInputModes.BASIC
                        : AddressInputModes.FORM,
                    );
                    setTransitionToMode(null);
                }

                function handleShowFormMode() {
                    setMode(AddressInputModes.FORM);
                }
    
                function handleShowBasicMode() {
                    setTransitionToMode(AddressInputModes.BASIC);
                    inputProps.onChange(null); // clear our previous search result.
                    inputState.canShowInvalid = false; // dont show validation errors; we're resetting the input.
                }

                function handleSearchComplete(values) {
                    setMode(AddressInputModes.FORM);
                    inputProps.onChange(values);
                    // highlight any invalid fields; some addresses from search may not have all required fields.
                    inputState.canShowInvalid = true;
                }

                function handleBlur(event) {
                    // if we are focusing on an element inside our container, don't blur.
                    if (event.relatedTarget?.closest?.(`[id="${containerId}"]`)) {
                        return;
                    }

                    inputProps.onBlur(event);
                }

                return (
                    <AddressInput.OuterContainer
                        {...otherProps}
                        id={containerId}
                    >
                        <AnimatedFadeContainer
                            duration={ANIMATION_MS}
                            isVisible={mode === AddressInputModes.BASIC && !transitionToMode}
                            onEnd={handleAnimationEnd}
                        >
                            <AddressInputBasic
                                id={`addressInput-${inputProps.id ?? inputProps.name}`}
                                name={`addressInput-${inputProps.name}-search`}

                                value={inputProps.value}

                                onChange={handleSearchComplete}
                                onChangeMode={handleShowFormMode}

                                searchResultTypes={searchResultTypes}

                                placeholder={placeholder}

                                isRequired={isRequired}
                                isDisabled={isDisabled}
                            />
                        </AnimatedFadeContainer>

                        <AnimatedFadeContainer
                            duration={ANIMATION_MS}
                            isVisible={mode === AddressInputModes.FORM && !transitionToMode}
                            onEnd={handleAnimationEnd}
                        >
                            <AddressFormComponent
                                name={inputProps.name}
                                tabIndex={tabIndex}
                                value={inputProps.value}

                                allowedProvinces={allowedProvinces}
                                shouldShowInvalid={inputState.shouldShowInvalid}

                                onChangeMode={handleShowBasicMode}
                                onChange={inputProps.onChange}
                                onBlur={handleBlur}

                                isDisabled={isDisabled}
                                // even if optional, we need to make sure the user has a valid address which requires specific fields.
                                isRequired={isRequired || !inputState.isValueEmpty}
                            />
                        </AnimatedFadeContainer>
                    </AddressInput.OuterContainer>
                );
            }}
        </InputController>
    );
}

AddressInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: AddressPropType,

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isVisible: PropTypes.bool,

    allowedProvinces: PropTypes.arrayOf(PropTypes.string),
    searchResultTypes: AddressSearchResultTypesPropType,
    
    tabIndex: PropTypes.number,
    variant: PropTypes.oneOf(
        Object.values(ADDRESS_VARIANTS),
    ),

    canShowInvalid: PropTypes.bool,

    placeholder: MessagePropType,

    onCreateField: PropTypes.func,
};

AddressInput.defaultProps = {
    id: undefined,
    value: undefined,

    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,

    allowedProvinces: Object.values(PROVINCE),
    searchResultTypes: undefined,

    isRequired: undefined,
    isDisabled: undefined,
    isVisible: undefined,

    tabIndex: 0,
    variant: ADDRESS_VARIANTS.DEFAULT,

    canShowInvalid: undefined,

    placeholder: undefined,

    onCreateField: undefined,
};

AddressInput.OuterContainer = styled.div`
    position: relative;
`;

export default observer(AddressInput);
export {
    ADDRESS_VARIANTS,
    AddressInputModes,
};
