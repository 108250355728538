import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';

import Colours from '../definitions/Colours';
import MessagePropType from '../definitions/MessagePropType';
import Sizes from '../definitions/Sizes';
import Paragraph from './Paragraph';
import PrimaryButton from './PrimaryButton';


const LAYOUT_STYLES = {
    NORMAL: 'normal',
    COMPACT: 'compact',
    VERTICAL: 'vertical',
};

function NavigationCard({
    onClick,
    'data-name': dataName,

    backgroundColour,
    hoverColour,
    activeColour,

    title,
    description,
    ctaText,

    titleMinLines,
    descriptionMinLines,

    layoutStyle,

    ...otherProps
}) {
    const useCompactLayout = layoutStyle === LAYOUT_STYLES.COMPACT;
    const useVerticalLayout = layoutStyle === LAYOUT_STYLES.VERTICAL;

    return (
        <Container
            as="div"
            backgroundColour={backgroundColour}
            hoverColour={hoverColour}
            activeColour={activeColour}
            onClick={onClick}
            decreasePadding={useCompactLayout}
            isDescriptionIncluded={!!description}
            shouldUseCompactLayout={useCompactLayout}
            shouldUseVerticalLayout={useVerticalLayout}
            titleMinLines={titleMinLines}
            descriptionMinLines={descriptionMinLines}
            data-name={dataName}
            role="button"
            {...otherProps}
        >
            <span className="grid-container">
                <Paragraph
                    className="title"
                    variant="light"
                    size="heading"
                    weight="medium"
                    forwardedAs="p"
                    message={title}
                />

                <If condition={description}>
                    <Paragraph
                        className="description"
                        variant="light"
                        size="normal"
                        forwardedAs="p"
                        message={description}
                    />
                </If>

                <PrimaryButton
                    className="continue-button"
                    type="button"
                    variant="coconut"
                    size={useVerticalLayout ? 'medium' : 'large'}
                    message={ctaText || MESSAGES.buttonText}
                />
            </span>
        </Container>
    );
}

NavigationCard.propTypes = {
    onClick: PropTypes.func.isRequired,
    'data-name': PropTypes.string,

    backgroundColour: PropTypes.string,
    hoverColour: PropTypes.string,
    activeColour: PropTypes.string,

    title: MessagePropType.isRequired,
    description: MessagePropType,
    ctaText: MessagePropType,

    titleMinLines: PropTypes.number,
    descriptionMinLines: PropTypes.number,

    layoutStyle: PropTypes.oneOf(
        Object.values(LAYOUT_STYLES),
    ),
};

NavigationCard.defaultProps = {
    'data-name': undefined,

    backgroundColour: Colours.COCONUT,
    hoverColour: undefined,
    activeColour: undefined,

    description: undefined,
    ctaText: undefined,

    titleMinLines: 2,
    descriptionMinLines: 3,

    layoutStyle: 'normal',
};

const TITLE_LINE_HEIGHT = Sizes.SPACING.THREE;
const DESCRIPTION_LINE_HEIGHT = Sizes.SPACING.TWO;

const Container = styled.button`
    width: 100%;
    height: 100%;

    /* vertical & horizontal centering of card content (the grid) */
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${Sizes.SPACING.THREE} 12%;

    @media (max-width: 1100px) {
        padding-left: 8%;
        padding-right: 8%;
    }

    ${props => props.shouldUseVertcialLayout && `
        padding-top: ${Sizes.SPACING.TWO_AND_A_HALF};
        padding-bottom: ${Sizes.SPACING.THREE};
    `}

    box-sizing: border-box;

    border: none;
    background-color: ${props => props.backgroundColour};

    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.hoverColour};
    }

    &:active {
        background-color: ${props => props.activeColour};
    }

    &:focus {
        outline: none;
    }

    .grid-container {
        /* Optically balance the content so it appears to sit a little closer
        to the vertical center of the viewport rather than the card */
        margin-top: -${props => props.shouldUseVerticalLayout ? Sizes.SPACING.THREE_QUARTERS : '10vh'};

        display: -ms-grid;
        display: grid;

        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;

        /* accomodates title & button */
        -ms-grid-rows: auto ${Sizes.SPACING.ONE} auto;
        grid-template-rows: auto auto;
        grid-row-gap: ${Sizes.SPACING.ONE};

        /* accomodates title, description & button */
        ${props => props.isDescriptionIncluded && `
            -ms-grid-rows: auto ${Sizes.SPACING.ONE} auto ${Sizes.SPACING.ONE} auto;
            grid-template-rows: auto auto auto;
            grid-row-gap: ${Sizes.SPACING.ONE};
        `}

        /* stacked, auto layout when mobile */
        ${props => props.shouldUseVerticalLayout && `
            height: auto;
            -ms-grid-rows: auto ${Sizes.SPACING.ONE} auto ${Sizes.SPACING.ONE} auto;
            grid-template-rows: auto auto auto;
        `}
    }

    .title {
        display: block;
        -ms-grid-row: 1;
        grid-row: 1;

        margin: 0;
        line-height: ${TITLE_LINE_HEIGHT};

        min-height: ${props => `calc(${TITLE_LINE_HEIGHT} * ${props.titleMinLines})`};

        ${props => props.shouldUseVerticalLayout && `
            min-height: auto; /* overrides min-height determined above */

            font-size: ${Sizes.FONTS.XL};
            line-height: 32px;
        `}
    }

    .description {
        display: block;
        -ms-grid-row: 3;
        grid-row: 2;

        margin: 0;
        line-height: ${DESCRIPTION_LINE_HEIGHT};

        min-height: ${props => `calc(${DESCRIPTION_LINE_HEIGHT} * ${props.descriptionMinLines})`};

        ${props => props.shouldUseVerticalLayout && `
            min-height: auto; /* overrides min-height determined above */

            font-size: ${Sizes.FONTS['S']};
            line-height: 18px;
        `}
    }

    .continue-button {
        display: block;
        -ms-grid-row: 5;
        grid-row: 3;

        margin-top: ${Sizes.SPACING.THREE_QUARTERS};
        margin-left: auto;
        margin-right: auto;
        max-width: 22rem;
    }
`;

const MESSAGES = defineMessages({
    buttonText: {
        id: 'base-ui.navigation-card.button-text',
        defaultMessage: 'Continue',
    },
});

export default NavigationCard;
export { LAYOUT_STYLES };
