import { Config, fetchJSON } from '@ratehub/base-ui';


/**
 * Fetch list of crypto products. Takes standard unprocessed array format.
 * @param {Array<string>} productIDs
 */

export default async function fetchCryptoProducts(productIDs) {
    return fetchJSON({
        url: `${Config.CRYPTO_PRODUCT_API_URL}?include=${productIDs}`,
    }, 'fetchCryptoProducts');
}
