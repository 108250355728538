import React from 'react';

import PAGE_TEMPLATES from '../definitions/PageTemplates';
import ArticleShowcase from './ArticleShowcase';


function PressCentre({ ...otherProps }) {
    return (
        <ArticleShowcase
            {...otherProps}
        />
    );
}

PressCentre.blockKey = 'rh/press-centre';
PressCentre.hasHeading = true;
PressCentre.requiresLayoutRow = true;
PressCentre.supportedPageTypes = [
    PAGE_TEMPLATES.corporate,
];

export default PressCentre;
