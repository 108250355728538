import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, defineMessages } from 'react-intl';
import Modal from 'react-modal';

import PositionOffscreenStyles from '../styles/PositionOffscreenStyles';
import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import Colours from '../definitions/Colours';
import ZIndex from '../definitions/ZIndex';
import Sizes from '../definitions/Sizes';
import LogoFull from '../graphics/LogoFull';
import LogoLetterMark from '../graphics/LogoLetterMark';
import CloseButton from './CloseButton';


// contentMaxWidth is only used by our style sheet
function ModalContainer({
    isOpen = true,
    onClose,

    style = FULL_SCREEN_TAKEOVER_STYLES,
    showBranding = false,
    partnerBranding,

    // eslint-disable-next-line no-unused-vars
    contentMaxWidth,     // Only used by our styled(ModalContainer); just want it out of otherProps.

    closeModalText,
    homepageLinkText,

    topRightElement,
    children,

    ...otherProps },
) {
    const intl = useIntl();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            style={{
                ...style,
                overlay: {
                    ...FULL_SCREEN_TAKEOVER_STYLES.overlay,
                    ...style?.overlay,
                },
                content: {
                    ...FULL_SCREEN_TAKEOVER_STYLES.content,
                    ...style?.content,
                },
            }}
            data-test-name="modal"
            {...otherProps}
        >
            <Choose>
                <When condition={partnerBranding}>
                    {partnerBranding}
                </When>
                <Otherwise>
                    <header className="chrome contentWrapper rh-display-grid">
                        <CloseButton
                            className="closeButton"
                            onClick={onClose}
                            isOpen={isOpen}
                            offscreenText={messageToString(closeModalText || MESSAGES.closeModalText, intl)}
                            data-name={'Modal-closeButton'}
                        />

                        <If condition={showBranding}>
                            <a
                                className="logoAnchor rh-display-block rh-align-items-center"
                                href={getHomepageUrl(intl)}
                            >
                                <span className="offscreen">
                                    {messageToString(homepageLinkText || MESSAGES.homepageLinkText, intl)}
                                </span>
                                <LogoFull className="logoFull" />
                                <LogoLetterMark className="logoSmall" />
                            </a>
                        </If>

                        <If condition={topRightElement}>
                            <div className="topRight rh-display-flex rh-align-items-center">
                                {topRightElement}
                            </div>
                        </If>
                    </header>
                </Otherwise>
            </Choose>

            <div className="rh-position-relative rh-overflow-auto">
                <div className="contentWrapper">
                    {children}
                </div>
            </div>
        </Modal>
    );
}

const FULL_SCREEN_TAKEOVER_STYLES = {
    overlay: {
        top: 0,
        left: 0,
        backgroundColor: Colours.TRANSPARENT,
        zIndex: ZIndex.MODALS,
    },
    content: {
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderWidth: 0,
        padding: 0,
        backgroundColor: Colours.COCONUT_DARK,
        overflow: 'auto',
    },
};


ModalContainer.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    closeModalText: MessagePropType,
    homepageLinkText: MessagePropType,
    style: PropTypes.shape({
        overlay: PropTypes.any,
        content: PropTypes.any,
    }),
    showBranding: PropTypes.bool,
    partnerBranding: PropTypes.node,
    contentMaxWidth: PropTypes.string,

    topRightElement: PropTypes.any,
    children: PropTypes.any.isRequired,
};


const MIN_WIDTH_BREAKPOINT = '(min-width: 1023px)';

const StyledModalContainer = styled(ModalContainer)`
    > .chrome {
        grid-template-columns: auto 1fr auto auto;

        height: ${Sizes.SPACING.FOUR};

        & .closeButton {
            grid-column: 1;
            grid-row: 1;

            margin: auto 0;

            @media ${MIN_WIDTH_BREAKPOINT} {
                grid-column: 4;

                /* Add a bit of breathing room to the left incase we have a "bonus element" */
                margin-left: ${Sizes.SPACING.TWO};
            }
        }

        & .logoAnchor {
            margin: auto;
            outline: none;

            grid-column: 1 / 4;
            grid-row: 1;

            width: ${Sizes.ICONS.XS};
            height: ${Sizes.ICONS.XS};

            @media ${MIN_WIDTH_BREAKPOINT} {
                width: auto;
                margin: auto 0;
                grid-column: 2;
                grid-row: 1;
            }

            & .offscreen {
                ${PositionOffscreenStyles}
            }

            & .logoSmall {
                @media ${MIN_WIDTH_BREAKPOINT} {
                    display: none;
                }
            }

            & .logoFull {
                display: none;

                @media ${MIN_WIDTH_BREAKPOINT} {
                    display: block;
                }
            }
        }

        & .topRight {
            grid-column: 3;
            grid-row: 1;
        }
    }

    /* Used by both header and wrapper for children */
    & .contentWrapper {
        width: 95%;
        ${props => props.contentMaxWidth && `
            max-width: ${props.contentMaxWidth};
        `};

        margin: auto;
    }
`;

function getHomepageUrl(intl) {
    return intl.locale.substring(0, 2) === 'en'
        ? '/'
        : '/comparez';
}

const MESSAGES = defineMessages({
    closeModalText: {
        id: 'base-ui.modalContainer.closeModalText',
        defaultMessage: 'Close dialogue',
    },
    homepageLinkText: {
        id: 'base-ui-modalContainer.homepageLinkText',
        defaultMessage: 'Ratehub.ca home page',
    },
});

export default StyledModalContainer;
