import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Config from '../definitions/Config';
import messageToString from '../functions/messageToString';
import getBrandLettermark from '../functions/getBrandLettermark';
import MessagePropType from '../definitions/MessagePropType';


const VARIANTS = {
    DARK: 'dark',
    LIGHT: 'light',
};

function LogoLetterMark({ variant, imageAlt, width, height, ...otherProps }) {
    const intl = useIntl();

    // Currently based on Config.SITE_BRANDING, which limits us to only
    // showing ratehub.ca || rhinsurance.ca brands. Could expand this to
    // allow showing any brand logo, but would require API changes on this
    // component.
    const options = {
        theme: variant,
    };
    const lettermark = getBrandLettermark(Config.SITE_BRANDING, options);

    if (!lettermark) {
        // eslint-disable-next-line no-console
        console.warn(`LogoFull: could not get brand logo for brand '${Config.SITE_BRANDING}' with options: ${options}`);
        return null;
    }

    return (
        <img
            src={lettermark?.filePath}
            width={width ?? lettermark?.intrinsicWidth}
            height={height ?? lettermark?.intrinsicHeight}
            alt={messageToString(imageAlt ?? lettermark?.altText, intl)}
            {...otherProps}
        />
    );
}

LogoLetterMark.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    imageAlt: MessagePropType,
    width: PropTypes.number, // unitless, but interpreted as pixels
    height: PropTypes.number, // unitless, but interpreted as pixels
};

LogoLetterMark.defaultProps = {
    variant: VARIANTS.DARK,
    imageAlt: undefined,
    width: undefined,
    height: undefined,
};

export default LogoLetterMark;
