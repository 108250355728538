const ProductsEN = {
    MORTGAGES: 'mortgages',
    CREDIT_CARDS: 'credit-cards',
    CHEQUING: 'chequing',
    SAVINGS: 'savings',
    GICS: 'gics',
    ROBO_ADVISORS: 'robo-advisors',
    HOME_INSURANCE: 'home-insurance',
    AUTO_INSURANCE: 'auto-insurance',
    LIFE_INSURANCE: 'life-insurance',
    CRYPTO: 'crypto',
};

const ProductsFR = {
    MORTGAGES: 'hypotheques',
    CREDIT_CARDS: 'cartes-de-credit',
    CHEQUING: 'comptes-cheques',
    SAVINGS: 'comptes-d-epargne',
    GICS: 'cpgs',
    ROBO_ADVISORS: 'robo-advisors', // TODO: update when it's defined
    HOME_INSURANCE: 'assurance-habitation',
    AUTO_INSURANCE: 'assurance-automobile',
    LIFE_INSURANCE: 'assurance-vie',
    CRYPTO: 'crypto', // TODO: update when it's defined
};

export default ProductsEN;
export { ProductsFR };