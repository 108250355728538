import { css, keyframes } from 'styled-components';

import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import {
    BORDER_RADIUS,
    BOX_SHADOW_STONE,
    BOX_SHADOW_BLUEBERRY,
    ACTIVE_BACKGROUND_COLOUR,
} from '../definitions/SharedInputStyles';
import CHECKBOX_VARIANTS from '../definitions/CheckboxVariants';


/**
 * This file provides the styling for three flavours of Checkboxes through use of the `variant` prop:
 *      single - single checkbox, minimal styling, traditional square box
 *      multiple - used by CheckboxGroup, hover and selection effects, a circle replaces the square box
 *      expandable - used by CheckboxGroupExpandable, borrows from `multiple`, but different selection and disabled effect
 */


const growCheckmarkAnimation = keyframes`
    0% {
        transform: rotate(45deg) scale(0);
    }

    75% {
        transform: rotate(45deg) scale(1.2);
    }

    100% {
        transform: rotate(45deg) scale(1);
    }
`;

export default css`
    > .checkbox-visual {
        display: inline-block;
        position: relative;

        width: 1.5em;
        height: 1.5em;
        outline: none;

        color: ${Colours.COCONUT};
        background-color: ${Colours.TRANSPARENT};

        border: 1px solid ${Colours.BLACKBERRY};
        border-radius: 5px;

        transition:
            color 300ms,
            background-color 300ms,
            border-color 300ms,
            box-shadow 300ms;

        /* Checkmark drawn with CSS Shape */
        &::after {
            content: ' ';
            position: absolute;

            left: 34%;
            top: 15%;
            width: 34%;
            height: 55%;

            transform: rotate(45deg);

            border: 0.175em solid ${Colours.COCONUT};
            border-top: 0;
            border-left: 0;
            border-radius: 0;
            box-sizing: border-box;

            visibility: hidden;

            transition: border-color 300ms;
        }

        ${props => props.checked && !props.isDisabled && css`
            &:after {
                visibility: visible;
                animation: 300ms ${growCheckmarkAnimation} forwards;
            }
        `}
    }

    > .checkbox-label {
        transition: color 300ms;
    }

    ${props => props.variant === CHECKBOX_VARIANTS.single && css`
        &:hover {
            > .checkbox-visual {
                border-color: ${Colours.BLUEBERRY};
                box-shadow: 0 0 0 1px ${Colours.BLUEBERRY} inset;
            }

            ${props.checked
        ? `
                > .checkbox-visual {
                    border-color: ${Colours.BLUEBERRY_DARK};
                    box-shadow: 0 0 0 1px ${Colours.BLUEBERRY_DARK} inset;

                    &::after {
                        border-color: ${Colours.BLUEBERRY_DARK};
                    }
                }
            `
        : ''}

            ${props.isFocused
        ? `
                > .checkbox-visual {
                    box-shadow: 0 0 0 1px ${Colours.BLUEBERRY} inset, ${BOX_SHADOW_BLUEBERRY};
                }
            `
        : ''}
        }

        ${props.checked
        ? `
            > .checkbox-visual {
                border-color: ${Colours.BLUEBERRY};
                box-shadow: 0 0 0 1px ${Colours.BLUEBERRY} inset;

                &::after {
                    border-color: ${Colours.BLUEBERRY};
                }
            }

        `
        : ''}

        ${props.isFocused
        ? `
            > .checkbox-visual {
                box-shadow: ${BOX_SHADOW_BLUEBERRY};
            }
        `
        : ''}

        ${props.isFocused && props.checked
        ? `
            > .checkbox-visual {
                box-shadow: 0 0 0 1px ${Colours.BLUEBERRY} inset, ${BOX_SHADOW_BLUEBERRY};
            }
        `
        : ''}

        ${props.isDisabled
        ? `
            > .checkbox-visual {
                border-color: ${Colours.STONE};
            }

            > .checkbox-label {
                color: ${Colours.STONE};
            }

            &:hover {
                > .checkbox-visual {
                    border-color: ${Colours.STONE};
                }

                > .checkbox-label {
                    color: ${Colours.STONE};
                }
            }
        `
        : ''}
    `}

    ${props => [ CHECKBOX_VARIANTS.multiple, CHECKBOX_VARIANTS.expandable ].includes(props.variant) && css`
        position: relative;

        display: flex;
        align-items: center;

        min-height: ${Sizes.SPACING.FOUR};
        box-sizing: border-box;

        padding: ${Sizes.SPACING.HALF} ${Sizes.SPACING.ONE};

        border: 1px solid ${props => props.variant === CHECKBOX_VARIANTS.multiple ? `${Colours.STONE_DARK}` : `${Colours.COCONUT}`};
        border-radius: ${BORDER_RADIUS};

        background-color: ${Colours.COCONUT};

        transition:
            border-color 300ms,
            background-color 300ms,
            box-shadow 300ms;

        cursor: pointer;

        > .checkbox-visual {
            border-radius: 50%; /* turns non-boxed square left of label into a circle */
            border-color: ${Colours.STONE_DARKEST};
        }

        > .checkbox-label {
            padding-top: 2px; /* visually the label looks off-centred, this is to combat that */
            color: ${Colours.BLACKBERRY_LIGHT};
        }

        &:hover {
            color: ${Colours.BLUEBERRY_DARK};
            border-color: ${Colours.BLUEBERRY_DARK};
            box-shadow: ${BOX_SHADOW_STONE};

            > .checkbox-visual {
                border-color: ${Colours.BLUEBERRY_DARK};
            }

            > .checkbox-label {
                color: ${Colours.BLUEBERRY_DARK};
            }
        }

        &:active {
            border-color: ${Colours.BLUEBERRY_DARKEST};
            background-color: ${ACTIVE_BACKGROUND_COLOUR};

            > .checkbox-label {
                color: ${Colours.BLUEBERRY_DARKEST};
            }
        }

        ${props.isFocused
        ? `
            box-shadow: ${BOX_SHADOW_BLUEBERRY};
        `
        : ''}

        ${props.checked
        ? `
            ${props.variant === CHECKBOX_VARIANTS.multiple
        ? `
            border-color: ${Colours.BLUEBERRY_DARK};
            background-color: ${Colours.BLUEBERRY_LIGHTEST};
        `
        : `
            border-color: transparent;
            background-color: transparent;
        `}

            > .checkbox-visual {
                background-color: ${Colours.BLUEBERRY_DARK};
                border-color: ${Colours.BLUEBERRY_DARK};
            }

            > .checkbox-label {
                color: ${Colours.BLUEBERRY_DARK};
            }
        `
        : ''}

        ${props.isDisabled
        ? `
            &,
            &:hover {
                ${props.variant === CHECKBOX_VARIANTS.multiple
        ? `
            border-color: ${Colours.STONE};
            background-color: ${Colours.COCONUT};
        `
        : `
            border-color: transparent;
            background-color: transparent;
        `}
                box-shadow: none;

                cursor: default;

                > .checkbox-visual {
                    border-color: ${Colours.STONE};
                }

                > .checkbox-label {
                    color: ${Colours.STONE};
                }
            }
        `
        : ''}
    `}
`;
