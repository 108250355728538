import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';

import { TABLE_STYLES, TABLE_STYLES_CSS_MAP } from '../styles/TableStyles';


function TableRawHTML({
    title,
    subtitle,
    children,
    tableStyle,
    size,
    className,
    ...otherProps
}) {
    const isMinimal = size?.width < 500;
    const activeStyle = TABLE_STYLES_CSS_MAP[tableStyle];

    return (
        <div className={className}>
            <If condition={title || subtitle}>
                <If condition={title}>
                    <p
                        className={
                            'rh-text-xl weight-medium rh-mt-0 rh-mb-0_5'
                        }
                    >
                        {title}
                    </p>
                </If>

                <If condition={subtitle}>
                    <p className={'rh-text-s rh-mt-0 rh-mb-0_5'}>
                        {subtitle}
                    </p>
                </If>
            </If>

            <TableContainer
                hasFooter
                hasHeader
                hasFixedLayout
                activeStyle={isMinimal ? TABLE_STYLES_CSS_MAP['minimal'] : activeStyle}
                {...otherProps}
            >
                {children}
            </TableContainer>
        </div>
    );
}

TableRawHTML.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.node, // children not required in order to support dangerouslySetInnerHTML content from CMS
    hasFixedLayout: PropTypes.bool,
    hasFooter: PropTypes.bool,
    hasHeader: PropTypes.bool,
    size: PropTypes.object.isRequired,
    tableStyle: PropTypes.oneOf(Object.values(TABLE_STYLES)),
    className: PropTypes.string,
};

TableRawHTML.defaultProps = {
    title: undefined,
    subtitle: undefined,
    children: undefined,
    hasFixedLayout: false,
    hasFooter: false,
    hasHeader: false,
    tableStyle: TABLE_STYLES.DEFAULT,
    className: undefined,
};

const TableContainer = styled.div`
    margin: 0;

    table {
        strong {
            font-weight: 500;
        }

        table-layout: ${props => (props.hasFixedLayout ? 'fixed' : 'auto')};

        &.alignleft {
            width: auto;

            margin-left: 0;
            margin-right: auto;
        }

        &.aligncenter {
            width: auto;

            margin-left: auto;
            margin-right: auto;
        }

        &.alignright {
            width: auto;

            margin-left: auto;
            margin-right: 0;
        }

        &.alignwide {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }

        &.alignfull {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            /* When the table is full width, we don't need horizontal
                padding on these elements because the table will automatically
                adjust the spacing between columns */
            th,
            td  {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    th,
    td {
        &.has-text-align-left {
            text-align: left;
        }

        &.has-text-align-center {
            text-align: center;
        }

        &.has-text-align-right {
            text-align: right;
        }
    }

    ${props => props.activeStyle};
`;

export default withSize()(TableRawHTML);
