import React from 'react';
import PropTypes from 'prop-types';


/**
 * Wordpress core block which is used to group blocks together.
 * In this implementation we assume it's a dumb container and don't support any other props.
 */

function WordpressCoreGroup({ children }) {
    return (
        <>
            {children}
        </>
    );
}

WordpressCoreGroup.propTypes = {
    children: PropTypes.any,
};

WordpressCoreGroup.defaultProps = {
    children: undefined,
};

WordpressCoreGroup.blockKey = 'core/group';

export default WordpressCoreGroup;

