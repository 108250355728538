import Locales from '../definitions/Locales';


export default function getTermsURL(locale) {
    switch (locale) {
        case Locales.ENGLISH:
            return '/terms';

        case Locales.FRENCH:
            return '/conditions';

        default:
            throw new RangeError(`[getTermsURL] unrecognized locale ${locale}`);
    }
}
