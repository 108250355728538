import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import {
    Colours,
    Sizes,
    Paragraph,
    Typography,
    ZIndex,
    MessagePropType,
    useSession,
} from '@ratehub/base-ui';
import { SiteSettings } from '@ratehub/web-components';


const MIN_WIDTH_BREAKPOINT = '(min-width: 1023px)';
const NUBBIN_SIZE = Sizes.SPACING.THREE_QUARTERS;

function HeaderUserAccountMenuDropdown({
    id,
    navItems,

    isDropDownOpen,
    setIsDropDownOpen,

    userGreetingText,
    signOutText,
}) {
    const sessionStore = useSession();

    function handleClick() {
        setIsDropDownOpen(false);
    }

    async function handleSignout() {
        // do not refetch Session in logout function
        // user will get redirected, and get new Session in the home page
        if (!(await sessionStore.logout(false))) {
            return;
        }

        handleClick();

        document.location.href = '/';
    }

    return (
        <Container
            id={id}
            aria-expanded={isDropDownOpen}
            nubbinSize={NUBBIN_SIZE}
        >
            <ul className="menuList">
                <li className="menuListItem">
                    <p className={classNames('userGreeting', Typography.CLASSES.TITLE_S)}>
                        {userGreetingText}
                    </p>
                </li>

                <For
                    each="item"
                    index="index"
                    of={navItems}
                >
                    <li
                        className="menuListItem"
                        key={index}
                    >
                        <Paragraph
                            className="interactiveMenuItem"
                            href={item.href}
                            data-dropdownlink={true}
                            onClick={handleClick}
                            forwardedAs="a"
                            message={item.label}
                        />
                    </li>
                </For>

                <li className="menuListItem">
                    <Paragraph
                        className="interactiveMenuItem"
                        weight="regular"
                        data-dropdownlink={true}
                        onClick={handleSignout}
                        forwardedAs="button"
                        message={signOutText}
                    />
                </li>
            </ul>
        </Container>
    );
}

HeaderUserAccountMenuDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            label: MessagePropType,
        }),
    ).isRequired,

    isDropDownOpen: PropTypes.bool.isRequired,
    setIsDropDownOpen: PropTypes.func.isRequired,

    userGreetingText: PropTypes.string.isRequired,
    signOutText: PropTypes.string.isRequired,
};

/* STYLE CONSTS */
const TRANSITION_TIMING_LONG = '0.4s';
const TRANSITION_EASING_EASE = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

const slideDownAndFadeIn = keyframes`
  from {
      transform: translateY(-3%);
      opacity:  0;
  }
  to {
    transform: translateY(0%);
      opacity: 1;
  }
`;

const Container = styled.div`
    display: none;
    width: 100%;
    height: 100%;

    animation: ${slideDownAndFadeIn} ${TRANSITION_TIMING_LONG} forwards ${TRANSITION_EASING_EASE};

    &[aria-expanded='true'] {
        display: block;
	    background-color: ${Colours.COCONUT};
    }

    position: fixed;
    top: ${Sizes.SPACING.FOUR};
    left: 0;
    z-index: ${ZIndex.PRIMARY_NAVIGATION};

    background-color: transparent;

    transition: background-color 0.5s ease-out;

    @media ${MIN_WIDTH_BREAKPOINT} {
        width: 18rem;
        height: auto;

        position: absolute;
        top: 3.25rem;
        right: 0;
        left: auto;

        border: 1px solid ${Colours.STONE};
        border-radius: 0.5em;

        /* CSS triangle AKA nubbin */
        &::before,
        &::after {
            content: '';

            display: block;
            width: 0;
            height: 0;

            position: absolute;

            border-style: solid;
            border-color: transparent;
            border-top: 0;
        }

        /* Stroke */
        &::before {
            top: calc(-${props => props.nubbinSize} - 1px);
            right: calc(${props => props.nubbinSize} - 1px);

            border-bottom-color: ${Colours.STONE};
            border-width: ${props => props.nubbinSize};
        }

        /* Fill */
        &::after {
            top: calc(-${props => props.nubbinSize} + 1px);
            right: ${props => props.nubbinSize};

            border-bottom-color: ${Colours.COCONUT};
            border-width: calc(${props => props.nubbinSize} - 1px);
        }
    }

    > .menuList {
        display: -ms-grid;
        display: grid;
        /* template columns same as Header's mega/mobile navigation menus */
        grid-template-columns: auto calc(${SiteSettings.NARROW_CONTENT_AREA} - 3.4rem) auto;

        list-style: none;

        padding-left: 0;
        padding-top: ${Sizes.SPACING.ONE_AND_A_HALF};
        padding-bottom: ${Sizes.SPACING.ONE_AND_A_HALF};

        margin-top: 0;
        margin-bottom: 0;

        @media ${MIN_WIDTH_BREAKPOINT} {
            display: block;
        }

        > .menuListItem {
            -ms-grid-column: 2;
            grid-column: 2;

            > .userGreeting {
                display: block;

                margin-top: 0;

                @media ${MIN_WIDTH_BREAKPOINT} {
                    display: none;

                    font-size: ${Sizes.FONTS['2XS']};
                    font-weight: ${Typography.WEIGHTS.REGULAR};

                    margin-bottom: 0;
                    margin-right: ${Sizes.SPACING.THREE_QUARTERS};
                }
            }

            /* Rendered as either an <a> or a <button> */
            > .interactiveMenuItem {
                box-sizing: border-box;
                width: 100%;

                display: flex;
                align-items: center;

                text-decoration: none;
                text-align: left;

                background-color: ${Colours.TRANSPARENT};

                border-width: 0;

                margin-top: 0;
                margin-bottom: 0;

                padding: ${Sizes.SPACING.HALF} 0;

                outline: none;

                &:hover,
                &:focus {
                    font-weight: ${Typography.WEIGHTS.MEDIUM};
                    color: ${Colours.BLUEBERRY};
                }

                &:active {
                    font-weight: ${Typography.WEIGHTS.MEDIUM};
                    color: ${Colours.BLUEBERRY_DARK};
                }

                @media ${MIN_WIDTH_BREAKPOINT} {
                    padding-left: ${Sizes.SPACING.TWO};
                    padding-right: ${Sizes.SPACING.TWO};

                    &:hover,
                    &:focus {
                        color: ${Colours.BLUEBERRY};
                        background-color: ${rgba(Colours.BLUEBERRY_LIGHT, 0.3)};
                    }
                }
            }
        }
    }
`;

export default observer(HeaderUserAccountMenuDropdown);
export {
    TRANSITION_TIMING_LONG,
    TRANSITION_EASING_EASE,
};
