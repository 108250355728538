import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
    isElementVisible,
    Colours,
    Sizes,
    getLanguageFromLocale,
    noticeError,
    Config,
    usePageSettings,
    BusinessUnits,
    BusinessUnitsFR,
    AdTypes,
    isCMS,
} from '@ratehub/base-ui';

import PageTypes from '../definitions/PageTypes';
import AdSizes from '../definitions/AdSizes';


// Developer's note:
// If you want to see additional information about the ad slots, attach ?google_console=1 as a query param to any page.
// Example: http://localhost:3000/meilleurs-taux-hypothecaires?google_console=1

function Ad({ type, targeting, overrideAdIndex, adSize, ...otherProps }) {
    const adSizesForType = AdSizes[type];

    const [ adSizeRendered, setAdSizeRendered ] = useState(adSize);
    const containerRef = useRef(null);

    // Once we determine a size of ad to render, it cannot be changed.
    useEffect(() => {
        if (adSize != null && adSizeRendered == null && adSize !== adSizeRendered) {
            setAdSizeRendered(adSize);
        }
    }, [ adSize, adSizeRendered ]);

    // BUSINESS REQUIREMENT: we should NOT make actual calls in the WP editor.
    if (isCMS() || !Config.ENABLE_ADS) {
        return null;
    }

    // Run our hook which will take care of registering our slot within DFP.
    const id = useDFPSlot({
        type,
        overrideAdIndex,
        targeting,
        adSize: adSizeRendered,
        containerRef,
    });

    // Ensure our placeholder element has some minimum sizes to hold atleast the minimum amount of space.
    // TODO: write a media query which provides a proper min-width and min-height depending on viewport size.
    // QUESTION: Should this be loading a placeholder that matches the adSize prop that's being
    // passed to this component? Would result in behaviour change (placeholders are currently smallest supported size).
    const minWidth = `${Math.min(...adSizesForType.map(supportedSize => supportedSize[0]))}px`;
    const minHeight = `${Math.min(...adSizesForType.map(supportedSize => supportedSize[1]))}px`;

    return (
        <Container
            {...otherProps}
            id={id}
            style={{ minWidth, minHeight }} // inline style to reserve space to prevent shift once it loads
            className="rh-position-relative"
            ref={containerRef}

            // Prevents '<frame> or <iframe> elements do not have a title' error
            // in lighthouse. iframe is added by Google so we can't really
            // alter it, so this hides it from screen readers.
            aria-hidden="true"
        >
            {/* Placeholder div gets replaced by an ad when the ad sucessfully loads.
            However, placeholder will remain in place when the ad cannot load
            (e.g. when the ad is out of inventory OR if user is running an ad blocker) */}
            <div className="placeholder">
                <span className="rh-text-xs">
                    <FormattedMessage
                        id="web-components.ad.placeholder"
                        defaultMessage="advertisement"
                    />
                </span>
            </div>
        </Container>
    );
}

Ad.propTypes = {
    type: PropTypes.oneOf(Object.values(AdTypes)).isRequired,
    targeting: PropTypes.object, // key-value pairs, where the values are strings or arrays of strings

    // Allows specifying the adIndex from parent component. This component
    // falls back to determining this itself if not specified.
    overrideAdIndex: PropTypes.number,

    adSize: PropTypes.array, // width and height for the desired ad size.
};

Ad.defaultProps = {
    targeting: undefined,

    overrideAdIndex: undefined,

    adSize: undefined,
};

const Container = styled.div`
    /* Show a border around ads always. Render border inset and on top of the
    placeholder/ad so we never get double borders in the event that either one
    also has it's own border. */
    ::before {
        content: "";
        position: absolute;
        inset: 0;

        box-shadow: inset 0 0 0 1px ${Colours.STONE};
        pointer-events: none;
    }

    .placeholder {
        min-width: inherit;
        min-height: inherit;

        /* ensure borders don't make the placeholder larger than the ad */
        box-sizing: border-box;

        background-color: ${Colours.COCONUT};
        border-radius: 4px;

        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

        span {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            gap: ${Sizes.SPACING.HALF};

            &:before,
            &:after {
                display: inline-block;
                content: '';
                width: ${Sizes.SPACING.ONE};
                height: 1px;
                background-color: ${Colours.BLACKBERRY};
            }
        }
    }
`;

// Based on https://medium.com/js-dojo/how-to-implement-dfp-doubleclick-for-publishers-in-react-js-vue-js-and-amp-653bd31c6e43
// And https://www.monterail.com/blog/gpt-ads-in-spa-next.js
function useDFPSlot({ type, overrideAdIndex, targeting, adSize, containerRef }) {
    const intl = useIntl();
    const pageSettings = usePageSettings();

    const adConfig = getAdConfigFromPageSettings(pageSettings);

    // define the various targeting values
    const businessUnitCode = adConfig.adUnitSlug;
    const businessUnitTargeting = adConfig.businessUnits;
    const pageTypeTargeting = Object.values(PageTypes).includes(pageSettings.pageType)
        ? pageSettings.pageType
        : '';
    const blogTargeting = pageSettings.blogKeywords || [];
    const languageCode = getLanguageFromLocale(intl.locale);

    // These are values which the Ads team would like for campaign analysis.
    const extendedTargeting = {
        language: languageCode.toUpperCase(),
        business_unit: businessUnitTargeting,
        page_type: pageTypeTargeting,
        section: [ businessUnitTargeting, pageTypeTargeting, ...blogTargeting ], // for compatibility with ratehub/ratehub ads
        blog_keywords: blogTargeting,

        // Added so we can compare ad revenue differences between pingu and classic versions of /credit-cards
        url: typeof window !== 'undefined'
            ? window.location.pathname
            : '',

        page_id: pageSettings.pageID,
        ...targeting,
    };

    // Compute ad index:
    // Wait to generate our ad unit index until we're being rendered by the client.
    // This allow ads to be placed within experiments and not have a client/server mismatch due to Cloudflare pruning.
    const [ index, setIndex ] = useState();
    useEffect(() => {
        // BUSINESS REQUIREMENT: sequentially number (by render order) which Ad we are of our type
        // PROBLEM: if we change type post-render, we will NOT decrement our type.
        // SOLUTION: would need to decrement and store references in context?? Unsure exactly.
        const computedIndex = overrideAdIndex ?? ++pageSettings.adCounts[type];

        // ASSUMPTION: index shouldn't ever change; in theory this may work, however
        // the original expectation was that once an Ad has rendered, it won't ever change.
        if (index !== undefined && index !== computedIndex) {
            noticeError(new Error('Ad.jsx has re-registered it\'s slot'), {
                oldIndex: index,
                newIndex: computedIndex,
                newType: type,
                overrideAdIndex,
            });
        }

        setIndex(computedIndex);
    }, [ type, overrideAdIndex ]);

    // The element ID to attach to the DOM element we will have our script target.
    const id = index !== undefined
        ? `gpt-ad-${type}-${index}`
        : undefined;

    // The ad unit we want to display in this slot.
    const testPrefix = Config.ENABLE_TEST_ADS ? 'test_' : '';
    const adUnit = businessUnitCode && index !== undefined
        ? `rh_${testPrefix}${businessUnitCode}_${type}_${index}_${languageCode}`
        : undefined;
    const adUnitPath = adUnit
        ? `/57452754/${adUnit}`
        : undefined;

    // Lazy loaded ads:
    // Track the position of the ad in the viewport and once the ad is in view,
    // set isAdInView high and never set it low again (even if the user scrolls away and back).
    // Depends on id because we only want to set up observers for whitelisted ads.
    const [ isAdInView, setIsAdInView ] = useState(false);
    useEffect(() => {
        // adUnit needed to determine if we want to do lazy loading or not.
        // if isAdInView has already been set truthy, ad is already loaded and we are done here.
        if (!adUnit || isAdInView) {
            return;
        }

        // Immediately flag as in-view if we're NOT lazy loading this ad unit.
        if (!Config.LAZY_LOADED_ADS.some(adUnitPrefix => adUnitPrefix && adUnit.startsWith(adUnitPrefix))) {
            setIsAdInView(true);
            return;
        }

        // Ref needed to establish intersection observer.
        if (!containerRef.current) {
            return;
        }

        // Lazy load the ad.
        const targetElement = containerRef?.current;

        const observer = new IntersectionObserver(
            ([ entry ]) => {
                if (entry.isIntersecting) {
                    setIsAdInView(true);
                    observer?.disconnect();
                }
            },
            {
                root: null,
                threshold: 0, // *any* amount intersecting should trigger load
                rootMargin: '100px', // simulate pre-load of ad
            },
        );

        observer.observe(targetElement);
        return () => observer?.disconnect();
    }, [ adUnit, isAdInView, setIsAdInView, containerRef?.current ]);

    // Register our ad unit with the Google ad service.
    // NOTE: we are ONLY taking a dependency on ID, as we only expect to be run once. We are purposely choosing not to take
    //     a dependency on the other parts referenced in this code.
    useEffect(() => {
        // NOTE: id and adSize will not be set on initial render; wait until these have been set.
        if (!id || !adSize || !isAdInView || !adUnitPath) {
            return;
        }

        // Skip registering the ad slot if the ad (or one of it's ancestors) is display: none.
        if (!isElementVisible(`#${id}`)) {
            return;
        }

        // Grab a reference to the previously mounted library.
        // ASSUMPTION: this should get mounted within _document?
        const googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];

        // Push a Command to define a new Ad unit
        googletag.cmd.push(function() {
            // Define the slot itself.
            const slot = googletag.defineSlot(adUnitPath, adSize, id);

            // Add the targetting info.
            Object.entries(extendedTargeting).forEach(([ key, value ]) => slot.setTargeting(key, value));

            // Slot has finished being constructed; make it available to GPT.
            slot.addService(googletag.pubads());

            // This seems like general config... but was in the tutorials. Multiple calls seem OK?
            googletag.enableServices();
        });

        // After it's fully created our ad, ask it to display.
        googletag.cmd.push(function() {
            googletag.display(id);
        });
    }, [ id, adSize, isAdInView, adUnitPath ]);

    return id;
}

/**
 * Get the configuration to use for an ad, given the CMS page settings
 * @param {object} pageSettings - CMS page settings the ad is being rended within
 * @param {string} pageSettings.businessUnit - business unit of the page
 * @returns {object} config
 * @returns {string} config.adUnitSlug - slug to use for the business unit in the ad
 * @returns {string|[string]} config.businessUnits - a single string or an array of strings to indicate parent/child BU
 */
function getAdConfigFromPageSettings(pageSettings) {
    const businessUnit = pageSettings.businessUnit;

    switch (businessUnit) {

        case BusinessUnits.MORTGAGES:
        case BusinessUnitsFR.MORTGAGES:
            return {
                adUnitSlug: 'mtg',
                businessUnits: 'mortgages',
            };

            // ---
        
        case BusinessUnits.CREDIT_CARDS:
        case BusinessUnitsFR.CREDIT_CARDS:
            return {
                adUnitSlug: 'cc',
                businessUnits: 'credit-cards',
            };

        case BusinessUnits.BANKING:
        case BusinessUnitsFR.BANKING:
            return {
                adUnitSlug: 'bnk',
                businessUnits: 'banking',
            };

        case 'chequing':
        case 'comptes-cheques':
            return {
                adUnitSlug: 'bnk',
                businessUnits: [ 'banking', 'chequing' ],
            };

        case 'savings':
        case 'comptes-d-epargne':
            return {
                adUnitSlug: 'bnk',
                businessUnits: [ 'banking', 'savings' ],
            };

        case BusinessUnits.INVESTING:
        case BusinessUnitsFR.INVESTING:
            return {
                adUnitSlug: 'inv',
                businessUnits: 'investing',
            };
        
        case 'gics':
        case 'cpgs':
            return {
                adUnitSlug: 'inv',
                businessUnits: [ 'investing', 'gics' ],
            };
        
        case 'robo-advisors': 
            return {
                adUnitSlug: 'inv',
                businessUnits: [ 'investing', 'robo-advisors' ],
            };

            // ---

        case BusinessUnits.INSURANCE:
        case BusinessUnitsFR.INSURANCE:
            return {
                adUnitSlug: 'ins',
                businessUnits: 'insurance',
            };
        
        case 'auto-insurance':
        case 'assurance-automobile':
            return {
                adUnitSlug: 'ins',
                businessUnits: [ 'insurance', 'auto-insurance' ],
            };
        
        case 'condo-insurance': 
        case 'assurance-copropriete':
            return {
                adUnitSlug: 'ins',
                businessUnits: [ 'insurance', 'condo-insurance' ],
            };
        
        case 'home-insurance': 
        case 'assurance-habitation':
            return {
                adUnitSlug: 'ins',
                businessUnits: [ 'insurance', 'home-insurance' ],
            };
        
        case 'life-insurance': 
        case 'assurance-vie':
            return {
                adUnitSlug: 'ins',
                businessUnits: [ 'insurance', 'life-insurance' ],
            };
        
        case 'business-insurance': 
        case 'assurance-entreprise':
            return {
                adUnitSlug: 'ins',
                businessUnits: [ 'insurance', 'business-insurance' ],
            };
        
        case 'travel-insurance': 
        case 'assurance-voyage':
            return {
                adUnitSlug: 'ins',
                businessUnits: [ 'insurance', 'travel-insurance' ],
            };

            // ---

        default:
            return {
                adUnitSlug: 'nbu',
                businessUnits: 'general',
            };
    }
}

export default Ad;
