import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { rgba } from 'polished';

import { Sizes, Colours, usePageSettings } from '@ratehub/base-ui';
import { LayoutGlobals } from '@ratehub/base-ui/src/styles';

import { IMAGE_SELECTION_COLOUR } from '@ratehub/base-ui/src/definitions/ImageSelectionStyles';
import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import SiteSettings from '../definitions/SiteSettings';
import getTableOfContentsDataAttributes from '../functions/getTableOfContentsDataAttributes';


const BACKGROUND_GRADIENT_LEVELS = {
    NONE: 'none',
    DARK: 'dark',
    DARKER: 'darker',
};
const BACKGROUND_GRADIENT_DIRECTIONS = {
    TO_LEFT: 'to left',
    TO_RIGHT: 'to right',
};
const GRADIENT_ALPHAS = {
    [BACKGROUND_GRADIENT_LEVELS.DARK]: .33,
    [BACKGROUND_GRADIENT_LEVELS.DARKER]: .8,
};

const BOX_SHADOW_OPTIONS = {
    NONE: 'none',
    SMALL: 'small',
    LARGE: 'large',
};
const BOX_SHADOW_MAP = {
    [BOX_SHADOW_OPTIONS.NONE ] : 'none',
    [BOX_SHADOW_OPTIONS.SMALL ] : `0 0 6px 0 ${Colours.STONE}`,
    [BOX_SHADOW_OPTIONS.LARGE ] : `0 0 16px 0 ${Colours.STONE}`,
};

function LayoutRow({
    as,
    id,

    flexDirection,
    wrapDirection,
    justifyContent,
    alignItems,

    includeContentPadding,
    columnGutterSize,
    rowGutterSize,

    position,
    overflow,

    backgroundImage,
    backgroundColour,
    backgroundSize,
    backgroundPositionX,
    backgroundPositionY,
    backgroundGradientLevel,
    backgroundGradientDirection,

    borderRadius,
    boxShadow,

    tableOfContentsExclude,
    tableOfContentsTitle,

    children,
    className,
    dataName,
    ...otherProps
}) {
    const pageSettings = usePageSettings();
    const tableOfContentsAttributes = getTableOfContentsDataAttributes(pageSettings, tableOfContentsExclude, tableOfContentsTitle);

    // Background gradient
    const colorStop = backgroundGradientLevel !== BACKGROUND_GRADIENT_LEVELS.NONE
        ? `${rgba(Colours.BLACKBERRY_LIGHT, GRADIENT_ALPHAS[backgroundGradientLevel])}`
        : 'transparent';
    const backgroundGradient = backgroundGradientLevel !== BACKGROUND_GRADIENT_LEVELS.NONE
        ? `${backgroundGradientDirection}, ${colorStop}, transparent`
        : undefined;

    return (
        <Container
            as={as}
            id={id}
            flexDirection={flexDirection}
            wrapDirection={wrapDirection}
            justifyContent={justifyContent}
            alignItems={alignItems}
            includeContentPadding={includeContentPadding}
            columnGutterSize={columnGutterSize}
            rowGutterSize={rowGutterSize}
            overflow={overflow}
            backgroundImage={backgroundImage ? extractBackgroundImage(backgroundImage) : undefined}
            backgroundColour={backgroundColour}
            backgroundSize={backgroundSize}
            backgroundPositionX={backgroundPositionX}
            backgroundPositionY={backgroundPositionY}
            backgroundGradient={backgroundGradient}
            borderRadius={borderRadius}
            className={classNames(
                className,
                'rh-layout-row',
                `box-shadow-${boxShadow}`,
                {
                    // Note: static renders no className as static is default for all elements.
                    // relative positioning is needed when a gradient is present to position the shield.
                    'rh-position-relative': position === 'relative' || backgroundGradient,
                    'rh-position-fixed': position === 'fixed' && !backgroundGradient,
                    'rh-position-absolute': position === 'absolute' && !backgroundGradient,
                },
            )}
            data-name={dataName}
            {...tableOfContentsAttributes}
            {...otherProps}
        >
            <div className="flex-content">
                {children}
            </div>
        </Container>
    );
}

LayoutRow.propTypes = {
    as: PropTypes.oneOf([ 'section', 'div', 'aside' ]),
    id: PropTypes.string,

    flexDirection: PropTypes.oneOf([ 'row', 'row-reverse', 'column', 'column-reverse' ]),
    wrapDirection: PropTypes.oneOf([ 'wrap', 'wrap-reverse', 'nowrap' ]),
    justifyContent: PropTypes.oneOf([
        'normal',
        'flex-start',
        'center',
        'flex-end',
        'space-between',
        'space-around',
        'space-evenly',
        'stretch',
    ]),
    alignItems: PropTypes.oneOf([ 'normal', 'flex-start', 'center', 'flex-end', 'stretch' ]),

    includeContentPadding: PropTypes.bool,
    columnGutterSize: PropTypes.oneOf([ 0, ...Object.values(Sizes.SPACING) ]),
    rowGutterSize: PropTypes.oneOf([ 0, ...Object.values(Sizes.SPACING) ]),

    position: PropTypes.oneOf([ 'static', 'relative', 'fixed', 'absolute' ]),
    overflow: PropTypes.oneOf([ 'hidden', 'visible', 'auto', 'clip', 'scroll', 'inherit', 'initial', 'unset' ]),

    backgroundImage: ImageCollectionPropType,
    backgroundColour: PropTypes.oneOf(Object.values(Colours)),
    backgroundSize: PropTypes.string,
    backgroundPositionX: PropTypes.string,
    backgroundPositionY: PropTypes.string,
    backgroundGradientLevel: PropTypes.oneOf(Object.values(BACKGROUND_GRADIENT_LEVELS)),
    backgroundGradientDirection: PropTypes.oneOf(Object.values(BACKGROUND_GRADIENT_DIRECTIONS)),

    borderRadius: PropTypes.string,
    boxShadow: PropTypes.oneOf(Object.values(BOX_SHADOW_OPTIONS)),

    tableOfContentsExclude: PropTypes.bool,
    tableOfContentsTitle: PropTypes.string,

    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    dataName: PropTypes.string,
};

LayoutRow.defaultProps = {
    as: 'section',
    id: undefined,

    includeContentPadding: true,
    columnGutterSize: 0,
    rowGutterSize: 0,

    flexDirection: 'row',
    wrapDirection: 'wrap',
    justifyContent: 'normal',
    alignItems: 'normal',

    position: 'static',
    overflow: 'visible',

    backgroundImage: undefined,
    backgroundColour: Colours.TRANSPARENT,
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
    backgroundGradientLevel: BACKGROUND_GRADIENT_LEVELS.NONE,
    backgroundGradientDirection: BACKGROUND_GRADIENT_DIRECTIONS.TO_LEFT,

    borderRadius: undefined,
    boxShadow: BOX_SHADOW_OPTIONS.NONE,

    tableOfContentsExclude: false,
    tableOfContentsTitle: undefined,

    className: '',
    dataName: undefined,
};

const Container = styled.div`
    /* Typically not placed in flex contexts but should support non-flex, flex
    column AND flex-row contexts.

    Flex-basis (whether this is width or height) is be determined by the content.
    However we will set a width of 100% to ensure the LayoutRow takes up the full
    horizontal space available to it, regardless of the type of context. */
    width: 100%;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    box-sizing: border-box;

    // Centring the content when we're trying to pull right leaves a gap on the left side of the component
    ${props => !props.className.includes('rh-layout-pull-right') && `
        justify-content: center;
    `}

    margin-left: auto;
    margin-right: auto;

    background-color: ${props => props.backgroundColour};

    ${props => props.backgroundImage && `
        background-image: url(${props.backgroundImage});
        background-size: ${props.backgroundSize};
        background-position: ${`${props.backgroundPositionX} ${props.backgroundPositionY}`};
        background-repeat: no-repeat;
    `}

    // Shield/gradient
    ${props => props.backgroundGradient && `
        &::before {
            content: "";

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-image: linear-gradient(${props.backgroundGradient});

            mix-blend-mode: multiply;
        }
    `}

    border-radius: ${props => props.borderRadius};

    overflow-x: ${props => props.overflow};

    &.box-shadow-small {
        box-shadow: ${BOX_SHADOW_MAP[BOX_SHADOW_OPTIONS.SMALL]};
    }

    &.box-shadow-large {
        box-shadow: ${BOX_SHADOW_MAP[BOX_SHADOW_OPTIONS.LARGE]};
    }

    *::selection {
        /* The selection ignores opacity 1 so we have to set it to 0.99
            to get the look we want */
        background-color: ${props => props.backgroundColour === Colours.BLUEBERRY_LIGHT ? rgba(Colours.BLUEBERRY, 0.99): rgba(Colours.BLUEBERRY_LIGHT, 0.99)};
        color: ${Colours.BLACKBERRY};
    }

    /* Image selection defined separately because an opacity of 0.99 makes the image invisible when highlighted */
    img::selection {
        background-color: ${IMAGE_SELECTION_COLOUR};
    }

    > .flex-content {
        display: flex;
        justify-content: ${props => props.justifyContent};
        flex-wrap: ${props => props.wrapDirection};
        /* Same deal as the outer container - let flex-basis get set by the content,
        but take up the full WIDTH of the outer container always. */
        flex-basis: auto;
        width: 100%;
        flex-direction: ${props => props.flexDirection};
        align-items: ${props => props.alignItems};

        column-gap: ${props => props.columnGutterSize};
        row-gap: ${props => props.rowGutterSize};

        max-width: ${LayoutGlobals.CONTENT_MAX_WIDTH};
        box-sizing: border-box;

        padding-left: ${props => (props.includeContentPadding ? SiteSettings.CHROME_BASE_PADDING : '0')};
        padding-right: ${props => (props.includeContentPadding ? SiteSettings.CHROME_BASE_PADDING : '0')};

        ${props => props.includeContentPadding && `
            ${SiteSettings.CHROME_EXTENDED_PADDING_QUERY};
        `}
    }
`;

LayoutRow.blockKey = 'rh/layout-row';
LayoutRow.hasSpacingOptions = true;

// XL size is 1400px
function extractBackgroundImage(imageCollection) {
    return imageCollection.sizes?.XL?.url || imageCollection.sizes?.full?.url;
}

export default LayoutRow;
export { BACKGROUND_GRADIENT_LEVELS, BACKGROUND_GRADIENT_DIRECTIONS };
