import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function AccordionContainer({ heading, variant, children, className }) {
    return (
        <div
            className={classNames('rh-max-width-800px rh-mx-auto', className)}
        >
            <HeadingBlock
                variant={variant}
                {...heading}
            />

            {children}
        </div>
    );
}

AccordionContainer.propTypes = {
    heading: HeadingPropTypes,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

AccordionContainer.defaultProps = {
    heading: undefined,
    variant: VARIANTS.DARK,
    className: undefined,
};

AccordionContainer.blockKey = 'rh/accordion-container';
AccordionContainer.hasHeading = true;
AccordionContainer.requiresLayoutRow = true;


export default AccordionContainer;
