import { PROVINCE_OPTIONS } from '../definitions/Provinces';
import renderMessage from './renderMessage';


export default function getSortedProvinceOptions(intl) {
    return PROVINCE_OPTIONS
        .map(province => ({
            value: province.value,
            label: renderMessage(province.label, intl).children,
        }))
        .sort((a, b) => a.label < b.label ? -1 : 1);
}
