import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import Colours from '../definitions/Colours';


function AnimatedExpandingContainer({
    isVisible,
    children,

    duration = 500,
    withColour = false,
    alwaysRenderChildMarkup = false,
    isInitiallyExpanded = false,

    onBegin,
    onEnd,

    ...otherProps
}) {
    return (
        <CSSTransition
            in={isVisible}
            timeout={duration}
            withColour={withColour}
            classNames="expanding"
            mountOnEnter={!alwaysRenderChildMarkup}
            unmountOnExit={!alwaysRenderChildMarkup}
            onEnter={() => onBegin && onBegin(isVisible)}
            onExit={() => onBegin && onBegin(isVisible)}
            onEntered={() => onEnd && onEnd(isVisible)}
            onExited={() => onEnd && onEnd(isVisible)}
        >
            <Container
                duration={duration}
                alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                isInitiallyExpanded={isInitiallyExpanded}
                isVisible={isVisible}
                aria-hidden={!isVisible}
                {...otherProps}
            >
                {children}
            </Container>
        </CSSTransition>
    );
}

AnimatedExpandingContainer.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    duration: PropTypes.number,
    withColour: PropTypes.bool,

    onBegin: PropTypes.func,
    onEnd: PropTypes.func,

    alwaysRenderChildMarkup: PropTypes.bool,
    isInitiallyExpanded: PropTypes.bool,

    children: PropTypes.node.isRequired,
};


const MAX_ANIMATION_HEIGHT = '100vh';


const Container = styled.div`
    /* Mount state: only hide if we're not transitioning */
    ${props => props.alwaysRenderChildMarkup
        && !props.isVisible
        && `
        &:not(.expanding-enter-active):not(.expanding-exit):not(.expanding-exit-active) {
            display: none;
        }
    `}

    /* Expanding-in: enter -> enter-active -> enter-done */
    &.expanding-enter {
        opacity: 0;
        max-height: 0;
        ${props => props.withColour && `
            background-color: ${Colours.BLUEBERRY_LIGHT};
       `}
    }
    &.expanding-enter-active {
        opacity: 1;
        transition: all ${props => props.duration}ms ease-in;
        max-height: ${MAX_ANIMATION_HEIGHT};
        overflow: hidden; /* Don't want content to exceed height of container while transitioning */
        ${props => props.withColour && `
            background-color: transparent;
        `}
    }
    &.expanding-enter-done {
        opacity: 1;
        max-height: fit-content;
    }

    /* Expanding-out: exit -> exit-active -> exit-done */
    &.expanding-exit {
        opacity: 1;
        max-height: ${MAX_ANIMATION_HEIGHT};
    }
    &.expanding-exit-active {
        opacity: 1;
        max-height: 0;
        transition: all ${props => props.duration}ms ease-out;
        overflow: hidden; /* Don't want content to exceed height of container while transitioning */
        ${props => props.withColour && `
            background-color: ${Colours.BLUEBERRY_LIGHTEST};
        `}
    }
    &.expanding-exit-done {
        opacity: 1;
        max-height: fit-content;
    }
`;


export default AnimatedExpandingContainer;
