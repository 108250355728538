import { Routes } from '@ratehub/base-ui';


export default {
    myQuotes: {
        id: 'myQuotes',
        route: Routes.MY_QUOTE_ROUTE,
        fullRoute: Routes.MY_QUOTE_ROUTE,
    },
    mortgage: {
        id: 'mortgage',
        route: '/mortgage',
        fullRoute: '/my-quotes/mortgage',
    },
    autoInsurance: {
        id: 'auto',
        route: '/auto-insurance',
        fullRoute: '/my-quotes/auto-insurance',
    },
    homeInsurance: {
        id: 'mortgage',
        route: '/home-insurance',
        fullRoute: '/my-quotes/home-insurance',
    },
    creditCard: {
        id: 'creditCard',
        route: '/credit-card',
        fullRoute: '/my-quotes/credit-card',
    },
    signUpWithLink: {
        id: 'signUpWithLink',
        route: '/sign-up/:magiclink/:starttab',
        fullRoute: '/sign-up/:magicLink/:starttab',
    },
    profileDetails: {
        id: 'profileDetails',
        route: Routes.PROFILE_DETAILS_ROUTE,
        fullRoute: Routes.PROFILE_DETAILS_ROUTE,
    },
};
