import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import { observer } from 'mobx-react-lite';

import { FormattedMessage, useIntl } from 'react-intl';
import { MONTH_DAY_YEAR_FORMAT } from '../definitions/DateFormats';
import toDate from '../functions/toDate';
import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import InputController from './InputController';
import DateInputSeparateInputs from './DateInputSeparateInputs';
import DateInputMasked from './DateInputMasked';


dayjs.extend(CustomParseFormat);

function DateInput({
    name,
    value,
    minAllowedDate,
    maxAllowedDate,
    defaultErrorMessage,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    dateFormat,

    isRequired,
    isDisabled,

    canShowInvalid,
    isSeparateInputs,

    onCreateField,
    
    ...otherProps
}) {
    const getParsedDate = useCallback(
        dateStringOrDate => parseDate(dateStringOrDate, dateFormat),
        [ dateFormat ],
    );
    
    const getFormattedDate = useCallback(
        date => formatDate(date, dateFormat),
        [ dateFormat ],
    );

    const getErrorMessage = useCallback(
        parsedDate => {
            const parsedMinAllowedDate = getParsedDate(minAllowedDate);

            if (parsedMinAllowedDate != null && parsedDate < parsedMinAllowedDate) {
                return false;
            }

            const parsedMaxAllowedDate = getParsedDate(maxAllowedDate);

            if (parsedMaxAllowedDate != null && parsedDate > parsedMaxAllowedDate) {
                return false;
            }
        },
        [ getParsedDate, minAllowedDate, maxAllowedDate ],
    );

    const intl = useIntl();

    return (
        <InputController 
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            getParsedValue={getParsedDate}
            getFormattedValue={getFormattedDate}
            getErrorMessageByValue={getErrorMessage}
            isRequired={isRequired}
            isDisabled={isDisabled}
            defaultErrorMessage={defaultErrorMessage
                ? messageToString(defaultErrorMessage, intl)
                : DEFAULT_ERROR_MESSAGE}
            canShowInvalid={canShowInvalid}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                const sharedProps = {
                    ...inputProps,

                    dateFormat,
                    
                    errorMessage: inputState.errorMessage,
                    isInvalid: inputState.isInvalid,
                    shouldShowInvalid: inputState.shouldShowInvalid,
                };

                return (
                    <Choose>
                        <When condition={isSeparateInputs}>
                            <DateInputSeparateInputs 
                                {...sharedProps}
                            />
                        </When>
                        <Otherwise>
                            <DateInputMasked 
                                {...sharedProps}
                            />
                        </Otherwise>
                    </Choose>
                );
            }}
        </InputController>
    );
}

/**
 * @typedef {Date | dayjs.Dayjs | string} ParsableDate
 */
const DatePropType = PropTypes.oneOfType([
    PropTypes.instanceOf(Date), // preferred
    PropTypes.instanceOf(dayjs), // convenience
    // primarily intended for legacy support (must match dateFormat exactly)
    // eg. dateFormat = "MM/DD/YYYY", value = "10/01/2021"
    PropTypes.string,
]);

DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: DatePropType,
    dateFormat: PropTypes.string,
    minAllowedDate: DatePropType,
    maxAllowedDate: DatePropType,
    defaultErrorMessage: MessagePropType,
    
    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    
    canShowInvalid: PropTypes.bool,
    isSeparateInputs: PropTypes.bool,

    onCreateField: PropTypes.func,
};

DateInput.defaultProps = {
    value: undefined,
    dateFormat: MONTH_DAY_YEAR_FORMAT,
    minAllowedDate: undefined,
    maxAllowedDate: undefined,
    defaultErrorMessage: undefined,

    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onFocus: undefined,
    onBlur: undefined,

    isDisabled: undefined,
    isRequired: undefined,
    
    canShowInvalid: undefined,
    isSeparateInputs: false,

    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.date-input.errors.default"
        defaultMessage="Please enter a valid date"
    />
);

/**
 * 
 * @param {ParsableDate} parsableDate 
 * @param {string} dateFormat - date is parsed to same granularity as dateFormat (ie. if dateFormat is 'YYYY', then date parsed to start of year)
 * @returns {Date | null} - null if not parsable
 */
function parseDate(parsableDate, dateFormat) {
    const parsedDate = toDate(parsableDate, dateFormat);

    // Ensure date is parsed to same granularity as dateFormat
    if (parsedDate instanceof Date) {
        const scopedDate = dayjs(dayjs(parsedDate).format(dateFormat), dateFormat, true);

        return scopedDate.toDate();
    }

    return parsedDate;
}

/**
 * 
 * @param {ParsableDate} parsableDate 
 * @param {string} dateFormat 
 * @returns {string | null} - string might not be valid date if passed an invalid date string
 */
function formatDate(parsableDate, dateFormat) {
    const parsedDate = parseDate(parsableDate, dateFormat);

    return parsedDate instanceof Date
        ? dayjs(parsedDate).format(dateFormat)
        : parsableDate; // if not valid, return as-is
}

export default observer(DateInput);
