import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Colours,
} from '@ratehub/base-ui';


const WIDTHS = {
    SMALL: 4,
    MEDIUM: 8,
    LARGE: 12,
};

function HorizontalRule({
    width,
    colour,
    ...otherProps
}) {
    return (
        <Container
            width={width}
            colour={getHexColour(colour)}
            {...otherProps}
        />
    );
}

HorizontalRule.propTypes = {
    width: PropTypes.oneOf(Object.values(WIDTHS)),
    colour: PropTypes.oneOf([
        ...Object.values(Colours),
        ...Object.keys(Colours),
    ]),
};

HorizontalRule.defaultProps = {
    width: WIDTHS.MEDIUM,
    colour: Colours.BLUEBERRY,
};

function getHexColour(colour) {
    return Object.values(Colours).includes(colour)
        ? colour // is a hex value already
        : Colours[colour]; // is a colour key
}

const Container = styled.hr`
    border-style: none;
    height: 3px;
    background-color: ${props => props.colour};
    border-radius: 999px;

    width: ${props => props.width}rem;
`;

HorizontalRule.WIDTHS = WIDTHS;

HorizontalRule.blockKey = 'rh/horizontal-rule';
HorizontalRule.hasSpacingOptions = true;

export default HorizontalRule;
