/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/

function ContentAreaSlot() {
    return null;
}

ContentAreaSlot.blockKey = 'rh/content-area-slot';

export default ContentAreaSlot;
