import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import Form from './Form';
import QuestionCompact from './QuestionCompact';
import PrimaryButton from './PrimaryButton';


function EmailCaptureScreen({
    onSubmit,
    onCreateFormState,
    onClose,
}){
    const [ isSubmitted, setIsSubmitted ] = useState(false);

    function handleSubmit(event) {
        onSubmit(event);
        setIsSubmitted(true);
    }

    return (
        <Container>
            <h1
                className={classNames(Typography.CLASSES.TITLE_M, 'newEmailHeader')}
            >
                <FormattedMessage
                    id="Accounts.EmailCaptureScreen.NewEmailHeader"
                    defaultMessage="{
                        isSubmitted, select,
                            true {This feature is coming soon, we'll let you know as soon as it's available, thanks for showing interest!}
                            other {Please enter your email address to save.}
                        }"
                    values={{ isSubmitted }}
                />
            </h1>

            <Choose>
                <When condition={!isSubmitted}>
                    <Form
                        onSubmit={handleSubmit}
                        onCreateFormState={onCreateFormState}
                    >
                        <QuestionCompact
                            className="emailInputField"
                            field={{ name: 'email', type: 'email' }}
                            labelMessage={(
                                <FormattedMessage
                                    id="Accounts.EmailCaptureScreen.EmailLabel"
                                    defaultMessage="Email address"
                                />
                            )}
                            placeholder={(
                                <FormattedMessage
                                    id="Accounts.EmailCaptureScreen.EmailPlaceholder"
                                    defaultMessage="Enter email"
                                />
                            )}
                        />

                        <PrimaryButton
                            className="buttonToContinue"
                            type="submit"
                            message={(
                                <FormattedMessage
                                    id="Accounts.EmailCaptureScreen.ButtonToContinue"
                                    defaultMessage="continue"
                                />
                            )}
                        />
                    </Form>
                </When>
                <When condition={isSubmitted && onClose}>
                    <PrimaryButton
                        className="buttonToContinue"
                        type="button"
                        message={(
                            <FormattedMessage
                                id="Accounts.EmailCaptureScreen.ButtonToClose"
                                defaultMessage="close"
                            />
                        )}
                        onClick={onClose}
                    />
                </When>
            </Choose>
        </Container>
    );
}

EmailCaptureScreen.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCreateFormState: PropTypes.func,
    onClose: PropTypes.func,
};

EmailCaptureScreen.defaultProps = {
    onCreateFormState: undefined,
    onClose: undefined,
};

const Container = styled.div`
    margin: 0 auto;
    max-width: 480px;
    text-align: center;

    > .newEmailHeader {
        font-size: ${Sizes.FONTS['3XL']};
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        margin-bottom: ${Sizes.SPACING.ONE};
    }

    .emailInputField {
        margin-bottom: ${Sizes.SPACING.THREE};
    }

    > .buttonToContinue {
        width: 100%;
        max-width: 320px;
    }
`;

export default EmailCaptureScreen;
