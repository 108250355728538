import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconMinus({ outlineWidth, stroke, strokeWidth, fill, ...otherProps }) {
    return (
        <IconMinus.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            {...otherProps}
        >
            <circle 
                cx="31" 
                cy="31" 
                r="29"
            />
            <path d="M15.5 31.0312L46.5 31.0313" />
        </IconMinus.Svg>
    );
}

IconMinus.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconMinus.defaultProps = {
    outlineWidth: '2px',
    stroke: Colours.BLACKBERRY,
    strokeWidth: '2px',
    fill: Colours.TRANSPARENT,
};

IconMinus.Svg = styled.svg`
    stroke: ${props => props.strokeColour};
    stroke-width: ${props => props.strokeWidth};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconMinus;
