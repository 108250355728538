import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Typography from '../definitions/Typography';
import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import { INPUT_HORIZONTAL_PADDING, INPUT_HORIZONTAL_PADDING_CONDENSED } from '../definitions/SharedInputStyles';
import messageToString from '../functions/messageToString';
import getOptionByValue from '../functions/getSelectOptionByValue';
import ChevronDown from '../graphics/ChevronDown';
import InputStyle from '../styles/InputStyle';
import useSafeLayoutEffect from '../hooks/useSafeLayoutEffect';
import MessagePropType from '../definitions/MessagePropType';
import { useCondensedTheme } from './CondensedTheme';


const PLACEHOLDER_VALUE = '_placeholder';
const INLINE_PADDING = Sizes.SPACING.HALF;


function SelectNative({
    id,
    name,
    value,
    options,

    onChange,
    onFocus,
    onBlur,

    label,
    placeholder,
    isInvalid = false,
    shouldShowInvalid = false,
    isDisabled = false,
    isRequired,
    isInline = false,

    className,
    ...otherProps
}) {
    const intl = useIntl();
    const isCondensed = useCondensedTheme();

    /* For the inline select, HTML selects will default their width to the
        length of the longest option. Our inline select requires that the width
        is as small as the selected option. To do this, we need to calculate a width
        based on the text of the selected option since we do not have access to an
        option's width. */
    const [ inlineSelectWidth, setInlineSelectWidth ] = useState('auto');
    const selectedOption = getOptionByValue(options, value);

    // If we're inline, we want to calculate the select's width based on the selected option's label
    useSafeLayoutEffect(() => {
        if (isInline) {
            setInlineSelectWidth(getInlineSelectWidth(selectedOption?.label));
        }
    }, [ isInline, selectedOption?.label ]);

    function handleChange(e) {
        onChange(e.target.value);

        if (isInline) {
            setInlineSelectWidth(
                getInlineSelectWidth(getOptionByValue(options, e.target.value)?.label),
            );
        }
    }

    const shouldShowPlaceholder = placeholder && value == null;

    return (
        <Container
            className={classNames({
                'is-inline': isInline,
                'has-label': !!label,
                'has-value': !!value,
                'is-invalid': shouldShowInvalid,
                'is-disabled': isDisabled,
            })}
            $inlineSelectWidth={inlineSelectWidth}
        >
            <If condition={label}>
                <label
                    className="label rh-text-m rh-text-align-left"
                    htmlFor={id ?? name}
                >
                    {messageToString(label, intl)}
                </label>
            </If>

            <select
                id={id}
                name={name}
                /* value has to be set to our placeholder value for the placeholder
                    to show as the selected option if we do not have a value */
                value={shouldShowPlaceholder ? PLACEHOLDER_VALUE : value}
                className={classNames('select-native input', className, {
                    'has-placeholder': shouldShowPlaceholder,
                    'is-condensed': isCondensed,
                })}

                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}

                disabled={isDisabled}
                required={isRequired}

                data-invalid={isInvalid ? 'true' : undefined}
                aria-invalid={shouldShowInvalid ? 'true' : undefined}

                {...otherProps}
            >
                {/* Unfortunately, Selects don't offer real placeholders and will default to the first option.
                    We have to set it  as a default selected hidden option so it shows
                    up as a placeholder, but is not in the list of rendered options. */}
                <If condition={placeholder}>
                    <option
                        value={PLACEHOLDER_VALUE}
                        disabled
                        hidden
                    >
                        {placeholder}
                    </option>
                </If>

                <For
                    each="option"
                    of={options}
                >
                    <Choose>
                        <When condition={option.options}>
                            <optgroup
                                className="select-option"
                                label={option.label}
                                key={option.label}
                            >
                                <For
                                    each="innerOption"
                                    of={option.options}
                                >
                                    <option
                                        className="select-option"
                                        key={innerOption.value}
                                        value={innerOption.value}
                                        disabled={innerOption.disabled}
                                    >
                                        {messageToString(innerOption.label, intl)}
                                    </option>
                                </For>
                            </optgroup>
                        </When>
                        <Otherwise>
                            <option
                                className="select-option"
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                            >
                                {messageToString(option.label, intl)}
                            </option>
                        </Otherwise>
                    </Choose>
                </For>
            </select>

            <ChevronDown
                className="chevron-icon"
            />
        </Container>
    );
}

SelectNative.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any.isRequired,
            disabled: PropTypes.bool,
        }),
        PropTypes.object, // mobx observable array
        PropTypes.array,
    ]).isRequired,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    label: MessagePropType,
    placeholder: PropTypes.node,
    isInvalid: PropTypes.bool,
    shouldShowInvalid: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isInline: PropTypes.bool,

    className: PropTypes.string,
};


function getInlineSelectWidth(selectedOptionLabel) {
    // If we have no label, just go back to the largest option
    if (!selectedOptionLabel) {
        return 'auto';
    }

    // To calculate the width required for our select, we're creating a
    //  div using the same font styles and positioning it off screen.
    const hiddenElement = document.createElement('div');
    hiddenElement.className = 'rh-visually-hidden rh-text-l rh-font-family-gordita weight-medium';

    // We're setting our hidden element's text to match the selected option.
    //  This is so our width can be calculated using the styles above with the correct text.
    // Label might be a FormattedMessage, we need this to be a string.
    hiddenElement.innerHTML = messageToString(selectedOptionLabel);

    // We're adding the hidden element to the body
    //  If this SelectNative component happens to be hidden via a parent with display: none,
    //  we can't get the width from any elements rendered in SelectNative. By adding the element to
    //  the body, we can ensure this element isn't hidden via display: none and can get its width.
    document.body.appendChild(hiddenElement);

    const calculatedWidth = hiddenElement.offsetWidth;

    // Remove the element now that we have its width
    document.body.removeChild(hiddenElement);

    return `${calculatedWidth}px`;
}

const CHEVRON_WIDTH = '13px';
const TRANSITION_TIME = '150ms';

const Container = styled.div`
    position: relative;

    > .select-native {
        ${InputStyle};

        /* Native select behaves weirdly with line-height set */
        line-height: unset;

        &.has-placeholder {
            color: ${Colours.STONE_DARKEST};
        }

        &:hover,
        &:focus {
            &.has-placeholder {
                color: ${Colours.BLUEBERRY_DARK};
            }

            + .chevron-icon {
                stroke: ${Colours.BLUEBERRY_DARK};
            }
        }

        > .select-option {
            color: ${Colours.BLACKBERRY};
        }

        &.is-condensed {
            /* Native select behaves weirdly with line-height set */
            line-height: unset;

            + .chevron-icon {
                right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
            }
        }
    }

    > .chevron-icon {
        width: ${CHEVRON_WIDTH};
        height: 8px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: ${INPUT_HORIZONTAL_PADDING};

        stroke: ${Colours.STONE_DARK};

        pointer-events: none;
    }

    /* Inline styles */
    &.is-inline {
        > .select-native {
            display: inline-flex;

            width: ${props => props.$inlineSelectWidth};
            height: ${Sizes.SPACING.TWO};
            line-height: unset;
            padding: 0 calc(${CHEVRON_WIDTH} + ${INLINE_PADDING}) 0 0;

            font-size: ${Sizes.FONTS.M};
            font-weight: ${Typography.WEIGHTS.MEDIUM};
            color: ${Colours.BLUEBERRY_DARK};

            border-style: solid;
            border-width: 0;
            border-bottom-width: 1px;
            border-radius: 0;
            border-color: ${Colours.BLACKBERRY};

            background-color: ${Colours.TRANSPARENT};

            cursor: default;

            box-sizing: content-box;

            &.has-placeholder {
                color: ${Colours.STONE_DARKEST};
                font-weight: ${Typography.WEIGHTS.REGULAR};
            }

            &:hover,
            &:focus {
                box-shadow: none;
                border-color: ${Colours.BLUEBERRY_DARK};
            }
        }

        > .chevron-icon {
            right: 0;
            stroke: ${Colours.BLACKBERRY};
        }
    }

    /* only apply compact label styling if we have a label */
    &.has-label {
        > .input {
            padding: ${Sizes.SPACING.ONE_AND_A_HALF} 1.125rem 0.125rem;
        }

        > .label {
            position: absolute;
            top: 50%;
            left: ${Sizes.SPACING.ONE_AND_A_HALF};
            right: ${Sizes.SPACING.ONE_AND_A_HALF}; /* Used to force truncation if text is too long */
            z-index: 10;

            transform: translateY(-50%);
            transform-origin: left top;
            transition: transform ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1), color ${TRANSITION_TIME} cubic-bezier(0.4, 0, 0.2, 1);
            will-change: transform;

            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;

            text-overflow: ellipsis;
            overflow: hidden;

            color: ${Colours.STONE_DARK};
            white-space: nowrap;

            cursor: text;
            pointer-events: none;
        }

        /* Applying this style as a negation so we don't have to choose a default color
            for when we have a value or are focused */
        /* SelectNative doesn't have a real placeholder pseudo-element so
            this ensures only the label is visible at this point.
            Other alternatives were to always show the placeholder if its available so
            the label would always show on top, but that would also require us to target
            the placeholder via CSS since some inputs have default placeholders; but since
            SelectNative does not use a real placeholder, that would also be insufficient. */
        &:not(.has-value, :focus-within) {
            > .input {
                    color: transparent;
                    transition: color ${TRANSITION_TIME};
                }
            }
        }

        :focus-within,
        &.has-value {
            > .label {
                /* Unset the right space in case the text was truncated and now can fit */
                right: unset;
                transform: translateX(-0.25rem) translateY(-100%) scale(0.75);
                color: ${Colours.BLACKBERRY};
            }
        }

        :hover {
            > .label {
                color: ${Colours.BLUEBERRY_DARK};
            }
        }

        &.is-invalid {
            > .label {
                color: ${Colours.STRAWBERRY_DARK};
            }
        }

        &.is-disabled {
            > .label {
                color: ${Colours.STONE};
            }
        }

        /* Condensed style overrides */
        &.is-condensed {
            > .input {
                padding-left: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
                padding-right: ${INPUT_HORIZONTAL_PADDING_CONDENSED};
            }

            > .label {
                left: 1.25rem;
            }

            &:focus-within,
            &.has-value {
                > .label {
                    transform: translateX(-0.25rem) translateY(-95%) scale(0.75);
                }
            }
        }
    }
`;

export default SelectNative;
