import Locales from '../definitions/Locales';


export default function getContactRatehubURL(locale) {
    switch (locale) {
        case Locales.ENGLISH:
            return '/contact-ratehub';

        case Locales.FRENCH:
            return '/contactez-nous';

        default:
            throw new RangeError(`[getContactRatehubURL] unrecognized locale ${locale}`);
    }
}