import { useState, useEffect } from 'react';
import { observable, runInAction, reaction, comparer } from 'mobx';
import _set from 'lodash.set';
import _get from 'lodash.get';
import deepClone from 'clone-deep';


function useFormState({ onSubmit, onSubmitFailed, onChange, isAutoFocusEnabled, shouldIncludeHiddenValues }) {
    const [ state, setState ] = useState(() =>
        createFormState({
            onSubmit,
            onSubmitFailed,

            isAutoFocusEnabled,
            shouldIncludeHiddenValues,
        }),
    );

    // Ensure callback stays in-sync
    state.onSubmit = onSubmit;
    state.onSubmitFailed = onSubmitFailed;

    // Begin an onChange reaction
    useEffect(() => {
        if (typeof onChange !== 'function') {
            return;
        }

        return reaction(
            // Need to touch EVERY value in the form (so mobx will trigger this on ANY value change) 
            () => deepClone(state.values),
            values => onChange(values, state),
            // structural prevents object & array type values from causing infinite loops when onChange results in other <Form> changes
            { name: 'useFormState.onChange reaction', equals: comparer.structural },
        );
    }, [ state, onChange ]);

    return [ state, params => setState(createFormState(params)) ];
}

function createFormState({ onSubmit, onSubmitFailed, isAutoFocusEnabled = false, shouldIncludeHiddenValues = false }) {
    const self = observable({
        onSubmit,
        onSubmitFailed,

        isAutoFocusEnabled,
        shouldIncludeHiddenValues,

        /* Queued actions to run as a transaction; array of functions */
        _transactions: undefined,

        fields: [],
        computeds: [], // { name, fn }
        hiddenFields: [],

        hasAttemptedSubmit: false,

        get visibleFields() {
            return self.fields.filter(field => self.hiddenFields.includes(field) === false);
        },
        /* includedFields are referenced by values() */
        get includedFields() {
            return shouldIncludeHiddenValues ? self.fields : self.visibleFields;
        },

        get isValid() {
            // only fields the user can interact with participate in this
            return self.visibleFields.every(f => f.isValid);
        },

        get values() {
            // PROBLEM:
            // - when a computed is defined, it needs our .values object to compute the result.
            // - since resolution of the fn causes the .values to change, it causes a cycle.

            // SOLUTION:
            // - instead of adding the actual value, represent each leaf as a getter.
            const result = {};

            // Add a getter to provide the .typedValue of each field.
            self.includedFields.forEach(field => {
                insertGetter(result, field.name, () => field.typedValue);
            });

            // Add a getter to provide the result of each computed.
            self.computeds.forEach(computed => {
                insertGetter(result, computed.name, () => computed.fn(self.values, self));
            });

            // Prevent accidental mutation; would cause VERY difficult to find bugs.
            // NOTE: if 'use strict' is on, it'll throw. Otherwise it's just a no-op....
            Object.freeze(result);

            return result;
        },
        get hasTransactions() {
            return self._transactions?.length > 0;
        },

        getFieldNamed(name) {
            return self.fields.find(f => f.name === name);
        },
        getComputedNamed(name) {
            return self.computeds.find(f => f.name === name);
        },
        getValue(name) {
            return _get(self.values, name);
        },
        hasValueNamed(name) {
            return !!self.getFieldNamed(name) || !!self.getComputedNamed(name);
        },
        isFieldVisible(name) {
            return !!self.visibleFields.find(field => field.name === name);
        },

        addField(field) {
            self._applyAction(() => {
                // REQUIREMENT: name must be unique.
                if (self.hasValueNamed(field.name)) {
                    throw new RangeError(
                        `Attempted to add a field named "${field.name}", but a value with that name already exists`,
                    );
                }

                field.setForm(self); // register field with form context

                self.fields.push(field);
            });
        },
        removeField(field) {
            self._applyAction(() => {
                self.fields.remove(field);
                self.hiddenFields.remove(field);
            });
        },
        setFieldVisibility(field, isVisible) {
            if (typeof field === 'string') {
                field = self.getFieldNamed(field);
            }
            
            self._applyAction(() => {
                if (isVisible) {
                    if (self.hiddenFields.includes(field)) {
                        self.hiddenFields.remove(field);
                    }
                } else {
                    if (!self.hiddenFields.includes(field)) {
                        self.hiddenFields.push(field);
                    }
                }
            });
        },

        addComputed(name, value) {
            // important: must be wrapped in observable to make sure we can return the reference
            const computed = observable({
                name,
                fn: null, // IMPORTANT: must initialize before we register
                setValue(newValue) {
                    computed.fn = typeof newValue === 'function' ? newValue : () => newValue;
                },
            });
            computed.setValue(value);

            self._applyAction(() => {
                // REQUIREMENT: name must be unique.
                if (self.hasValueNamed(name)) {
                    throw new RangeError(
                        `Attempted to add a computed named "${name}", but a value with that name already exists`,
                    );
                }

                self.computeds.push(computed);
            });

            // returning the reference allows calling function to interact with computed before its registered (ie. because of transactions)
            return computed;
        },
        removeComputed(name) {
            self._applyAction(() => {
                self.computeds = self.computeds.filter(c => c.name !== name);
            });
        },
        setComputed(name, value) {
            const computed = self.getComputedNamed(name);

            // The most common cause of this is updating a computed after a Component has been unmounted
            // (most common in reaction or async function).
            // Choosing to warn rather than throw for now.
            if (computed == null) {
                // eslint-disable-next-line no-console
                console.warn(`useFormState.setComputed called on missing computed: "${name}"`);
                return;
            }

            computed.fn = typeof value === 'function' ? value : () => value;
        },
        setValue(name, value) {
            const field = self.getFieldNamed(name);

            if (field == null) {
                throw new RangeError(`useFormState.setValue unable to find a field named "${name}"`);
            }

            field.setValue(value);
        },
        touchVisibleFields() {
            /* only looks at fields the user can interact with */
            self.visibleFields.forEach(field => field.setTouched(true));
        },

        async submit() {
            // REQUIREMENT: force every required & empty _visible_ field to show as invalid
            self.touchVisibleFields();
            self.hasAttemptedSubmit = true;

            if (!self.isValid) {
                self.onSubmitFailed?.();
                return false;
            }

            // NOTE: pass the submit a COPY of the values. 
            // It's a common pattern for the caller to do some light mutation of the payload.
            const result = await self.onSubmit?.(deepClone(self.values), self);

            if (result === false) {
                self.onSubmitFailed?.();
            }

            return result !== false;
        },

        /**
         * Begin batching all mutations to our .fields and .computeds into an array which can be run within a mobx action context.
         * This can lead to MUCH better performance when dealing with large numbers of fields, as computed values are not re-evaluated after every add/remove
         */
        startTransaction() {
            // SPECIAL CASE: a transaction is already open; do nothing.
            // TODO: perhaps this should throw to figure out why it was attempted?
            if (self._transactions != null) {
                return;
            }

            self._transactions = [];
        },
        /**
         * End the currently active transaction and run all batched mutations within a mobx action context
         */
        endTransaction() {
            try {
                // OPTIMIZATION: bail if there is no active transaction.
                // NOTE: this NEEDS to be within the try otherwise our finally block won't trigger, and we'll never properly "end" our transaction
                if (!self.hasTransactions) {
                    return;
                }

                // Run each member of the transaction
                runInAction(() => {
                    self._transactions.forEach(o => o());
                });
            } finally {
                self._transactions = undefined;
            }
        },
        /**
         * If we have an open transaction, queue the argument within the transaction.
         * If we don't have an open transaction, run it immediately within a mobx action context.
         * @param {function} action
         */
        _applyAction(action) {
            if (self._transactions) {
                self._transactions.push(action);
            } else {
                runInAction(action);
            }
        },
    });

    return self;
}

/**
 * Insert a new getter into an object.
 * @param {object} obj
 * @param {string} name
 * @param {function} fn
 */
function insertGetter(obj, path, fn) {
    const pathParts = path.split('.');
    const leafName = pathParts.pop(); // NOTE: mutates array.
    const pathToLeaf = pathParts.join('.');

    // BUG: we cannot currently support leaf nodes which are array entries.
    // See https://ratehub.atlassian.net/browse/FIT-3117 for the work item & proposed solution.
    if (leafName.includes('[')) {
        throw new Error('We cannot currently support leaf nodes being arrays. If it becomes necessary, prioritize FIT-3117');
    }

    // Insert a PLACEHOLDER (null) at the desired location.
    // _set will create any necessary sub-objects along the way.
    _set(obj, path, null);

    // Get a reference to the PARENT of the placeholder.
    const placeholderParent = pathToLeaf.length === 0 ? obj : _get(obj, pathToLeaf);
    
    // Replace the placeholder with a getter.
    delete placeholderParent[leafName];

    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/delete
    Object.defineProperty(placeholderParent, leafName, { get: fn, enumerable: true, configurable: true }); // mark as configurable can always delete ownable property

    // Allow for call chaining (ex: within a .reduce)
    return obj;
}

export default useFormState;
