import React, { useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dynamic from 'next/dynamic';

import {
    useModal,
    trackHeapEvent,
    CircleSpinner,
} from '@ratehub/base-ui';

import navigateAndConvertLead from '../functions/navigateAndConvertLead';





// Importing will cause the entire mortgage document model + MST to be imported.
// This hook is used on quite a few SEO landing pages, where we want to keep the bundle size as small as possible.
// Since this is also shown in a Modal, having a slight delay in rendering the contents is generally fine (it's not part of the landing experience)
const MortgageLeadForm = dynamic(() => import ('../components/MortgageLeadForm'), {
    loading: CircleSpinner,
    ssr: false,
});

/**
 * Creates a callback which can be used to apply for a mortgage rate.
 * Handles CPA/CPC links and shows a lead form when necessary
 */
function useApplyForRateCallback() {
    const intl = useIntl();
    const modal = useModal();

    return useCallback(
        ({ rate, rateFetchingOptions, onCTAClick, ...otherProps }) => {
            // handle CPC or CPA redirects
            if (!shouldShowLeadForm(rate)) {
                return navigateAndConvertLead({
                    rate,
                    // location is a legacy key; city is preferred
                    city: rateFetchingOptions?.city ?? rateFetchingOptions?.location,
                    locale: intl.locale,
                });
            }

            // TODO: analytics needs an event so they can track whether the lead form is open or not
            // TODO: we should likely do ^ within the actual <Modal> itself, instead of within this hook. It makes it easier to do the right thing

            // Show the <MortgageLeadForm> in the desired shape.
            // Note the layer ID we were assigned incase we want to programmatically close it in our onCTAClick callback.
            const modalLayerID = modal.open({
                isContentFullWidth: true,
                showBranding: true,
                verticalAlignment: 'flex-start',

                screenReaderLabel: (
                    <FormattedMessage
                        id="mtg-common.useApplyForRateCallback.screenReaderLabel"
                        defaultMessage="Rate inquiry modal"
                    />
                ),

                // react-sizeme has an internal check which is causing it to fail if we render it here, and only pass a React.node.
                // WORKAROUND: passing it inside a lambda seems to get around this issue.
                children: () => (
                    <MortgageLeadForm
                        {...otherProps}

                        rate={rate}
                        rateFetchingOptions={rateFetchingOptions}

                        onCTAClick={() => {
                            modal.close(modalLayerID);
                            onCTAClick?.();
                        }}
                    />
                ),
            });

            trackHeapEvent('Lead Form Open', { rate });

        },
        [ intl.locale, modal ],
    );
}

function shouldShowLeadForm(rate) {
    return !rate.isFeatured // Show non-monetized lead form
        || !rate.cpcLink && !rate.cpaLink; // show monetized lead form
}

export default useApplyForRateCallback;
