import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import useFormState from '../hooks/useFormState';
import getNextFormFocusElement from '../functions/getNextFormFocusElement';
import focusFirstInputWithin from '../functions/focusFirstInputWithin';
import getFirstInvalidInput from '../functions/getFirstInvalidInput';
import scrollToFirstInvalidField from '../functions/scrollToFirstInvalidField';


const Context = React.createContext();
const FORM_KEY = '__rhForm';

function Form({
    onSubmit,
    onSubmitFailed,
    onChange,
    onCreateFormState,

    isAutoFocusEnabled,
    shouldIncludeHiddenValues,
    shouldOnlyButtonSubmit,
    shouldUseTransactions,

    children,
    ...otherProps
}) {
    const [ state ] = useFormState({
        onSubmit,
        onSubmitFailed: handleSubmitFailed,
        onChange,
        
        isAutoFocusEnabled,
        shouldIncludeHiddenValues,
    });
    const formRef = useRef();

    // Raise our state for outside use.
    useEffect(
        () => {
            onCreateFormState?.(state);
        },
        [ state, onCreateFormState ],
    );

    // On mount & if auto focus is enabled
    //  we focus the first input in the form to kick off auto focus
    useEffect(() => {
        if (isAutoFocusEnabled) {
            const firstQuestion = getNextFormFocusElement();
            firstQuestion && focusFirstInputWithin(firstQuestion);
        }
    }, []);

    // If configured to do so, start a transaction within our form state.
    // Transactions allows us to batch mutations (example: adding a whole bunch of fields to a <Form>) preventing re-evaluating our computed between every addition/removal.
    // When adding/removing very large numbers of fields, this can cause pauses of multiple seconds depending on complexity.
    // NOTE: We need to start this IMMEDIATELY so our children will be rendered within this transaction. 
    // Generally it's bad practice to invoke a state mutation in a render method, but this is necessary and presently safe.
    if (shouldUseTransactions) {
        state.startTransaction();
    }
    useEffect(
        () => {
            // End any previous started transaction.
            // NOTE: if we've JUST disabled transactions, we need to end any previously existing transaction.
            if (state.hasTransactions) {
                state.endTransaction();
            }

            // Start a new transaction if enabled.
            // NOTE: since we have a dependency on state.hasTransactions, this will cause us to re-render if some part of the application causes us to mutate our field members.
            if (shouldUseTransactions) {
                state.startTransaction();
            }
        },
        [ state, state.hasTransactions, shouldUseTransactions ],
    );

    // If submit fails for whatever reason, we want to
    //  try to scroll to the first invalid input
    function handleSubmitFailed() {
        onSubmitFailed?.();

        if (formRef.current && getFirstInvalidInput(formRef.current)) {
            scrollToFirstInvalidField(formRef.current);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();     // we're an SPA, don't ACTUALLY do a <form> submit)

        state.submit();
    }

    function handleKeyPress(event) {

        // This onKeyDown is used to prevent Form submit when the Enter key is
        //  pressed on anything other than button[type=submit] as we want to
        //  maintain Enter press behaviour for our submit button.

        // Form's onSubmit doesn't receive any information about the means it was
        //  submitted (button submit vs enter press) and due to Safari only
        //  supporting document.activeElement for input elements and elements that
        //  have manually used .focus(), we have to prevent the behaviour here.

        // Our objectives with this are as follows:
        //  [x] pressing enter on any focused input should not submit form
        //  [x] pressing enter on any focused button[type=submit] should submit the form
        //  [x] pressing enter on any focused button[type=button] should not submit the form

        if (document.activeElement?.type === 'submit') {
            return true;
        }

        if (shouldOnlyButtonSubmit && event.key === 'Enter') {
            event.preventDefault();
        }
    }

    return (
        <StyledForm
            {...otherProps}
            ref={self => {
                if (self) {
                    self[FORM_KEY] = state;
                    formRef.current = self;
                }
            }}
            onSubmit={handleSubmit}
            isAutoFocusEnabled={isAutoFocusEnabled}
            onKeyPress={handleKeyPress}
            noValidate // disable HTML validation; we do our own validation
        >
            <Context.Provider value={state}>
                {typeof children === 'function'
                    ? children(state)
                    : children
                }
            </Context.Provider>
        </StyledForm>
    );
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    onSubmitFailed: PropTypes.func,
    onChange: PropTypes.func,
    onCreateFormState: PropTypes.func,

    isAutoFocusEnabled: PropTypes.bool,
    shouldIncludeHiddenValues: PropTypes.bool,
    shouldOnlyButtonSubmit: PropTypes.bool, // restrict Input submission
    shouldUseTransactions: PropTypes.bool,

    children: PropTypes.oneOfType([
        PropTypes.func, // render prop
        PropTypes.node,
    ]).isRequired,
};

Form.defaultProps = {
    onSubmit: undefined,
    onSubmitFailed: undefined,
    onChange: undefined,
    onCreateFormState: undefined,

    isAutoFocusEnabled: false,
    shouldIncludeHiddenValues: false,
    shouldOnlyButtonSubmit: true,
    shouldUseTransactions: false,
};

const StyledForm = styled.form`
    /* With auto focus we want to add space to the bottom of
        the page so our last few questions can scroll to the top. */
    ${props => props.isAutoFocusEnabled && `
        margin-bottom: 80vh;
    `}
`;

export {
    Context,
    FORM_KEY,
};
export default observer(Form);
