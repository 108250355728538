import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ElementAttributes from '../definitions/ElementAttributes';
import { useBannerContext } from './BannerProvider';
import Portal from './Portal';
import AnimatedFadeContainer from './AnimatedFadeContainer';


/* This component just provides a div in the area we want to trigger the
    sticky banner and asks useBannerContext to display the banner. */
function StickyBannerTrigger({ children, ...otherProps }) {
    const bannerContext = useBannerContext();
    const containerRef = useRef();
    
    const [ isVisible, setIsVisible ] = useState(false);

    useEffect(() => {
        const targetElement = containerRef?.current;

        if (!targetElement || !bannerContext) {
            return;
        }
        
        // REQUIREMENT: Contents of this banner only become visible once we scroll
        //  past where this component is in the viewport. We're using IO for this
        //  to notify us when we're either at the top of the viewport or leaving the
        //  top of the viewport.
        const observer = new IntersectionObserver(
            ([ entry ]) => {
                // If our boundingClientRect.top is less than or equal to zero, the user is scrolling down
                //  and we want our content to become visible. Otherwise they're scrolling upwards 
                //  above our component and we want to remain hidden.
                // Additionally, this banner is the lowest priority of all banners. If another
                //  banner is visible, then we do not want to show.
                setIsVisible(entry.boundingClientRect.top <= 0 && !bannerContext.isVisible);
            },
            {
                root: null,
                threshold: 0,
            },
        );

        observer.observe(targetElement);

        return () => observer.disconnect();
    }, [ containerRef?.current, bannerContext ]);
    
    return (
        <div
            ref={containerRef}
            {...otherProps}
        >
            <Portal id={ElementAttributes.STICKY_BANNER_PORTAL_ID}>
                <AnimatedFadeContainer 
                    isVisible={isVisible}
                    withColour={false}
                    duration={300}
                >
                    {children}
                </AnimatedFadeContainer>
            </Portal>
        </div>
    );
}

StickyBannerTrigger.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StickyBannerTrigger;