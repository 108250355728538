import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import useFormComputed from '../hooks/useFormComputed';
import FormValue from './FormValue';


function FormComputed({ name, value, hidden, dependencies, ...otherProps }) {
    // Inject our computed into the <Form>.
    useFormComputed({ name, value, dependencies });

    // Remaining code is only for presentation
    if (hidden) {
        return null;
    }

    return (
        <FormValue
            name={name}
            {...otherProps}
        />
    );
}

FormComputed.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,

    hidden: PropTypes.bool,
    dependencies: PropTypes.array,
};

FormComputed.defaultProps = {
    hidden: true,
    dependencies: undefined,
};

export default observer(FormComputed);
