import React from 'react';

import LINK_TARGETS from '../definitions/LinkTargets';


// We are using an HOC; this is the "best-practice" way to do it, but ESLint does not like it...
// eslint-disable-next-line react/prop-types
const withLink = (WrappedComponent) => ({ href, hrefLabel, target, children, ...otherProps }) => {
    const linkTarget = (target === LINK_TARGETS.NEW_TAB ? '_blank' : '_self');

    return (
        <a
            href={href}
            target={linkTarget}
            rel={linkTarget === '_blank' ? 'noopener' : ''}
            aria-label={hrefLabel}
        >
            <WrappedComponent {...otherProps}>
                {children}
            </WrappedComponent>
        </a>
    );
};

export default withLink;
