import React from 'react';
import PropTypes from 'prop-types';

import MAGIC_LINK_VARIANTS from '../definitions/MagicLinkVariants';
import MagicLinkForms from './MagicLinkForms';
import ModalContainer from './ModalContainer';


function SignInModal({
    isOpen,
    onClose,
    supportPhone,
    messages,
    hideLoginDescription,
    onRequestLinkCustom,
    emailForForcedLogin,
    magicLinkVariant,
    resumeApplicationLink,
    documentOptions,
    shouldShowNewsletter,
    shouldShowGoogleSignIn,
    ...otherProps
}) {
    return (
        <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            showBranding={true}
            contentMaxWidth="712px"
            {...otherProps}
        >
            <MagicLinkForms
                supportPhone={supportPhone}
                messages={messages}
                hideLoginDescription={hideLoginDescription}
                onRequestLinkCustom={onRequestLinkCustom}
                emailForForcedLogin={emailForForcedLogin}
                magicLinkVariant={magicLinkVariant}
                resumeApplicationLink={resumeApplicationLink}
                documentOptions={documentOptions}
                shouldShowNewsletter={shouldShowNewsletter}
                shouldShowGoogleSignIn={shouldShowGoogleSignIn}
            />
        </ModalContainer>
    );
}

SignInModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    supportPhone: PropTypes.string,
    messages: PropTypes.object,
    hideLoginDescription: PropTypes.bool,
    onRequestLinkCustom: PropTypes.func,
    emailForForcedLogin: PropTypes.string,
    magicLinkVariant: PropTypes.oneOf(Object.values(MAGIC_LINK_VARIANTS)),
    resumeApplicationLink: PropTypes.string,
    documentOptions: PropTypes.object,
    shouldShowNewsletter: PropTypes.bool,
    shouldShowGoogleSignIn: PropTypes.bool,
};

SignInModal.defaultProps = {
    isOpen: true,
    onClose: undefined,
    supportPhone: undefined,
    messages: {},
    hideLoginDescription: false,
    onRequestLinkCustom: undefined,
    emailForForcedLogin: undefined,
    magicLinkVariant: undefined,
    resumeApplicationLink: undefined,
    documentOptions: {},
    shouldShowNewsletter: false,
    shouldShowGoogleSignIn: false,
};

export default SignInModal;
