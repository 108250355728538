/**
 * Regex to test a string to see if it's a valid float. 
 * Unable to use parseFloat as it is not strict enough.
 */
export const TEST_REGEX = /^(-|\+|.)?([0-9]+(\.[0-9]*)?)$/;

/**
 * Attempt to transform the input into a number and return it.
 * @param {any} value 
 * @returns {number|null}
 */
export default function toFloat(value) {    
    if (TEST_REGEX.test(value)) {
        return Number(value);
    }
    else {
        return null;
    }
}
