/* eslint-disable max-len */
/* THIS IS GENERATED BY generateBlockMaps.js - PLEASE DO NOT EDIT */
import {
    ArticleTable,
    NewsShowcase,
    PressCentre,
} from '@ratehub/web-components';


const BLOCK_TO_COMPONENT_MAP = {
    [ArticleTable.blockKey]: { render: ArticleTable },
    [NewsShowcase.blockKey]: { render: NewsShowcase },
    [PressCentre.blockKey]: { render: PressCentre },
};

export default BLOCK_TO_COMPONENT_MAP;
