// These terms should be pulled from some kind of mtg-common definitions file when it exists
import { defineMessages } from 'react-intl';

import { SCENARIO_TYPES as MORTGAGE_SCENARIOS, MORTGAGE_EXTENDED_RATE_TYPES, MORTGAGE_RATE_TYPES } from '@ratehub/mtg-common';
import { PROVINCE, getProvincePrincipalCity } from '@ratehub/base-ui';


export const FIXED = 'fixed';
export const VARIABLE = 'variable';

// TODO: Remove these and replace with mtg-common defintions.
//  They are currenrly used by 20+ files
export const MORTGAGE_TYPES = MORTGAGE_RATE_TYPES;
export const MORTGAGE_FILTER_RATE_TYPES = MORTGAGE_EXTENDED_RATE_TYPES;

export const INSURED = 'insured';
export const INSURABLE = 'insurable';
export const UNINSURED = 'uninsured';

export const INSURABILITY_TYPES = {
    INSURED,
    INSURABLE,
    UNINSURED,
};

// 1-10 and 25 years in months
export const MORTGAGE_TERMS_ALL = 'all';
export const MORTGAGE_TERMS = [ 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 300 ];

export const DEFAULT_MORTGAGE_PROVINCE = PROVINCE.ONTARIO;
export const DEFAULT_MORTGAGE_CITY = getProvincePrincipalCity(DEFAULT_MORTGAGE_PROVINCE).slug;
export const DEFAULT_MORTGAGE_TYPE = MORTGAGE_EXTENDED_RATE_TYPES.FIXED;
export const DEFAULT_MORTGAGE_TERM = MORTGAGE_TERMS[4];
export const DEFAULT_MORTGAGE_HOME_PRICE = 400000;
export const DEFAULT_MORTGAGE_HELOC_AMOUNT = 200000;
export const DEFAULT_MORTGAGE_RENEW_BALANCE = 350000;
export const DEFAULT_MORTGAGE_REFINANCE_BALANCE = 300000;
export const DEFAULT_MORTGAGE_DOWN_PAYMENT_PERCENT = 0.05;
export const DEFAULT_MORTGAGE_AMORTIZATION = 25;
export const DEFAULT_MORTGAGE_RENEW_AMORTIZATION = 20;


export const INSURABILITY_VALUES = Object.freeze({
    [INSURED]: {
        downPaymentPercent: DEFAULT_MORTGAGE_DOWN_PAYMENT_PERCENT,
        homePrice: DEFAULT_MORTGAGE_HOME_PRICE,
    },
    [INSURABLE]: {
        downPaymentPercent: 0.2,
        homePrice: DEFAULT_MORTGAGE_HOME_PRICE,
    },
    [UNINSURED]: {
        downPaymentPercent: 0.2,
        homePrice: 1000000,
    },
});

export const Messages = defineMessages({
    [MORTGAGE_EXTENDED_RATE_TYPES.FIXED]: {
        id: 'web-components.mortgageTerms.typesFixed',
        defaultMessage: 'Fixed',
    },
    [MORTGAGE_EXTENDED_RATE_TYPES.VARIABLE]: {
        id: 'web-components.mortgageTerms.typesVariable',
        defaultMessage: 'Variable',
    },
    [MORTGAGE_EXTENDED_RATE_TYPES.FIXED_OPEN]: {
        id: 'web-component.productTableV2.rateTypes.fixedOpen',
        defaultMessage: 'Fixed - Open',
    },
    [MORTGAGE_EXTENDED_RATE_TYPES.VARIABLE_OPEN]: {
        id: 'web-component.productTableV2.rateTypes.variable-open',
        defaultMessage: 'Variable - Open',
    },
    [MORTGAGE_EXTENDED_RATE_TYPES.CASHBACK]: {
        id: 'web-component.productTableV2.rateTypes.cashback',
        defaultMessage: 'Cash Back',
    },
    [MORTGAGE_TERMS_ALL]: {
        id: 'web-components.mortgageTerms.allTerms',
        defaultMessage: 'All',
    },
    [MORTGAGE_SCENARIOS.PURCHASE]: {
        id: 'web-components.mortgageTerms.purchase',
        defaultMessage: 'Buying a home',
    },
    [MORTGAGE_SCENARIOS.RENEWAL]: {
        id: 'web-components.mortgageTerms.renewal',
        defaultMessage: 'Renewing',
    },
    [MORTGAGE_SCENARIOS.REFINANCE]: {
        id: 'web-components.mortgageTerms.heloc',
        defaultMessage: 'Refinancing',
    },
    [MORTGAGE_SCENARIOS.HELOC]: {
        id: 'web-components.mortgageTerms.home_equity',
        defaultMessage: 'Home equity line of credit',
    },
    HELOC_SHORT: {
        id: 'web-components.mortgageTerms.helocShortForm',
        defaultMessage: 'HELOC',
    },
});

export const DEFAULT_ALL_PURCHASE_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
    homePrice: DEFAULT_MORTGAGE_HOME_PRICE,
    downPaymentPercent: DEFAULT_MORTGAGE_DOWN_PAYMENT_PERCENT,
    amortization: DEFAULT_MORTGAGE_AMORTIZATION,
    isOwnerOccupied: 1,
    isCashBack: 0,
    isOpen: 0,
    type: DEFAULT_MORTGAGE_TYPE,
    term: DEFAULT_MORTGAGE_TERM,
});

export const DEFAULT_BEST_PURCHASE_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
    amortization: DEFAULT_MORTGAGE_AMORTIZATION,
    downPaymentPercent: DEFAULT_MORTGAGE_DOWN_PAYMENT_PERCENT,
    homePrice: DEFAULT_MORTGAGE_HOME_PRICE,
    isOwnerOccupied: 1,
    isCashBack: 0,
    isOpen: 0,
    type: [ DEFAULT_MORTGAGE_TYPE ],
    term: [ DEFAULT_MORTGAGE_TERM ],
});

export const DEFAULT_ALL_HELOC_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
});

export const DEFAULT_BEST_HELOC_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
});

export const DEFAULT_ALL_REFINANCE_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
    amortization: DEFAULT_MORTGAGE_AMORTIZATION,
    isOwnerOccupied: 1,
    isCashBack: 0,
    isOpen: 0,
    type: DEFAULT_MORTGAGE_TYPE,
    term: DEFAULT_MORTGAGE_TERM,
});

export const DEFAULT_BEST_REFINANCE_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
    amortization: DEFAULT_MORTGAGE_AMORTIZATION,
    isOwnerOccupied: 1,
    isCashBack: 0,
    isOpen: 0,
    type: DEFAULT_MORTGAGE_TYPE,
    term: DEFAULT_MORTGAGE_TERM,
});

export const DEFAULT_ALL_RENEW_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
    isInsured: 1,
    isOwnerOccupied: 1,
    isCashBack: 0,
    isOpen: 0,
    type: DEFAULT_MORTGAGE_TYPE,
    term: DEFAULT_MORTGAGE_TERM,
});

export const DEFAULT_BEST_RENEW_PARAMS = Object.freeze({
    province: DEFAULT_MORTGAGE_PROVINCE,
    city: DEFAULT_MORTGAGE_CITY,
    amortization: DEFAULT_MORTGAGE_RENEW_AMORTIZATION,
    isInsured: 1,
    isOwnerOccupied: 1,
    isCashBack: 0,
    isOpen: 0,
    type: DEFAULT_MORTGAGE_TYPE,
    term: DEFAULT_MORTGAGE_TERM,
});

export const ALL_MORTGAGE_SCENARIO_DEFAULT_PARAMS = Object.freeze({
    [MORTGAGE_SCENARIOS.PURCHASE]: DEFAULT_ALL_PURCHASE_PARAMS,
    [MORTGAGE_SCENARIOS.RENEWAL]: DEFAULT_ALL_RENEW_PARAMS,
    [MORTGAGE_SCENARIOS.REFINANCE]: DEFAULT_ALL_REFINANCE_PARAMS,
    [MORTGAGE_SCENARIOS.HELOC]: DEFAULT_ALL_HELOC_PARAMS,
});

export const BEST_MORTGAGE_SCENARIO_DEFAULT_PARAMS = Object.freeze({
    [MORTGAGE_SCENARIOS.PURCHASE]: DEFAULT_BEST_PURCHASE_PARAMS,
    [MORTGAGE_SCENARIOS.RENEWAL]: DEFAULT_BEST_RENEW_PARAMS,
    [MORTGAGE_SCENARIOS.REFINANCE]: DEFAULT_BEST_REFINANCE_PARAMS,
    [MORTGAGE_SCENARIOS.HELOC]: DEFAULT_BEST_HELOC_PARAMS,
});
