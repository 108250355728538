export default {
    BLOG: {
        FEATURED_ARTICLES_THUMBNAIL: {
            WIDTH: 260,
            HEIGHT: 260,
        },
        SPOTLIGHT_THUMBNAIL: {
            WIDTH: 300,
            HEIGHT: 300,
        },
    },
    CREDIT_CARDS: {
        CARD: {
            WIDTH: 200,
            HEIGHT: 126,
        },
        CARD_FEATURED_PRODUCT: {
            WIDTH: 152,
            HEIGHT: 96,
        },
        CARD_LAYOUT: {
            WIDTH: 98,
            HEIGHT: 68.5,
        },
    },
    PROVIDERS: {
        BADGE_SMALL: {
            WIDTH: 28,
            HEIGHT: 28,
        },
        BADGE_MEDIUM: {
            WIDTH: 84,
            HEIGHT: 84,
        },
    },
};
