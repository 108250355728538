import { isWidget } from '@ratehub/base-ui';

import INCENTIVE_VARIANT from '../definitions/IncentiveVariants';

/**
 * Given the boolean results of the experiments affecting the CC incentive dialogue, return the incentive variant.
 * This function exists because the logic for experimenting with the incentive dialogue has been subject to frequent change.
 *
 * @param {null|boolean} isIncentiveCompactPage
 * @return {null|string}
 */
function getIncentiveVariant({
    isIncentiveCompactPage = null,
} = {}) {
    if (isWidget()) {
        return INCENTIVE_VARIANT.dialogueBox;     // our current CONTROL
    }

    return isIncentiveCompactPage == null
        ? null
        : isIncentiveCompactPage
            ? INCENTIVE_VARIANT.compactPage
            : INCENTIVE_VARIANT.dialogueBox;
}

export default getIncentiveVariant;
