/**
 * Represents supported inputs.
 */
export default {
    ADDRESS: 'address',
    CHECKBOX: 'checkbox',
    CHECKBOX_GROUP: 'checkbox-group',
    CHECKBOX_EXPANDABLE: 'checkbox-expandable',
    CITY: 'city',
    CURRENCY: 'currency',
    DATE: 'date',
    DROPDOWN: 'dropdown',
    EMAIL: 'email',
    NUMBER: 'number',
    PERCENT: 'percent',
    POSTAL_CODE: 'postal',
    PHONE: 'phone',
    RADIO: 'radio',
    SELECTOR: 'selector',
    TEXT: 'text',
    TEXTAREA: 'textarea',
};
